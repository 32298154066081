import { TooltipKind, TooltipShowTrigger, SpsIcon } from "@spscommerce/ds-shared";
import { Position } from "@spscommerce/positioning";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { SpsTooltip, TooltipVisibility, toggleTooltipState } from "../../tooltip/SpsTooltip";
import { contentOf, spsGlobalPropTypes } from "../../util";
import { SpsFormControl } from "../hooks/formControl";
import { SpsFormComponentWrapper } from "../SpsFormComponentWrapper";

const propsDoc = {
    for: { type: "SpsFormControl<any>", required: true },
    description: "string",
    stronglySuggested: "boolean",
    help: "ReactNodeOrRenderFn",
    helpIcon: "SpsIcon",
    helpIconColor: "string",
    errors: "ReactNodeOrRenderFn",
};

const propTypes = {
    ...spsGlobalPropTypes,
    description: PropTypes.string,
    for: PropTypes.impl<SpsFormControl<any>>().isRequired,
    stronglySuggested: PropTypes.bool,
    help: PropTypes.nodeOrRenderFn,
    helpIcon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    helpIconColor: PropTypes.string,
    errors: PropTypes.nodeOrRenderFn,
};

export type SpsLabelProps = PropTypes.InferTS<typeof propTypes, HTMLLabelElement>;

export function SpsLabel(props: SpsLabelProps) {
    const {
        children,
        className,
        description,
        "for": formControl,
        stronglySuggested,
        help,
        helpIcon = SpsIcon.QUESTION_CIRCLE,
        helpIconColor = "blue300",
        errors,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const label = React.useRef<HTMLSpanElement>();
    const suggestedIcon = React.useRef<HTMLElement>();
    const helpIconRef = React.useRef<HTMLElement>();

    const [showHelpTip, setShowHelpTip] = React.useState(TooltipVisibility.HIDDEN);
    const [showErrorTip, setShowErrorTip] = React.useState(TooltipVisibility.HIDDEN);
    const [showSuggestedTip, setShowSuggestedTip] = React.useState(TooltipVisibility.HIDDEN);

    function hideTooltips() {
        setShowHelpTip(TooltipVisibility.HIDDEN);
        setShowErrorTip(TooltipVisibility.HIDDEN);
        setShowSuggestedTip(TooltipVisibility.HIDDEN);
    }

    function handleSuggestedIconClick(event) {
        event.preventDefault();
        hideTooltips();
        setShowSuggestedTip(toggleTooltipState(showSuggestedTip));
    }

    function getPriorityTooltip(): [TooltipVisibility, React.Dispatch<TooltipVisibility>] {
        return formControl && formControl.errors
            ? [showErrorTip, setShowErrorTip]
            : help
                ? [showHelpTip, setShowHelpTip]
                : [showSuggestedTip, setShowSuggestedTip];
    }

    function handleLabelClick() {
        hideTooltips();
        const [showPriorityTooltip, setShowPriorityTooltip] = getPriorityTooltip();
        setShowPriorityTooltip(toggleTooltipState(showPriorityTooltip));
    }

    function doShowPriorityTooltip() {
        hideTooltips();
        const [, setShowPriorityTooltip] = getPriorityTooltip();
        setShowPriorityTooltip(TooltipVisibility.VISIBLE);
    }

    function handleHelpClick() {
        hideTooltips();
        setShowHelpTip(toggleTooltipState(showHelpTip));
    }

    if (formControl) {
        formControl.onFocus = doShowPriorityTooltip;
        formControl.onBlur = hideTooltips;
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-form-group__label",
        className
    );

    return (
        <SpsFormComponentWrapper formControl={formControl}>
            <label htmlFor={formControl && formControl.id}
                className={classes}
                {...rest}
            >
                <i ref={suggestedIcon}
                    className={clsx(
                        "sps-icon",
                        "sps-icon-exclamation-triangle",
                        "orange200",
                        "sps-form-group__label-suggested-icon",
                        !stronglySuggested && "d-none",
                    )}
                    onClick={handleSuggestedIconClick}
                    onMouseEnter={() => { hideTooltips(); setShowSuggestedTip(TooltipVisibility.VISIBLE); }}
                    onMouseLeave={hideTooltips}
                ></i>
                <span ref={label}
                    className="sps-form-group__label-content"
                    onClick={handleLabelClick}
                    onMouseEnter={doShowPriorityTooltip}
                    onMouseLeave={hideTooltips}
                >{
                    children
                }</span>
                <i ref={helpIconRef}
                    className={clsx(
                        "sps-icon",
                        `sps-icon-${helpIcon}`,
                        helpIconColor,
                        !help && "d-none",
                    )}
                    onClick={handleHelpClick}
                    onMouseEnter={() => { hideTooltips(); setShowHelpTip(TooltipVisibility.VISIBLE); }}
                    onMouseLeave={hideTooltips}
                ></i>
            </label>
            <SpsTooltip kind={TooltipKind.DEFAULT}
                for={helpIconRef}
                showOn={TooltipShowTrigger.MANUAL}
                isShown={showHelpTip}
            >
                {contentOf(help)}
            </SpsTooltip>
            <SpsTooltip kind={TooltipKind.ERROR}
                for={label}
                showOn={TooltipShowTrigger.MANUAL}
                isShown={showErrorTip}
            >
                {contentOf(errors)}
            </SpsTooltip>
            <SpsTooltip kind={TooltipKind.DEFAULT}
                for={suggestedIcon}
                position={Position.TOP_LEFT}
                offsets="13, 20"
                showOn={TooltipShowTrigger.MANUAL}
                isShown={showSuggestedTip}
            >
                This field is strongly suggested.
            </SpsTooltip>
            {description && <div className="sps-form-control__description">{description}</div>}
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsLabel, {
    props: propsDoc,
    propTypes,
    displayName: "SpsLabel"
});
