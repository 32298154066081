import { SpsIcon, TagKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    icon: "SpsIcon",
    kind: "TagKind",
};

const propTypes = {
    ...spsGlobalPropTypes,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    kind: PropTypes.enumValue<TagKind>(TagKind),
};

export type SpsTagProps = PropTypes.InferTS<typeof propTypes, HTMLSpanElement>;

export function SpsTag(props: SpsTagProps) {
    const {
        children,
        className,
        icon,
        kind = TagKind.DEFAULT,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-badge",
        `sps-badge--${kind}`,
        icon && "sps-badge--with-icon",
        className,
    );

    return (
        <span className={classes} data-testid={`${testId}__tag`} {...rest}>
            {icon && (
                <i
                    aria-hidden="true"
                    className={`sps-icon sps-icon-${icon}`}
                    data-testid={`${testId}__icon`}
                />
            )}
            {children}
        </span>
    );
}

Object.assign(SpsTag, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTag"
});
