import { code } from "@spscommerce/utils";

export const SpsContentRowExamples = {
    basic: {
        label: "Basic",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const things = [
                            {
                                icon: SpsIcon.STATUS_ERROR,
                                title: "Title",
                                subtitle: "Subtitle",
                                stuff: "Pretend there's some stuff here.",
                            },
                            {
                                icon: SpsIcon.STATUS_OK,
                                title: "Title",
                                subtitle: "Subtitle",
                                stuff: "Pretend there's some stuff here.",
                            },
                            {
                                icon: SpsIcon.STATUS_IN_TRANSIT,
                                title: "Title",
                                subtitle: "Subtitle",
                                stuff: "Pretend there's some stuff here.",
                            },
                        ];

                        return (
                            <React.Fragment>
                                {things.map((thing, i) =>
                                    <SpsContentRow key={i}>
                                        <SpsContentRowCol>
                                            <i className={\`sps-icon sps-icon-{thing.icon} sps-icon--lg align-middle mr-2 pr-1\`}/>
                                            <div className="d-inline-block align-middle">
                                                <div className="fs-14 lh-20"><a href="#">{thing.title}</a></div>
                                                <div>{thing.subtitle}</div>
                                            </div>
                                        </SpsContentRowCol>
                                        <SpsContentRowCol style={{ width: "9.25rem" }}>
                                            {thing.stuff}
                                        </SpsContentRowCol>
                                        <SpsContentRowCol style={{ width: "3.625rem" }} leaner>
                                            <SpsDropdown options={[]}></SpsDropdown>
                                        </SpsContentRowCol>
                                    </SpsContentRow>
                                )}
                            </React.Fragment>
                        );
                    }
                `
            },
            borderlessCol: {
                description: code`<p>The horizontal border of a content row column can be removed.</p>`,
                jsx: code`
                    <SpsContentRow>
                        <SpsContentRowCol>
                            <div className="fs-14 lh-20">Heading/Title</div>
                        </SpsContentRowCol>
                        <SpsContentRowCol style={{ width: "9.25rem" }} borderless>
                            General Text Content
                        </SpsContentRowCol>
                    </SpsContentRow>
                `
            }
        }
    },
    expandable: {
        label: "Expandable",
        examples: {
            expandable: {
                react: code`
                    function DemoComponent() {
                        function handleExpand() {
                            console.log("Content row expanded");
                        }

                        function handleCollapse() {
                            console.log("Content row collapsed");
                        }

                        return (
                            <SpsContentRow onExpanded={handleExpand} onCollapsed={handleCollapse}>
                                <SpsContentRowCol>
                                    <div className="fs-14 lh-20">Heading</div>
                                </SpsContentRowCol>
                                <SpsContentRowCol style={{ width: "9.25rem" }}>
                                    General Text Content
                                </SpsContentRowCol>
                                <SpsContentRowExpansion>
                                    <SpsCard>
                                        <br/><br/><br/><br/>
                                        Additional content
                                        <br/><br/><br/><br/>
                                    </SpsCard>
                                </SpsContentRowExpansion>
                            </SpsContentRow>
                        );
                    }
                `
            }
        }
    },
    card: {
        label: "In a Card",
        examples: {
            card: {
                react: code`
                    function DemoComponent() {
                        things = [
                            {
                                title: "Title",
                                stuff: "Pretend there's some stuff here.",
                            },
                            {
                                title: "Title",
                                stuff: "Pretend there's some stuff here.",
                            },
                            {
                                title: "Title",
                                stuff: "Pretend there's some stuff here.",
                            },
                        ];

                        return (
                            <SpsCard headerTitle="Card Cap" headerIcon={SpsIcon.CHART_PIE}>
                                {things.map((thing, i) => (
                                    <SpsContentRow key={i}>
                                        <SpsContentRowCol>
                                            <div className="fs-14 lh-20">{thing.title}</div>
                                        </SpsContentRowCol>
                                        <SpsContentRowCol style={{ width: "9.25rem" }}>
                                            {thing.stuff}
                                        </SpsContentRowCol>
                                        <SpsContentRowExpansion>
                                            <SpsCard>
                                                <br/><br/>
                                                Additional content
                                                <br/><br/>
                                            </SpsCard>
                                        </SpsContentRowExpansion>
                                    </SpsContentRow>
                                ))}
                            </SpsCard>
                        );
                    }
                `
            }
        }
    },
    internalCardSpacing: {
      label: "In a Card with spacing",
      examples: {
          card: {
              react: code`
                  function DemoComponent() {
                      things = [
                          {
                              title: "Title",
                              stuff: "Pretend there's some stuff here.",
                          },
                          {
                              title: "Title",
                              stuff: "Pretend there's some stuff here.",
                          },
                          {
                              title: "Title",
                              stuff: "Pretend there's some stuff here.",
                          },
                      ];

                      return (
                          <SpsCard headerTitle="Card Cap" headerIcon={SpsIcon.CHART_PIE}>
                              {things.map((thing, i) => (
                                  <SpsContentRow cardSpacing={true} key={i}>
                                      <SpsContentRowCol>
                                          <div className="fs-14 lh-20">{thing.title}</div>
                                      </SpsContentRowCol>
                                      <SpsContentRowCol style={{ width: "9.25rem" }}>
                                          {thing.stuff}
                                      </SpsContentRowCol>
                                      <SpsContentRowExpansion>
                                          <SpsCard>
                                              <br/><br/>
                                              Additional content
                                              <br/><br/>
                                          </SpsCard>
                                      </SpsContentRowExpansion>
                                  </SpsContentRow>
                              ))}
                          </SpsCard>
                      );
                  }
              `
          }
      }
  }
};
