import { useEffect } from "react";
import nanoid from "nanoid";

import { SpsFormControl } from "./formControl";

export function useFormControlId(id: string, formControl?: SpsFormControl<any>) {
    const ctrlId = (id || nanoid()) + "_ctrl";
    useEffect(() => {
        if (id && formControl) {
            formControl.id = ctrlId;
            formControl.update();
        }
    }, [id]);

    return formControl ? formControl.id : ctrlId;
}
