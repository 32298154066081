/* tslint:disable:no-bitwise */
// @dynamic
export class Op {
    public static ADD = (a, b) => a + b;
    public static AND = (a, b) => a && b;
    public static BAND = (a, b) => a & b;
    public static BOR = (a, b) => a | b;
    public static BXOR = (a, b) => a ^ b;
    public static BNOT = a => ~a;
    public static DECR = a => --a;
    public static DIV = (a, b) => a / b;
    public static EQ = (a, b) => a === b;
    public static EXP = (a, b) => a ** b;
    public static GT = (a, b) => a > b;
    public static GTE = (a, b) => a >= b;
    public static INCR = a => ++a;
    public static LSHIFT = (a, b) => a << b;
    public static LT = (a, b) => a < b;
    public static LTE = (a, b) => a <= b;
    public static MUL = (a, b) => a * b;
    public static NEG = a => -a;
    public static NEQ = (a, b) => a !== b;
    public static NOT = a => !a;
    public static OR = (a, b) => a || b;
    public static REM = (a, b) => a % b;
    public static RSHIFT = (a, b) => a >> b;
    public static RSHIFT_ZF = (a, b) => a >>> b;
    public static SUB = (a, b) => a - b;
    public static XOR = (a, b) => (a || b) && !(a && b);
}
