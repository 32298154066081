import { SpsIcon } from "./icon";

export const GROWLER_VISIBLE_DURATION_MS = 7000;
// If you change this don't forget to also change the CSS animation
// time in ds-styles
export const GROWLER_FADEOUT_DURATION_MS = 2000;

export enum GrowlerKind {
    ACTIVITY = "activity",
    ERROR = "error",
    INFO = "info",
    PROGRESS = "progress",
    SUCCESS = "success",
    WARNING = "warning",
}

export const GrowlerIcon: Map<GrowlerKind, SpsIcon> = new Map([
    [GrowlerKind.INFO, SpsIcon.INFO_CIRCLE],
    [GrowlerKind.WARNING, SpsIcon.EXCLAMATION_TRIANGLE],
    [GrowlerKind.ERROR, SpsIcon.EXCLAMATION_CIRCLE],
    [GrowlerKind.SUCCESS, SpsIcon.CHECKMARK]
]);
