import { GrowlerKind } from "@spscommerce/ds-shared";
import * as React from "react";

import { ReactNodeOrRenderFn } from "../prop-types";
import { contentOf } from "../util";
import { SpsGrowler } from "./SpsGrowler";

export interface GrowlerConfig {
    title: string;
    content: ReactNodeOrRenderFn;
    kind?: GrowlerKind;
    imgSrc?: string;
    onClose?: () => void;
    _closed?: boolean;
}

function handleGrowlerClose(growler: GrowlerConfig) {
    if (growler.onClose) {
        growler.onClose();
    }
    growler._closed = true;
}

export function useGrowlers(): [() => JSX.Element[], (config: GrowlerConfig) => void] {
    const [growlerList, setGrowlerList] = React.useState<GrowlerConfig[]>([]);

    function growlers() {
        return growlerList.map((g, i) =>
        <SpsGrowler title={g.title} kind={g.kind} imgSrc={g.imgSrc}
          onClose={() => handleGrowlerClose(g)} key={i}>{
            contentOf(g.content)
        }</SpsGrowler>);
    }

    const createGrowler = React.useCallback((config: GrowlerConfig) => {
        config.kind = config.kind || GrowlerKind.INFO;
        setGrowlerList(currentList => {
            const l = currentList.some(g => !g._closed) ? currentList : [];
            return [...l, config];
        });
    }, []);

    return [growlers, createGrowler];
}
