import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsPageSubtitleProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsPageSubtitle(props: SpsPageSubtitleProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-page-title__subtitle",
        className,
    );

    return (
        <div className={classes} data-testid={testId} {...rest}>
            {children}
        </div>
    );
}

Object.assign(SpsPageSubtitle, {
    props: propsDoc,
    propTypes,
    displayName: "SpsPageSubtitle"
});
