import { code, mergePropertiesDeep } from "@spscommerce/utils";

export const NgSpsInsightCardExamples = {
  basic: {
    label: "Basic Insight Cards",
    examples: {
      basic: {
        template: code`
            <sps-insights>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                ></sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                ></sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                ></sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                ></sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                ></sps-insight-card>
            </sps-insights>
        `
      },
    }
  },
  details: {
    label: "With Details",
    examples: {
      details: {
        template: code`
            <sps-insights>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
            </sps-insights>
        `
      },
    }
  },
  partner_count: {
    label: "Partner Count",
    examples: {
      partner_count: {
        template: code`
            <sps-insights>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="250"
                    totalPartners="250"
                ></sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="122"
                    totalPartners="250"
                ></sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="207"
                    totalPartners="250"
                ></sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="12"
                    totalPartners="250"
                ></sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="94"
                    totalPartners="250"
                ></sps-insight-card>
            </sps-insights>
        `
      },
    }
  },
  expansion: {
    label: "10+ Insight Cards",
    examples: {
      expansion: {
        template: code`
            <sps-insights>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="general"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="processing"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="success"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
                <sps-insight-card kind="warning"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                ></sps-insight-card>
                <sps-insight-card kind="error"
                    metric="1234"
                    title="Insight Card Title"
                    partnerCount="100"
                    totalPartners="200"
                >
                    <span class="gray600 font-weight-bold">Detail:</span> 792 units
                </sps-insight-card>
            </sps-insights>
        `
      },
    }
  },
};

export const ReactSpsInsightCardExamples = mergePropertiesDeep({}, NgSpsInsightCardExamples, {
    basic: {
        examples: {
            basic: {
                jsx: NgSpsInsightCardExamples.basic.examples.basic.template,
                template: undefined,
            }
        }
    },
    details: {
        examples: {
            details: {
                jsx: NgSpsInsightCardExamples.details.examples.details.template.replace(/class=/g, "className="),
                template: undefined,
            }
        }
    },
    partner_count: {
        examples: {
            partner_count: {
                jsx: NgSpsInsightCardExamples.partner_count.examples.partner_count.template,
                template: undefined,
            }
        }
    },
    expansion: {
        examples: {
            expansion: {
                jsx: NgSpsInsightCardExamples.expansion.examples.expansion.template.replace(/class=/g, "className="),
                template: undefined,
            }
        }
    },
    events: {
        label: "Adding a click handler",
        description: code`
            <p>Attaching event handlers to native web components in React cannot be done the usual way at this time.
            Here is how to do it:</p>
        `,
        examples: {
            events: {
                react: code`
                    function Component() {
                        function handleClick() {
                            window.alert("Insight card clicked");
                        }

                        const ref = React.useRef();
                        React.useEffect(() => {
                            ref.current.addEventListener("click", handleClick);
                            return () => {
                                ref.current.removeEventListener("click", handleClick);
                            };
                        }, []);

                        return (
                            <sps-insights>
                                <sps-insight-card ref={ref}
                                    kind="general"
                                    metric="1234"
                                    title="Insight Card Title"
                                ></sps-insight-card>
                                <sps-insight-card kind="general"
                                    metric="1234"
                                    title="Insight Card Title"
                                ></sps-insight-card>
                                <sps-insight-card kind="general"
                                    metric="1234"
                                    title="Insight Card Title"
                                ></sps-insight-card>
                            </sps-insights>
                        );
                    }
                `
            }
        }
    }
});
