import { DropdownKind, SPS_ACTION_DEFAULTS, SpsActionDescriptor, SpsActionMethod, SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import { SpsOptionList } from "../option-list/SpsOptionList";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    alignLeft: "boolean",
    disabled: "boolean",
    icon: "SpsIcon",
    kind: "DropdownKind",
    label: "string",
    options: "Array<SpsActionMethod | [SpsActionDescriptor, () => void]>",
    spinning: "boolean",
    spinningTitle: "string",
    onOpen: "() => void",
    onClose: "() => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    alignLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    kind: PropTypes.enumValue<DropdownKind>(DropdownKind),
    label: PropTypes.string,
    options: PropTypes.arrayOf<SpsActionMethod | [SpsActionDescriptor, () => void]>(
        PropTypes.oneOfType([PropTypes.func, PropTypes.any])
    ).isRequired,
    spinning: PropTypes.bool,
    spinningTitle: PropTypes.string,
    onOpen: PropTypes.fun<() => void>(),
    onClose: PropTypes.fun<() => void>(),
};

let idNum = 0;

export type SpsDropdownProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsDropdown(props: SpsDropdownProps) {
    const {
        alignLeft,
        className,
        disabled,
        icon: iconProp,
        id = `sps-dropdown-${idNum++}`,
        kind: kindProp,
        label,
        options: optionsProp = [],
        tabIndex,
        spinning,
        spinningTitle,
        unsafelyReplaceClassName,
        onOpen,
        onClose,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const [isOpen, setIsOpen] = React.useState(false);
    const [opensUpward, setOpensUpward] = React.useState(false);
    const [keyDown, setKeyDown] = React.useState(null);
    const options = optionsProp.map(o =>
        typeof o === "function" ? o : Object.assign(o[1], {
            ...SPS_ACTION_DEFAULTS,
            ...o[0]
        })
    );

    const buttonId = `${id}-button`;
    const menuId = `${id}-menu`;

    let icon, kind;
    if (label) {
        kind = kindProp || DropdownKind.DEFAULT;
        icon = iconProp;
    } else {
        kind = DropdownKind.ICON;
        icon = iconProp || SpsIcon.ELLIPSES;
    }

    const buttonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (isOpen && onOpen) {
            onOpen();
        } else if (!isOpen && onClose) {
            onClose();
        }
    }, [isOpen]);

    function handleDocumentClick() {
        setIsOpen(false);
    }

    function handleButtonClick(event: React.MouseEvent) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        if (!disabled && !spinning) {
            setIsOpen(!isOpen);
        }
    }

    function handleSelfToggle(olIsOpen) {
        setIsOpen(olIsOpen);
    }

    function handleOptionSelected(option) {
        setIsOpen(false);
    }
    function handleKeyDown(event: React.KeyboardEvent) {
        if (!disabled) {
            if (["Up", "ArrowUp", "Down", "ArrowDown"].indexOf(event.key) > -1) {
                event.preventDefault();
            }
            event.persist();
            setKeyDown(event);
        }
    }

    React.useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, []);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-dropdown",
        isOpen && "sps-dropdown--open",
        isOpen && "z-stratum-dropdown",
        opensUpward && "sps-dropdown--opens-upward",
        !label && "sps-dropdown--no-label",
        disabled && "disabled",
        className,
    );

    return (
        <div id={id} className={classes} {...rest} onKeyDown={handleKeyDown}>
            <SpsOptionList isOpen={isOpen}
                aria-labelledby={buttonId}
                keyDown={keyDown}
                onOptionSelected={handleOptionSelected}
                alignLeft={alignLeft}
                id={menuId}
                attachTo={buttonRef}
                conformWidth={kind !== DropdownKind.ICON}
                onPositionFlip={setOpensUpward}
                onSelfToggle={handleSelfToggle}
                options={options}
                offsets={[1, 1]}>
            </SpsOptionList>
            <div onClick={handleButtonClick} className={clsx("sps-btn", `sps-btn--${kind}`, disabled && "disabled", { 'sps-btn--spinning': spinning })}>
                <button ref={buttonRef}
                    id={buttonId}
                    aria-haspopup="true"
                    aria-controls={menuId}
                    aria-expanded={isOpen ? true : null}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    onClick={handleButtonClick}
                >
                    <i className={clsx("sps-icon", `sps-icon-${icon}`)}></i>
                    {label && <span>{label}</span>}
                    {spinning && (<div className="sps-spinner" key={1}>{spinningTitle || t("design-system:button.spinningTitle")}</div>)}
                </button>
            </div>
        </div>
    );
}

Object.assign(SpsDropdown, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDropdown"
});
