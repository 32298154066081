import { code } from "@spscommerce/utils";

export const SpsAutocompleteExamples = {
    basic: {
        label: "Basic",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const [color, setColor] = useState<string>();
                        const colors = ["red", "blue", "green"];

                        function handleChange(event) {
                            setColor(event.target.value);
                        }

                        return (
                            <SpsAutocomplete suggestions={colors}
                                value={color}
                                onChange={handleChange}
                                placeholder="Enter a color"
                            />
                        );
                    }
                `
            },
            disabled: {
                jsx: code`
                    <SpsAutocomplete suggestions={["red", "blue", "green"]} value="yellow" disabled id="disabledAutocomplete"/>
                `
            },
            form: {
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"];

                        const form = useForm(formGroup({ color: formControl(colors[0]) }));

                        return (
                            <SpsForm form={form}>
                                <div className="sps-row">
                                    <div className="col-4">
                                        <SpsLabel for={form.get("color")}>Color</SpsLabel>
                                        <SpsAutocomplete suggestions={colors} formControl={form.get("color")} tabIndex={1}/>
                                    </div>
                                    <div className="col-8">
                                        <br/>
                                        <p>Color: {form.get("color").value}</p>
                                    </div>
                                </div>
                            </SpsForm>
                        )
                    }
                `
            },
        }
    },
    async: {
        label: "Async Autocomplete",
        examples: {
            func: {
                react: code`
                    function DemoComponent() {
                        const [color, setColor] = useState<string>();
                        function colorSearch(search: string) {
                            return ["red", "blue", "green"];
                        }

                        function handleChange(event) {
                            setColor(event.target.value);
                        }

                        return (
                            <SpsAutocomplete suggestions={colorSearch} value={color} onChange={handleChange} placeholder="Enter a color"/>
                        );
                    }
                `
            },
            debounce: {
                react: code`
                    function DemoComponent() {
                        const [color, setColor] = useState<string>();
                        function colorSearch(search: string) {
                            return ["red", "blue", "green"];
                        }

                        function handleChange(event) {
                            setColor(event.target.value);
                        }

                        return (
                            <SpsAutocomplete suggestions={colorSearch} debounce={1000} value={color} onChange={handleChange} placeholder="Enter a color"/>
                        );
                    }
                `
            },
            promise: {
                react: code`
                    function DemoComponent() {
                        const [color, setColor] = useState<string>();
                        function colorSearch(search: string) {
                            return new Promise((resolve, reject) => {
                                resolve(["red", "blue", "green"]);
                            });
                        }

                        function handleChange(event) {
                            setColor(event.target.value);
                        }

                        return (
                            <SpsAutocomplete suggestions={colorSearch} value={color} onChange={handleChange} placeholder="Enter a color"/>
                        );
                    }
                `
            }
        }
    }
};
