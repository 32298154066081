/**
 * Enqueues the given function to run on the next "tick" of the JS event
 * loop cycle. If you ever encounter the "value changed after it was
 * checked" error in Angular when trying to assign a value of a
 * component class, performing that assignment on the next tick, i.e. when
 * all of Angular's current cycle is finished, will resolve that error.
 */
export function onNextTick(fn: (...args: any[]) => any, thisArg?: any): void {
    fn = thisArg ? fn.bind(thisArg) : fn;
    setTimeout(fn, 0);
}
