import { code } from "@spscommerce/utils";

export const SpsProductBarExamples = {
    basic: {
        label: "Product Bar",
        description: code`
            <p>Product navigation bar</p>
        `,
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const navTabs = ["Foo", "Bar", "Baz"];
                        function handleTabChange(newTab: string) {
                            alert(newTab);
                        }

                        return (
                            <SpsProductBar productName="My Product"
                                productLogoSrc="https://cdn.prod.spsc.io/web/framework/assets/17.08.01/icons/cube/cube.svg"
                                tabs={navTabs}
                                activeTab="Bar"
                                onTabChange={handleTabChange}
                                fullWidth
                                style={{ position: "relative" }} // display this example in its place instead of at the top
                            ><SpsButton>do the thing</SpsButton></SpsProductBar>
                        );
                    }
                `
            },
        }
    }
};
