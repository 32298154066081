// This import nonsense is because ng-packagr uses rollup and rollup sucks
import * as isPlainObjectImport from "lodash.isplainobject";
const isPlainObject = (<any>isPlainObjectImport).default || isPlainObjectImport;

/**
 * Recursive merge of any number of objects
 */
export function mergeDeep(...objectsToMerge: any[]): any {
    const result = {};
    for (const mergee of objectsToMerge) {
        for (const key of Object.keys(mergee)) {
            if (result.hasOwnProperty(key)
                && Object.getOwnPropertyDescriptor(result, key).writable
                && isPlainObject(result[key])
            ) {
                result[key] = mergeDeep(result[key], mergee[key]);
            } else if (isPlainObject(mergee[key])) {
                result[key] = mergeDeep({}, mergee[key]);
            } else {
                result[key] = mergee[key];
            }
        }
    }
    return result;
}
