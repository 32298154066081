import { TooltipKind, TooltipShowTrigger } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import { SpsFormControl } from "../form/hooks/formControl";
import * as PropTypes from "../prop-types";
import { SpsTooltip, TooltipVisibility } from "../tooltip/SpsTooltip";
import { contentOf, spsGlobalPropTypes } from "../util";

const propsDoc = {
    error: "boolean",
    errors: "ReactNodeOrRenderFn",
    help: "ReactNodeOrRenderFn",
    labelFor: "SpsFormControl<any>",
    required: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    error: PropTypes.bool,
    errors: PropTypes.nodeOrRenderFn,
    help: PropTypes.nodeOrRenderFn,
    labelFor: PropTypes.impl<SpsFormControl<any>>(),
    required: PropTypes.bool,
};

export type SpsDescriptionListTermProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsDescriptionListTerm(props: SpsDescriptionListTermProps) {
    const {
        children,
        className,
        error,
        errors,
        help,
        "labelFor": formControl,
        required,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const dtElement = React.useRef<HTMLElement>();
    const [tipKind, setTipKind] = React.useState<TooltipKind>();
    const [showTip, setShowTip] = React.useState(TooltipVisibility.HIDDEN);

    React.useEffect(() => {
        setTipKind(formControl && formControl.errors ? TooltipKind.ERROR : TooltipKind.HELP);
    });

    if (formControl) {
        formControl.onFocus = () => setShowTip(TooltipVisibility.VISIBLE);
        formControl.onBlur = () => setShowTip(TooltipVisibility.HIDDEN);
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-description-list__term",
        (required || (formControl && formControl.isRequired())) && "sps-description-list__term--required",
        (error || (formControl && !formControl.isPristine() && formControl.invalid)) && "sps-description-list__term--error",
        className,
    );

    return <>
        <dt className={classes} data-testid={`${testId}`}
            ref={dtElement}
            onMouseEnter={() => setShowTip(TooltipVisibility.VISIBLE)}
            onMouseLeave={() => setShowTip(TooltipVisibility.DELAYED_HIDDEN)}
            onClick={() => setShowTip(showTip === TooltipVisibility.VISIBLE ? TooltipVisibility.HIDDEN : TooltipVisibility.VISIBLE)}
            {...rest}
        >
            {children}
        </dt>
        <SpsTooltip kind={tipKind} for={dtElement} showOn={TooltipShowTrigger.MANUAL} isShown={showTip}>
            {contentOf(tipKind === TooltipKind.ERROR ? errors : help)}
        </SpsTooltip>
    </>;
}

Object.assign(SpsDescriptionListTerm, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionListTerm / SpsDt"
});

export function SpsDt(p: SpsDescriptionListTermProps) {
    return SpsDescriptionListTerm(p);
}

Object.assign(SpsDt, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionListTerm / SpsDt"
});
