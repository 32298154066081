import clsx from "clsx";
import * as React from "react";
import { ButtonKind, SpsIcon } from "@spscommerce/ds-shared";

import { SpsButton } from "../button/SpsButton";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, selectChildren } from "../util";
import { SpsFocusedTaskActions } from "./SpsFocusedTaskActions";
import { I18nContext } from "../i18n";

const propsDoc = {
    isOpen: "boolean",
    onClose: "() => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    isOpen: PropTypes.bool,
    onClose: PropTypes.fun<() => void>()
};

export type SpsFocusedTaskProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsFocusedTask(props: SpsFocusedTaskProps) {
    const {
        children,
        className,
        isOpen,
        onClose,
        title,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-focused-task",
        "z-stratum-dialog",
        isOpen && "sps-focused-task--open",
        className,
    );

    const [
        actions,
        content,
    ] = selectChildren(children, [
        { type: SpsFocusedTaskActions },
    ]);

    return (
        <div className={classes} data-testid={testId} {...rest}>
            <div className="sps-focused-task__title">
                <SpsButton className="sps-focused-task__close-btn"
                    kind={ButtonKind.ICON}
                    icon={SpsIcon.X}
                    onClick={onClose}
                    title={t("design-system:focusedTask.close")}
                />
            </div>
            <div className="sps-page">
                {isOpen && content}
            </div>
            {actions}
        </div>
    );
}

Object.assign(SpsFocusedTask, {
    props: propsDoc,
    propTypes,
    displayName: "SpsFocusedTask",
});
