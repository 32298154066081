import { SortChangeHandler, SortedColumn } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsTableHead, SpsThead } from "./SpsTableHead";

const propsDoc = {
    sort: "SortedColumn",
    onSortChange: "SortChangeHandler",
    selectable: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    onSortChange: PropTypes.fun<SortChangeHandler>(),
    sort: PropTypes.arrayOf(PropTypes.impl<SortedColumn>()),
    selectable: PropTypes.bool
};

export type SpsTableProps = PropTypes.InferTS<typeof propTypes, HTMLTableElement>;

export function SpsTable(props: SpsTableProps) {
    const {
        children,
        className,
        onSortChange,
        sort,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [currentSort, setSort] = React.useState(sort);

    const updateSortingDisplay = (newSort) => {
        const sortedColumn = newSort && newSort[0];
        React.Children.map(children, (child: React.ReactElement<any>, index) => {
            return React.Children.map(child.props.children, (c: React.ReactElement<any>) => {
                return React.Children.map(c.props.children, (c2: any) => {
                    if (sortedColumn && (c2.props.sortKey && c2.props.sortKey === sortedColumn.key)) {
                        setSort([sortedColumn]);
                    }
                });
            });
        });
    };

    const sortChange = (newSort: SortedColumn[]) => {
        setSort(newSort);
        onSortChange(newSort);
        updateSortingDisplay(newSort);
    };

    const newProps = {
        onSortChange: sortChange,
        currentSort: currentSort
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-table-container",
        className,
    );

    return (
        <div className={classes}>
            <table className="sps-table"
                role="table"
                data-testid={`${testId}`}
                {...rest}
            >
                {
                    React.Children.map(children, (child: any) => {
                        if (child.type === SpsTableHead || child.type === SpsThead) {
                            return React.cloneElement(child, newProps);
                        }
                        return child;
                    })
                }
            </table>
        </div>
    );
}

Object.assign(SpsTable, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTable"
});
