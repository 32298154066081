/** @jsx h */
import { SpsInsightCardKind, SpsInsightCardIcons } from "@spscommerce/ds-shared";
import { ClassBindings, Component, Content, Prop, QuerySelector } from "../../decorators/index";
import { h } from "../../utils/pragma";

const CPT_NAME = "sps-insight-card";

@Component({ tag: CPT_NAME })
export class SpsInsightCardComponent extends HTMLElement {
    static readonly displayName = CPT_NAME;
    static readonly props = {
        kind: "SpsInsightCardKind",
        title: "string",
        metric: "number",
        partnerCount: "number",
        totalPartners: "number",
        secondary: "boolean",
    };

    /** Specifies which of the visual styling variants of the Insight Card to use. */
    @Prop() kind = SpsInsightCardKind.GENERAL;
    @Prop() title: string;
    /** The main number representing the metric that the Insight Card is intended to surface. */
    @Prop() metric: number;
    /** The number of partners this insight applies to. Must be used together with `totalPartners`. */
    @Prop() partnerCount: number;
    /** The total number of partners. Must be used together with `partnerCount`. */
    @Prop() totalPartners: number;
    /** Whether this is a "secondary" Insight Card, meaning it will be displayed in a compact layout. */
    @Prop() secondary: boolean;

    @Content() private content;
    @QuerySelector(`.${CPT_NAME}__detail`) private detail;

    get [ClassBindings]() {
        return [
            CPT_NAME,
            `${CPT_NAME}--${this.kind}`,
            this.detail && this.detail.children.length ? `${CPT_NAME}--has-detail` : null,
        ];
    }

    render({ t }) {
        const metric = <div className={`${CPT_NAME}__metric-count`}>{this.metric}</div>;
        const description = (
            <div className={`${CPT_NAME}__description`}>
                <div className={`${CPT_NAME}__title`}
                    // Both Chrome & FF ignore -webkit-box-orient in the actual CSS for some reason
                    style={{ "-webkit-box-orient": "vertical" }}>{
                    this.title
                }</div>
                <div className={`${CPT_NAME}__detail`}>{this.content}</div>
            </div>
        );

        const [count, of_, total] = (
            this.partnerCount
                ? t("design-system:insightCard.partnerCount", { count: this.partnerCount, total: this.totalPartners })
                : ""
        ).split("|");

        return (
            <frag>
                <div className={`${CPT_NAME}__body`}>
                    <i className={`sps-icon sps-icon-${SpsInsightCardIcons[this.kind]}`}/>
                    {metric}
                    {description}
                </div>
                {this.partnerCount && <div className={`${CPT_NAME}__partner-count`}>
                    {this.partnerCount === this.totalPartners
                        ? <div>{t("design-system:insightCard.all")}</div>
                        : <frag>
                            <div>{count}</div>
                            <div>{of_}</div>
                            <div>{total}</div>
                        </frag>
                    }
                    <div className={`${CPT_NAME}__partners-text`}>{t("design-system:insightCard.partners")}</div>
                </div>}
            </frag>
        );
    }
}
