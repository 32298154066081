import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { selectChildren, spsGlobalPropTypes, usePatchReducer } from "../util";
import { SpsSummaryListExpansion } from "./SpsSummaryListExpansion";

const propsDoc = {
    expanded: "boolean",
    selected: "boolean",
    onToggle: "(isExpanded: boolean) => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    expanded: PropTypes.bool,
    selected: PropTypes.bool,
    onToggle: PropTypes.fun<(isExpanded: boolean) => void>(),
};

export type SpsSummaryListRowProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsSummaryListRow(props: SpsSummaryListRowProps) {
    const {
        children,
        className,
        expanded,
        onToggle,
        selected,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [state, patchState] = usePatchReducer({
        isExpanded: expanded,
        expandable: false,
    });

    React.useEffect(() => {
        const expandable = React.Children.toArray(children).some(child =>
            React.isValidElement(child) && child.type === SpsSummaryListExpansion
        );
        patchState({ expandable });
    }, []);

    function toggle() {
        const isExpanded = !state.isExpanded;
        patchState({ isExpanded });
        if (onToggle) {
            onToggle(isExpanded);
        }
    }

    function handleRowClick(event: React.MouseEvent) {
        event.stopPropagation();
        toggle();
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Enter" || event.key === " " || event.key === "Spacebar") {
            event.preventDefault();
            event.stopPropagation();
            toggle();
        }
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-summary-list-row",
        "sps-content-row",
        state.expandable && "sps-summary-list-row--expandable",
        state.isExpanded && "sps-content-row--expanded",
        selected && "sps-summary-list-row--selected",
        className,
    );

    const [
        expansion,
        otherChildren,
    ] = selectChildren(children, [
        { type: SpsSummaryListExpansion }
    ]);

    return (
        <div className={classes}
            data-testid={testId}
            {...rest}
        >
            <div className="sps-content-row__col-group"
                data-testid={`${testId}__col-group`}
                onClick={handleRowClick} onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                {otherChildren}
            </div>
            {expansion}
        </div>
    );
}

Object.assign(SpsSummaryListRow, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSummaryListRow"
});
