import { SpsAbstractControl, SpsAbstractControlConfig } from "./SpsAbstractControl.interface";
import { SpsControlSet, getMember } from "./SpsControlSet.interface";

export interface SpsFormGroup extends SpsControlSet {
    members: { [key: string]: SpsAbstractControl<any> };
    addControl(name: string, control: SpsAbstractControl<any>);
    removeControl(name: string);
    setControl(name: string, control: SpsAbstractControl<any>);
    contains(name: string): boolean;
}

export function formGroup(
    members: { [key: string]: SpsAbstractControl<any> } = {},
    config: SpsAbstractControlConfig<any> = {}
): SpsFormGroup {
    return <SpsFormGroup>{
        validators: [],
        ...config,
        _type: "Group",
        members: { ...members },
        errors: null,
        invalid: false,
        isValid() {
            return !this.invalid && Object.keys(this.members).every(key => this.members[key].isValid());
        },
        hasError(errorKey: string) {
            return this.errors && this.errors.hasOwnProperty(errorKey);
        },
        setInitValue(newValue = {}): SpsFormGroup {
            for (const key of Object.keys(this.members)) {
                this.members[key].setInitValue(newValue[key]);
            }
            return this;
        },
        getValue() {
            return Object.keys(this.members)
                .reduce((partial, key) => Object.assign(partial, {
                    [key]: this.members[key].getValue()
                }), {});
        },
        setValue(newValue = {}): SpsFormGroup {
            for (const key of Object.keys(this.members)) {
                this.members[key].setValue(newValue[key]);
            }
            return this;
        },
        update(): SpsFormGroup {
            return this;
        },
        isFocused() {
            return Object.keys(this.members).some(key => this.members[key].isFocused());
        },
        focus(): SpsFormGroup {
            const keys = Object.keys(this.members);
            if (keys.length) {
                this.members[keys[0]].focus();
            }
            return this;
        },
        blur(): SpsFormGroup {
            for (const key of Object.keys(this.members)) {
                this.members[key].blur();
            }
            return this;
        },
        isPristine() {
            return Object.keys(this.members).every(key => this.members[key].isPristine());
        },
        markAsPristine(): SpsFormGroup {
            for (const key of Object.keys(this.members)) {
                this.members[key].markAsPristine();
            }
            return this;
        },
        markAsDirty(): SpsFormGroup {
            for (const key of Object.keys(this.members)) {
                this.members[key].markAsDirty();
            }
            return this;
        },
        reset(value?: any): SpsFormGroup {
            this.members = { ...members };
            for (const key of Object.keys(this.members)) {
                if (value && value.hasOwnProperty(key)) {
                    this.members[key].reset(value[key]);
                } else {
                    this.members[key].reset();
                }
            }
            this.update();
            return this;
        },
        get<T extends SpsAbstractControl<any>>(path: PropertyKey | PropertyKey[]): T {
            return getMember<T>(this, path);
        },
        addControl(name: string, control: SpsAbstractControl<any>): SpsFormGroup {
            if (this.contains(name)) {
                throw new Error(`Form group already contains a control called "${name}".`);
            }
            this.members[name] = control;
            this.update();
            return this;
        },
        removeControl(name: string): SpsFormGroup {
            delete this.members[name];
            this.update();
            return this;
        },
        setControl(name: string, control: SpsAbstractControl<any>): SpsFormGroup {
            this.members[name] = control;
            this.update();
            return this;
        },
        contains(name: string): boolean {
            return Object.keys(this.members).indexOf(name) > -1;
        },
    };
}
