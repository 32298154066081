import clsx from "clsx";
import * as React from "react";
import { Eventually } from "@spscommerce/utils";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, FauxChangeEvent, usePatchReducer } from "../util";
import { useFormControlId } from "../form/hooks/useFormControlId";
import { SpsFormControl } from "../form/hooks/formControl";
import { SpsFormComponentWrapper } from "../form/SpsFormComponentWrapper";
import { SpsOptionList } from "../option-list/SpsOptionList";
import { SpsMultiSelectTag } from "../multi-select-tag/SpsMultiSelectTag";

const propsDoc = {
    captionKey: "string",
    debounce: "number",
    disabled: "boolean",
    formControl: "SpsFormControl<string>",
    hideSelected: "boolean",
    onChange: "ChangeEventHandler",
    options: "Eventually<Array<any>> | (filter?: string) => Eventually<Array<any>>",
    placeholder: "string",
    textKey: "string",
    value: "any",
    zeroState: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    captionKey: PropTypes.string,
    debounce: PropTypes.number,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<string>>(),
    hideSelected: PropTypes.bool,
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.instanceOf<Promise<any[]>>(Promise),
        PropTypes.fun<(filter?: string) => Eventually<any[]>>(),
    ]).isRequired,
    placeholder: PropTypes.string,
    textKey: PropTypes.string,
    value: PropTypes.any,
    zeroState: PropTypes.string,
};

export type SpsMultiSelectProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsMultiSelect(props: SpsMultiSelectProps) {
    const {
        captionKey,
        className,
        debounce,
        disabled,
        formControl,
        hideSelected,
        id,
        onChange,
        options,
        placeholder,
        textKey,
        unsafelyReplaceClassName,
        value,
        zeroState = "There are no matching options.",
        ...rest
    } = props;

    const [state, patchState] = usePatchReducer({
        isOpen: false,
        keyDown: null,
        opensUpward: false,
        searchText: "",
        activeTagIndex: null,
    });

    function getValue() {
        return (formControl ? formControl.value : value) || [];
    }

    const rootElement = React.useRef<HTMLDivElement>();
    const inputVisual = React.useRef<HTMLDivElement>();
    const textInput = React.useRef<HTMLInputElement>();

    function open() {
        patchState({ activeTagIndex: null, isOpen: true });
    }

    function focusTextInput() {
        setTimeout(() => { textInput.current.focus(); }, 0);
    }

    function updateValue(newValue) {
        patchState({ searchText: "" });
        if (formControl) {
            formControl.setValue(newValue);
            formControl.markAsDirty();
        }
        if (onChange) {
            onChange(new FauxChangeEvent<any>({ value: newValue }));
        }
    }

    function selectOption(option) {
        if (getValue().indexOf(option) === -1) {
            updateValue([ ...getValue(), option ]);
        }
        focusTextInput();
    }

    function removeOptionByIndex(indexToRemove) {
        const newValue = getValue().filter((option, i) => i !== indexToRemove);
        updateValue(newValue);
        if (state.activeTagIndex === null || state.activeTagIndex === getValue().length - 1) {
            focusTextInput();
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        patchState({ searchText: event.target.value });
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        if (!disabled) {
            const cursorIsAtLeftOfTextInputAndThereIsAtLeastOneSelection =
                !hideSelected
                && !(event.target as HTMLInputElement).selectionStart
                && getValue()
                && getValue().length;

            switch (event.key) {
                case "Backspace":
                    if (state.activeTagIndex !== null) {
                        removeOptionByIndex(state.activeTagIndex);
                        return;
                    }
                    if (cursorIsAtLeftOfTextInputAndThereIsAtLeastOneSelection) {
                        updateValue(getValue().slice(0, getValue().length - 1));
                        return;
                    }
                    break;

                case "Delete":
                    if (state.activeTagIndex !== null) {
                        removeOptionByIndex(state.activeTagIndex);
                        return;
                    }
                    break;

                case "Up":
                case "ArrowUp":
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    break;

                case "Left":
                case "ArrowLeft":
                    if (cursorIsAtLeftOfTextInputAndThereIsAtLeastOneSelection) {
                        rootElement.current.focus();
                        if (state.activeTagIndex === null) {
                            patchState({ activeTagIndex: getValue().length - 1, isOpen: false });
                        } else if (state.activeTagIndex > 0) {
                            patchState({ activeTagIndex: state.activeTagIndex - 1, isOpen: false });
                        }
                        return;
                    }
                    break;

                case "Right":
                case "ArrowRight":
                    if (state.activeTagIndex !== null) {
                        if (state.activeTagIndex === getValue().length - 1) {
                            patchState({ activeTagIndex: null, isOpen: true });
                            textInput.current.focus();
                        } else {
                            patchState({ activeTagIndex: state.activeTagIndex + 1 });
                        }
                        return;
                    }
                    break;
            }

            event.persist();
            patchState({ keyDown: event });
        }
    }

    function handleSelfToggle(olIsOpen) {
        patchState({ isOpen: olIsOpen });
    }

    function handlePositionFlip(opensUpward) {
        patchState({ opensUpward });
    }

    function handleClearIconClick() {
        updateValue([]);
        focusTextInput();
    }

    function handleDocumentClick() {
        patchState({ isOpen: false });
    }

    React.useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, []);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-multi-select",
        state.isOpen && "sps-multi-select--open",
        state.isOpen && "z-stratum-dropdown",
        state.opensUpward && "sps-multi-select--opens-upward",
        disabled && "sps-form-control--disabled",
        className,
    );

    const tagIds = (new Array(getValue().length)).fill(0).map((_, i) => `${id}_tag-${i}`);

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            onClick={event => event.nativeEvent.stopImmediatePropagation()}
            tabIndex={-1}
            ref={rootElement}
            onKeyDown={handleKeyDown}
            aria-activedescendant={tagIds[state.activeTagIndex]}
        >
            <SpsOptionList attachTo={inputVisual}
                captionKey={captionKey}
                isOpen={state.isOpen}
                keyDown={state.keyDown}
                options={options}
                onOptionSelected={selectOption}
                onPositionFlip={handlePositionFlip}
                onSelfToggle={handleSelfToggle}
                hideInlineSearch={true}
                conformWidth={true}
                searchDebounce={debounce}
                search={state.searchText}
                textKey={textKey}
                zeroState={zeroState}
            />
            <div className="sps-text-input" ref={inputVisual}>
                <div className={clsx("sps-form-control", !!getValue() && !disabled && "sps-form-control--clearable")}>
                    {hideSelected ? null : getValue().map((opt, i) =>
                        <SpsMultiSelectTag id={tagIds[i]}
                        role="option"
                        disabled={disabled}
                        aria-selected="true"
                        className="mt-1 ml-1" key={i}
                        active={i === state.activeTagIndex}
                        onRemove={() => removeOptionByIndex(i)}>{
                            textKey ? opt[textKey] : opt
                        }</SpsMultiSelectTag>
                    )}
                    <input type="text" ref={textInput}
                        value={state.searchText}
                        className="sps-text-input__input"
                        placeholder={placeholder}
                        id={useFormControlId(id, formControl)}
                        onFocus={open}
                        onClick={open}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                        role="listbox"
                        aria-multiselectable="true"
                        aria-owns={tagIds.join(" ")}
                        {...rest}
                    />
                </div>
                {!!getValue() && !disabled
                    ? <i className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                        onClick={handleClearIconClick}>
                    </i>
                    : null
                }
            </div>
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsMultiSelect, {
    props: propsDoc,
    propTypes,
    displayName: "SpsMultiSelect"
});
