/** Marks a component class property as a prop, meaning that a value can be passed
 * in for it via an attribute on the element, and whenever that value changes, the
 * component will be re-rendered.
 */
export function Prop(castToType?: Function): PropertyDecorator {
    return (target, key: string) => {
        const c: any = target.constructor;
        const keyLowercase = key.toLowerCase();
        c.observedAttributes = c.observedAttributes || ["classname", "className", "style"];
        c.observedAttributes.push(key);
        c.observedAttributes.push(keyLowercase);

        if (keyLowercase !== key) {
            Object.defineProperty(target, key, {
                get() {
                    return this[keyLowercase];
                },
                set(newValue) {
                    this[keyLowercase] = newValue;
                }
            });
        }

        if (castToType) {
            const privateKey = Symbol.for(key);
            Object.defineProperty(target, keyLowercase, {
                get() {
                    return this[privateKey];
                },
                set(newValue) {
                    if (
                        castToType === Boolean
                        && typeof newValue === "string"
                        && newValue.toLowerCase() === "false"
                    ) {
                        newValue = false;
                    } else {
                        newValue = castToType(newValue);
                    }
                    this[privateKey] = newValue;
                }
            });
        }
    };
}
