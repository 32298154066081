import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

import * as momentImport from "moment-timezone";
const moment = momentImport["default"] || momentImport as any; // required for rollup to work without a TypeScript error

const currentLocale = moment.locale();

require("moment/locale/en-au");
require("moment/locale/en-ca");
require("moment/locale/en-ie");
require("moment/locale/en-gb");
require("moment/locale/fr-ca");
require("moment/locale/fr");
require("moment/locale/es");
require("moment/locale/zh-cn");
require("moment/locale/zh-tw");

const currentlyLoadedLocales = moment.locales();
if (currentlyLoadedLocales.indexOf("en-US") === -1) {
    moment.defineLocale("en-US", {
        parentLocale: "en",
        longDateFormat: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "MM/DD/YYYY",
            LL: "MMMM D, YYYY",
            LLL: "MMMM D, YYYY @ LT",
            LLLL: "dddd MMMM D, YYYY @ LT"
        }
    });
}
if (currentlyLoadedLocales.indexOf("en-US24") === -1) {
    moment.defineLocale("en-US24", {
        parentLocale: "en-US",
        longDateFormat: {
            LT: "HH:mm",
            LTS: "HH:mm:ss"
        }
    });
}
if (currentlyLoadedLocales.indexOf("en-PH") === -1) {
    // using tl-ph.js as a primary reference
    moment.defineLocale("en-PH", {
        parentLocale: "en-GB"
    });
    moment.updateLocale("en-PH", {
        longDateFormat: {
            LT: "h:mm A",
            LTS: "h:mm:ss A"
        }
    });
}
if (currentlyLoadedLocales.indexOf("en-IN") === -1) {
    // using pa-IN.js as a primary reference
    moment.defineLocale("en-IN", {
        parentLocale: "en-GB"
    });
    moment.updateLocale("en-IN", {
        longDateFormat: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            ll: "D-MMM-YYYY"
        }
    });
}
if (currentlyLoadedLocales.indexOf("en-SG") === -1) {
    moment.defineLocale("en-SG", {
        parentLocale: "en-IN"
    });
    moment.updateLocale("en-SG", {
        longDateFormat: {
            ll: "D MMM YYYY"
        }
    });
}
if (currentlyLoadedLocales.indexOf("en-HK") === -1) {
    moment.defineLocale("en-HK", {
        parentLocale: "en-GB"
    });
}

moment.locale(currentLocale);

const formatMaps = {
    "ISO_8601": "ISO_8601",             // 2016-02-29T15:03:00-06:00
    "ISO_8601_UTC": "ISO_8601_UTC",     // 2016-02-29T21:03:00.000Z

    "SHORT_TIME": "LT",                 // 3:03 PM
    "SHORT_TIME_ZONE": "LT z",          // 3:03 PM CST

    "LONG_TIME": "LTS",                 // 3:03:50 PM
    "LONG_TIME_ZONE": "LTS z",          // 3:03:50 PM CST

    "NUM_DATE": "L",                    // 02/29/2016
    "SHORT_DATE": "ll",                 // Feb 29, 2016
    "LONG_DATE": "LL",                  // February 29, 2016

    "SHORT_DATETIME": "lll",            // Feb 29, 2016 @ 3:03 PM
    "SHORT_DATETIME_ZONE": "lll z",     // Feb 29, 2016 @ 3:03 PM CST

    "LONG_DATETIME": "LLL",             // February 29, 2016 @ 3:03 PM
    "LONG_DATETIME_ZONE": "LLL z",      // February 29, 2016 @ 3:03 PM CST

    "SHORT_FULLDATETIME": "llll",       // Mon Feb 29, 2016 3:03 PM
    "SHORT_FULLDATETIME_ZONE": "llll z", // Mon Feb 29, 2016 3:03 PM CST

    "LONG_FULLDATETIME": "LLLL",        // Monday February 29, 2016 3:03 PM
    "LONG_FULLDATETIME_ZONE": "LLLL z"  // Monday February 29, 2016 3:03 PM CST
};

const propsDoc = {
    currentUser: "any",
    dateToFormat: "string | Date",
    format: "string",
    locale: "string",
    relative: "boolean",
    relativeHrs: "number",
    tz: "string",
};

const propTypes = {
  ...spsGlobalPropTypes,
  currentUser: PropTypes.any,
  dateToFormat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  format: PropTypes.string,
  locale: PropTypes.string,
  relative: PropTypes.bool,
  relativeHrs: PropTypes.number,
  tz: PropTypes.string,
};

export type SpsDateTimeProps = PropTypes.InferTS<typeof propTypes, HTMLSpanElement>;

// determine if relative formatting should be used
// based on a diff of relativeHrs
// TODO: this has only been teste with dates in the past
function showRelative(dateToCheck: string | Date, relative: boolean, relativeHrs: number): boolean {
    if (!relativeHrs && !relative) {
        return false;
    }
    if (relative) {
        return true;
    }
    const targetDate = moment(dateToCheck);
    const diff = moment().diff(targetDate, "hours");
    return diff < relativeHrs;
}

// use moment and available props to determine how to format date
function getDate({
    locale,
    tz,
    dateToFormat,
    format,
    currentUser,
    relative,
    relativeHrs,
    children,
}: SpsDateTimeProps) {
    if (currentUser && currentUser.preferences) {
        tz = tz ? tz :
            currentUser.preferences.timezone ? currentUser.preferences.timezone :
            moment.tz.guess();
        locale = locale ? locale :
            currentUser.preferences.locale ? currentUser.preferences.locale :
            window.navigator.language;
    } else {
        tz = tz ? tz : moment.tz.guess();
        locale = locale ? locale : window.navigator.language;
    }
    const formatString = format ? formatMaps[format] :
        formatMaps[format] ? formatMaps[format]
        : "lll";
    const date = dateToFormat || String(children);
    const momentDate = moment(date);
    momentDate.locale(locale);
    // check to see if date is valid
    if (!momentDate.isValid()) {
        throw new Error("invalid date");
    }
    momentDate.tz(tz);
    if (showRelative(date, relative, relativeHrs)) {
        return momentDate.fromNow();
    } else {
        return momentDate.format(formatString);
    }
}

export function SpsDateTime(props: SpsDateTimeProps) {
    const {
        dateToFormat,
        "data-testid": testId,
        locale,
        tz,
        format,
        currentUser,
        relative,
        relativeHrs,
        children,
        ...rest
    } = props;

    return (
        <span data-testid={testId} {...rest}>{
          getDate({
            locale,
            tz,
            dateToFormat,
            format,
            currentUser,
            relative,
            relativeHrs,
            children
          })}</span>
    );
}

Object.assign(SpsDateTime, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDateTime"
});
