import { FeedbackBlockIcons, FeedbackBlockKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    kind: "FeedbackBlockKind",
};

const propTypes = {
    ...spsGlobalPropTypes,
    kind: PropTypes.enumValue<FeedbackBlockKind>(FeedbackBlockKind),
};

export type SpsFeedbackBlockProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsFeedbackBlock(props: SpsFeedbackBlockProps) {
    const {
        children,
        className,
        kind = FeedbackBlockKind.TIP,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-feedback-block",
        `sps-feedback-block--${kind}`,
        className,
    );

    return (
        <div className={classes} role="alert" data-testid={testId} {...rest}>
            <i className={clsx("sps-icon", `sps-icon-${FeedbackBlockIcons[kind]}`)} aria-hidden="true"></i>
            <span className="sps-feedback-block__content" data-testid={`${testId}__text`}>{children}</span>
        </div>
    );
}

Object.assign(SpsFeedbackBlock, {
    props: propsDoc,
    propTypes,
    displayName: "SpsFeedbackBlock"
});
