import { SpsIcon, TagKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { SpsTag } from "../tag/SpsTag";
import { jsxTestId, spsGlobalPropTypes } from "../util";

const propsDoc = {
    activateTab: "Function",
    active: "boolean",
    icon: "SpsIcon",
    label: "string",
    index: "number",
    tag: "string",
    tagKind: "TagKind",
    tagIcon: "SpsIcon",
};

const propTypes = {
    ...spsGlobalPropTypes,
    activateTab: PropTypes.func,
    active: PropTypes.bool,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    label: PropTypes.string,
    index: PropTypes.number,
    tag: PropTypes.string,
    tagKind: PropTypes.enumValue<TagKind>(TagKind),
    tagIcon: PropTypes.enumValue<SpsIcon>(SpsIcon),
};

export type SpsCardTabbedPaneProps = PropTypes.InferTS<typeof propTypes, HTMLAnchorElement>;

export function SpsCardTabbedPane(props: SpsCardTabbedPaneProps) {
    const {
        activateTab,
        active,
        children,
        className,
        label,
        icon,
        id,
        index,
        tag,
        tagKind,
        tagIcon,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const onAnchorClick = e => {
        activateTab(this, index, children);
    };

    const handleKeyDown = event => {
        if (event.key === "Enter" || event.key === " " || event.key === "Spacebar") {
            event.preventDefault();
            event.stopPropagation();
            activateTab(this, index, children);
        }
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-nav__item",
        "sps-nav__link",
        active && "active",
        className,
    );

    return (
        <a
            className={classes}
            onClick={onAnchorClick}
            data-toggle="tab"
            id={id}
            role="tab"
            aria-controls={id}
            {...jsxTestId(testId)}
            {...rest}
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            {
                icon && <i className={`sps-icon sps-icon-${icon}`} />
            }
            <span>{label}</span>
            {tag && <SpsTag kind={tagKind || TagKind.KEY} icon={tagIcon}>{tag}</SpsTag>}
        </a >
    );
}

Object.assign(SpsCardTabbedPane, {
    props: propsDoc,
    propTypes,
    displayName: "SpsCardTabbedPane"
});
