import { code } from "@spscommerce/utils";

export const SpsTableExamples = {
    basic: {
        label: "Basic Usage",
        description: code`
          <p>The table is comprised of a set of components mirroring the
          HTML table you're used to. Instead of <code>&lt;table&gt;</code>,
          use <code>&lt;SpsTable&gt;</code>. Instead of <code>&lt;tr&gt;</code>,
          <code>&lt;SpsTr&gt;</code>, etc.</p>
        `,
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const items = [
                            {
                                spine: 2,
                                title: "Seven Samurai",
                                year: 1954,
                                director: "Akira Kurosawa",
                                countries: ["Japan"],
                                price: "$39.96"
                            },
                            {
                                spine: 29,
                                title: "Picnic at Hanging Rock",
                                year: 1975,
                                director: "Peter Weir",
                                countries: ["Australia"],
                                price: "$39.95"
                            },
                            {
                                spine: 62,
                                title: "The Passion of Joan of Arc",
                                year: 1928,
                                director: "Carl Theodor Dreyer",
                                countries: ["France"],
                                price: "$31.96"
                            },
                            {
                                spine: 484,
                                title: "Jeanne Dielman, 23, quai du Commerce, 1080 Bruxelles",
                                year: 1975,
                                director: "Chantal Akerman",
                                countries: ["France", "Belgium"],
                                price: "$31.96"
                            },
                            {
                                spine: 701,
                                title: "Persona",
                                year: 1966,
                                director: "Ingmar Bergman",
                                countries: ["Sweden"],
                                price: "$31.96"
                            },
                            {
                                spine: 740,
                                title: "The Bitter Tears of Petra von Kant",
                                year: 1972,
                                director: "Rainer Werner Fassbinder",
                                countries: ["West Germany"],
                                price: "$31.96"
                            },
                            {
                                spine: 782,
                                title: "The Apu Trilogy",
                                year: 1959,
                                director: "Satyajit Ray",
                                countries: ["India"],
                                price: "$79.96"
                            },
                            {
                                spine: 888,
                                title: "Stalker",
                                year: 1979,
                                director: "Andrei Tarkovsky",
                                countries: ["Soviet Union"],
                                price: "$31.96"
                            }
                        ];

                        return (
                            <SpsTable>
                                <SpsTableHead>
                                    <SpsTableRow>
                                        <SpsTableHeader>Spine#</SpsTableHeader>
                                        <SpsTableHeader>Title</SpsTableHeader>
                                        <SpsTableHeader>Year</SpsTableHeader>
                                        <SpsTableHeader>Director</SpsTableHeader>
                                        <SpsTableHeader>Countries</SpsTableHeader>
                                        <SpsTableHeader className="text-right">Price</SpsTableHeader>
                                    </SpsTableRow>
                                </SpsTableHead>
                                <SpsTableBody>
                                    {items.map((row, index) => {
                                        return (
                                            <SpsTableRow key={index}>
                                                <SpsTableCell>{row["spine"]}</SpsTableCell>
                                                <SpsTableCell>{row["title"]}</SpsTableCell>
                                                <SpsTableCell>{row["year"]}</SpsTableCell>
                                                <SpsTableCell>{row["director"]}</SpsTableCell>
                                                <SpsTableCell>{row["countries"]}</SpsTableCell>
                                                <SpsTableCell className="text-right">{row["price"]}</SpsTableCell>
                                            </SpsTableRow>
                                        );
                                    })}
                                </SpsTableBody>
                            </SpsTable>
                        );
                    }
                `
            }
        }
    },
    horizontalScroll: {
        label: "Horizontal Scrolling",
        description: "<p>If a table is too wide to fit in its container, it will scroll horizontally.</p>",
        examples: {
            scroll: {
                react: code`
                    function DemoComponent() {
                        const items = [
                            {
                              spine: 2,
                              title: "Seven Samurai",
                              year: 1954,
                              director: "Akira Kurosawa",
                              runtime: 207,
                              aspect_ratio: "1.33:1",
                              countries: ["Japan"],
                              languages: ["Japanese"],
                              summary:
                                "One of the most thrilling movie epics of all time, Seven Samurai (Shichinin no samurai) tells the story of a sixteenth-century village whose desperate inhabitants hire the eponymous warriors to protect them from invading bandits. This three-hour ride from Akira Kurosawa—featuring legendary actors Toshiro Mifune and Takashi Shimura—seamlessly weaves philosophy and entertainment, delicate human emotions and relentless action, into a rich, evocative, and unforgettable tale of courage and hope.",
                              price: "$39.96"
                            },
                            {
                              spine: 29,
                              title: "Picnic at Hanging Rock",
                              year: 1975,
                              director: "Peter Weir",
                              runtime: 107,
                              aspect_ratio: "1.78:1",
                              countries: ["Australia"],
                              languages: ["English"],
                              summary:
                                "This sensual and striking chronicle of a disappearance and its aftermath put director Peter Weir on the map and helped usher in a new era of Australian cinema. Based on an acclaimed 1967 novel by Joan Lindsay, Picnic at Hanging Rock is set at the turn of the twentieth century and concerns a small group of students from an all- female college who vanish, along with a chaperone, while on a St. Valentine’s Day outing. Less a mystery than a journey into the mystic, as well as an inquiry into issues of class and sexual repression in Australian society, Weir’s gorgeous, disquieting film is a work of poetic horror whose secrets haunt viewers to this day.",
                              price: "$39.95"
                            },
                            {
                              spine: 62,
                              title: "The Passion of Joan of Arc",
                              year: 1928,
                              director: "Carl Theodor Dreyer",
                              runtime: 81,
                              aspect_ratio: "1.33:1",
                              countries: ["France"],
                              languages: ["French"],
                              summary:
                                "Spiritual rapture and institutional hypocrisy come to stark, vivid life in one of the most transcendent masterpieces of the silent era. Chronicling the trial of Joan of Arc in the hours leading up to her execution, Danish master Carl Theodor Dreyer depicts her torment with startling immediacy, employing an array of techniques—expressionistic lighting, interconnected sets, painfully intimate close-ups—to immerse viewers in her subjective experience. Anchoring Dreyer’s audacious formal experimentation is a legendary performance by Renée Falconetti, whose haunted face channels both the agony and the ecstasy of martyrdom.",
                              price: "$31.96"
                            },
                            {
                              spine: 484,
                              title: "Jeanne Dielman, 23, quai du Commerce, 1080 Bruxelles",
                              year: 1975,
                              director: "Chantal Akerman",
                              runtime: 201,
                              aspect_ratio: "1.66:1",
                              countries: ["France", "Belgium"],
                              languages: ["French"],
                              summary:
                                "A singular work in film history, Chantal Akerman’s Jeanne Dielman, 23, quai du Commerce, 1080 Bruxelles meticulously details, with a sense of impending doom, the daily routine of a middle-aged widow, whose chores include making the beds, cooking dinner for her son, and turning the occasional trick. In its enormous spareness, Akerman’s film seems simple, but it encompasses an entire world. Whether seen as an exacting character study or as one of cinema’s most hypnotic and complete depictions of space and time, Jeanne Dielman is an astonishing, compelling movie experiment, one that has been analyzed and argued over for decades.",
                              price: "$31.96"
                            },
                            {
                              spine: 701,
                              title: "Persona",
                              year: 1966,
                              director: "Ingmar Bergman",
                              runtime: 83,
                              aspect_ratio: "1.37:1",
                              countries: ["Sweden"],
                              languages: ["Swedish"],
                              summary:
                                "By the midsixties, Ingmar Bergman had already conjured many of the cinema’s most unforgettable images. But with the radical Persona, he attained new levels of visual poetry. In the first of a series of legendary performances for Bergman, Liv Ullmann plays a stage actor who has inexplicably gone mute; an equally mesmerizing Bibi Andersson is the garrulous young nurse caring for her in a remote island cottage. While isolated together there, the women undergo a mysterious spiritual and emotional transference. Performed with astonishing nuance and shot in stark contrast and soft light by Sven Nykvist, the influential Persona is a penetrating, dreamlike work of profound psychological depth.",
                              price: "$31.96"
                            },
                            {
                              spine: 740,
                              title: "The Bitter Tears of Petra von Kant",
                              year: 1972,
                              director: "Rainer Werner Fassbinder",
                              runtime: 125,
                              aspect_ratio: "1.37:1",
                              countries: ["West Germany"],
                              languages: ["German"],
                              summary:
                                "In the early 1970s, Rainer Werner Fassbinder discovered the American melodramas of Douglas Sirk and was inspired by them to begin working in a new, more intensely emotional register. One of the first and best-loved films of this period in his career is The Bitter Tears of Petra von Kant, which balances a realistic depiction of tormented romance with staging that remains true to the director’s roots in experimental theater. This unforgettable, unforgiving dissection of the imbalanced relationship between a haughty fashion designer (Margit Carstensen) and a beautiful but icy ingenue (Hanna Schygulla)—based, in a sly gender reversal, on the writer-director’s own desperate obsession with a young actor—is a true Fassbinder affair, featuring exquisitely claustrophobic cinematography by Michael Ballhaus and full-throttle performances by an all-female cast.",
                              price: "$31.96"
                            },
                            {
                              spine: 782,
                              title: "The Apu Trilogy",
                              year: 1959,
                              director: "Satyajit Ray",
                              aspect_ratio: "1.37:1",
                              countries: ["India"],
                              languages: ["Bengali"],
                              summary:
                                "Two decades after its original negatives were burned in a fire, Satyajit Ray’s breathtaking milestone of world cinema rises from the ashes in a meticulously reconstructed new restoration. The Apu Trilogy brought India into the golden age of international art-house film, following one indelible character, a free-spirited child in rural Bengal who matures into an adolescent urban student and finally a sensitive man of the world. These delicate masterworks—Pather Panchali (Song of the Little Road), Aparajito (The Unvanquished), and Apur Sansar (The World of Apu)—based on two books by Bibhutibhusan Banerjee, were shot over the course of five years, and each stands on its own as a tender, visually radiant journey. They are among the most achingly beautiful, richly humane movies ever made—essential works for any film lover.",
                              price: "$79.96"
                            },
                            {
                              spine: 888,
                              title: "Stalker",
                              year: 1979,
                              director: "Andrei Tarkovsky",
                              runtime: 161,
                              aspect_ratio: "1.37:1",
                              countries: ["Soviet Union"],
                              languages: ["Russian"],
                              summary:
                                "Andrei Tarkovsky’s final Soviet feature is a metaphys­ical journey through an enigmatic postapocalyptic landscape, and a rarefied cinematic experience like no other. A hired guide—the Stalker—leads a writer and a professor into the heart of the Zone, the restricted site of a long-ago disaster, where the three men eventually zero in on the Room, a place rumored to fulfill one’s most deeply held desires. Adapting a science-fiction novel by Arkady and Boris Strugatsky, Tarkovsky created an immersive world with a wealth of material detail and a sense of organic atmosphere. A religious allegory, a reflection of contemporaneous political anxieties, a meditation on film itself—Stalker envelops the viewer by opening up a multitude of possible meanings.",
                              price: "$31.96"
                            }
                          ];

                        return (
                            <SpsTable>
                                <SpsTableHead>
                                    <SpsTableRow>
                                        <SpsTableHeader>Spine#</SpsTableHeader>
                                        <SpsTableHeader>Title</SpsTableHeader>
                                        <SpsTableHeader>Year</SpsTableHeader>
                                        <SpsTableHeader>Director</SpsTableHeader>
                                        <SpsTableHeader>Countries</SpsTableHeader>
                                        <SpsTableHeader>Summary</SpsTableHeader>
                                        <SpsTableHeader className="text-right">Price</SpsTableHeader>
                                    </SpsTableRow>
                                </SpsTableHead>
                                <SpsTableBody>
                                    {items.map((row, index) => {
                                        return (
                                            <SpsTableRow key={index}>
                                                <SpsTableCell>{row["spine"]}</SpsTableCell>
                                                <SpsTableCell>{row["title"]}</SpsTableCell>
                                                <SpsTableCell>{row["year"]}</SpsTableCell>
                                                <SpsTableCell>{row["director"]}</SpsTableCell>
                                                <SpsTableCell>{row["countries"]}</SpsTableCell>
                                                <SpsTableCell>{row["summary"]}</SpsTableCell>
                                                <SpsTableCell className="text-right">{row["price"]}</SpsTableCell>
                                            </SpsTableRow>
                                        );
                                    })}
                                </SpsTableBody>
                            </SpsTable>
                        );
                    }
                `
            }
        }
    },
    wrap: {
        label: "Wrapping Text",
        description: "<p>Cells can wrap their contents at 200, 300, 400, 500, or 600 pixels wide.</p>",
        examples: {
            wrapping: {
                react: code`
                    function DemoComponent() {
                        const items = [
                            {
                              title: "Seven Samurai",
                              year: 1954,
                              director: "Akira Kurosawa",
                              summary:
                                "One of the most thrilling movie epics of all time, Seven Samurai (Shichinin no samurai) tells the story of a sixteenth-century village whose desperate inhabitants hire the eponymous warriors to protect them from invading bandits. This three-hour ride from Akira Kurosawa—featuring legendary actors Toshiro Mifune and Takashi Shimura—seamlessly weaves philosophy and entertainment, delicate human emotions and relentless action, into a rich, evocative, and unforgettable tale of courage and hope."
                            },
                            {
                              title: "Picnic at Hanging Rock",
                              year: 1975,
                              director: "Peter Weir",
                              summary:
                                "This sensual and striking chronicle of a disappearance and its aftermath put director Peter Weir on the map and helped usher in a new era of Australian cinema. Based on an acclaimed 1967 novel by Joan Lindsay, Picnic at Hanging Rock is set at the turn of the twentieth century and concerns a small group of students from an all- female college who vanish, along with a chaperone, while on a St. Valentine’s Day outing. Less a mystery than a journey into the mystic, as well as an inquiry into issues of class and sexual repression in Australian society, Weir’s gorgeous, disquieting film is a work of poetic horror whose secrets haunt viewers to this day."
                            },
                            {
                              title: "The Passion of Joan of Arc",
                              year: 1928,
                              director: "Carl Theodor Dreyer",
                              summary:
                                "Spiritual rapture and institutional hypocrisy come to stark, vivid life in one of the most transcendent masterpieces of the silent era. Chronicling the trial of Joan of Arc in the hours leading up to her execution, Danish master Carl Theodor Dreyer depicts her torment with startling immediacy, employing an array of techniques—expressionistic lighting, interconnected sets, painfully intimate close-ups—to immerse viewers in her subjective experience. Anchoring Dreyer’s audacious formal experimentation is a legendary performance by Renée Falconetti, whose haunted face channels both the agony and the ecstasy of martyrdom."
                            },
                            {
                              title: "Persona",
                              year: 1966,
                              director: "Ingmar Bergman",
                              summary:
                                "By the midsixties, Ingmar Bergman had already conjured many of the cinema’s most unforgettable images. But with the radical Persona, he attained new levels of visual poetry. In the first of a series of legendary performances for Bergman, Liv Ullmann plays a stage actor who has inexplicably gone mute; an equally mesmerizing Bibi Andersson is the garrulous young nurse caring for her in a remote island cottage. While isolated together there, the women undergo a mysterious spiritual and emotional transference. Performed with astonishing nuance and shot in stark contrast and soft light by Sven Nykvist, the influential Persona is a penetrating, dreamlike work of profound psychological depth."
                            },
                            {
                              title: "The Bitter Tears of Petra von Kant",
                              year: 1972,
                              director: "Rainer Werner Fassbinder",
                              summary:
                                "In the early 1970s, Rainer Werner Fassbinder discovered the American melodramas of Douglas Sirk and was inspired by them to begin working in a new, more intensely emotional register. One of the first and best-loved films of this period in his career is The Bitter Tears of Petra von Kant, which balances a realistic depiction of tormented romance with staging that remains true to the director’s roots in experimental theater. This unforgettable, unforgiving dissection of the imbalanced relationship between a haughty fashion designer (Margit Carstensen) and a beautiful but icy ingenue (Hanna Schygulla)—based, in a sly gender reversal, on the writer-director’s own desperate obsession with a young actor—is a true Fassbinder affair, featuring exquisitely claustrophobic cinematography by Michael Ballhaus and full-throttle performances by an all-female cast."
                            },
                            {
                              title: "The Apu Trilogy",
                              year: 1959,
                              director: "Satyajit Ray",
                              summary:
                                "Two decades after its original negatives were burned in a fire, Satyajit Ray’s breathtaking milestone of world cinema rises from the ashes in a meticulously reconstructed new restoration. The Apu Trilogy brought India into the golden age of international art-house film, following one indelible character, a free-spirited child in rural Bengal who matures into an adolescent urban student and finally a sensitive man of the world. These delicate masterworks—Pather Panchali (Song of the Little Road), Aparajito (The Unvanquished), and Apur Sansar (The World of Apu)—based on two books by Bibhutibhusan Banerjee, were shot over the course of five years, and each stands on its own as a tender, visually radiant journey. They are among the most achingly beautiful, richly humane movies ever made—essential works for any film lover.",
                            },
                            {
                              title: "Stalker",
                              year: 1979,
                              director: "Andrei Tarkovsky",
                              summary:
                                "Andrei Tarkovsky’s final Soviet feature is a metaphys­ical journey through an enigmatic postapocalyptic landscape, and a rarefied cinematic experience like no other. A hired guide—the Stalker—leads a writer and a professor into the heart of the Zone, the restricted site of a long-ago disaster, where the three men eventually zero in on the Room, a place rumored to fulfill one’s most deeply held desires. Adapting a science-fiction novel by Arkady and Boris Strugatsky, Tarkovsky created an immersive world with a wealth of material detail and a sense of organic atmosphere. A religious allegory, a reflection of contemporaneous political anxieties, a meditation on film itself—Stalker envelops the viewer by opening up a multitude of possible meanings."
                            }
                          ];

                        return (
                            <SpsTable>
                                <SpsTableHead>
                                    <SpsTableRow>
                                        <SpsTableHeader>Title</SpsTableHeader>
                                        <SpsTableHeader>Year</SpsTableHeader>
                                        <SpsTableHeader>Director</SpsTableHeader>
                                        <SpsTableHeader>Summary</SpsTableHeader>
                                    </SpsTableRow>
                                </SpsTableHead>
                                <SpsTableBody>
                                    {items.map((row, index) => {
                                        return (
                                            <SpsTableRow key={index}>
                                                <SpsTableCell>{row["title"]}</SpsTableCell>
                                                <SpsTableCell>{row["year"]}</SpsTableCell>
                                                <SpsTableCell>{row["director"]}</SpsTableCell>
                                                <SpsTableCell  wrap={400}>{row["summary"]}</SpsTableCell>
                                            </SpsTableRow>
                                        );
                                    })}
                                </SpsTableBody>
                            </SpsTable>
                        );
                    }
                `
            }
        }
    },
    sorting: {
        label: "Sorting",
        description: code`
          <p>Any header cell with the <code>sortKey</code> prop will be interactable
          with the mouse or keyboard to sort by that column. The actual sorting of the
          data is up to you in response to the <code>onSortChange</code> event on the
          main <code>&lt;SpsTable&gt;</code> component.</p>
        `,
        examples: {
            sorting: {
                react: code`
                function DemoComponent() {
                    const items = [
                      {
                        spine: 2,
                        title: "Seven Samurai",
                        year: 1954,
                        director: "Akira Kurosawa",
                        runtime: 207,
                        aspect_ratio: "1.33:1",
                        countries: ["Japan"],
                        languages: ["Japanese"]
                      },
                      {
                        spine: 29,
                        title: "Picnic at Hanging Rock",
                        year: 1975,
                        director: "Peter Weir",
                        runtime: 107,
                        aspect_ratio: "1.78:1",
                        countries: ["Australia"],
                        languages: ["English"]
                      },
                      {
                        spine: 62,
                        title: "The Passion of Joan of Arc",
                        year: 1928,
                        director: "Carl Theodor Dreyer",
                        runtime: 81,
                        aspect_ratio: "1.33:1",
                        countries: ["France"],
                        languages: ["French"]
                      },
                      {
                        spine: 484,
                        title: "Jeanne Dielman, 23, quai du Commerce, 1080 Bruxelles",
                        year: 1975,
                        director: "Chantal Akerman",
                        runtime: 201,
                        aspect_ratio: "1.66:1",
                        countries: ["France", "Belgium"],
                        languages: ["French"]
                      },
                      {
                        spine: 701,
                        title: "Persona",
                        year: 1966,
                        director: "Ingmar Bergman",
                        runtime: 83,
                        aspect_ratio: "1.37:1",
                        countries: ["Sweden"],
                        languages: ["Swedish"]
                      },
                      {
                        spine: 740,
                        title: "The Bitter Tears of Petra von Kant",
                        year: 1972,
                        director: "Rainer Werner Fassbinder",
                        runtime: 125,
                        aspect_ratio: "1.37:1",
                        countries: ["West Germany"],
                        languages: ["German"]
                      },
                      {
                        spine: 782,
                        title: "The Apu Trilogy",
                        year: 1959,
                        director: "Satyajit Ray",
                        aspect_ratio: "1.37:1",
                        countries: ["India"],
                        languages: ["Bengali"]
                      },
                      {
                        spine: 888,
                        title: "Stalker",
                        year: 1979,
                        director: "Andrei Tarkovsky",
                        runtime: 161,
                        aspect_ratio: "1.37:1",
                        countries: ["Soviet Union"],
                        languages: ["Russian"]
                      }
                    ]

                    const initialSort = [{ key: "title", direction: SortDirection.ASCENDING }]

                    const [itemsSorted, setItemsSorted] = React.useState(items)

                    function ignoreArticles(value) {
                      return typeof value === "string"
                        ? value.replace(/^(The|A) /, "")
                        : value
                    }

                    function sortItems([newSort]) {
                      const { key, direction } = newSort;
                      itemsSorted.sort((a, b) =>
                        (direction === SortDirection.ASCENDING ? 1 : -1)
                        * (ignoreArticles(a[key]) >= ignoreArticles(b[key]) ? 1 : -1)
                      )
                      setItemsSorted([...itemsSorted])
                    }

                    React.useEffect(() => {
                      sortItems(initialSort)
                    }, [])

                    return (
                      <SpsTable
                        sort={initialSort}
                        onSortChange={sortItems}
                      >
                        <SpsTableHead>
                          <SpsTableRow>
                            <SpsTableHeader sortKey="spine">Spine #</SpsTableHeader>
                            <SpsTableHeader sortKey="title">Title</SpsTableHeader>
                            <SpsTableHeader sortKey="year">Year</SpsTableHeader>
                            <SpsTableHeader sortKey="director">Director</SpsTableHeader>
                            <SpsTableHeader sortKey="runtime">Runtime</SpsTableHeader>
                            <SpsTableHeader sortKey="aspect_ratio">Aspect Ratio</SpsTableHeader>
                            <SpsTableHeader>Countries</SpsTableHeader>
                            <SpsTableHeader sortKey="price" className="text-right">
                              Price
                            </SpsTableHeader>
                          </SpsTableRow>
                        </SpsTableHead>
                        <SpsTableBody>
                          {itemsSorted.map((row, i) => {
                            return (
                              <SpsTableRow key={i}>
                                <SpsTableCell>{row["spine"]}</SpsTableCell>
                                <SpsTableCell>{row["title"]}</SpsTableCell>
                                <SpsTableCell>{row["year"]}</SpsTableCell>
                                <SpsTableCell>{row["director"]}</SpsTableCell>
                                <SpsTableCell>{row["runtime"]}</SpsTableCell>
                                <SpsTableCell>{row["aspect_ratio"]}</SpsTableCell>
                                <SpsTableCell>{
                                  row["countries"].map((country, j) => <SpsTag key={j} className="mr-1">{country}</SpsTag>)
                                }</SpsTableCell>
                                <SpsTableCell className="text-right">
                                  {row["price"]}
                                </SpsTableCell>
                              </SpsTableRow>
                            )
                          })}
                        </SpsTableBody>
                      </SpsTable>
                    )
                  }
                `
            }
        }
    },
    controlsAndButtons: {
        label: "Controls & Buttons",
        description: code`
          <p>When the contents of a table cell are a button or an input such
          as a checkbox or toggle, it requires different padding than a normal
          data cell. The <code>controlCell</code> and <code>buttonCell</code>
          props on <code>&lt;SpsTh&gt;</code> & <code>&lt;SpsTd&gt;</code>.</p>
        `,
        examples: {
            controls: {
                react: code`
                    function DemoComponent() {
                        const items = [
                            {
                              title: "Seven Samurai",
                              year: 1954,
                              director: "Akira Kurosawa"
                            },
                            {
                              title: "Picnic at Hanging Rock",
                              year: 1975,
                              director: "Peter Weir"
                            },
                            {
                              title: "The Passion of Joan of Arc",
                              year: 1928,
                              director: "Carl Theodor Dreyer"
                            },
                            {
                              title: "Persona",
                              year: 1966,
                              director: "Ingmar Bergman"
                            },
                            {
                              title: "The Bitter Tears of Petra von Kant",
                              year: 1972,
                              director: "Rainer Werner Fassbinder"
                            },
                            {
                              title: "The Apu Trilogy",
                              year: 1959,
                              director: "Satyajit Ray"
                            },
                            {
                              title: "Stalker",
                              year: 1979,
                              director: "Andrei Tarkovsky"
                            }
                          ];

                        return (
                            <SpsTable>
                                <SpsTableHead>
                                    <SpsTableRow>
                                        <SpsTableHeader>Title</SpsTableHeader>
                                        <SpsTableHeader>Year</SpsTableHeader>
                                        <SpsTableHeader>Director</SpsTableHeader>
                                        <SpsTableHeader className="text-right">Actions</SpsTableHeader>
                                    </SpsTableRow>
                                </SpsTableHead>
                                <SpsTableBody>
                                    {items.map((row, index) => {
                                        return (
                                            <SpsTableRow key={index}>
                                                <SpsTableCell>{row["title"]}</SpsTableCell>
                                                <SpsTableCell>{row["year"]}</SpsTableCell>
                                                <SpsTableCell>{row["director"]}</SpsTableCell>
                                                <SpsTableCell className="text-right" buttonCell>
                                                    <SpsButton kind="icon" icon="ellipses" />
                                                </SpsTableCell>
                                            </SpsTableRow>
                                        );
                                    })}
                                </SpsTableBody>
                            </SpsTable>
                        );
                    }
                `
            }
        }
    }
};
