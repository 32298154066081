import { code } from "@spscommerce/utils";
import * as moment from "moment-timezone";
export const SpsDateTimeExamples = {
  basic: {
    label: "DateTime Component",
    description:
      "<p>Describe something cool here</p>",
    examples: {
      basicAttribute: {
        jsx: code`
                    <SpsDateTime dateToFormat="2018-09-11T16:32:52.822000Z" />
                `
      },
      basicChild: {
        jsx: code`
                    <SpsDateTime>2018-09-11T16:32:52.822000Z</SpsDateTime>
                  `
      }
    }
  }
};

