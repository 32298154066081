import { code } from "@spscommerce/utils";

export const SpsSlackLinkExamples = {
    basic: {
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        return (
                            <SpsProductBar productName="My Product"
                                productLogoSrc="https://cdn.prod.spsc.io/web/framework/assets/17.08.01/icons/cube/cube.svg"
                                tabs={["Foo", "Bar", "Baz"]}
                                activeTab="Bar"
                                fullWidth
                                style={{ position: "relative" }} // display this example in its place instead of at the top
                            ><SpsSlackLink channelId="CELEGDK4Y" /></SpsProductBar>
                        );
                    }
                `
            },
        }
    }
};
