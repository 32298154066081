import { code } from "@spscommerce/utils";

export const SpsAdvancedSearchExamples = {
    basic: {
        label: "Basic Usage",
        examples: {
            basic: {
                react: code`
                    function Component(props) {
                        const [toolbarSearchText, setToolbarSearchText] = React.useState("");
                        const [isToolbarPinned, setIsToolbarPinned] = React.useState(false);
                        const [advSearch, patchAdvSearch] = usePatchReducer({
                            isOpen: true,
                            enteredFields: 0,
                        });

                        const form = useForm(formGroup({
                            sender: formControl(),
                            receiver: formControl(),
                            doctype: formControl(),
                            status: formControl(),
                        }));

                        React.useEffect(() => {
                            form.get("receiver").setValue("Walmart");
                            patchAdvSearch({ enteredFields: 1 });
                        }, []);


                        function handleToolbarSearchChange(event) {
                            setToolbarSearchText(event.target.value);
                        }

                        function handleToolbarPinned(isPinned) {
                            setIsToolbarPinned(isPinned);
                        }

                        function handleToggleAdvancedSearch(isOpen) {
                            patchAdvSearch({ isOpen });
                        }

                        function handleAdvancedSearchSubmit() {
                            const formValue = form.getValue();
                            const enteredFields = Object.keys(formValue)
                                .filter(key => formValue[key] !== form.get(key).initValue)
                                .length;
                            patchAdvSearch({ enteredFields });
                        }

                        function handleAdvancedSearchClear() {
                            patchAdvSearch({ enteredFields: 0 });
                        }

                        return <>
                            <SpsListToolbar advancedSearch={advSearch}
                                onToggleAdvancedSearch={handleToggleAdvancedSearch}
                                onToolbarPinned={handleToolbarPinned}
                            >
                                <SpsListToolbarSearch>
                                    <SpsTextInput value={toolbarSearchText}
                                        onChange={handleToolbarSearchChange}
                                        disabled={advSearch.isOpen}
                                        title="Search"
                                    />
                                    <SpsButton kind={ButtonKind.ICON}
                                        icon={SpsIcon.SEARCH}
                                        disabled={advSearch.isOpen}
                                    />
                                </SpsListToolbarSearch>
                            </SpsListToolbar>
                            <SpsAdvancedSearch {...advSearch}
                                formGroup={form}
                                onSubmit={handleAdvancedSearchSubmit}
                                onClear={handleAdvancedSearchClear}
                                pinned={isToolbarPinned}
                            >
                                <div className="sps-row">
                                    <div className="col-4">
                                        <SpsLabel for={form.get("sender")}>Sender</SpsLabel>
                                        <SpsTextInput formControl={form.get("sender")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("receiver")}>Receiver</SpsLabel>
                                        <SpsTextInput formControl={form.get("receiver")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("doctype")}>Document Type</SpsLabel>
                                        <SpsTextInput formControl={form.get("doctype")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("status")}>Status</SpsLabel>
                                        <SpsTextInput formControl={form.get("status")}/>
                                    </div>
                                </div>
                            </SpsAdvancedSearch>
                        </>;
                    }
                `
            }
        }
    },
    disabledButtons: {
        label: "Disabling the Clear/Search Buttons",
        examples: {
            disabledButtons: {
                react: code`
                    function Component() {
                        const [toolbarSearchText, setToolbarSearchText] = React.useState("");
                        const [isToolbarPinned, setIsToolbarPinned] = React.useState(false);
                        const [advSearch, setAdvSearch] = React.useState({
                            isOpen: true,
                            controlsDisabled: true,
                        });

                        function handleChange(event) {
                            setToolbarSearchText(event.target.value);
                        }

                        function handleToggleAdvancedSearch(isOpen) {
                            setAdvSearch({ isOpen });
                        }

                        function handleToolbarPinned(isPinned) {
                            setIsToolbarPinned(isPinned);
                        }

                        return <>
                            <SpsListToolbar advancedSearch={advSearch}
                                onToggleAdvancedSearch={handleToggleAdvancedSearch}
                                onToolbarPinned={handleToolbarPinned}
                            >
                                <SpsListToolbarSearch>
                                    <SpsTextInput value={toolbarSearchText}
                                        onChange={handleChange}
                                        disabled={advSearch.isOpen}
                                        placeholder="Type here to search for something"
                                    />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.SEARCH} disabled={advSearch.isOpen} />
                                </SpsListToolbarSearch>
                            </SpsListToolbar>
                            <SpsAdvancedSearch pinned={isToolbarPinned} {...advSearch}>
                                <i>insert form here</i>
                            </SpsAdvancedSearch>
                        </>;
                    }
                `
            }
        }
    },
    footerLink: {
        label: "Footer Link",
        description: code`
            <p>Some applications have a need to add a link button to the footer
            as directed by the product design team.</p>
        `,
        examples: {
            footerLink: {
                react: code`
                    function Component(props) {
                        const [toolbarSearchText, setToolbarSearchText] = React.useState("");
                        const [isToolbarPinned, setIsToolbarPinned] = React.useState(false);
                        const [advSearch, setAdvSearch] = React.useState({
                            isOpen: true
                        });

                        function handleChange(event) {
                            setToolbarSearchText(event.target.value);
                        }

                        function handleToggleAdvancedSearch(isOpen) {
                            setAdvSearch({ isOpen });
                        }

                        function handleToolbarPinned(isPinned) {
                            setIsToolbarPinned(isPinned);
                        }

                        return <>
                            <SpsListToolbar advancedSearch={advSearch}
                                onToggleAdvancedSearch={handleToggleAdvancedSearch}
                                onToolbarPinned={handleToolbarPinned}
                            >
                                <SpsListToolbarSearch>
                                    <SpsTextInput value={toolbarSearchText}
                                        onChange={handleChange}
                                        disabled={advSearch.isOpen}
                                        placeholder="Type here to search for something"
                                    />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.SEARCH} disabled={advSearch.isOpen} />
                                </SpsListToolbarSearch>
                            </SpsListToolbar>
                            <SpsAdvancedSearch pinned={isToolbarPinned} {...advSearch}
                                footerLinks={
                                    <SpsButton kind={ButtonKind.LINK} icon={SpsIcon.GEAR}>
                                        Advanced Search Defaults
                                    </SpsButton>
                                }
                            >
                                <i>insert form here</i>
                            </SpsAdvancedSearch>
                        </>;
                    }
                `
            }
        }
    },
    withTitle: {
        label: "Title Bar With No List Toolbar Search",
        description: code`
            <p>If you do not provide a search within the List Toolbar, then it will
            act as a title bar and the Advanced Search will not be togglable.</p>
        `,
        examples: {
            withTitle: {
                react: code`
                    function Component(props) {
                        const [advSearch, patchAdvSearch] = usePatchReducer({
                            isOpen: true,
                            controlsDisabled: false,
                            isPinned: false
                        });

                        const form = useForm(formGroup({
                            sender: formControl(""),
                            receiver: formControl(""),
                            doctype: formControl(""),
                            status: formControl(""),
                        }));

                        function handleAdvancedSearchSubmit(form) {
                            console.log(form);
                        }

                        function handleAdvancedSearchClear() {
                            console.log("Reset");
                        }

                        function handleToolbarPinned(isPinned) {
                            patchAdvSearch({isPinned : isPinned});
                        }

                        return <>
                            <SpsListToolbar
                                title="Advanced Search"
                                advancedSearch={advSearch}
                                onToolbarPinned={handleToolbarPinned}
                            />
                            <SpsAdvancedSearch
                                formGroup={form}
                                isOpen={advSearch.isOpen}
                                onSubmit={handleAdvancedSearchSubmit}
                                onClear={handleAdvancedSearchClear}
                                pinned={advSearch.isPinned}
                            >
                                <div className="sps-row">
                                    <div className="col-4">
                                        <SpsLabel for={form.get("sender")}>Sender</SpsLabel>
                                        <SpsTextInput formControl={form.get("sender")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("receiver")}>Receiver</SpsLabel>
                                        <SpsTextInput formControl={form.get("receiver")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("doctype")}>Doctype</SpsLabel>
                                        <SpsTextInput formControl={form.get("doctype")}/>
                                    </div>
                                    <div className="col-4">
                                        <SpsLabel for={form.get("status")}>Status</SpsLabel>
                                        <SpsTextInput formControl={form.get("status")}/>
                                    </div>
                                </div>
                            </SpsAdvancedSearch>
                        </>;
                    }
                `
            }
        }
    }
};
