import { Duration, Moment } from "moment-timezone";

export interface MomentRange {
    start: Moment;
    end: Moment;
    preset?: Duration;
}

export interface SimpleDate {
    year: number;
    month: number;
    date: number;
}

export enum SelectMode {
    START,
    END,
}

export interface DateSelectionEvent<T = Moment | MomentRange> {
    value: T;
    mode: SelectMode;
}

/** A list of lists representing the weeks and
 *  days of a month, e.g.
 *  [ [   ,   ,  1,  2,  3,  4,  5 ],
 *    [  6,  7,  8,  9, 10, 11, 12 ],
 *    [ 13, 14, 15, 16, 17, 18, 19 ],
 *    [ 20, 21, 22, 23, 24, 25, 26 ],
 *    [ 27, 28, 29, 30,   ,   ,    ] ]
 * but where each number is a SimpleDate object */
export type CalendarArray = ReadonlyArray<ReadonlyArray<SimpleDate>>;
