import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    activeTab: "string",
    fullWidth: "boolean",
    onTabChange: "(newTab: string) => void",
    productLogoSrc: "string",
    productName: { type: "string", required: true },
    tabs: { type: "Array<string>", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    activeTab: PropTypes.string,
    fullWidth: PropTypes.bool,
    onTabChange: PropTypes.fun<(newTab: string) => void>(),
    productLogoSrc: PropTypes.string,
    productName: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export type SpsProductBarProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsProductBar(props: SpsProductBarProps) {
    const {
        activeTab: activeTabInit,
        children,
        className,
        fullWidth,
        onTabChange,
        productLogoSrc,
        productName,
        tabs,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [activeTab, setActiveTab] = React.useState(activeTabInit || tabs[0]);

    React.useEffect(() => {
        setActiveTab(activeTabInit);
    }, [activeTabInit]);

    function handleTabClick(tab) {
        setActiveTab(tab);
        if (onTabChange) {
            onTabChange(tab);
        }
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-navbar-container",
        "z-stratum-bar",
        className,
    );

    return (
        <div className={classes} {...rest}>
            <nav className={clsx("sps-navbar", "sps-navbar--expand-lg", fullWidth && "sps-navbar--full-width")}>
                <a className="sps-navbar__brand">
                    {productLogoSrc && <img className="sps-navbar__brand-logo"
                        src={productLogoSrc}
                        aria-hidden="true"
                        alt={`${productName} Logo`}
                    />}
                    <span className="sps-navbar__brand-name">{productName}</span>
                </a>
                <span className="sps-vertical-rule"></span>
                <div className="sps-navbar__nav">
                    {tabs.map((tab, i) => (
                        <a className={clsx("sps-nav__item", "sps-nav__link", tab === activeTab && "active")}
                            key={i} onClick={() => handleTabClick(tab)}
                        >
                            <span>{tab}</span>
                            {tab === activeTab && <span className="sr-only">(current)</span>}
                        </a>
                    ))}
                </div>
                <div className="sps-navbar__content">{children}</div>
            </nav>
        </div>
    );
}

Object.assign(SpsProductBar, {
    props: propsDoc,
    propTypes,
    displayName: "SpsProductBar"
});
