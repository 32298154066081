/** Template tag function for making strings containing code blocks easier to deal with. */
export function code(strings: TemplateStringsArray, ...interpolations: any[]) {
    const s = strings.reduce((a, b, i) => a + (i <= interpolations.length ? interpolations[i - 1] : "") + b);
    const leadingWhitespace = Math.min(
        ...s.split(/[\r\n]/)
            .filter(l => l.trim())
            .map(l => l.match(/^ */)[0].length)
    );
    return s.replace(new RegExp(`^ {${leadingWhitespace}}`, "gm"), "").trim();
}
