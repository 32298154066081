import { CalendarArray, MomentRange, SelectMode, SimpleDate } from "@spscommerce/ds-shared";
import { Position, PositioningService } from "@spscommerce/positioning";
import clsx from "clsx";
import * as momentImport from "moment-timezone";
import { Duration, Moment } from "moment-timezone";
import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    placeholder: "string",
    disabled: "boolean",
    format: "string",
    minDate: "string",
    maxDate: "string",
    availablePresets: "Array<Duration>",
    onChange: "(newValue: Moment) => void",
    value: "MomentRange",
    preset: "Duration",
};

const propTypes = {
    ...spsGlobalPropTypes,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    availablePresets: PropTypes.array,
    onChange: PropTypes.fun<(newValue: Moment) => void>(),
    value: PropTypes.impl<MomentRange>(),
    preset: PropTypes.impl<Duration>(),
};

export function weekOfMonth(mmt: Moment) {
    return Math.floor((mmt.clone().date(1).day() + mmt.date() - 1) / 7);
}

const moment = momentImport["default"] || momentImport as any; // required for rollup to work without a TypeScript error

const defaultPresets: Array<Duration> = [
    { days: 1 },
    { days: 7 },
    { days: 30 },
    { days: 60 },
    { days: 90 },
    { years: 1 }
].map(moment.duration);

const _positioningService = new PositioningService();
export type SpsDateRangePickerProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

let idNum = 0;

export function SpsDateRangePicker(props: SpsDateRangePickerProps) {
    const {
        className,
        placeholder = "MM/DD/YYYY - MM/DD/YYYY",
        disabled,
        format = moment.localeData().longDateFormat("MM/DD/YYYY") || "MM/DD/YYYY",
        maxDate,
        minDate,
        availablePresets,
        preset,
        onChange,
        "data-testid": testId,
        unsafelyReplaceClassName,
        value,
        ...rest
    } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [currentValue, setCurrentValue] = React.useState();
    const [view, setView] = React.useState();
    const [text, setText] = React.useState("");
    const [presets, setPreset] = React.useState(null);
    const [selectedPreset, setSelectedPreset] = React.useState(undefined);
    const [mouseoverRange, setMouseoverRange] = React.useState(null);
    const [selectMode, setSelectMode] = React.useState(SelectMode.START);
    const [keyboardFocusDay, setKeyboardFocusDay] = React.useState();
    const [minDateMoment, setMinDateMoment] = React.useState(minDate ? moment(minDate) : {});
    const [maxDateMoment, setMaxDateMoment] = React.useState(maxDate ? moment(maxDate) : {});
    const [isInitialValue, setIsInitialValue] = React.useState(true);
    const [months, setMonths] = React.useState(null);

    const inputElement = React.useRef(null);
    const calendar = React.useRef(null);
    const dateRange = React.useRef(null);
    const weekDayNames = Object.freeze(moment.weekdaysShort());

    let leftAligned = false;
    const _idNum = idNum++;
    const textRef = React.useRef();

    React.useEffect(() => {
        textRef.current = currentValue; // Write it to the ref
        updateText();
    }, [currentValue]);

    const clearMouseoverRange = (event?) => {
        if (!event || (event.target instanceof HTMLElement && event.target.tagName !== "TD")) {
            setMouseoverRange(null);
        }
    };

    const updateText = (optionalValue?: MomentRange) => {
        if (optionalValue) {
            setText([
                optionalValue.start ? optionalValue.start.format(format) : "",
                optionalValue.end ? optionalValue.end.format(format) : ""
            ].join(" - "));
        } else {
            setText(currentValue
                ? [
                    currentValue.start ? currentValue.start.format(format) : "",
                    currentValue.end ? currentValue.end.format(format) : ""
                ].join(" - ")
                : "");
        }
    };

    const close = () => {
        const currValue = currentValue ? currentValue : textRef.current;
        setIsOpen(false);
        _positioningService.release(calendar.current);
        if (currValue && !currValue.end) {
            currValue.end = currValue.start.clone().endOf("day");
            setCurrentValue(currValue);
            setIsInitialValue(false);
            setSelectMode(SelectMode.START);
            clearMouseoverRange();
        }
    };

    const onOutsideClick = (event) => {
        if (event && document.body.contains((event.target) as Node)) {
            if (!dateRange.current.contains(event.target)) {
                close();
            }
        }
    };

    const newWeekArray = (): SimpleDate[] => {
        return new Array(7);
    };

    const generateWeeks = (date: Moment): CalendarArray => {
        const baseDate = {
            year: date.year(),
            month: date.month()
        };

        const weekdayArrayIndex = date.weekday();
        const daysInMonth = date.daysInMonth();
        const weeksInMonth = Math.ceil((daysInMonth + weekdayArrayIndex) / 7);

        const weeks = (new Array(weeksInMonth)).fill(true).map(newWeekArray);

        for (let dateIndex = 0; dateIndex < daysInMonth; dateIndex++) {
            const offsetDay = dateIndex + weekdayArrayIndex;
            const week = weeks[Math.floor(offsetDay / 7)];
            const dayOfWeekIndex = offsetDay % 7;

            week[dayOfWeekIndex] = Object.freeze(
                Object.assign({ date: dateIndex + 1 }, baseDate)
            );

            if (dayOfWeekIndex === 6) {
                Object.freeze(week);
            }
        }

        return Object.freeze(weeks);
    };

    const generateMonths = (newView?) => {
        setMonths([generateWeeks(newView ? newView.start : view.start), generateWeeks(newView ? newView.end : view.end)]);
    };

    const setCalendarView = (displayMoment?: Moment) => {
        if (currentValue) {
            if (currentValue.end && currentValue.end.isValid()) {
                displayMoment = currentValue.end.clone();
            } else {
                return;
            }
        } else if (maxDate) {
            displayMoment = maxDateMoment.clone();
        } else {
            displayMoment = moment();
        }
        displayMoment.startOf("month");
        const viewStart = displayMoment.clone().subtract(1, "month");
        const newView = { start: viewStart, end: displayMoment };
        setView(newView);
        generateMonths(newView);
    };

    const onViewportIntersection = (event) => {
        if (event.target === calendar.current && event.intersectionRatio < 1) {
            leftAligned = event.intersectionRect.left <= 0
                && event.boundingClientRect.width > (event.rootBounds.right - event.boundingClientRect.right);
            _positioningService.reposition(calendar.current, {
                position: leftAligned ? Position.BOTTOM_LEFT : Position.BOTTOM_RIGHT
            });
        }
    };

    const dateIsDisabled = (date: SimpleDate | Moment): boolean => {
        return date &&
            (minDate && minDateMoment.isAfter(date, "day")) ||
            (maxDate && maxDateMoment.isBefore(date, "day"));
    };

    const updatePresets = () => {
        if (availablePresets) {
            const psts = availablePresets;
            const today = moment();
            const todayIsAllowed = !dateIsDisabled(today);
            const t = psts.filter(pst =>
                todayIsAllowed && !dateIsDisabled(today.subtract(pst))
            );
            setPreset(t);
        } else {
            setPreset(defaultPresets);
        }
    };

    React.useEffect(() => {
        _positioningService.on("viewportIntersection", onViewportIntersection);
        document.addEventListener("mousedown", onOutsideClick);
        setCalendarView();
        updatePresets();
        return () => {
            document.removeEventListener("mousedown", onOutsideClick);
        };
    }, []);

    const matchPreset = (newPreset: Duration): Duration => {
        if (presets && newPreset) {
            const match = presets.find(pst =>
                pst.asMilliseconds() === moment.duration(newPreset).asMilliseconds()
            );
            return match;
        }
        return undefined;
    };

    const updateFromPreset = (newPreset: Duration) => {
        const matchedPreset = matchPreset(newPreset);
        let newValue;
        if (matchedPreset) {
            setSelectedPreset(matchedPreset);
            const today = moment();
            newValue = {
                preset: matchedPreset,
                start: today.clone().subtract(matchedPreset).add(1, "day").startOf("day"),
                end: today.endOf("day")
            };
            setCurrentValue(newValue);
        } else {
            setCurrentValue(undefined);
            setSelectedPreset(undefined);
        }
        setIsInitialValue(false);
    };

    React.useEffect(() => {
        if (value !== currentValue) {
            setIsInitialValue(true);
            setCurrentValue(value);
            setView(value);
            if (value && value.preset) {
                setSelectedPreset(value.preset);
            } else {
                setSelectedPreset(undefined);
            }
        }
    }, [value]);

    React.useEffect(() => {
        setCalendarView();
        if (onChange && typeof onChange === 'function' && !isInitialValue) { onChange(currentValue); }
    }, [currentValue]);

    React.useEffect(() => {
        setMinDateMoment(moment(minDate).add(1, "day"));
    }, [minDate]);

    React.useEffect(() => {
        updatePresets();
    }, [minDate, maxDate]);

    React.useEffect(() => {
        setMaxDateMoment(moment(maxDate).add(1, "day"));
    }, [maxDate]);

    React.useEffect(() => {
        setTimeout(() => {
            if (selectedPreset) {
                updateFromPreset(selectedPreset);
            }
            if (preset) {
                setSelectedPreset(moment.duration(preset));
                updateFromPreset(moment.duration(preset));
            }
        }, 0);
    }, [presets]);

    const open = () => {
        if (!isOpen) {
            if (!view) {
                setCalendarView();
            }
            setIsOpen(true);
            _positioningService.position(calendar.current, {
                relativeTo: inputElement.current,
                position: Position.BOTTOM_LEFT,
                offsets: [0, 1]
            });
        }
    };

    const parse = (input: string): MomentRange => {
        if (input) {
            const split = input.split("-");
            let startStr, endStr, start, end;

            if (split.length > 2) {
                let prev;
                for (let i = 0; i <= Math.ceil(input.length / 2); i++) {
                    const sub = input.substr(i, input.length - 2 * i);
                    if (sub.indexOf("-") === -1) {
                        startStr = input.substring(0, i + prev.indexOf("-") - 1);
                        endStr = input.substring(i + prev.indexOf("-"));
                        break;
                    }
                    prev = sub;
                }
            } else {
                [startStr, endStr] = split;
                const startDateStr = startStr ? startStr.trim().split("/") : [];
                start = moment({ year: parseInt(startDateStr[2], 10), month: parseInt(startDateStr[0], 10) - 1, day: parseInt(startDateStr[1], 10) });

                const endDateStr = endStr ? endStr.trim().split("/") : [];
                end = moment({ year: parseInt(endDateStr[2], 10), month: parseInt(endDateStr[0], 10) - 1, day: parseInt(endDateStr[1], 10) });
            }

            return {
                start: start,
                end: end
            };
        }
    };

    const onTextChanged = e => {
        const val = e.target.value;
        const range = parse(val);
        if (range && range.start.isValid() && range.end.isValid()) {
            setCurrentValue(range);
        }
        setIsInitialValue(false);
        setText(e.target.value);
    };

    const getPresetText = (pst: Duration) => {
        if (pst.asDays() === 1) {
            return "Today"; // today is a special snowflake
        }
        return `Last ${pst.humanize().replace('a ', '')}`;
    };

    const dateIsSelected = (date: SimpleDate | Moment): boolean => {
        return date &&
            currentValue && (
                (currentValue.start && currentValue.start.isSame(date, "day")) ||
                (currentValue.end && currentValue.end.isSame(date, "day")) ||
                (
                    currentValue.start &&
                    currentValue.end &&
                    currentValue.start.isBefore(date, "day") &&
                    currentValue.end.isAfter(date, "day")
                )
            );
    };

    const dateIsFocused = (date: SimpleDate | Moment): boolean => {
        return date && keyboardFocusDay && keyboardFocusDay._isAMomentObject && keyboardFocusDay.isSame(date, "day");
    };

    const dateIsSelectionStart = (date: SimpleDate | Moment): boolean => {
        return date &&
            currentValue &&
            currentValue.start && currentValue.start.isSame(date, "day") &&
            currentValue.end && !currentValue.end.isSame(date, "day");
    };

    const dateIsSelectionMiddle = (date: SimpleDate | Moment): boolean => {
        return date &&
            currentValue &&
            currentValue.start && currentValue.start.isBefore(date, "day") &&
            currentValue.end && currentValue.end.isAfter(date, "day");
    };

    const dateIsSelectionEnd = (date: SimpleDate | Moment): boolean => {
        return date &&
            currentValue &&
            currentValue.start && !currentValue.start.isSame(date, "day") &&
            currentValue.end && currentValue.end.isSame(date, "day");
    };

    const dateIsWithinPendingSelection = (date: SimpleDate | Moment): boolean => {
        return date &&
            mouseoverRange &&
            mouseoverRange.start.isBefore(date, "day") &&
            mouseoverRange.end.isAfter(date, "day");
    };

    const datepickerPrevButtonIsDisabled = () => {
        return minDate && view && view.start && view.start.isSame(minDateMoment, 'month');
    };

    const datepickerNextButtonIsDisabled = () => {
        return minDate && view && view.start && view.start.isSame(minDateMoment, 'month');
    };

    const datepickerInputWrapperIsDisabled = () => {
        return currentValue && currentValue.start && currentValue.end && !disabled;
    };

    const onDatepickerMouseDown = event => {
        event.preventDefault();
    };

    const selectDate = (date: Moment) => {
        if (date && !dateIsDisabled(date)) {
            const start = currentValue && currentValue.start;
            const end = currentValue && currentValue.end;
            const simpleDate: SimpleDate = { year: date.year(), month: date.month(), date: date.date() };
            date = date.clone();
            setSelectedPreset(undefined);
            let newValue;
            if (selectMode === SelectMode.START) {
                newValue = {
                    start: start ? start.set(simpleDate) : date,
                    end: null
                };
                setSelectMode(SelectMode.END);
            } else if (!start || date.isBefore(start)) {
                newValue = {
                    start: start ? start.set(simpleDate) : date,
                    end: null
                };
            } else {
                newValue = {
                    start,
                    end: (end ? end.set(simpleDate) : date).endOf("day")
                };

                setSelectMode(SelectMode.START);
                clearMouseoverRange();
                setKeyboardFocusDay(null);
                inputElement.current.focus();
                close();
            }
            setIsInitialValue(false);
            setCurrentValue(newValue);
        }
    };

    const onCalendarClick = (event) => {
        if (event && event.target) {
            try {
                const date = JSON.parse(event.target.getAttribute("data-date"));
                selectDate(moment(date));
            } catch (ignore) { }
        }
    };

    const focusCalendar = () => {
        calendar.current.focus();
        setKeyboardFocusDay(view.start.clone());
    };

    const updateMouseoverRange = (pendingSelectionEndDate: Moment) => {
        if (currentValue && currentValue.start && currentValue.end === null) {
            setMouseoverRange({
                start: currentValue.start.clone(),
                end: pendingSelectionEndDate
            });
        }
    };

    const onCalendarMouseover = event => {
        try {
            const date = JSON.parse(event.target.getAttribute("data-date"));
            updateMouseoverRange(moment(date));
        } catch (ignore) { }
    };

    const onNewPresetSelected = (event, range?: Duration) => {
        setSelectedPreset(range);
        updateFromPreset(range);
        setSelectMode(SelectMode.START);
        inputElement.current.focus();
    };

    const clear = () => {
        setCurrentValue(undefined);
        setSelectedPreset(undefined);
        setIsInitialValue(false);
        setText("");
        setSelectMode(SelectMode.START);
        setTimeout(() => inputElement.current.focus(), 0);
    };

    const viewNextMonth = () => {
        if (!maxDate || view.end.isBefore(maxDateMoment, "month")) {
            view.start.add(1, "month");
            view.end.add(1, "month");
            generateMonths();
        }
    };

    const viewPreviousMonth = () => {
        if (!minDate || view.start.isAfter(minDateMoment, "month")) {
            view.start.subtract(1, "month");
            view.end.subtract(1, "month");
            generateMonths();
        }
    };

    const renderCalendar = (month) => {
        const cal = [];
        const attr = {};
        for (let i = 0; i < month.length; i++) {
            const children = [];
            const week = month[i];
            for (let j = 0; j < week.length; j++) {
                const date = week[j];
                const dateClass = clsx("sps-datepicker__calendar-cell", {
                    "sps-datepicker__calendar-day": date,
                    "sps-datepicker__calendar-day--disabled": dateIsDisabled(date),
                    "sps-datepicker__calendar-day--focus": dateIsFocused(date),
                    "sps-datepicker__calendar-day--selected": dateIsSelected(date),
                    "sps-datepicker__calendar-day--selection-start": dateIsSelectionStart(date),
                    "sps-datepicker__calendar-day--selection-middle": dateIsSelectionMiddle(date),
                    "sps-datepicker__calendar-day--selection-end": dateIsSelectionEnd(date),
                    "sps-datepicker__calendar-day--pending-selection": dateIsWithinPendingSelection(date)
                });

                attr["data-date"] = JSON.stringify(date);
                children.push(
                    <td key={j} {...attr} className={dateClass}>
                        {date && (date.date)}
                    </td>);
            }
            cal.push(<tr key={i}>{children}</tr>);
        }
        return cal;
    };

    const onKeyDown = (event) => {
        let newKeyboardFocusDay;
        switch (event.key) {
            case "Down":
            case "ArrowDown":
                event.preventDefault();

                if (!isOpen) {
                    open();
                }

                if (keyboardFocusDay) {
                    newKeyboardFocusDay = moment.min(
                        keyboardFocusDay.clone().add(1, "week"),
                        keyboardFocusDay.date(keyboardFocusDay.daysInMonth())
                    );
                    setKeyboardFocusDay(newKeyboardFocusDay);
                } else {
                    focusCalendar();
                }
                updateMouseoverRange(newKeyboardFocusDay);
                break;

            case "Up":
            case "ArrowUp":
                if (keyboardFocusDay) {
                    event.preventDefault();
                    if (weekOfMonth(keyboardFocusDay) === 0) {
                        newKeyboardFocusDay = null;
                        setKeyboardFocusDay(null);
                        inputElement.current.focus();
                    } else {
                        newKeyboardFocusDay = moment.max(
                            keyboardFocusDay.clone().subtract(1, "week"),
                            keyboardFocusDay.date(1)
                        );
                        setKeyboardFocusDay(newKeyboardFocusDay);
                    }
                }
                updateMouseoverRange(newKeyboardFocusDay);
                break;

            case "Left":
            case "ArrowLeft":
                if (keyboardFocusDay) {
                    event.preventDefault();
                    if (keyboardFocusDay.day() === 0 || keyboardFocusDay.date() === 1) {
                        const week = weekOfMonth(keyboardFocusDay);
                        keyboardFocusDay.subtract(1, "month").date(1).day(6);
                        if (week) {
                            newKeyboardFocusDay = moment.min(
                                keyboardFocusDay.clone().add(week, "weeks"),
                                keyboardFocusDay.date(keyboardFocusDay.daysInMonth())
                            );
                            setKeyboardFocusDay(newKeyboardFocusDay);
                        }
                    } else {
                        newKeyboardFocusDay = keyboardFocusDay.subtract(1, "day");
                        setKeyboardFocusDay(newKeyboardFocusDay);
                    }
                }
                updateMouseoverRange(newKeyboardFocusDay);
                break;

            case "Right":
            case "ArrowRight":
                if (keyboardFocusDay) {
                    event.preventDefault();
                    if (keyboardFocusDay.day() === 6 || keyboardFocusDay.date() === keyboardFocusDay.daysInMonth()) {
                        const week = weekOfMonth(keyboardFocusDay);
                        keyboardFocusDay.add(1, "month").date(1);
                        if (week) {
                            newKeyboardFocusDay = moment.min(
                                keyboardFocusDay.clone().add(week, "weeks").day(0),
                                keyboardFocusDay.date(keyboardFocusDay.daysInMonth()).day(0)
                            );
                            setKeyboardFocusDay(newKeyboardFocusDay);
                        }
                    } else {
                        newKeyboardFocusDay = keyboardFocusDay.add(1, "day");
                        setKeyboardFocusDay(newKeyboardFocusDay);
                    }
                }
                updateMouseoverRange(newKeyboardFocusDay);
                break;

            case "Enter":
                if (keyboardFocusDay) {
                    selectDate(keyboardFocusDay);
                }
                break;

            case "Esc":
            case "Escape":
                if (keyboardFocusDay && keyboardFocusDay._isAMomentObject) {
                    setKeyboardFocusDay(null);
                    inputElement.current.focus();
                } else {
                    close();
                }
                if (selectMode === SelectMode.END) {
                    setSelectMode(SelectMode.START);
                }
                break;
        }

        if (keyboardFocusDay && keyboardFocusDay._isAMomentObject) {
            if (keyboardFocusDay.isBefore(view.start, "month")) {
                viewPreviousMonth();
            } else if (keyboardFocusDay.isAfter(view.end, "month")) {
                viewNextMonth();
            }
        }
    };

    const datepickerClasses = clsx(
        unsafelyReplaceClassName || "sps-datepicker",
        "sps-date-range-picker",
        isOpen && "open",
        isOpen && "z-stratum-dropdown",
    );

    const prevButtonClasses = clsx(
        "sps-datepicker__button",
        "sps-datepicker__button-previous-month",
        datepickerPrevButtonIsDisabled && "sps-datepicker__button--disabled",
    );

    const nextButtonClasses = clsx(
        "sps-datepicker__button",
        "sps-datepicker__button-next-month",
        datepickerNextButtonIsDisabled && "sps-datepicker__button--disabled",
    );

    const inputWrapperClasses = clsx(
        "sps-form-control",
        datepickerInputWrapperIsDisabled() && "sps-datepicker__input--clearable",
        disabled && "disabled",
    );

    return (
        <div className={datepickerClasses} onKeyDown={onKeyDown} {...rest} data-testid={`${testId}__datepicker`} ref={dateRange}>
            <div
                className={"sps-datepicker__dropdown"}
                ref={calendar}
                tabIndex={-1} data-testid={`${testId}__datepicker__dropdown`}
            >
                {presets && presets.length > 0 && (
                    <div className="sps-datepicker__presets" data-testid={`${testId}__datepicker__presets`}>
                        <fieldset>
                            <legend className="sps-datepicker__presets-label">Ranges</legend>
                            <div className="sps-custom-control sps-custom-radio sps-custom-control--no-error">
                                <input type="radio" id={`sps-date-range-picker-${_idNum}__range-custom`}
                                    className="sps-custom-control__input"
                                    checked={selectedPreset === undefined ? true : false}
                                    name={`sps-date-range-picker-${_idNum}__range`}
                                    onChange={onNewPresetSelected}
                                />
                                <label className="sps-custom-control__label"
                                    htmlFor={`sps-date-range-picker-${_idNum}__range-custom`}
                                >Custom</label>
                            </div>
                            {presets.map((range, i) => {
                                return (
                                    <div key={i} className="sps-custom-control sps-custom-radio sps-custom-control--no-error">
                                        <input type="radio" id={`sps-date-range-picker-${_idNum}__range-${i}`}
                                            key={i} name={`sps-date-range-picker-${_idNum}__range`}
                                            onChange={(e) => { onNewPresetSelected(e, range); }}
                                            value={range}
                                            checked={selectedPreset === range ? true : false}
                                            className="sps-custom-control__input"
                                        />
                                        <label className="sps-custom-control__label"
                                            htmlFor={`sps-date-range-picker-${_idNum}__range-${i}`}
                                        >{getPresetText(range)}</label>
                                    </div>
                                );
                            })}
                        </fieldset>
                    </div>
                )}
                <div className={"sps-datepicker__calendar"} data-testid={`${testId}__datepicker__calendar`}>
                    <div className={"sps-datepicker__calendar-head"}>
                        <a className={prevButtonClasses} aria-label="prev"
                            onClick={viewPreviousMonth} onMouseDown={onDatepickerMouseDown} >
                            <i className="sps-icon sps-icon-chevron-left" />
                        </a>
                        <span className="sps-datepicker__calendar-head-label sps-datepicker__calendar-head-label--rangepicker">
                            <span className="sps-datepicker__calendar-head-label-month">{view && view.start && view.start.format("MMMM")}</span>
                            <span className="sps-datepicker__calendar-head-label-year">{view && view.start && view.start.year()}</span>
                        </span>
                        <span className="sps-datepicker__calendar-head-label sps-datepicker__calendar-head-label--rangepicker">
                            <span className="sps-datepicker__calendar-head-label-month">{view && view.end && view.end.format("MMMM")}</span>
                            <span className="sps-datepicker__calendar-head-label-year">{view && view.end && view.end.year()}</span>
                        </span>
                        <a className={nextButtonClasses}
                            onClick={viewNextMonth} onMouseDown={onDatepickerMouseDown}>
                            <i className="sps-icon sps-icon-chevron-right"></i>
                        </a>
                    </div>
                    <div className="sps-datepicker__calendar-body"
                        onClick={onCalendarClick}
                        onMouseOver={onCalendarMouseover}
                        onMouseOut={clearMouseoverRange}
                    >
                        {months && months.map((month, i) => {
                            return (
                                <table key={i}>
                                    <thead>
                                        <tr>
                                            {weekDayNames.map((dayName, j) => {
                                                return (
                                                    <th key={j}>
                                                        {dayName}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>{renderCalendar(month)}</tbody>
                                </table>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="sps-datepicker__inputs" data-testid={`${testId}__datepicker__inputs`}>
                <span className="sps-datepicker__input">
                    <span className={inputWrapperClasses} data-testid={`${testId}__datepicker__input-wrapper`}>
                        {
                            currentValue && currentValue.preset && (
                                <span className="sps-badge sps-badge--info">{getPresetText(currentValue.preset)}</span>
                            )
                        }
                        <input
                            ref={inputElement}
                            style={{
                                opacity: (currentValue && currentValue.preset) ? 0 : 1
                            }}
                            type="text"
                            className="sps-datepicker__text-input"
                            disabled={disabled}
                            value={text}
                            onClick={open}
                            onFocus={open}
                            onChange={onTextChanged}
                            placeholder={placeholder}
                            data-testid={`${testId}__datepicker__input`}
                        />
                    </span>
                    <i className="sps-icon sps-icon-calendar" />
                    {(currentValue && currentValue.start && currentValue.end && !disabled) && (
                        <i
                            className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                            onClick={clear}
                        />
                    )}
                </span>
            </div>
        </div>
    );
}

Object.assign(SpsDateRangePicker, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDateRangePicker",
});
