import { template } from "@spscommerce/utils";

const noI18nText = {
    "advancedSearch.clear": "Clear",
    "advancedSearch.search": "Search",
    "button.spinningTitle": "Loading…",
    "contentRow.collapse": "Collapse",
    "contentRow.expand": "Expand",
    "fileUpload.acceptedTypes_plural": "{{fileTypes}} formats accepted",
    "fileUpload.acceptedTypes": "{{fileTypes}} format accepted",
    "fileUpload.cancelled_plural": "Upload Cancelled. {{fileNames}} are not supported.",
    "fileUpload.cancelled": "Upload Cancelled. {{fileNames}} is not supported.",
    "fileUpload.close": "Close",
    "fileUpload.errorInstructions_plural": "Make sure your files meet the listed requirements and |try again|.",
    "fileUpload.errorInstructions": "Make sure your file meets the listed requirements and |try again|.",
    "fileUpload.instructions_plural": "Drag and drop your files here or |browse for files| on your desktop.",
    "fileUpload.instructions": "Drag and drop your file here or |browse for a file| on your desktop.",
    "fileUpload.maximumSize": "{{size}} maximum",
    "fileUpload.processing": "Processing Upload…",
    "fileUpload.title": "Upload Your {{description}}",
    "focusedTask.close": "Close",
    "growler.dismiss": "Dismiss",
    "insightCard.all": "ALL",
    "insightCard.partnerCount": "{{count}} |of| {{total}}",
    "insightCard.partners": "PARTNERS",
    "listActionBar.clearSelected": "Clear Selected",
    "listActionBar.itemsSelected": "Items Selected",
    "listToolbar.advancedSearchToggle": "Advanced Search",
    "pagination.nextPage": "Next Page",
    "pagination.ofMany": "of many",
    "pagination.ofPageCount": "of {{pageCount}}",
    "pagination.page": "Page",
    "pagination.prevPage": "Previous Page",
    "searchResultsBar.clear": "Clear",
    "searchResultsBar.count": "{{count}} of {{total}}",
    "searchResultsBar.matchingResults": "Matching results",
    "select.defaultPlaceholder": "Select one…",
    "slackLink.label": "Chat",
    "spinner.defaultAltText": "Loading…",
    "taskQueue.clearCompleted": "Clear Completed",
    "taskQueue.newTask": "You have a new task in your queue.",
    "taskQueue.noTasks": "There are currently no tasks in your queue.",
};

export interface I18nObject {
    t(key: string, interpolations?: any): string;
}

export const noI18nI18n: I18nObject = {
    t(key: string, interpolations?: any): string {
        const text = noI18nText[key.replace(/^design-system:/, "")];
        return interpolations
            ? template(text)(interpolations)
            : text;
    },
};
