import { GROWLER_FADEOUT_DURATION_MS, GROWLER_VISIBLE_DURATION_MS, GrowlerIcon, GrowlerKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";
import { createPortal } from "react-dom";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    imgSrc: "string",
    kind: "GrowlerKind",
    onClose: "() => void",
    title: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    imgSrc: PropTypes.string,
    kind: PropTypes.enumValue<GrowlerKind>(GrowlerKind),
    onClose: PropTypes.fun<() => void>(),
    title: PropTypes.string,
};

export type SpsGrowlerProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsGrowler(props: SpsGrowlerProps) {
    const {
        children,
        className,
        imgSrc,
        kind = GrowlerKind.INFO,
        onClose,
        title,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    let displayArea = document.getElementById("sps-growler-display-area");
    if (!displayArea) {
        displayArea = document.createElement("div");
        displayArea.setAttribute("id", "sps-growler-display-area");
        displayArea.classList.add("sps-growler-area");
        displayArea.classList.add("z-stratum-dialog");
        document.body.appendChild(displayArea);
    }

    const [shown, setShown] = React.useState(true);
    const [fade, setFade] = React.useState(false);

    function createTimers() {
        return [
            window.setTimeout(() => setFade(true), GROWLER_VISIBLE_DURATION_MS),
            window.setTimeout(() => {
                setShown(false);
                if (onClose) {
                    onClose();
                }
            }, GROWLER_VISIBLE_DURATION_MS + GROWLER_FADEOUT_DURATION_MS),
        ];
    }

    const [timers, setTimers] = React.useState();

    function startTimers() {
        setTimers(createTimers());
    }

    if (!timers) {
        startTimers();
    }

    function cancelTimers() {
        for (const timer of timers) {
            window.clearTimeout(timer);
        }
        setShown(true);
        setFade(false);
    }

    function close() {
        cancelTimers();
        setFade(false);
        setShown(false);
        if (onClose) {
            onClose();
        }
    }

    const iconOrSpinnerClass = kind === GrowlerKind.PROGRESS
        ? "sps-spinner"
        : clsx("sps-icon", `sps-icon-${GrowlerIcon.get(kind)}`);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-growler",
        shown && "show",
        fade && "fade",
        `sps-growler--${kind}`,
        className,
    );

    return createPortal(
        <div role="alert" className={classes}
            onMouseEnter={cancelTimers}
            onMouseLeave={startTimers}
            {...rest}
        >
        <div className="sps-growler__icon-box">
            {imgSrc && <img src={imgSrc} />}
            {!imgSrc && <i className={iconOrSpinnerClass} aria-hidden="true"></i>}
        </div>
            <div className="sps-growler__message-box">
                <div className="sps-growler__message-box-text">
                    <span className="sps-growler__message-box-title d-block">{title}</span>
                    <div className="sps-growler__message-box-subtitle">
                        {children}
                    </div>
                </div>
                <button type="button"
                    className="sps-growler__close-button"
                    aria-hidden="true"
                    onClick={close}
                >
                    <i className="sps-icon sps-icon-x" title={t("design-system:growler.dismiss")}></i>
                </button>
            </div>
        </div>,
        displayArea
    );
}

Object.assign(SpsGrowler, {
    props: propsDoc,
    propTypes,
    displayName: "SpsGrowler"
});
