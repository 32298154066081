import { patch } from "incremental-dom";
import { I18nObject } from "@spscommerce/ds-shared";

import { ComponentConfig, Metadata, MARK_CONTENT_START, MARK_CONTENT_END, ComponentChildQuery, getI18n } from "../utils/index";

export const AttrBindings = Symbol("Component Attribute Bindings");
export const ClassBindings = Symbol("Component Class Bindings");
export const StyleBindings = Symbol("Component Style Bindings");

interface DSElementExtension {
    __initialized: boolean;
    __observer: MutationObserver;
    __queuedUpdate: number;
    __managedClasses: Set<string>;

    __cbAttributeChanged?(key: string, oldValue: any, newValue: any);
    __cbConnected?();
    __cbDisconnected?();
    __updateHostBindings();
    __updateChildQueries(adhereToRefreshOption?: boolean);
    __gatherChildren();
    __startMutObs();
    __stopMutObs();
    __doRender();
    update();
    attributeChangedCallback(key: string, oldValue: any, newValue: any);
    connectedCallback();
    disconnectedCallback();
    contentChangedCallback?();
    render?(i18n: I18nObject);
}

export type DSComponent = HTMLElement & DSElementExtension;

const ext: DSElementExtension = {
    __initialized: false,
    __observer: null,
    __queuedUpdate: null,
    __managedClasses: new Set<string>(),

    __updateHostBindings() {
        const self = this as DSComponent;
        for (const attrName of Object.keys(self[AttrBindings] || {})) {
            self.setAttribute(attrName, self[AttrBindings][attrName]);
        }
        for (const styleKey of Object.keys(self[StyleBindings] || {})) {
            self.style[styleKey] = self[StyleBindings][styleKey];
        }
        for (const className of self.__managedClasses) {
            self.classList.remove(className);
        }
        for (const className of (self[ClassBindings] || []).filter(Boolean)) {
            self.__managedClasses.add(className);
            self.classList.add(className);
        }
        for (const className of (self.getAttribute("classname") || "").split(/\w+/).filter(Boolean)) {
            self.classList.add(className);
        }
    },

    __updateChildQueries(adhereToRefreshOption?: boolean) {
        const self = this as DSComponent;
        // See query-selector.ts for explanation of refresh option
        for (const childQuery of Metadata.get<Array<ComponentChildQuery>>(self.constructor, "childQueries", [])) {
            if (!adhereToRefreshOption || (adhereToRefreshOption && childQuery.refresh)) {
                const queryMethod = childQuery.all ? self.querySelectorAll : self.querySelector;
                self[childQuery.key] = queryMethod.call(self, childQuery.selector);
            }
        }
    },

    __gatherChildren() {
        const self = this as DSComponent;
        let childNodes = Array.from(self.childNodes);
        let contentStartIndex = null;
        let contentEndIndex = childNodes.length;
        for (let i = 0; i < childNodes.length; i++) {
            const node = childNodes[i];
            if (node instanceof Comment) {
                if (node.data === MARK_CONTENT_START) {
                    contentStartIndex = i + 1;
                } else if (node.data === MARK_CONTENT_END) {
                    contentEndIndex = i - 1;
                }
            }
        }

        if (contentStartIndex === null) {
            // Entire contents were replaced (React),
            // or initial render is about to happen
            // We must remove the children so they can be
            // placed in the proper location on render
            for (const node of childNodes) {
                node.parentNode.removeChild(node);
            }
        } else {
            // Contents were updated in-place (Angular)
            // Just update contentProp with the correct list
            // of children
            childNodes = childNodes.slice(contentStartIndex, contentEndIndex);
        }

        if (Metadata.has(self.constructor, "contentProp")) {
            self[Metadata.get(self.constructor, "contentProp")] = childNodes;
        }
    },

    __startMutObs() {
        const self = this as DSComponent;
        if (!self.__observer) {
            self.__observer = new MutationObserver(() => {
                if (self.contentChangedCallback) {
                    self.contentChangedCallback();
                }
                self.__gatherChildren();
                self.update();
            });
        }
        self.__observer.observe(self, { childList: true });
    },

    __stopMutObs() {
        const self = this as DSComponent;
        if (self.__observer) {
            self.__observer.disconnect();
        }
    },

    __doRender() {
        const self = this as DSComponent;
        self.__stopMutObs();
        patch(self, self.render(getI18n()));
        self.__startMutObs();
    },

    update() {
        const self = this as DSComponent;
        if (self.__initialized && !self.__queuedUpdate) {
            self.__queuedUpdate = window.requestAnimationFrame(() => {
                self.__doRender();
                setTimeout(() => {
                    self.__updateChildQueries(true);
                    self.__updateHostBindings();
                }, 0);
                self.__queuedUpdate = null;
            });
        }
    },

    attributeChangedCallback(key: string, oldValue: any, newValue: any) {
        const self = this as DSComponent;
        newValue = newValue === "" ? true : newValue;

        if (newValue !== oldValue) {
            if (self.__cbAttributeChanged) {
                self.__cbAttributeChanged(key, self[key], newValue);
            }

            if (key !== "style") {
                self[key] = newValue;
            }

            if (self.__initialized) {
                self.update();
            }
        }
    },

    connectedCallback() {
        const self = this as DSComponent;
        if (self.__cbConnected) {
            self.__cbConnected();
        }

        const listeners = Metadata.get(self.constructor, "eventListeners", []);

        setTimeout(() => {
            if (!self.__initialized) {
                self.__gatherChildren();

                for (const [, key] of listeners) {
                    self[key] = self[key].bind(self);
                }

                self.__initialized = true;
            }

            for (const [eventName, key] of listeners) {
                self.addEventListener(eventName, self[key]);
            }

            self.__doRender();
            setTimeout(() => {
                self.__updateChildQueries();
                self.__updateHostBindings();
            }, 0);
        }, 0);
    },

    disconnectedCallback() {
        const self = this as DSComponent;
        if (self.__cbDisconnected) {
            self.__cbDisconnected();
        }

        for (const [eventName, key] of Metadata.get(self.constructor, "eventListeners", [])) {
            self.removeEventListener(eventName, self[key]);
        }

        self.__stopMutObs();
    },
};

/** Marks a class as a component, setting the tag it will be registered under
 * when the class is passed to `register()`.
 */
export function Component(config: ComponentConfig): ClassDecorator {
    return (target) => {
        Metadata.add(target, config);
        Object.assign(target.prototype, {
            __cbAttributeChanged: target.prototype.attributeChangedCallback,
            __cbConnected: target.prototype.connectedCallback,
            __cbDisconnected: target.prototype.disconnectedCallback,
        }, ext);
    };
}
