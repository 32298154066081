import { code } from "@spscommerce/utils";

export const SpsPageTitleExamples = {
    basic: {
        label: "Basic",
        examples: [{
            jsx: code`
                <SpsPageTitle>
                    Cabela's Purchase Order #123456789012
                </SpsPageTitle>
            `
        }]
    },
    withIcon: {
        label: "With Icon",
        examples: [{
            jsx: code`
                 <SpsPageTitle>
                    <i className="sps-icon sps-icon-exclamation-circle red200" aria-hidden="true"></i>
                    Cabela's Purchase Order #123456789012
                </SpsPageTitle>
            `
        }]
    },
    withSubTitle: {
        label: "With Subtitle",
        examples: [{
            jsx: code`
                <SpsPageTitle>
                    Cabela's Purchase Order #123456789012
                    <SpsPageSubtitle>Cabela's
                        <i className="sps-icon sps-icon-outgoing"></i>
                        MacNeil Automotive Parts
                    </SpsPageSubtitle>
                </SpsPageTitle>
            `
        }]
    },
    withSubtitleAndIcon: {
        label: "With Subtitle and Icon",
        examples: [{
            jsx: code`
                <SpsPageTitle>
                    <i aria-hidden="true" className="sps-icon sps-icon-exclamation-circle red200"></i>
                    Cabela's Purchase Order #123456789012
                    <SpsPageSubtitle>
                        Cabela's <i aria-hidden="true" className="sps-icon sps-icon-outgoing"></i>
                        MacNeil Automotive Parts
                    </SpsPageSubtitle>
                </SpsPageTitle>
            `
        }]
    }
};
