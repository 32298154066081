import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    itemKey: { type: "string", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    itemKey: PropTypes.string.isRequired,
};

export type SpsKeyValueListItemProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsKeyValueListItem(props: SpsKeyValueListItemProps) {
    const {
        children,
        className,
        itemKey,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-key-value-list__item",
        className,
    );

    return (
        <div className={classes} data-testid={`${testId}`} {...rest}>
            <span className="sps-key-value-list__key" data-testid={`${testId}__key`}>{itemKey}</span>
            <span className="sps-key-value-list__value" data-testid={`${testId}__value`}>{children}</span>
        </div>
    );
}

Object.assign(SpsKeyValueListItem, {
    props: propsDoc,
    propTypes,
    displayName: "SpsKeyValueListItem"
});
