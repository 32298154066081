import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsFormControl } from "./hooks/formControl";

const spsFormComponentWrapperPropTypes = {
    ...spsGlobalPropTypes,
    formControl: PropTypes.impl<SpsFormControl<any>>(),
    inputRef: PropTypes.ref<HTMLInputElement | HTMLTextAreaElement>(),
};

export type SpsFormComponentWrapperProps =
    PropTypes.InferProps<typeof spsFormComponentWrapperPropTypes> & React.HTMLAttributes<HTMLDivElement>;
type SpsFormComponentWrapperForwardRefType =
    SpsFormComponentWrapperProps & React.RefAttributes<HTMLDivElement>;
type SpsFormComponentWrapperForwardRefExoticComponent =
    React.ForwardRefExoticComponent<SpsFormComponentWrapperForwardRefType>;

export const SpsFormComponentWrapper: SpsFormComponentWrapperForwardRefExoticComponent = React.forwardRef(
    (props: SpsFormComponentWrapperProps, ref: React.MutableRefObject<HTMLDivElement>) => {
        const {
            className,
            children,
            formControl,
            inputRef = ref,
            ...rest
        } = props;

        function handleFocus() {
            if (formControl) {
                formControl.focus();
            }
        }

        function handleBlur() {
            if (formControl) {
                formControl.blur();
            }
        }

        React.useEffect(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.addEventListener("focus", handleFocus);
                inputRef.current.addEventListener("blur", handleBlur);
            }
            return () => {
                if (inputRef && inputRef.current) {
                    inputRef.current.removeEventListener("focus", handleFocus);
                    inputRef.current.removeEventListener("blur", handleBlur);
                }
            };
        }, [inputRef]);

        const classes = clsx(
            "sps-form-group",
            formControl && formControl.isRequired() && "sps-form-group--required",
            formControl && !formControl.isPristine() && formControl.invalid && "sps-form-group--error",
            className
        );

        return (
            <div className={classes} ref={ref} {...rest}>
                {children}
            </div>
        );
    }
);

Object.assign(SpsFormComponentWrapper, {
    spsFormComponentWrapperPropTypes,
    displayName: "SpsFormComponentWrapper"
});
