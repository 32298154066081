import { lockToAnimationFrames } from "../function";

/**
 * Decorator that applies `lockToAnimationFrames`
 */
export function lockedToAnimationFrames(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
) {
    const originalDescriptorCopy = Object.assign({}, descriptor);
    descriptor.get = function() {
        /* Make a new copy each time; if you don't then every instance after the first
         * one of the class containing the decorated method will break. */
        const newDescriptor = Object.assign({}, originalDescriptorCopy);
        newDescriptor.value = lockToAnimationFrames(originalDescriptorCopy.value, this);
        Object.defineProperty(this, propertyKey, newDescriptor);
        return newDescriptor.value;
    };
    descriptor.configurable = true;
    delete descriptor.writable;
    delete descriptor.value;
    return descriptor;
}
