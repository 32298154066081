import { code } from "@spscommerce/utils";

export const SpsSelectExamples = {
    basic: {
        label: "Basic",
        description: "info about basic selects",
        examples: {
            simple: {
                description: "Simple value options",
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "blue", "green"];

                        return (
                            <SpsSelect id="basic" options={colors}/>
                        )
                    }
                `
            },
            formHooks: {
                description: "Form hooks",
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "blue", "green"];
                        const form = useForm(
                            formGroup({
                                color: formControl(colors[0])
                            })
                        );

                        return (
                            <SpsForm form={form}>
                                <SpsLabel for={form.get("color")}>Color</SpsLabel>
                                <SpsSelect options={colors} formControl={form.get("color")}/>
                            </SpsForm>
                        )
                    }
                `
            },
            placeholder: {
                description: "Custom placeholder text",
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "blue", "green"];

                        return (
                            <SpsSelect  id="placeholder" options={colors} placeholder="Choose a color…"/>
                        )
                    }
                `
            },
            notClearable: {
                description: "Not clearable",
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "blue", "green"];

                        return (
                            <SpsSelect options={colors} value="red" notClearable />
                        )
                    }
                `
            },
            zeroState: {
                description: "Zero state",
                react: code`
                    function DemoComponent() {
                        return (
                            <SpsSelect options={[]} zeroState="We're sorry, there are no options to choose from at this time, because reasons." />
                        )
                    }
                `
            }
        }
    },
    objectOpts: {
        label: "Object Options",
        description: "info about object options",
        examples: {
            basic: {
                description: "Basic use of object options",
                react: code`
                    function DemoComponent() {
                        const sizes = [
                            { size: "S", price: "10.99" },
                            { size: "M", price: "12.99" },
                            { size: "L", price: "14.99" }
                        ];

                        return (
                            <SpsSelect options={sizes} textKey="size" />
                        )
                    }
                `
            },
            captions: {
                description: "Captions",
                react: code`
                    function DemoComponent() {
                        const lorem = [
                            {
                                title: "Lorem",
                                description: "Ut enim ad minim veniam"
                            },
                            {
                                title: "Ipsum",
                                description: "Excepteur sint occaecat cupidatat non proident"
                            }
                        ];

                        return (
                            <SpsSelect options={lorem} textKey="title" captionKey="description" />
                        )
                    }
                `
            }
        }
    },
    searchable: {
        label: "Search/Filter",
        description: "info about search/filter",
        examples: {
            simple: {
                description: "Simple search function",
                react: code`
                    function DemoComponent() {
                        function colorSearch(search) {
                            return ["red", "orange", "yellow", "green", "blue", "indigo", "violet"]
                                .filter(s => (new RegExp(search, "i")).test(s));
                        }

                        return (
                            <SpsSelect  id="search-function" options={colorSearch} zeroState="While I'm sure that's a lovely color, it's not an option we support."/>
                        )
                    }
                `
            },
            promise: {
                description: "Search function returning a Promise",
                react: code`
                    function DemoComponent() {
                        function colorSearch(search) {
                            return new Promise((resolve, reject) => {
                                const result = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"]
                                    .filter(s => new RegExp(search, "i").test(s));
                                resolve(result);
                            });
                        }

                        return (
                            <SpsSelect options={colorSearch}/>
                        )
                    }
                `
            },
            debounce: {
                description: "Search with Debounce",
                react: code`
                    function DemoComponent() {
                        function colorSearch(search) {
                            return ["red", "orange", "yellow", "green", "blue", "indigo", "violet"]
                                .filter(s => (new RegExp(search, "i")).test(s));
                        }

                        return (
                            <SpsSelect id="debounce-select" options={colorSearch} searchDebounce={2000}/>
                        )
                    }
                `
            }
        }
    },
    specialAction: {
        label: "Simple Action",
        description: "info about simple action selects",
        examples: {
            action: {
                description: "Action",
                react: code`
                    function DemoComponent() {
                        const colors = ["red", "orange", "yellow", "blue", "indigo", "violet"];
                        const [action, updateAction] = useSpsAction(
                            {
                                label: "Test Action",
                                icon: "drill"
                            },
                            () => console.log("Action")
                        );
                        function handleChange(event) {
                            updateAction({
                                label: "Test Action"
                            });
                        }

                        return (
                            <SpsSelect  id="special-action" options={colors} action={action} onChange={handleChange}/>
                        )
                    }
                `
            }
        }
    }
};
