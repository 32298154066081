import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    buttonCell: "boolean",
    controlCell: "boolean",
    wrap: "SpsTableCellWrapWidth",
};

const propTypes = {
    ...spsGlobalPropTypes,
    buttonCell: PropTypes.bool,
    controlCell: PropTypes.bool,
    wrap: PropTypes.oneOf([200, 300, 400, 500, 600]),
};

export type SpsTableCellProps = PropTypes.InferTS<typeof propTypes, HTMLTableCellElement>;

export function SpsTableCell(props: SpsTableCellProps) {
    const {
        buttonCell,
        children,
        className,
        controlCell,
        "data-testid": testId,
        unsafelyReplaceClassName,
        wrap,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-table__cell",
        buttonCell && "sps-table__cell--button-cell",
        controlCell && "sps-table__cell--control",
        wrap && `sps-table__cell--text-wrap-${wrap}`,
        className,
    );

    return (
        <td className={classes}
            role="cell"
            data-testid={`${testId}__cell`}
            {...rest}
        >
            {children}
        </td>
    );
}

Object.assign(SpsTableCell, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTableCell"
});

export function SpsTd(props: SpsTableCellProps) {
    return SpsTableCell(props);
}

Object.assign(SpsTd, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTd"
});
