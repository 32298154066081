import { SpsActionMethod } from "@spscommerce/ds-shared";
import { Eventually } from "@spscommerce/utils";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, FauxChangeEvent, usePatchReducer, useDidUpdateEffect } from "../util";
import { I18nContext } from "../i18n";
import { SpsFormComponentWrapper } from "../form/SpsFormComponentWrapper";
import { SpsFormControl } from "../form/hooks/formControl";
import { SpsOptionList } from "../option-list/SpsOptionList";
import { useFormControlId } from "../form/hooks/useFormControlId";

const propsDoc = {
    action: "SpsActionMethod",
    captionKey: "string",
    comparisonKey: "string",
    disabled: "boolean",
    formControl: "SpsFormControl<any>",
    notClearable: "boolean",
    options: "Eventually<Array<any>> | (filter?: string) => Eventually<Array<any>>",
    onChange: "ChangeEventHandler",
    placeholder: "string",
    searchDebounce: "number",
    searchPlaceholder: "string",
    textKey: "string",
    valueKey: "string",
    value: "any",
    zeroState: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    action: PropTypes.fun<SpsActionMethod>(),
    captionKey: PropTypes.string,
    comparisonKey: PropTypes.string,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<any>>(),
    notClearable: PropTypes.bool,
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.instanceOf<Promise<any[]>>(Promise),
        PropTypes.fun<(filter?: string) => Eventually<any[]>>(),
    ]).isRequired,
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
    placeholder: PropTypes.string,
    searchDebounce: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    value: PropTypes.any,
    zeroState: PropTypes.string,
};

export type SpsSelectProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsSelect(props: SpsSelectProps) {
    const {
        action,
        captionKey,
        className,
        comparisonKey,
        disabled,
        formControl,
        id,
        notClearable,
        options,
        onChange,
        placeholder,
        searchDebounce,
        searchPlaceholder = "Search…",
        textKey,
        valueKey,
        unsafelyReplaceClassName,
        value: valueProp,
        zeroState,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const [state, patchState] = usePatchReducer({
        isOpen: false,
        value: formControl ? formControl.value : valueProp,
        text: "",
        keyDown: null,
        opensUpward: false,
    });

    function setValueAndText(newValue) {
        patchState({
            value: newValue,
            text: newValue && textKey ? newValue[textKey] : newValue,
        });
    }

    function updateValue(newValue) {
        setValueAndText(newValue);
        const actualValue = (newValue && valueKey &&  typeof newValue === "object")
            ? newValue[valueKey]
            : newValue;
        if (formControl) {
            formControl.setValue(actualValue);
            formControl.markAsDirty();
        }
        if (onChange) {
            onChange(new FauxChangeEvent<any>({ value: actualValue }));
        }
    }

    React.useEffect(() => {
        setValueAndText(valueProp);
    }, [valueProp, textKey]);

    React.useEffect(() => {
        if (formControl) {
            setValueAndText(formControl.value);
        }
    }, [formControl ? formControl.value : null]);

    function handleClearIconClick() {
        updateValue(undefined);
    }

    function handleDocumentClick() {
        patchState({ isOpen: false });
    }

    function handleControlButtonClick(event: React.MouseEvent) {
        event.nativeEvent.stopImmediatePropagation();
        if (!disabled) {
            patchState({ isOpen: !state.isOpen });
        }
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (!disabled) {
            if (["Up", "ArrowUp", "Down", "ArrowDown"].indexOf(event.key) > -1) {
                event.preventDefault();
            }
            event.persist();
            patchState({ keyDown: event });
        }
    }

    function handleSelfToggle(olIsOpen) {
        patchState({ isOpen: olIsOpen });
    }

    function handlePositionFlip(opensUpward) {
        patchState({ opensUpward });
    }

    React.useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, []);

    const rootElement = React.useRef<HTMLDivElement>(null);
    const controlButton = React.useRef<HTMLDivElement>(null);

    useDidUpdateEffect(() => {
        if (!state.isOpen) {
            rootElement.current.focus();
        }
    }, [state.isOpen]);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-select",
        state.isOpen && "sps-select--open",
        state.isOpen && "z-stratum-dropdown",
        state.opensUpward && "sps-select--opens-upward",
        className,
    );

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            onKeyDown={handleKeyDown}
            formControl={formControl}
            ref={rootElement}
            {...rest}
        >
          <div className={clsx("sps-select__dropctrl", disabled && "disabled")}
            id={useFormControlId(id, formControl)}
            tabIndex={-1}
            onClick={handleControlButtonClick}
            ref={controlButton}
          >
            <div className="sps-select__dropctrl-content">
              {state.text
                ? <span className="sps-select__value">{state.text}</span>
                : <span className="sps-select__placeholder">{placeholder || t("design-system:select.defaultPlaceholder")}</span>
              }
              <i className="sps-icon sps-icon-chevron-down"></i>
            </div>
          </div>
          {state.value && !notClearable && !disabled &&
            <i
              className="sps-icon sps-icon-x-circle sps-select__clear-btn"
              onClick={handleClearIconClick}
            ></i>
          }
          <SpsOptionList attachTo={controlButton}
            captionKey={captionKey}
            comparisonKey={comparisonKey}
            isOpen={state.isOpen}
            keyDown={state.keyDown}
            nullOption={state.value && !notClearable ? placeholder : null}
            options={options}
            onOptionSelected={updateValue}
            onPositionFlip={handlePositionFlip}
            onSelfToggle={handleSelfToggle}
            searchDebounce={searchDebounce}
            searchPlaceholder={searchPlaceholder}
            conformWidth={true}
            selectedOption={state.value}
            specialAction={action}
            textKey={textKey}
            valueKey={valueKey}
            zeroState={zeroState}
          />
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsSelect, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSelect",
});
