import { code } from "@spscommerce/utils";

export const SpsWorkflowExamples = {
    basic: {
        label: "Basic Usage",
        examples: {
            basic: {
                jsx: code`
                <div className="col-4">
                    <SpsWorkflow icon="list-summary" title="Workflow">
                        <SpsWorkflowStep icon="heart" title="Name of Step">
                            <SpsWorkflowDocument>
                                <a href="https://github.com/SPSCommerce/ui-angular" target="_blank">
                                    Document Name
                                </a>
                                <SpsWorkflowDocumentStatus icon="status-error">
                                    Status
                                </SpsWorkflowDocumentStatus>
                            </SpsWorkflowDocument>
                            <SpsWorkflowDocument>
                                Document Name
                                <SpsWorkflowDocumentStatus>
                                    Status
                                </SpsWorkflowDocumentStatus>
                            </SpsWorkflowDocument>
                        </SpsWorkflowStep>
                        <SpsWorkflowStep icon="heart" title="Done">
                        </SpsWorkflowStep>
                    </SpsWorkflow>
                </div>
            `
            }
        }
    },
    withAlias: {
        label: "Basic Usage with Alias",
        examples: {
            withAlias: {
                jsx: code`
                <div className="col-4">
                    <SpsWf icon="list-summary" title="Workflow">
                        <SpsWfStep icon="heart" title="Name of Step">
                            <SpsWfDoc>
                                <a href="https://github.com/SPSCommerce/ui-angular" target="_blank">
                                    Document Name
                                </a>
                                <SpsWfDs icon="status-error">Status</SpsWfDs>
                            </SpsWfDoc>
                            <SpsWfDoc>
                                Document Name
                                <SpsWfDs>
                                    Status
                                </SpsWfDs>
                            </SpsWfDoc>
                        </SpsWfStep>
                        <SpsWfStep icon="heart" title="Done">
                        </SpsWfStep>
                    </SpsWf>
                </div>
            `
            }
        }
    }
};
