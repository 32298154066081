import { code } from "@spscommerce/utils";

export const SpsCardExamples = {
    basic: {
        label: "Basic card",
        description:
            "<p>Basic Cards can include any type of content, such as tables, forms, description lists, or general copy</p>",
        examples: {
            basic: {
                jsx: code`
                    <SpsCard>
                      <p>Plain card.</p>
                    </SpsCard>
                `
            },
            sections: {
                jsx: code`
                    <SpsCard>
                        <section>
                            <h3>Description</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                                non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </section>
                        <section>
                            <h3>Usage Guidelines</h3>
                            <p>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                                consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et
                                dolore magnam aliquam quaerat voluptatem.
                            </p>
                        </section>
                    </SpsCard>
                `
            },
            nestedSections: {
                jsx: code`
                    <SpsCard>
                        <section>
                            <p>Card Header</p>
                        </section>
                        <section className="d-flex">
                            <section>
                                <p>Section 1</p>
                            </section>
                            <section>
                                <p>Section 2</p>
                            </section>
                            <section>
                                <p>Section 3</p>
                            </section>
                        </section>
                    </SpsCard>
                `
            }
        }
    },
    headers: {
        label: "Card + Header",
        description:
            "<p>Cards requiring a descriptive title or actionable items such as buttons or links can utilize the Card Header</p>",
        examples: {
            title: {
                jsx: code`
                    <SpsCard headerTitle="Card Title">
                        <p>Card with title.</p>
                    </SpsCard>
                `
            },
            titleAndIcon: {
                jsx: code`
                    <SpsCard headerTitle="Card Title" headerIcon="chart-pie">
                        <p>Card with title and icon.</p>
                    </SpsCard>
                `
            },
            controls: {
                jsx: code`
                    <SpsCard headerTitle="Card Title"
                        headerContent={() =>
                            <SpsButtonGroup>
                                <SpsButton kind="link">Button Text</SpsButton>
                                <SpsButton kind="icon" icon="printer" aria-label="Print"></SpsButton>
                                <SpsButton
                                    kind="icon"
                                    icon="download-cloud"
                                    aria-label="Download"
                                ></SpsButton>
                                <SpsButton>Button</SpsButton>
                            </SpsButtonGroup>
                        }
                    >
                        <p>Card with controls in header.</p>
                    </SpsCard>
                `
            }
        }
    },
    footers: {
        label: "Card + Footer",
        description: "<p>Footer content could include buttons, links or basic text</p>",
        examples: {
            footerOnly: {
                jsx: code`
                    <SpsCard footer="Footer">
                        <p>Card with footer.</p>
                    </SpsCard>
                `
            },
            controls: {
                jsx: code`
                    <SpsCard footer={() =>
                        <SpsButtonGroup>
                            <SpsButton kind="link">Button Text</SpsButton>
                            <SpsButton kind="icon" icon="printer" aria-label="Print"></SpsButton>
                            <SpsButton
                                kind="icon"
                                icon="download-cloud"
                                aria-label="Download"
                            ></SpsButton>
                            <SpsButton>Button</SpsButton>
                        </SpsButtonGroup>
                    }>
                        <p>Card content</p>
                    </SpsCard>
                `
            },
            footerAndHeader: {
                jsx: code`
                    <SpsCard headerTitle="Card Title" footer="Footer">
                        <p>Card with header and footer.</p>
                    </SpsCard>
                `
            }
        }
    },
    tabbed: {
        label: "Card + Tabs",
        description: code`
            <p>Multiple cards can be combined into a single view with the use of tabs.</p>
            <ul>
                <li>
                    The content displayed in the Card below should be different for each tab
                </li>
                <li>
                    The content in each tab should have a relationship or association to one
                    another
                </li>
            </ul>
        `,
        examples: {
            tabsOnly: {
                jsx: code`
                    <SpsCard>
                        <SpsCardTabbedPane label="Tab 1">
                            Card with tabs, pane 1.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 2" icon="chart-area">
                            Card with tabs, pane 2.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 3" tag="12">
                            Card with tabs, pane 3.
                        </SpsCardTabbedPane>
                    </SpsCard>
                `
            },
            tabsAndHeader: {
                jsx: code`
                    <SpsCard headerTitle="Card Title">
                        <SpsCardTabbedPane label="Tab 1">
                            Card with tabs and title, pane 1.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 2">
                            Card with tabs and title, pane 2.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 3">
                            Card with tabs and title, pane 3.
                        </SpsCardTabbedPane>
                    </SpsCard>
                `
            },
            tabsAndFooter: {
                jsx: code`
                    <SpsCard footer="Footer">
                        <SpsCardTabbedPane label="Tab 1">
                            Card with tabs and footer, pane 1.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 2">
                            Card with tabs and footer, pane 2.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 3">
                            Card with tabs and footer, pane 3.
                        </SpsCardTabbedPane>
                    </SpsCard>
                `
            },
            everything: {
                jsx: code`
                    <SpsCard headerTitle="Card Title" footer="Footer">
                        <SpsCardTabbedPane label="Tab 1">
                            Card with tabs, title, and footer, pane 1.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 2">
                            Card with tabs, title, and footer, pane 2.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane label="Tab 3">
                            Card with tabs, title, and footer, pane 3.
                        </SpsCardTabbedPane>
                    </SpsCard>
                `
            },
            activeTabInput: {
                jsx: code`
                    <SpsCard activeTab="demo-tab-3">
                        <SpsCardTabbedPane id="demo-tab-1" label="Tab 1">
                            activeTab input demo, pane 1.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane id="demo-tab-2" label="Tab 2"
                            icon="chart-area"
                        >
                            activeTab input demo, pane 2.
                        </SpsCardTabbedPane>
                        <SpsCardTabbedPane id="demo-tab-3" label="Tab 3" tag="12">
                            activeTab input demo, pane 3.
                        </SpsCardTabbedPane>
                    </SpsCard>
                `
            }
        }
    }
};
