import { code } from "@spscommerce/utils";

export const SpsTaskQueueExamples = {
    inProgress: {
        label: "Task Queue in progress",
        description: "<p>Info about Task Queue</p>",
        examples: {
            inProgress: {
                react: code`
                function SpsTaskQueueInProgress() {
                    const [tasks, setTasks] = React.useState<ReactSpsTask[]>([
                        {
                            status: SpsTaskStatus.IN_PROGRESS,
                            unread: false,
                            heading: "Downloading 245 transactions",
                            subheading: "processing…",
                            actions: [
                                {
                                    icon: SpsIcon.X,
                                    onClick: task => {
                                        console.log("cancel", task);
                                    }
                                }
                            ]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/21/2019",
                            subheading: "245 transactions | Expires in 2 days",
                            actions: [
                                {
                                    icon: SpsIcon.SEARCH,
                                    onClick: task => {
                                        console.log("view", task);
                                    }
                                },
                                {
                                    icon: SpsIcon.DOWNLOAD_CLOUD,
                                    onClick: task => {
                                        console.log("download", task);
                                    }
                                }
                            ]
                        },
                        {
                            status: SpsTaskStatus.ERRORED,
                            unread: true,
                            heading: "Data XREF Test.xls",
                            subheading: "Failed | Reason it failed…",
                            actions: [
                                {
                                    icon: SpsIcon.REFRESH,
                                    onClick: task => {
                                        console.log("retry", task);
                                    }
                                }
                            ]
                        }
                    ]);

                    function handleOpen() {
                        console.log("opened");
                    }

                    function handleClose() {
                        console.log("closed (we mark all tasks as read)");
                        setTasks(currentTasks => currentTasks.map(task => ({ ...task, unread: false })));
                    }

                    function handleClearCompleted() {
                        setTasks(currentTasks =>
                            currentTasks.filter(task => task.status === SpsTaskStatus.IN_PROGRESS)
                        );
                    }

                    return (
                        <SpsTaskQueue
                            tasks={tasks}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            onClearCompleted={handleClearCompleted}
                        />
                    );
                }
                `
            }
        }
    },
    noPendingTasks: {
        label: "No Task in progress",
        description: "<p>Info about Task Queue</p>",
        examples: {
            noPendingTasks: {
                react: code`
                function SpsTaskQueueNoTasksInProgress() {
                    const view = {
                        icon: SpsIcon.SEARCH,
                        onClick: task => {
                            console.log("view", task);
                        }
                    };

                    const download = {
                        icon: SpsIcon.DOWNLOAD_CLOUD,
                        onClick: task => {
                            console.log("download", task);
                        }
                    };

                    const retry = {
                        icon: SpsIcon.REFRESH,
                        onClick: task => {
                            console.log("retry", task);
                        }
                    };

                    const [tasks, setTasks] = React.useState<ReactSpsTask[]>([
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/21/2019",
                            subheading: "245 transactions | Expires in 2 days",
                            actions: [view, download]
                        },
                        {
                            status: SpsTaskStatus.ERRORED,
                            unread: true,
                            heading: "Data XREF Test.xls",
                            subheading: "Failed | Reason it failed…",
                            actions: [retry]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/20/2019",
                            subheading: "240 transactions | Expires in 2 days",
                            actions: [view, download]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/20/2019",
                            subheading: "195 transactions | Expires in 2 days",
                            actions: [view, download]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/19/2019",
                            subheading: "225 transactions | Expires in 2 days",
                            actions: [view, download]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/19/2019",
                            subheading: "222 transactions | Expires in 2 days",
                            actions: [view, download]
                        },
                        {
                            status: SpsTaskStatus.COMPLETED,
                            unread: true,
                            heading: "Document Download - 02/19/2019",
                            subheading: "245 transactions | Expires in 2 days",
                            actions: [view, download]
                        }
                    ]);

                    function handleOpen() {
                        console.log("opened");
                    }

                    function handleClose() {
                        console.log("closed (we mark all tasks as read)");
                        setTasks(currentTasks => currentTasks.map(task => ({ ...task, unread: false })));
                    }

                    function handleClearCompleted() {
                        console.log("clearing non pending tasks");
                        setTasks(currentTasks =>
                            currentTasks.filter(task => task.status === SpsTaskStatus.IN_PROGRESS)
                        );
                    }

                    return (
                        <SpsTaskQueue
                            tasks={tasks}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            onClearCompleted={handleClearCompleted}
                        />
                    );
                }
                `
            }
        }
    },
    noTasks: {
        label: "No Tasks",
        description: "<p>Info about Task Queue</p>",
        examples: {
            noTasks: {
                react: code`
                    function SpsTaskQueueNoTasks() {
                        return (
                            <SpsTaskQueue tasks={[]} />
                        );
                    }
                `
            }
        }
    },
    taskQueueNotifications: {
        label: "Task Queue Notifications",
        description: "<p>Info about Task Queue</p>",
        examples: {
            taskQueueNotifications: {
                react: code`
                    function SpsTaskQueueNotifications() {
                        const generateTaskId = React.useMemo(() => {
                            let i = 2;
                            return () => String(++i);
                        }, []);

                        const removeAction = {
                            icon: SpsIcon.X,
                            onClick: task => {
                                setTasks(currentTasks => currentTasks.filter(t => t.id !== task.id));
                            }
                        };

                        const [tasks, setTasks] = React.useState<ReactSpsTask[]>([
                            {
                                id: "1",
                                status: SpsTaskStatus.COMPLETED,
                                unread: false,
                                heading: "Task 1",
                                actions: [removeAction]
                            },
                            {
                                id: "2",
                                status: SpsTaskStatus.ERRORED,
                                unread: true,
                                heading: "Task 2",
                                actions: [removeAction]
                            }
                        ]);

                        const form = useForm(
                            formGroup({
                                blueTooltip: formControl(false)
                            })
                        );

                        function handleAddTask() {
                            const newTaskId = generateTaskId();

                            setTasks(currentTasks => [
                                ...currentTasks,
                                {
                                    id: newTaskId,
                                    status: SpsTaskStatus.IN_PROGRESS,
                                    unread: true,
                                    heading: \`Task \${newTaskId}\`,
                                    actions: [removeAction]
                                }
                            ]);
                        }

                        function handleClearCompleted() {
                            setTasks(currentTasks =>
                                currentTasks.filter(task => task.status === SpsTaskStatus.IN_PROGRESS)
                            );
                        }

                        function handleClose() {
                            setTasks(currentTasks => currentTasks.map(task => ({ ...task, unread: false })));
                        }

                        const isTooltipBlue = form.get("blueTooltip").getValue();
                        const tooltipKind = isTooltipBlue ? TooltipKind.HELP : TooltipKind.DEFAULT;
                        const notificationText = isTooltipBlue
                            ? "There is a new task in your queue. Also the tooltip is blue now :O"
                            : undefined;

                        return (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <SpsCheckbox label="Blue Tooltip" formControl={form.get("blueTooltip")} />
                                    <SpsButton onClick={handleAddTask}>Add a new task to the queue</SpsButton>
                                </div>

                                <SpsTaskQueue
                                    tasks={tasks}
                                    notificationText={notificationText}
                                    tooltipConfig={{ kind: tooltipKind }}
                                    onClose={handleClose}
                                    onClearCompleted={handleClearCompleted}
                                />
                            </div>
                        );
                    }
                `
            }
        }
    }
};
