import { SpsTab } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propTypes = {
    ...spsGlobalPropTypes,
    tabs: PropTypes.arrayOf(PropTypes.impl<SpsTab>()).isRequired,
    activeTab: PropTypes.impl<SpsTab>(),
    onTabChange: PropTypes.fun<(newTab: SpsTab) => void>(),
};

export type SpsTabsProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsTabs(props: SpsTabsProps) {
    const {
        tabs,
        activeTab,
        onTabChange,
        className,
        unsafelyReplaceClassName,
        "data-testid": testId,
        ...rest
    } = props;

    const [activeTabState, setActiveTabState] = React.useState(null);
    React.useEffect(() => {
        if (tabs.length) {
            if (activeTab) {
                const obj = tabs.find(tab => tab.label === activeTab.label);
                setActiveTabState(obj);
            } else {
                setActiveTabState(tabs[0]);
            }
        }
    }, []);

    const onTabClick = (event, tab) => {
        event.preventDefault();
        setActiveTabState(tab);
        if (typeof onTabChange === "function") {
            onTabChange(tab);
        }
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-tabs",
        className,
    );

    return (
        <div className={classes}>
            <nav className="sps-nav sps-nav--tabs" role="tablist" data-testid={testId} {...rest}>
                {tabs.map((tab, index) => {
                    return (
                        <a
                            key={"tab_" + index}
                            id={tab.id}
                            className={clsx("sps-nav__item sps-nav__link", {
                                active: tab === activeTabState
                            })}
                            role="tab"
                            data-toggle="tab"
                            href={tab.controlsId ? "#" + tab.controlsId : null}
                            aria-controls={tab.controlsId}
                            aria-selected={tab === activeTab}
                            onClick={e => {
                                onTabClick(e, tab);
                            }}
                            data-testid={`${testId}__tab-link`}
                        >
                            {tab.icon && <i className={`sps-icon sps-icon-${tab.icon}`} />}
                            <span>{tab.label}</span>
                            {tab.tag && <span className="sps-badge sps-badge--key">{tab.tag}</span>}
                        </a>
                    );
                })}
            </nav>
        </div>
    );
}

Object.assign(SpsTabs, {
    propTypes,
    displayName: "SpsTabs"
});
