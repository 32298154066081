import { Position } from "./position.enum";
import { PositionAnchor } from "./position-anchor.enum";

/** Options that can be passed to `position()` method. */
export interface PositioningOptions {
    /** Indicates which point on the positioned element it should be positioned by. */
    anchor?: PositionAnchor;

    /** An array of pixel offsets. Each offset is applied
     * to the corresponding term in `position`. So to continue the above example of
     * `"bottom left"`, if the offsets are `[-7, 9]`, the element will be placed at
     * the bottom of the `relativeTo` element minus 7 pixels, and at the left of it
     * plus 9 pixels. (NB: The values will be converted to rem.) */
    offsets?: number[];

    /** When using `relativeTo`, this describes the positioning relationship between
     * the two elements. For example, if it's `"bottom left"`, the positioned element
     * will be placed at the bottom left of the `relativeTo` element. */
    position?: Position;

    /** Normally, an element positioned by the service will simply have its current
     * position on the page locked in place. Instead you can have it positioned
     * relative to another element. */
    relativeTo?: HTMLElement;

    /** The z-index to be applied to the element while it's being positioned. The
     * default is 1029, which puts it 1 z-index below the SPS navbar. */
    zIndex?: number | string;
    __observer?: IntersectionObserver;
}

export const DEFAULT_POSITIONING_OPTIONS: PositioningOptions = {
    anchor: PositionAnchor.TOP_LEFT,
    offsets: [],
    position: Position.TOP_LEFT,
    zIndex: "1028"
};
