/** A "watched" property will trigger an update whenever it's written to. */
export function Watch(): PropertyDecorator {
    return (target, key: string) => {
        const privateKey = Symbol(key);
        Object.defineProperties(target, {
            [privateKey]: {
                writable: true,
                enumerable: false,
                configurable: false,
                value: target[key],
            },
            [key]: {
                get() {
                    return this[privateKey];
                },
                set(newValue) {
                    if (this[privateKey] !== newValue) {
                        this[privateKey] = newValue;
                        this.update();
                    }
                }
            }
        });
    };
}
