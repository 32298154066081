import { code } from "@spscommerce/utils";

export const SpsRadioButtonExamples = {
    labels: {
        label: "Labels",
        description: "info about radio buttons with labels",
        examples: {
            label: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            labels: formControl("blue")
                        }));

                        return (
                            <SpsForm formGroup={form} id="labelRadioButton">
                                <SpsRadioButton name="blue" value="blue" label="Blue" formControl={form.get("labels")}></SpsRadioButton>
                                <SpsRadioButton name="green" value="green" label="Green" formControl={form.get("labels")}></SpsRadioButton>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        description: "info about disabled radio buttons",
        examples: {
            disabled: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            disabled: formControl("blue")
                        }));

                        return (
                            <SpsForm formGroup={form} id="disabledRadioButton">
                                <SpsRadioButton disabled name="blue" value="blue" label="Blue" formControl={form.get("disabled")}></SpsRadioButton>
                                <SpsRadioButton disabled name="green" value="green" label="Green" formControl={form.get("disabled")}></SpsRadioButton>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    noLabels: {
        label: "No Labels",
        description: "info about radio buttons without labels",
        examples: {
            noLabels: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            noLabels: formControl("green")
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsRadioButton name="blue" value="blue" formControl={form.get("noLabels")}></SpsRadioButton>
                                <SpsRadioButton name="green" value="green" formControl={form.get("noLabels")}></SpsRadioButton>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    }
};
