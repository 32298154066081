import { crumblePath } from "./crumble-path";

/** Generator for iterating down a path on an object. */
export function* traversePath(object, path) {
    yield object;
    for (const crumb of crumblePath(path)) {
        object = object[crumb];
        yield object;
    }
}
