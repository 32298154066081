/** This would be simpler to understand as a factory that returns a decorator function,
 *  but TypeScript AoT compliation doesn't allow that. This lets you create a decorator
 *  that takes in a metadata object as an argument and assigns its values along with
 *  default values to the decorated method. The decorator will look like a standard
 *  decorator but calling this method and returning the result is the only thing it
 *  needs to do. Example:
 * 
 *  ```typescript
 *  const defaultData: ExampleData = { a: 1, b: 2 };
 *  function ExampleMetadataDecorator(metadata: Partial<ExampleData>) {
 *    return (target: any, key: PropertyKey, descriptor: PropertyDescriptor) =>
 *      simpleMetadataDecoratorApplicator<ExampleData>(metadata, defaultData, target, key, descriptor);
 *  }
 *  class MyClass {
 *    @ExampleMetadataDecorator({ b: 5 })
 *    someMethod() {}
 *  }
 *  const myClass = new MyClass();
 *  console.log(myClass.someMethod['a']); // 1
 *  console.log(myClass.someMethod['b']); // 5
 *  ```
 */
export function simpleMetadataDecoratorApplicator<T, K>(
    metadata: Partial<T>,
    defaults: T,
    target: any,
    key: PropertyKey,
    descriptor: TypedPropertyDescriptor<K>
): TypedPropertyDescriptor<K> {
    descriptor = descriptor || Object.getOwnPropertyDescriptor(target, key);
    const getter = descriptor.get;
    const value = descriptor.value;

    descriptor.get = function get() {
        const self = this;
        const fn = value || getter.call(this);
        const boundFn = fn.bind(this);

        metadata = Object.assign({}, defaults, metadata);

        for (const prop of Object.keys(defaults)) {
            if (typeof metadata[prop] === "function") {
                Object.defineProperty(boundFn, prop, {
                    get() {
                        return metadata[prop](self);
                    }
                });
            } else {
                boundFn[prop] = metadata[prop];
            }
        }

        Object.defineProperty(this, key, {
            writable: true,
            configurable: true,
            enumerable: descriptor.enumerable,
            value: boundFn
        });
        return boundFn;
    };

    delete descriptor.value;
    delete descriptor.writable;

    return descriptor;
}
