import { code } from "@spscommerce/utils";

export const SpsMicroBlockExamples = {
    infoBlock: {
        label: "Info Micro Block",
        examples: {
            infoBlock: {
                jsx: code`
                    <SpsMicroBlock kind={FeedbackBlockKind.INFO} message="Hey, here's something neat."/>
                `
            }
        }
    },
    successBlock: {
        label: "Success Micro Block",
        examples: {
            successBlock: {
                jsx: code`
                    <SpsMicroBlock kind={FeedbackBlockKind.SUCCESS} message="That was amazing!"/>
                `
            }
        }
    },
    warningBlock: {
        label: "Warning Micro Block",
        examples: {
            warningBlock: {
                jsx: code`
                    <SpsMicroBlock kind={FeedbackBlockKind.WARNING} message="Watch yourself, Chester!"/>
                `
            }
        }
    },
    errorBlock: {
        label: "Error Micro Block",
        examples: {
            errorBlock: {
                jsx: code`
                    <SpsMicroBlock kind={FeedbackBlockKind.ERROR} message="Uh oh, big problems."/>
                `
            }
        }
    },
    tipBlock: {
        label: "Tip Micro Block",
        examples: {
            tipBlock: {
                jsx: code`
                    <SpsMicroBlock kind={FeedbackBlockKind.TIP} message="Pro Tip: Don't be a loser."/>
                `
            }
        }
    }
};
