import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsContentRowExpansionProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsContentRowExpansion(props: SpsContentRowExpansionProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-content-row__expansion",
        className,
    );

    return (
        <div className={classes} {...rest} data-testid={`${testId}__expansion`}>
            {children}
        </div>
    );
}

Object.assign(SpsContentRowExpansion, {
    props: propsDoc,
    propTypes,
    displayName: "SpsContentRowExpansion"
});
