import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { spsGlobalPropTypes } from "../../util";
import { useFormControlId } from "../hooks/useFormControlId";
import { SpsFormControl } from "../hooks/formControl";
import { SpsFormComponentWrapper } from "../SpsFormComponentWrapper";

const propsDoc = {
    checked: "boolean",
    disabled: "boolean",
    formControl: "SpsFormControl<any>",
    inline: "boolean",
    label: "string",
    name: { type: "string", required: true },
    onChange: "ChangeEventHandler",
    value: "any",
};

const propTypes = {
    ...spsGlobalPropTypes,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<any>>(),
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
    ref: PropTypes.ref<HTMLDivElement>(),
    value: PropTypes.any,
};

export type SpsRadioButtonProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsRadioButton(props: SpsRadioButtonProps) {
    const {
        checked,
        className,
        "data-testid": testId,
        disabled,
        formControl,
        id,
        inline,
        label,
        name,
        onChange,
        ref,
        value,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const inputElement = React.useRef<HTMLInputElement>();

    const inputId = useFormControlId(id);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-custom-radio",
        "sps-custom-control",
        inline && "sps-custom-control--inline",
        !label && "sps-custom-control--no-label",
        className
    );

    function handleChange(event) {
        if (formControl) {
            formControl.setValue(value);
            formControl.markAsDirty();
        }
        if (onChange) {
            onChange(event);
        }
        inputElement.current.focus();
    }

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            inputRef={inputElement}
            ref={ref}
            data-testid={`${testId}`}
            onClick={() => inputElement.current.click()}
        >
            <input
                type="radio"
                ref={inputElement}
                className="sps-custom-control__input"
                id={inputId}
                name={name}
                value={value}
                checked={formControl ? formControl.getValue() === value : checked}
                disabled={disabled}
                onChange={handleChange}
                {...rest}
            />
            <label className="sps-custom-control__label" htmlFor={inputId}>{label || ""}</label>
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsRadioButton, {
    props: propsDoc,
    propTypes,
    displayName: "SpsRadioButton"
});
