import * as isNilImport from "lodash.isnil";
import * as isEmptyImport from "lodash.isempty";

const isNil = (<any>isNilImport).default || isNilImport;
const isEmpty = (<any>isEmptyImport).default || isEmptyImport;

/**
 * Get the value at `path` starting from `object`. Allows optional chaining, e.g. `get(obj, 'a.b?.c')`
 */
export function getPath(
    object: any,
    path: string | PropertyKey | PropertyKey[],
    defaultValue?: any,
    allOptional = false
): any {
    if (isEmpty(path)) {
        return object;
    }

    const crumbs = typeof path === "string" ? path.match(/[^.\[\]]+/g) : [].concat(path);

    let value = object;
    for (const crumb of crumbs) {
        let key = crumb;
        let optional;
        if (typeof crumb === "string") {
            [, key, optional] = crumb.match(/^([^?]+)(\?)?$/);
        }

        value = value[key];

        if (isNil(value) && (optional || allOptional)) {
            return defaultValue;
        }
    }

    return isNil(value) ? defaultValue : value;
}
