import { code } from "@spscommerce/utils";

export const SpsFocusedTaskExamples = {
  basic: {
    examples: {
      basic: {
        react: code`
        function SpsFocusedTaskBasic() {
            const [showFocusedTask, setShowFocusedTask] = React.useState(false);

            function onClose() {
                setShowFocusedTask(false);
                console.log("onClose called");
            }

            function onFormSubmit() {
                setShowFocusedTask(false);
            }

            return (
                <React.Fragment>
                    <SpsButton onClick={() => setShowFocusedTask(true)}>Open Focused Task</SpsButton>
                    <SpsFocusedTask isOpen={showFocusedTask} onClose={onClose}>
                        <div style={{ width: "50%", margin: "auto" }}>
                            <h2>Focused Task</h2>
                            <SpsCard>
                                <i>pretend there's a form here</i>
                            </SpsCard>
                        </div>
                        <SpsFocusedTaskActions>
                          <SpsButton
                            kind="confirm"
                            onClick={onFormSubmit}
                          >
                            Submit
                          </SpsButton>
                        </SpsFocusedTaskActions>
                    </SpsFocusedTask>
                </React.Fragment>
             );
        }
        `
      }
    }
  }
};
