import { TooltipKind, TooltipShowTrigger } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { SpsTooltip, TooltipVisibility } from "../../tooltip/SpsTooltip";
import { contentOf, spsGlobalPropTypes } from "../../util";
import { SpsFormArray } from "../hooks/formArray";
import { SpsFormGroup } from "../hooks/formGroup";
import { SpsControlSet } from "../hooks/SpsControlSet.interface";
import { SpsCheckbox } from "../checkbox/SpsCheckbox";

const propsDoc = {
    errors: "ReactNodeOrRenderFn",
    formArray: "SpsFormArray<any>",
    formGroup: "SpsFormGroup",
    legend: "string",
    optional: "boolean",
    enabled: "boolean",
    onToggled: "(isEnabled: boolean) => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    errors: PropTypes.nodeOrRenderFn,
    formArray: PropTypes.impl<SpsFormArray<any>>(),
    formGroup: PropTypes.impl<SpsFormGroup>(),
    legend: PropTypes.string,
    optional: PropTypes.bool,
    enabled: PropTypes.bool,
    onToggled: PropTypes.fun<(isEnabled: boolean) => void>(),
};

export type SpsFieldsetProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsFieldset(props: SpsFieldsetProps) {
    const {
        children,
        className,
        errors,
        formArray,
        formGroup,
        legend,
        unsafelyReplaceClassName,
        optional = false,
        enabled,
        onToggled
    } = props;

    const formControlSet: SpsControlSet = formGroup || formArray;

    const legendElement: React.MutableRefObject<HTMLLegendElement> = React.useRef(null);
    const [showTip, setShowTip] = React.useState(TooltipVisibility.HIDDEN);
    const [wasFocused, setWasFocused] = React.useState();
    const [isChecked, setisChecked] = React.useState(false);

    React.useEffect(() => {
        if (enabled) {
            setisChecked(true);
        }
    }, []);

    React.useEffect(() => {
        if (typeof onToggled === "function") {
            onToggled(isChecked);
        }
    }, [isChecked]);

    React.useEffect(() => {
        if (!wasFocused && formControlSet.isFocused()) {
            setShowTip(TooltipVisibility.VISIBLE);
        } else if (wasFocused && !formControlSet.isFocused()) {
            setShowTip(TooltipVisibility.HIDDEN);
        }
        setWasFocused(formControlSet.isFocused());
    });

    const fieldsetClasses = clsx(
        unsafelyReplaceClassName || "sps-fieldset",
        formControlSet && !formControlSet.isPristine() && formControlSet.invalid && "sps-fieldset--error",
        className,
        optional && !isChecked && "sps-fieldset--collapsed",
    );

    return (
        <div className="sps-fieldset-container">
            <fieldset className={fieldsetClasses}>
                <legend className="sps-fieldset__legend" ref={legendElement}
                    onMouseEnter={() => setShowTip(TooltipVisibility.VISIBLE)}
                    onMouseLeave={() => setShowTip(TooltipVisibility.DELAYED_HIDDEN)}
                    onClick={() => setShowTip(showTip === TooltipVisibility.VISIBLE ? TooltipVisibility.HIDDEN : TooltipVisibility.VISIBLE)}
                >
                    {(optional) &&
                        <SpsCheckbox
                            checked={isChecked}
                            className="d-inline-flex"
                            onChange={e => {
                                setisChecked(!isChecked);
                            }}
                        />}{legend}</legend>
                <SpsTooltip for={legendElement} kind={TooltipKind.ERROR} showOn={TooltipShowTrigger.MANUAL} isShown={showTip}>
                    {contentOf(errors)}
                </SpsTooltip>
                {children}
            </fieldset>
        </div>
    );
}

Object.assign(SpsFieldset, {
    props: propsDoc,
    propTypes,
    displayName: "SpsFieldset"
});
