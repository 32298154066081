import { code } from "@spscommerce/utils";

export const SpsDescriptionListExamples = {
    basic: {
        label: "Basic Description List",
        examples: {
            basic: {
                jsx: code`
                    <SpsDl>
                        <SpsDt>Term 1</SpsDt>
                        <SpsDd>Definition</SpsDd>
                        <SpsDt>Term</SpsDt>
                        <SpsDd>Definition</SpsDd>
                        <SpsDt>
                            Term that wraps to the next line cause it's got so dang much text in it.
                        </SpsDt>
                        <SpsDd>Definition</SpsDd>
                        <SpsDt>Term</SpsDt>
                        <SpsDd>
                            Definition that has a LOOOOOOOOOOT of text in it.
                            Definition that has a LOOOOOOOOOOT of text in it.
                        </SpsDd>
                    </SpsDl>
                `
            },
            dynamic: {
                react: code`
                    function DemoComponent() {
                        const list = [
                            { term: "Term 1", definition: "Definition 1" },
                            { term: "Term 2", definition: "Definition 2" },
                            { term: "Term 3", definition: "Definition 3" },
                        ];

                        return (
                            <SpsDl>
                                {list.map((item, i) => (
                                    <React.Fragment>
                                        <SpsDt>{item.term}</SpsDt>
                                        <SpsDd>{item.definition}</SpsDd>
                                    </React.Fragment>
                                ))}
                            </SpsDl>
                        );
                    }
                `
            },
        },
    },
    advanced: {
        label: "Advanced Description List",
        examples: {
            advanced: {
                react: code`
                function DemoComponent() {
                    const form = useForm(
                      formGroup({
                        firstName: formControl(),
                        lastName: formControl("", {
                          validators: [SpsValidators.required]
                        }),
                        title: formControl(),
                        accomplishments: formControl()
                      })
                    )

                    React.useEffect(() => {
                      // Make the error state visible for this demo
                      form.get("lastName").markAsDirty()
                    }, [])

                    return (
                      <SpsCard>
                        <SpsForm formGroup={form}>
                          <SpsDl>
                            <SpsDt id="firstNameLabel" labelFor={form.get("firstName")}>
                              First Name
                            </SpsDt>
                            <SpsDd>
                              <SpsTextInput
                                aria-labelledby="firstNameLabel"
                                formControl={form.get("firstName")}
                              />
                            </SpsDd>
                            <SpsDt
                              id="lastNameLabel"
                              labelFor={form.get("lastName")}
                              errors={() =>
                                form.get("lastName").hasError("required") &&
                                "Please enter a last name."
                              }
                            >
                              Last Name
                            </SpsDt>
                            <SpsDd>
                              <SpsTextInput
                                aria-labelledby="lastNameLabel"
                                formControl={form.get("lastName")}
                              />
                            </SpsDd>
                            <SpsDt id="titleLabel" labelFor={form.get("title")}>
                              Title
                            </SpsDt>
                            <SpsDd>
                              <SpsTextInput
                                aria-labelledby="titleLabel"
                                formControl={form.get("title")}
                              />
                            </SpsDd>
                            <SpsDt
                              id="accomplishmentsLabel"
                              labelFor={form.get("accomplishments")}
                              help="If they haven't achieved anything, make stuff up."
                            >
                              Top 5 Greatest Accomplishments
                            </SpsDt>
                            <SpsDd>
                              <SpsTextarea
                                rows={3}
                                formControl={form.get("accomplishments")}
                                aria-labelledby="accomplishmentsLabel"
                              />
                            </SpsDd>
                          </SpsDl>
                        </SpsForm>
                      </SpsCard>
                    )
                  }
                `
            }
        }
    },
    compact: {
        label: "Compact Description List",
        examples: {
            compact: {
                jsx: code`
                    <SpsDl compact>
                      <SpsDt>Term 1</SpsDt>
                      <SpsDd>Definition</SpsDd>
                      <SpsDt>Term</SpsDt>
                      <SpsDd>Definition</SpsDd>
                      <SpsDt>Term that wraps to the next line cause it's got so dang much text in it.</SpsDt>
                      <SpsDd>Definition</SpsDd>
                      <SpsDt>Term</SpsDt>
                      <SpsDd>Definition that has a LOOOOOOOOOOT of text in it. Definition that has a LOOOOOOOOOOT of text in it.
                      </SpsDd>
                    </SpsDl>
                `
            }
        }
    },
    columnWidths: {
        label: "Alternate Column Widths",
        examples: {
            wideTerms: {
                jsx: code`
                    <SpsDl wideTerms>
                     <SpsDt>Term 1</SpsDt>
                     <SpsDd>Definition</SpsDd>
                     <SpsDt>Term</SpsDt>
                     <SpsDd>Definition</SpsDd>
                     <SpsDt>Term that wraps to the next line cause it's got so dang much text in it.</SpsDt>
                     <SpsDd>Definition</SpsDd>
                    </SpsDl>
                `
            },
            widerTerms: {
                jsx: code`
                    <SpsDl widerTerms>
                     <SpsDt>Term 1</SpsDt>
                     <SpsDd>Definition</SpsDd>
                     <SpsDt>Term</SpsDt>
                     <SpsDd>Definition</SpsDd>
                     <SpsDt>Term that wraps to the next line cause it's got so dang much text in it.</SpsDt>
                     <SpsDd>Definition</SpsDd>
                    </SpsDl>
                `
            },
        }
    }
};
