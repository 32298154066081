import { MetadataProperty, simpleMetadataDecoratorApplicator } from "@spscommerce/utils";

/** Metadata describing an "action" that can be passed in
 * to components such as modal and select.
 */
export interface SpsActionDescriptor {
    label?: MetadataProperty<string>;
    icon?: MetadataProperty<string>;
    caption?: MetadataProperty<string>;
    disabled?: MetadataProperty<boolean>;
    isLink?: MetadataProperty<boolean>;
    href?: MetadataProperty<string>;
}

export const SPS_ACTION_DEFAULTS: SpsActionDescriptor = {
    icon: "",
    label: "",
    caption: "",
    disabled: false,
    isLink: false
};

/** A method that has been decorated with `@SpsAction`. */
export interface SpsActionMethod extends SpsActionDescriptor {
    (...args: any[]): any;
}

/** Attaches metadata to the decorated method, designating it as an "action"
 * that can be passed in to components such as modal and select. For example,
 * if passed in to the modal, a button will be rendered in the modal using the
 * specified label, icon, etc, and clicking that button will call the method.
*/
export function SpsAction(actionDescriptor: SpsActionDescriptor): MethodDecorator {
    return (
        target: any,
        key: PropertyKey,
        descriptor: TypedPropertyDescriptor<any>
    ): TypedPropertyDescriptor<any> =>
        simpleMetadataDecoratorApplicator<SpsActionDescriptor, any>(
            actionDescriptor,
            SPS_ACTION_DEFAULTS,
            target,
            key,
            descriptor
        );
}
