import { SpinnerSize } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    alt: "string",
    size: "SpinnerSize",
    title: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    alt: PropTypes.string,
    size: PropTypes.enumValue<SpinnerSize>(SpinnerSize),
    title: PropTypes.string,
};

export type SpsSpinnerProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsSpinner(props: SpsSpinnerProps) {
    const {
        alt,
        className,
        size = SpinnerSize.MEDIUM,
        "data-testid": testId,
        title,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const altText = alt || title || t("design-system:spinner.defaultAltText");

    const spinnerClasses = clsx(
        unsafelyReplaceClassName || "sps-spinner",
        `sps-spinner--${size}`,
        className,
    );

    return (
        <i className={spinnerClasses} data-testid={testId} title={altText} {...rest}></i>
    );
}

Object.assign(SpsSpinner, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSpinner",
});
