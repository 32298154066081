export const TOOLTIP_HIDE_DELAY_MS_DEFAULT = 1000;

export enum TooltipShowTrigger {
    MANUAL = "manual",
    MOUSEOVER = "mouseover",
    CLICK = "click"
}

export enum TooltipKind {
    DEFAULT = "default",
    HELP = "help",
    ERROR = "error",
    WARNING = "warning",
    CONTAINER = "container"
}
