import { code } from "@spscommerce/utils";

export const SpsTooltipExamples = {
    basic: {
        label: "Basic",
        description: "Info about basic usage of tooltip",
        examples: {
            basic: {
                description: code`
                  <p>There are two ways to tell the tooltip what to attach to. The first is to
                  pass in a ref.</p>
                `,
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <div>
                            <div className="greybox" ref={target}>
                              target
                            </div>
                            <SpsTooltip for={target} title="Tooltip Title">
                              Tooltip content
                            </SpsTooltip>
                          </div>
                        );
                    }
                `
            },
            withElementId: {
                description: code`
                  <p>You can also pass in the ID of the element you want to attach to.</p>
                `,
                react: code`
                    function Component() {
                        return (
                          <React.Fragment>
                            <SpsButton id="testbtn" kind={ButtonKind.ICON} icon={SpsIcon.ELLIPSES} aria-labelledby="withEleIdTooltip" />
                            <SpsTooltip for="testbtn" id="withEleIdTooltip" title="Tooltip Title">
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    position: {
        label: "Position",
        examples: {
            topLeft: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div  className="greybox"  aria-labelledby="tooltip-target-top-left" ref={target} id="target-top-left">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-top-left" position={Position.TOP_LEFT}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            topMiddle: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-top-middle" >
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-top-middle" position={Position.TOP_MIDDLE}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            topRight: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-top-right">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-top-right" position={Position.TOP_RIGHT}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            rightTop: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-right-top">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-right-top" position={Position.RIGHT_TOP}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            rightMiddle: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-right-middle">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-right-middle" position={Position.RIGHT_MIDDLE}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            rightBottom: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-right-bottom">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-right-bottom" position={Position.RIGHT_BOTTOM}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            bottomRight: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-bottom-right">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-bottom-right" position={Position.BOTTOM_RIGHT}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            bottomMiddle: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-bottom-middle">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-bottom-middle" position={Position.BOTTOM_MIDDLE}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            bottomLeft: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-bottom-left">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-bottom-left" position={Position.BOTTOM_LEFT}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            leftBottom: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-left-bottom">
                              target
                            </div>
                            <SpsTooltip for={target}  id="tooltip-target-left-bottom" position={Position.LEFT_BOTTOM}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            leftMiddle: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-left-middle">
                              target
                            </div>
                            <SpsTooltip for={target} id="tooltip-target-left-middle" position={Position.LEFT_MIDDLE}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            leftTop: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="tooltip-target-left-top">
                              target
                            </div>
                            <SpsTooltip for={target}  id="tooltip-target-left-top" position={Position.LEFT_TOP}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
        }
    },
    style: {
        label: "Style",
        examples: {
            Help: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="helpTooltip">
                              target
                            </div>
                            <SpsTooltip for={target}  id="helpTooltip" kind={TooltipKind.HELP}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            error: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="errorTooltip">
                              target
                            </div>
                            <SpsTooltip for={target}  id="errorTooltip" kind={TooltipKind.ERROR}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            warning: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="warningTooltip">
                              target
                            </div>
                            <SpsTooltip for={target} id="warningTooltip" kind={TooltipKind.WARNING}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            },
            container: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="containerTooltip">
                              target
                            </div>
                            <SpsTooltip for={target} id="containerTooltip"  kind={TooltipKind.CONTAINER}>
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    showOnClick: {
        label: "Show on click",
        examples: {
            showOnClick: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <div>
                            <div className="greybox" ref={target}>
                              target
                            </div>
                            <SpsTooltip for={target} showOn={TooltipShowTrigger.CLICK}>
                              Tooltip content
                            </SpsTooltip>
                          </div>
                        );
                    }
                `
            }
        }
    },
    showHideManually: {
        label: "Programmatically show & hide",
        examples: {
            showHideManually: {
                react: code`
                    function Component() {
                        const target = React.useRef(null);
                        const [showTip, setShowTip] = React.useState(TooltipVisibility.HIDDEN);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} id="target-on-click">
                              target
                            </div>
                            <SpsTooltip
                              for={target}
                              showOn={TooltipShowTrigger.MANUAL}
                              isShown={showTip}
                              id="tooltip-target-on-click"
                              onDeferred={() => setShowTip(TooltipVisibility.HIDDEN)}
                            >
                              Tooltip content
                            </SpsTooltip>
                            <br />
                            <SpsButton
                              className="mr-1"
                              id="tooltip-target-on-click-show"
                              onClick={() => setShowTip(TooltipVisibility.VISIBLE)}
                              aria-labelledby="tooltip-target-on-click"
                            >
                              Show
                            </SpsButton>
                            <SpsButton
                              className="mr-1"
                              onClick={() => setShowTip(TooltipVisibility.DEFER_VISIBLE)}
                            >
                              Show, but only if no other tooltip is currently visible
                            </SpsButton>
                            <SpsButton
                              className="mr-1"
                              id="tooltip-target-on-click-hide"
                              onClick={() => setShowTip(TooltipVisibility.HIDDEN)}
                            >
                              Hide
                            </SpsButton>
                            <SpsButton
                              className="mr-1"
                              onClick={() => setShowTip(TooltipVisibility.DELAYED_HIDDEN)}
                            >
                              Hide with delay
                            </SpsButton>
                          </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    other: {
        label: "Other Settings",
        examples: {
            hideDelay: {
                description: code`
                  <p>You can customize the delay before the tooltip disappears
                  when the user moves the mouse away from the element the tooltip
                  is attached to.</p>
                `,
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <div id="hideDelayTooltip">
                            <div className="greybox" ref={target} aria-labelledby="hideDelayTooltip">
                              target
                            </div>
                            <SpsTooltip for={target} hideDelay={0}>
                              Tooltip content
                            </SpsTooltip>
                          </div>
                        );
                    }
                `
            },
            customPositioningOffsets: {
                description: "<p>Custom positioning offsets</p>",
                react: code`
                    function Component() {
                        const target = React.useRef(null);

                        return (
                          <React.Fragment>
                            <div className="greybox" ref={target} aria-labelledby="customTooltip">
                              target
                            </div>
                            <SpsTooltip  id="customTooltip" for={target} offsets="0, -100">
                              Tooltip content
                            </SpsTooltip>
                          </React.Fragment>
                        );
                    }
                `
            }
        }
    }
};
