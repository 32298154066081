import { code } from "@spscommerce/utils";

export const SpsPaginationExamples = {
    pagination: {
        label: "Pagination",
        description: "info about pagination",
        examples: {
            basic: {
                react: code`
                    function Component() {
                        const [page, setPage] = React.useState(2);
                        const [pageSize, setPageSize] = React.useState(25);

                        function handlePageChange(newPage, newPageSize) {
                          console.log("change", newPage, newPageSize);
                          setPage(newPage);
                          setPageSize(newPageSize);
                        }

                        return (
                          <React.Fragment>
                            <SpsPagination id="basic"
                              totalResults={123}
                              page={page}
                              pageSize={pageSize}
                              onPageChange={handlePageChange}
                            />
                            <span className="mr-2">page: {page}</span>
                            <span>pageSize: {pageSize}</span>
                          </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    pageSelector: {
        label: "Page selector",
        description: "info about page selector",
        examples: {
            basic: {
                react: code`
                    function Component() {
                        const pageChanged = val => {
                          console.log(val);
                        };
                        return <SpsPageSelector numPages={25} onPageChange={pageChanged} />;
                    }
                `
            }
        }
    }
};
