import { code } from "@spscommerce/utils";

export const SpsMultiSelectExamples = {
    basic: {
        label: "Basic",
        description:
            "<p>Multi select component</p>",
        examples: {
            basic: {
                react: code`
                    function Component() {
                        const [opts] = React.useState(["foo", "bar", "baz", "orp"])
                        const form = useForm(formGroup({
                            things: formControl([ opts[0] ])
                        }))

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("things")}>Things</SpsLabel>
                                <SpsMultiSelect
                                    options={opts}
                                    formControl={form.get("things")}
                                />
                                <br />
                                {JSON.stringify(form.get("things").value)}
                            </SpsForm>
                        )
                    }
                `
            },
            disabled: {
                react: code`
                    function Component() {
                        const [opts] = React.useState(["foo", "bar", "baz", "orp"])
                        const form = useForm(formGroup({
                            things: formControl([ opts[0] ])
                        }))

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("things")}>Things</SpsLabel>
                                <SpsMultiSelect
                                    options={opts}
                                    disabled={true}
                                    formControl={form.get("things")}
                                />
                                <br />
                                {JSON.stringify(form.get("things").value)}
                            </SpsForm>
                        )
                    }
                `
            },
            externalTags: {
                react: code`
                    function Component() {
                        const [foo, setFoo] = React.useState(["orp"])
                        const [opts] = React.useState(["foo", "bar", "baz", "orp"])
                        function handleChange(event) {
                            setFoo(event.target.value)
                        }
                        function removeByIndex(i) {
                            foo.splice(i, 1)
                            setFoo([...foo])
                        }
                        return (
                            <>
                                <SpsMultiSelect
                                    hideSelected
                                    options={opts}
                                    value={foo}
                                    onChange={handleChange}
                                />
                                <br />
                                {foo.map((opt, i) => (
                                    <SpsMultiSelectTag
                                        className="mt-1 ml-1"
                                        key={i}
                                        onRemove={() => removeByIndex(i)}
                                    >
                                        {opt}
                                    </SpsMultiSelectTag>
                                ))}
                            </>
                        )
                    }
                `
            },
            objects: {
                react: code`
                    function Component() {
                        const [opts] = React.useState([
                            { id: 1, name: "Target" },
                            { id: 2, name: "Cabela's" },
                            { id: 3, name: "Best Buy" },
                        ])
                        const [foo, setFoo] = React.useState([])
                        function handleChange(event) {
                            setFoo(event.target.value)
                        }
                        return (
                            <>
                                <SpsMultiSelect
                                    options={opts}
                                    textKey="name"
                                    value={foo}
                                    onChange={handleChange}
                                />
                                <br />
                                {JSON.stringify(foo)}
                            </>
                        )
                    }
                `
            }
        }
    }
};
