import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import clsx from "clsx";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes
};

export type SpsModalBodyProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsModalBody(props: SpsModalBodyProps) {
    const {
        children,
        className,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const bodyClass = unsafelyReplaceClassName || clsx("sps-modal__body", className);
    return (
        <div className={bodyClass} {...rest}>{children}</div>
    );
}

Object.assign(SpsModalBody, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModalBody"
});
