import clsx from "clsx";
import * as React from "react";
import { ButtonKind, SpsIcon } from "@spscommerce/ds-shared";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

import {
    SpsButton
} from "../button/SpsButton";

const propsDoc = {
    clearSelected: { type: "() => void", required: true },
    itemsSelected: { type: "string | number", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    clearSelected: PropTypes.fun<() => void>().isRequired,
    itemsSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export type SpsListActionBarProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsListActionBar(props: SpsListActionBarProps) {
    const {
        itemsSelected,
        clearSelected,
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const handleClearClick = () => {
        if (typeof clearSelected === "function") {
            clearSelected();
        }
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-list-action-bar-container",
        "z-stratum-bar",
        className,
    );

    return (
        (!!itemsSelected && <div className={classes} {...rest} data-testid={testId}>
            <div className="sps-list-action-bar">
                <div className="sps-list-action-bar__details">
                    <span className="sps-badge sps-badge--info" data-testid={`${testId}__badge`}>{itemsSelected}</span>
                    {t("design-system:listActionBar.itemsSelected")}
                </div>
                <div className="sps-list-action-bar__actions">{children}</div>
                <div className="sps-list-action-bar__clear">
                    <SpsButton
                        kind={ButtonKind.LINK}
                        icon={SpsIcon.X}
                        onClick={handleClearClick}
                        data-testid={`${testId}__clear-button`}
                    >
                        {t("design-system:listActionBar.clearSelected")}
                    </SpsButton>
                </div>
            </div>
        </div>)
    );
}

Object.assign(SpsListActionBar, {
    props: propsDoc,
    propTypes,
    displayName: "SpsListActionBar"
});
