import { code } from "@spscommerce/utils";

export const SpsDatepickerExamples = {
    basic: {
        label: "Basic",
        description: code`<p>info about basic datepicker</p>`,
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-2">
                            <SpsDatepicker id="basic"/>
                        </div>
                    </div>
                `
            }
        }
    },
    minMax: {
        label: "Min-max",
        description: code`<p>info about datepicker with min and max dates</p>`,
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-2">
                            <SpsDatepicker minDate="01/25/2019" maxDate="07/25/2019"/>
                        </div>
                    </div>
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        description: code`<p>info about disabled datepicker</p>`,
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-2">
                            <SpsDatepicker disabled id="disabled"/>
                        </div>
                    </div>
                `
            }
        }
    }
};
