export enum SortDirection {
    ASCENDING = "ascending",
    DESCENDING = "descending"
}

export interface SortedColumn {
    key: string;
    direction: SortDirection;
}

export type SortChangeHandler = (newSort: SortedColumn[]) => void;

export type SpsTableCellWrapWidth = 200 | 300 | 400 | 500 | 600;
