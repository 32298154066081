import { code } from "@spscommerce/utils";

export const SpsSideNavExamples = {
    basic: {
        label: "Basic",
        description: "basic side nav",
        examples: {
            simple: {
                react: code`
                    function DemoComponent() {
                      const tabs = ["tab1", "tab2"];

                      function onChange(selection) {
                        console.log(selection)
                        }

                        const items = [
  {
    sectionTitle: "SubTitle",
    navItems: [
      {
        name: "Top Level 1",
        subNavItems: [
          {
            name: "child 1",
          },
          {
            name: "child 2",
            subNavItems: [
              {
                name: "child 2 child",
              },
            ],
          },
        ],
      },
      {
        name: "Top Level 2",
        subNavItems: [
          {
            name: "child 3",
            tag: TagKind.KEY,
            tagContent: "Info",
          },
          {
            name: "child 4",
            tag: TagKind.KEY,
            subNavItems: [
              {
                name: "child 4 child 1",
                tag: TagKind.KEY,
                tagContent: "Great!",
              },
              {
                name: "child 4 child 2",
                tag: TagKind.KEY,
                tagContent: "Great!",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "SubTitle 2",
    navItems: [
      {
        name: "Top Level 3",
      },
      {
        name: "Top Level 4",
      },
      {
        name: "Top Level 5",
      },
    ],
  }
];

                      return (
                          <SpsSideNav
                              title ="Test Title"
                              titleIcon ={SpsIcon.HEART}
                              navOptions = {items}
                              activeNavItem ="child 3"
                              tabs = {tabs}
                              onNavChange = {onChange}
                              onTabChange = {onChange}
                              filterable = {true}
                              filterInputPlaceholder="filter navigation"
                          / >
                      )
                  }
                `
            },
        },
    },
};
