/** A custom event dispatcher for a component. `T` is the type of `event.detail`,
 * which will be set to whatever is passed in to `dispatch()`.
 */
export interface Dispatcher<T> {
    dispatch: (detail: T) => CustomEvent<T>;
}

/** Defines a component class property as a dispatcher for a custom event. */
export function EventDispatcher(eventName?: string): PropertyDecorator {
    return (target, key: string) => {
        Object.defineProperty(target, key, {
            get() {
                Object.defineProperty(this, key, {
                    value: {
                        dispatch: <T>(detail: T) => {
                            const event = new CustomEvent<T>(eventName || key, { detail });
                            this.dispatchEvent(event);
                            return event;
                        }
                    }
                });
                return this[key];
            },
            configurable: true,
        });
    };
}
