import { code } from "@spscommerce/utils";

export const SpsTextInputExamples = {
    standard: {
        label: "Standard",
        description: "info about standard text inputs",
        examples: {
            standard: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl("",{validators: [SpsValidators.required]})
                        }));
                        React.useEffect(() => {
                            form.get("companyName").markAsDirty();
                        },[])

                        return (
                            <SpsForm id="basicTextInput" formGroup={form}>
                                <SpsLabel for={form.get("companyName")}>
                                    Company Name
                                </SpsLabel>
                                <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    placeholder: {
        label: "Placeholder",
        description: "info about text input placeholders",
        examples: {
            placeholder: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyNameWithPlaceholder: formControl()
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("companyNameWithPlaceholder")}>
                                    Company Name
                                </SpsLabel>
                                <SpsTextInput formControl={form.get("companyNameWithPlaceholder")} placeholder="Enter a company name"></SpsTextInput>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    description: {
        label: "Description",
        description: "info about text inputs with descriptions",
        examples: {
            description: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            textInputWithDescription: formControl()
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("textInputWithDescription")}
                                    description="This is a brief description of the field to provide additional context."
                                >
                                    Label
                                </SpsLabel>
                                <SpsTextInput formControl={form.get("textInputWithDescription")}/>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        description: "info about disabled text inputs",
        examples: {
            disabled: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            email: formControl("foo@bar.com")
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("email")}>
                                    Email Address
                                </SpsLabel>
                                <SpsTextInput id="disabledTextInput" disabled formControl={form.get("email")}></SpsTextInput>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    }
};
