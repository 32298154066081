import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    heading: "string",
    icon: "SpsIcon",
};

const propTypes = {
    ...spsGlobalPropTypes,
    heading: PropTypes.string,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
};

export type SpsWorkflowProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsWorkflow(props: SpsWorkflowProps) {
    const {
        children,
        className,
        icon,
        title,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-workflow",
        className,
    );

    return (
        <div className={classes} data-testid={`${testId}`} {...rest}>
            {title &&
                <h1 className="sps-workflow__heading" data-testid={`${testId}__title`}>
                    <span className="sps-workflow__icon" data-testid={`${testId}__icon`}>
                        <i aria-hidden="true" className={clsx("sps-icon", `sps-icon-${icon}`)}></i>
                    </span>
                    {title}
                </h1>
            }
            {children}
        </div>
    );
}

Object.assign(SpsWorkflow, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWorkflow"
});

export function SpsWf(props: SpsWorkflowProps) {
    return SpsWorkflow(props);
}

Object.assign(SpsWf, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWf"
});
