import { code } from "@spscommerce/utils";

export const SpsSummarylistViewExamples = {
    basic: {
        label: "Basic Usage",
        examples: {
            basic: {
                jsx: code`
                    <>
                        <SpsSummaryListRow>
                            <SpsSummaryListColumn width="2.8rem" lean>
                                <SpsI icon={SpsIcon.STAR} size={SpsIconSize.MEDIUM} className="d-block mb-2 yellow300" />
                                <SpsI icon={SpsIcon.FLAG} size={SpsIconSize.MEDIUM} className="d-block yellow300" />
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn>
                                <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                <div className="d-inline-block align-middle">
                                    <div className="fs-18">
                                        <a href="#">Heading</a>
                                    </div>
                                    <div className="fs-14">Sub Heading</div>
                                </div>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="13.5rem">
                                <SpsKeyValueList inline>
                                    <SpsKeyValueListItem itemKey={"Title"}>
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey={"Long Title"}>
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey={"Title"}>
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="9rem">
                                <SpsKeyValueList>
                                    <SpsKeyValueListItem itemKey="Buying From">
                                        75 Companies
                                        <div className="font-weight-light">38 of 75 Operational</div>
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="3.6rem" leaner>
                                <SpsDropdown
                                    label=""
                                    icon="ellipses"
                                    options={[[{ label: "Option A" }, () => console.log("Option A")], [{ label: "Option B" }, () => console.log("Option B")]]}
                                />
                            </SpsSummaryListColumn>
                        </SpsSummaryListRow>
                        <SpsSummaryListRow>
                            <SpsSummaryListColumn width="2.8rem" lean>
                                <SpsI icon={SpsIcon.STAR} size={SpsIconSize.MEDIUM} className="d-block mb-2 yellow300" />
                                <SpsI icon={SpsIcon.FLAG} size={SpsIconSize.MEDIUM} className="d-block yellow300" />
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn>
                                <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                <div className="d-inline-block align-middle">
                                    <div className="fs-18">
                                        <a href="#">Heading</a>
                                    </div>
                                    <div className="fs-14">Sub Heading</div>
                                </div>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="13.5rem">
                                <SpsKeyValueList inline>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Long Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="9rem">
                                <SpsKeyValueList>
                                    <SpsKeyValueListItem itemKey="Buying From">
                                        75 Companies
                                        <div className="font-weight-light">38 of 75 Operational</div>
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="3.6rem" leaner>
                                <SpsDropdown
                                    label=""
                                    icon="ellipses"
                                    options={[[{ label: "Option A" }, () => console.log("Option A")], [{ label: "Option B" }, () => console.log("Option B")]]}
                                />
                            </SpsSummaryListColumn>
                        </SpsSummaryListRow>
                    </>`
            }
        }
    },
    expandable: {
        label: "Expandable",
        description: code`
            <p>If you put a <code>&lt;SpsSummaryListExpansion&gt;&lt;/SpsSummaryListExpansion&gt;</code>
            inside a Summary List Row, then it will be expandable to show the content in the expansion
            element. It can be toggled by clicking on it or by tabbing focus to it and hitting ENTER
            or SPACEBAR.</p>
        `,
        examples: {
            expandable: {
                jsx: code`
                    <>
                        <SpsSummaryListRow>
                            <SpsSummaryListColumn width="2.8rem" lean>
                                <SpsI icon={SpsIcon.STAR} size={SpsIconSize.MEDIUM} className="d-block mb-2 yellow300" />
                                <SpsI icon={SpsIcon.FLAG} size={SpsIconSize.MEDIUM} className="d-block yellow300" />
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn>
                                <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                <div className="d-inline-block align-middle">
                                    <div className="fs-18">
                                        <a href="#">Heading</a>
                                    </div>
                                    <div className="fs-14">Sub Heading</div>
                                </div>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="13.5rem">
                                <SpsKeyValueList inline>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Long Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="9rem">
                                <SpsKeyValueList>
                                    <SpsKeyValueListItem itemKey="Buying From">
                                        75 Companies
                                        <div className="font-weight-light">38 of 75 Operational</div>
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="3.6rem" leaner>
                                <SpsDropdown
                                    label=""
                                    icon="ellipses"
                                    options={[[{ label: "Option A" }, () => console.log("Option A")], [{ label: "Option B" }, () => console.log("Option B")]]}
                                />
                            </SpsSummaryListColumn>
                            <SpsSummaryListExpansion>
                                <SpsCard
                                    headerContent={() => <span>Custom Header</span>}>
                                    <div>Content</div>
                                </SpsCard>
                                <div className="text-center mt-3">
                                    <SpsButton kind={ButtonKind.LINK}>Show More</SpsButton>
                                </div>
                            </SpsSummaryListExpansion>
                        </SpsSummaryListRow>
                    </> `
            }
        }
    },
    borderless: {
        label: "Removing Borders",
        description: code`
            <p>Placing the <code>borderless</code> prop on a column will remove its left border.</p>
        `,
        examples: {
            borderless: {
                jsx: code`
                    <>
                        <SpsSummaryListRow>
                            <SpsSummaryListColumn>
                                <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                <div className="d-inline-block align-middle">
                                    <div className="fs-18">
                                        <a href="#">Heading</a>
                                    </div>
                                    <div className="fs-14">Sub Heading</div>
                                </div>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="13.5rem" borderless>
                                <SpsKeyValueList inline>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Long Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                    <SpsKeyValueListItem itemKey="Title">
                                        Sub copy goes here
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="9rem" borderless>
                                <SpsKeyValueList>
                                    <SpsKeyValueListItem itemKey="Buying From">
                                        75 Companies
                                        <div className="font-weight-light">38 of 75 Operational</div>
                                    </SpsKeyValueListItem>
                                </SpsKeyValueList>
                            </SpsSummaryListColumn>
                            <SpsSummaryListColumn width="3.6rem" leaner>
                                <SpsDropdown
                                    label=""
                                    icon="ellipses"
                                    options={[[{ label: "Option A" }, () => console.log("Option A")], [{ label: "Option B" }, () => console.log("Option B")]]}
                                />
                            </SpsSummaryListColumn>
                        </SpsSummaryListRow>
                    </>`
            }
        }
    },
    selectable: {
        label: "Selectable",
        description: code`
            <p>By placing a column with a checkbox and wiring that checkbox to control the
            <code>selected</code> prop on the row, you can make rows selectable.</p>
        `,
        examples: {
            selectable: {
                react: code`
                    function Component() {
                        const [rowIsSelected, setRowIsSelected] = React.useState(true);

                        function handleSelectionChange(event) {
                            setRowIsSelected(event.target.checked);
                        }

                        return (
                            <SpsSummaryListRow selected={rowIsSelected}>
                                <SpsSummaryListColumn width="2.8rem" lean>
                                    <SpsCheckbox aria-label="Row Selected" checked={rowIsSelected} onChange={handleSelectionChange}/>
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn width="2.8rem" lean>
                                    <SpsI icon={SpsIcon.STAR} size={SpsIconSize.MEDIUM} className="d-block mb-2 yellow300" />
                                    <SpsI icon={SpsIcon.FLAG} size={SpsIconSize.MEDIUM} className="d-block yellow300" />
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn>
                                    <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                    <div className="d-inline-block align-middle">
                                        <div className="fs-18">
                                            <a href="#">Heading</a>
                                        </div>
                                        <div className="fs-14">Sub Heading</div>
                                    </div>
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn width="13.5rem">
                                    <SpsKeyValueList inline>
                                        <SpsKeyValueListItem itemKey="Title">
                                            Sub copy goes here
                                        </SpsKeyValueListItem>
                                        <SpsKeyValueListItem itemKey="Long Title">
                                            Sub copy goes here
                                        </SpsKeyValueListItem>
                                        <SpsKeyValueListItem itemKey="Title">
                                            Sub copy goes here
                                        </SpsKeyValueListItem>
                                    </SpsKeyValueList>
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn width="9rem">
                                    <SpsKeyValueList>
                                        <SpsKeyValueListItem itemKey="Buying From">
                                            75 Companies
                                            <div className="font-weight-light">38 of 75 Operational</div>
                                        </SpsKeyValueListItem>
                                    </SpsKeyValueList>
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn width="3.6rem" leaner>
                                    <SpsDropdown
                                        label=""
                                        icon="ellipses"
                                        options={[[{ label: "Option A" }, () => console.log("Option A")], [{ label: "Option B" }, () => console.log("Option B")]]}
                                    />
                                </SpsSummaryListColumn>
                            </SpsSummaryListRow>
                        );
                    }
                `
            }
        }
    },
};
