import { code } from "@spscommerce/utils";

export const SpsToggleExamples = {
    small: {
        label: "Small",
        description: "info about small toggle buttons",
        examples: {
            small: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            toggle: formControl()
                        }));

                        return (
                            <SpsForm id="small-toggle" formGroup={form}>
                                <SpsToggle formControl={form.get("toggle")}/>
                            </SpsForm>
                        )
                    }
                `
            },
            changeHandler: {
                description: "With UseState",
                react: code`
                    function DemoComponent() {
                        function changeHandler() {
                            setChecked(!checked);
                        }
                        const [checked, setChecked] = React.useState();
                        return (
                            <SpsToggle onChange={changeHandler}/>
                        )
                    }
                `
            }
        }
    },
    large: {
        label: "Large",
        description: "info about large toggle buttons",
        examples: {
            large: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            toggle: formControl()
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsToggle large formControl={form.get("toggle")}/>
                            </SpsForm>
                        )
                    }
                `
            },
            changeHandler: {
                description: "With UseState",
                react: code`
                    function DemoComponent() {
                        function changeHandler() {
                            setChecked(!checked);
                        }
                        const [checked, setChecked] = React.useState();
                        return (
                            <SpsToggle large onChange={changeHandler}/>
                        )
                    }
                `
            }
        }
    },
    statusLabel: {
        label: "Status Label",
        description: "toggles with status labels",
        examples: {
            label: {
                react: code`
                    function DemoComponent() {
                        function changeHandler() {
                            setChecked(!checked);
                        }
                        const [checked, setChecked] = React.useState();
                        return (
                            <SpsToggle
                                activeLabel="ACTIVE"
                                activeDescription="active description"
                                onChange={changeHandler}
                                inactiveLabel="INACTIVE"
                                inactiveDescription="inactive description" />
                        )
                    }
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        description: "info about disabled toggles",
        examples: {
            disabled: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            toggle: formControl()
                        }));
                        return (
                            <SpsForm
                                id="disabled-toggle"
                                formGroup={form}
                            >
                                <SpsToggle
                                    disabled
                                    formControl={form.get("toggle")}
                                    activeLabel="ACTIVE"
                                    inactiveLabel="INACTIVE"
                                />
                            </SpsForm>
                        )
                    }
                `
            }
        }
    }
};
