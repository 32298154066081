import { code } from "@spscommerce/utils";

export const NgSpsNavigationTabsExamples = {
  basic: {
    label: "Basic Navigation Tabs",
    description: code`
      <p>Navigation tabs native web component</p>
    `,
    examples: {
      basic: {
        template: code`
            <sps-nav-tab-set>
                <sps-nav-tab
                    *ngFor="let tab of tabs"
                    [attr.icon]="tab.icon"
                    [attr.label]="tab.label"
                    [attr.tag]="tab.tag"
                    [attr.active]="tab === activeTab || null"
                    (click)="activeTab = tab"
                ></sps-nav-tab>
            </sps-nav-tab-set>
        `,
        class: code`
            class MyComponent implements OnInit {
                activeTab;
                tabs = [
                    { label: "Tab A", icon: "chart-line" },
                    { label: "Tab B", icon: "chart-bar", tag: "12" },
                    { label: "Tab C", icon: "chart-pie" },
                ]

                ngOnInit() {
                    this.activeTab = this.tabs[0];
                }
            }
        `,
      },
    }
  },
};

export const ReactSpsNavigationTabsExamples = {
    basic: {
        label: "Basic Navigation Tabs",
        description: code`
          <p>Navigation tabs native web component</p>
        `,
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const tabs = [
                            { label: "Tab A", icon: "chart-line" },
                            { label: "Tab B", icon: "chart-bar", tag: "12" },
                            { label: "Tab C", icon: "chart-pie" }
                        ]

                        const [activeTab, setActiveTab] = React.useState(0)

                        return (
                        <sps-nav-tab-set>
                            {tabs.map((tab, index) => (
                                <sps-nav-tab
                                    key={index}
                                    icon={tab.icon}
                                    label={tab.label}
                                    tag={tab.tag}
                                    active={index === activeTab || null}
                                    onClick={() => setActiveTab(index)}
                                />
                            ))}
                        </sps-nav-tab-set>
                        )
                    }
                `,
            },
        }
    },
};
