/** @jsx h */
import { ClassBindings, Component, Prop } from "../../decorators/index";
import { h } from "../../utils/pragma";
import { SpsIcon } from "@spscommerce/ds-shared";

@Component({ tag: "sps-nav-tab" })
export class SpsNavTabComponent extends HTMLElement {
    static readonly displayName = "sps-nav-tab";
    static readonly props = {
        active: "boolean",
        label: "string",
        icon: "SpsIcon",
        tag: "string",
        href: "string",
        target: "string",
    };

    @Prop() active = false;
    @Prop() label = "";
    @Prop() icon: SpsIcon;
    @Prop() tag = "";
    /** If you need the tab to operate as an HTML link, provide an href. */
    @Prop() href: string;
    /** If you provide href, target is the same attribute as on an <a> element. */
    @Prop() target: string;

    get [ClassBindings]() {
        return [
            "sps-tabbed-nav__nav-item",
            this.active && "sps-tabbed-nav__nav-item--active",
        ];
    }

    render() {
        return <a href={this.href} target={this.target} className="sps-tabbed-nav__nav-item-link" tabIndex={0}>
            {this.icon && <i className={`sps-icon sps-icon-${this.icon}`}/>}
            {this.label && <span>{this.label}</span>}
            {this.tag && <span className="sps-badge sps-badge--default">{this.tag}</span>}
        </a>;
    }
}
