import { ButtonKind, SpsTab, TagKind } from "@spscommerce/ds-shared";
import { lockToAnimationFrames } from "@spscommerce/utils";
import clsx from "clsx";
import * as React from "react";

import { SpsButton } from "../button/SpsButton";
import * as PropTypes from "../prop-types";
import { SpsTabs } from "../tabs/SpsTabs";
import { SpsTag } from "../tag/SpsTag";
import { selectChildren, spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";
import { SpsListToolbarSearch } from "./SpsListToolbarSearch";

const propsDoc = {
    activeTab: "SpsTab",
    advancedSearch: "{ isOpen: boolean, enteredFields: number }",
    onToggleAdvancedSearch: "(boolean) => void",
    onTabChange: "(SpsTab) => void",
    title: "string",
    tabs: "Array<SpsTab>",
    onToolbarPinned: "(boolean) => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    activeTab: PropTypes.impl<SpsTab>(),
    advancedSearch: PropTypes.shape({
        isOpen: PropTypes.bool,
        enteredFields: PropTypes.number
    }),
    onToggleAdvancedSearch: PropTypes.fun<(boolean) => void>(),
    onTabChange: PropTypes.fun<(SpsTab) => void>(),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf<SpsTab>(PropTypes.impl<SpsTab>()),
    onToolbarPinned: PropTypes.fun<(boolean) => void>()
};

export type SpsListToolbarProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsListToolbar(props: SpsListToolbarProps) {
    const {
        activeTab,
        advancedSearch,
        children,
        className,
        onToggleAdvancedSearch,
        onTabChange,
        tabs,
        title,
        unsafelyReplaceClassName,
        onToolbarPinned,
        "data-testid": testId,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    let unpinScrollY: number = null;
    const [isPinned, setIsPinned] = React.useState(false);

    const rootElement = React.useRef<HTMLDivElement>();

    const onScroll = lockToAnimationFrames(function () {
        if (rootElement.current) {
            const rect = rootElement.current.getBoundingClientRect();
            if (rect && rect.height > 0 && rect.top <= 60) {
                if (typeof unpinScrollY === "number") {
                    if (window.scrollY < unpinScrollY) {
                        unpinScrollY = null;
                        setIsPinned(false);
                    }
                } else {
                    unpinScrollY = window.scrollY;
                    setIsPinned(true);
                }
            }
        }
    });

    React.useEffect(() => {
        emitToolbarPinned(isPinned);
    }, [isPinned]);

    React.useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    function handleTabChange(tab: SpsTab) {
        if (onTabChange) {
            onTabChange(tab);
        }
    }

    function toggleAdvancedSearch() {
        if (onToggleAdvancedSearch) {
            onToggleAdvancedSearch(!advancedSearch.isOpen);
        }
    }

    function emitToolbarPinned(status) {
        if (onToolbarPinned) {
            onToolbarPinned(status);
        }
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-list-toolbar",
        isPinned && "sps-list-toolbar--pinned",
        isPinned && "z-stratum-bar",
        advancedSearch && advancedSearch.isOpen && "sps-list-toolbar--advanced-search-open",
        className,
    );

    const [
        listToolbarSearch,
        otherChildren
    ] = selectChildren(children, [
        { type: SpsListToolbarSearch }
    ]);

    return (
        <div className={classes} data-testid={testId} ref={rootElement} {...rest}>
            {tabs && <SpsTabs tabs={tabs} onTabChange={handleTabChange} activeTab={activeTab} data-testid={`${testId}__tabs`} />}
            <div className="sps-list-toolbar__search-controls">
                {!title && listToolbarSearch}
                {listToolbarSearch.length > 0 && advancedSearch && (
                    <div
                        className="sps-list-toolbar__advanced-search-toggle"
                        data-testid={`${testId}__advanced-search`}
                    >
                        <div className="sps-list-toolbar__advanced-search-toggle-button-wrapper">
                            <SpsButton kind={ButtonKind.LINK} onClick={toggleAdvancedSearch}>
                                {t("design-system:listToolbar.advancedSearchToggle")}
                            </SpsButton>
                        </div>
                        {advancedSearch.enteredFields > 0 && (
                            <SpsTag kind={TagKind.INFO}>
                                <span>{advancedSearch.enteredFields}</span>
                            </SpsTag>
                        )}
                    </div>
                )}
                {title &&
                    (
                        <div data-testid={`${testId}-title`} className="sps-list-toolbar__advanced-search-title">
                            {title}
                        </div>
                    )

                }
            </div>
            <div className="sps-list-toolbar__buttons">{otherChildren}</div>
        </div>
    );
}

Object.assign(SpsListToolbar, {
    props: propsDoc,
    propTypes,
    displayName: "SpsListToolbar",
});
