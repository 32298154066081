import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    compact: "boolean",
    wideTerms: "boolean",
    widerTerms: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    compact: PropTypes.bool,
    wideTerms: PropTypes.bool,
    widerTerms: PropTypes.bool,
};

export type SpsDescriptionListProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsDescriptionList(props: SpsDescriptionListProps) {
    const {
        children,
        className,
        compact = false,
        "data-testid": testId,
        unsafelyReplaceClassName,
        wideTerms = false,
        widerTerms = false,
        ...rest
    } = props;

    const childrenWithProps = React.Children.map(children, (child: any, index) => {
        if (!(child.type.toString() === React.Fragment.toString())) {
            const rowIndex = parseInt("" + index / 2, 10) + 1;
            return React.cloneElement(child, {
                style: { ...child.props.style, msGridRow: rowIndex }
            });
        } else {
            // During dynamic dt and dt creation they will be received in Fragment.
            const nestedChildrens = child.props.children;
            const fragmentChildrens = React.Children.map(nestedChildrens, chld => {
                return React.cloneElement(chld, {
                    style: { ...chld.props.style, msGridRow: index + 1 }
                });
            });
            return fragmentChildrens;
        }
    });

    const classes = clsx(
        unsafelyReplaceClassName || "sps-description-list",
        compact && "sps-description-list--compact",
        wideTerms && "sps-description-list--wide-terms",
        widerTerms && "sps-description-list--wider-terms",
        className,
    );

    return (
        <dl className={classes} data-testid={`${testId}`} {...rest}>
            {childrenWithProps}
        </dl>
    );
}

Object.assign(SpsDescriptionList, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionList / SpsDl"
});

export function SpsDl(p: SpsDescriptionListProps) {
    return SpsDescriptionList(p);
}

Object.assign(SpsDl, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionList / SpsDl"
});
