import * as React from "react";
import clsx from "clsx";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    disabled: "boolean",
    min: "number",
    max: "number",
    step: "number",
    onValueChange: "(newValue: number) => void",
};

const propTypes = {
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onValueChange: PropTypes.fun<(newValue: number) => void>(),
    ...spsGlobalPropTypes
};

export type SpsIncrementorProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsIncrementor(props: SpsIncrementorProps) {
    const {
        disabled,
        min,
        max,
        step = 1,
        onValueChange,
        unsafelyReplaceClassName,
        className,
        "data-testid": testId,
        ...rest
    } = props;

    const [value, setValue] = React.useState(min ? min : 0);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        if (onValueChange && typeof onValueChange === "function") {
            onValueChange(value);
        }
        if ((min && value >= min) || (max && value <= max)) {
            if (hasError) {
                setHasError(false);
            }
        } else if (min || max) {
            setHasError(true);
        }
    }, [value]);

    const minus = () => {
        if (value <= min) {
            return false;
        }

        if (value > max) {
            setValue(max);
        } else {
            setValue(v => v - step);
        }
    };

    const plus = () => {
        if (value >= max) {
            return false;
        }

        if (value < min) {
            setValue(min);
        } else {
            setValue(v => v + step);
        }
    };

    const onInputChange = e => {
        if (e.target.value !== "") {
            setValue(parseInt(e.target.value, 10));
        }
    };

    const classes =
        unsafelyReplaceClassName ||
        clsx("sps-incrementor sps-input-group sps-input-group__append", className);

    return (
        <div className={classes} {...rest} data-testid={testId}>
            <div className="sps-btn sps-btn--icon">
                <button data-testid={`${testId}__minus`} onClick={minus} disabled={disabled} aria-label={`Subtract ${step}`}>
                    <i className="sps-icon sps-icon-minus" aria-hidden="true" />
                </button>
            </div>
            <div className="sps-text-input">
                <input
                    type="text"
                    className={clsx("sps-form-control", hasError && "sps-input--error")}
                    value={value}
                    onChange={onInputChange}
                    disabled={disabled}
                    data-testid={`${testId}__input`}
                />
            </div>
            <div className="sps-btn sps-btn--icon">
                <button data-testid={`${testId}__plus`} onClick={plus} disabled={disabled} aria-label={`Add ${step}`}>
                    <i className="sps-icon sps-icon-plus-sign" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
}

Object.assign(SpsIncrementor, {
    props: propsDoc,
    propTypes,
    displayName: "SpsIncrementor",
});

