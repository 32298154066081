/** Parses a string as a file size and returns the size in bytes. For example,
 * `parseFileSize("2KB")` will return `2048`.
 */
export function parseFileSize(fileSize: string): number {
    const unitMatch = fileSize.match(/[A-Z]?B$/);
    if (!unitMatch) {
        throw new Error(
            `Input to parseFileSize ("${fileSize}") doesn't seem like a file size; ` +
            "cannot identify a unit (e.g. KB, GB)"
        );
    }

    const [unit] = unitMatch;
    const num = +fileSize.replace(unit, "");
    if (Number.isNaN(num)) {
        throw new Error(`Could not parse a number out of input to parseFileSize ("${fileSize}")`);
    }

    const magnitude = ["B", "KB", "MB", "GB", "TB", "PB"].indexOf(unit);
    if (magnitude === -1) {
        throw new Error("parseFileSize does not support units above PB");
    }

    return Math.ceil(num * Math.pow(1024, magnitude));
}
