import * as isEmptyImport from "lodash.isempty";

import { crumblePath } from "./crumble-path";

const isEmpty = (<any>isEmptyImport).default || isEmptyImport;

/** Set the value at `path` on `object`. */
export function setPath(object: any, path: PropertyKey | PropertyKey[], newValue: any) {
    if (typeof object !== "object") {
        throw new Error("object is required");
    }

    if (isEmpty(path)) {
        throw new Error("path is required");
    }

    const crumbs = crumblePath(path);
    const finalCrumb = crumbs.splice(crumbs.length - 1, 1)[0];

    let current = object;
    for (const crumb of crumbs) {
        current = current[crumb];
        if (typeof current !== "object") {
            throw new Error(`Cannot set value at given path: Path deadends at ${String(crumb)}`);
        }
    }

    current[finalCrumb] = newValue;
}
