import * as React from "react";

import { SpsInputGroup } from "../form/input-group/SpsInputGroup";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    onSubmit: "FormEventHandler",
};

const propTypes = {
    ...spsGlobalPropTypes,
    onSubmit: PropTypes.fun<React.FormEventHandler>(),
};

export type SpsListToolbarSearchProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsListToolbarSearch(props: SpsListToolbarSearchProps) {
    const {
        children,
        onSubmit,
    } = props;

    function handleSubmit(event) {
        event.preventDefault();
        if (onSubmit) {
            onSubmit(event);
        }
    }

    return (
        <form onSubmit={handleSubmit}
            className="sps-list-toolbar__search-form"
            noValidate
        >
            <SpsInputGroup className="sps-list-toolbar__search-field">
                {children}
            </SpsInputGroup>
        </form>
    );
}

Object.assign(SpsListToolbarSearch, {
    props: propsDoc,
    propTypes,
    displayName: "SpsListToolbarSearch"
});
