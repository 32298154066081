import { code } from "@spscommerce/utils";

export const SpsZeroStatesExamples = {
    regular: {
        label: "Regular",
        examples: {
            regular: {
                jsx: code`
                    <SpsZeroState
                        heading="No records found"
                        artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-error.svg"
                    />
                `
            },
            withButton: {
                jsx: code`
                    <SpsZeroState
                        heading="Search for a record"
                        subHeading="Add new companies by clicking the Add button below."
                        artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-empty-search@2x.svg"
                    >
                        <SpsButton
                            aria-label="search"
                            kind={ButtonKind.CONFIRM}
                        >Search Record</SpsButton>
                    </SpsZeroState>
                `
            },
            withSearch: {
                react: code`
                    function Component () {
                        const [searchText, setSearchText] = React.useState("");

                        function handleChange(e) {
                            setSearchText(e.target.value)
                        }

                        return (
                            <SpsZeroState
                              heading="Search for a record"
                              subHeading="Find companies by using the search box below."
                              artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-empty-search@2x.svg"
                            >
                                <SpsInputGroup>
                                    <SpsTextInput
                                        name="search"
                                        value={searchText}
                                        placeholder="Search for record"
                                        onChange={handleChange}
                                    />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.SEARCH} aria-label="Search"/>
                                </SpsInputGroup>
                            </SpsZeroState>
                        );
                      }
                `
            }
        }
    },
    micro: {
        label: "Micro",
        examples: {
            inCard: {
                jsx: code`
                    <SpsCard headerTitle="Attributes" className="col-3">
                        <SpsMicroZeroState
                            heading="This item doesn't have any attributes yet."
                            icon={SpsIcon.ITEM_TAG}
                        />
                    </SpsCard>
                `
            },
            inWorkflow: {
                jsx: code`
                    <SpsWf icon="list-summary" title="Workflow" className="col-3">
                        <SpsMicroZeroState
                            heading="No Document Found"
                            subHeading="There are no documents."
                            icon={SpsIcon.FILE}
                        />
                    </SpsWf>
                `
            }
        }
    }
};
