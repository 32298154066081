// This import nonsense is because ng-packagr uses rollup and rollup sucks
import * as isPlainObjectImport from "lodash.isplainobject";
const isPlainObject = (<any>isPlainObjectImport).default || isPlainObjectImport;

/**
 * Recursively navigate a nested object & call `fn` for each key/value pair
 */
export function forEachEntryDeep(
    object: any,
    fn: (key: string, value: any, object: any) => any
): any {
    for (const key of Object.keys(object)) {
        const value = object[key];
        if (isPlainObject(value) || Array.isArray(value)) {
            forEachEntryDeep(value, fn);
        } else {
            fn(key, value, object);
        }
    }
}
