import * as React from "react";
import clsx from "clsx";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    borderless: "boolean",
    lean: "boolean",
    leaner: "boolean",
    width: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    borderless: PropTypes.bool,
    lean: PropTypes.bool,
    leaner: PropTypes.bool,
    width: PropTypes.string,
};

export type SpsSummaryListColumnProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsSummaryListColumn(props: SpsSummaryListColumnProps) {
    const {
        className,
        children,
        borderless,
        lean,
        leaner,
        "data-testid": testId,
        unsafelyReplaceClassName,
        width,
        ...rest
    } = props;

    const classes =  clsx(
        unsafelyReplaceClassName || "sps-content-row__col",
        lean && "sps-content-row__col--lean",
        leaner && "sps-content-row__col--leaner",
        borderless && "sps-content-row__col--borderless",
        className,
    );

    return (
        <div className={classes}
            style={{ width }}
            {...rest}
            data-testid={`${testId}__col`}
        >
            {children}
        </div>
    );
}

Object.assign(SpsSummaryListColumn, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSummaryListColumn"
});
