import { SpsIcon } from "./icon";

export enum FeedbackBlockKind {
    TIP = "tip",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    INFO = "info",
}

export const FeedbackBlockIcons = Object.freeze({
    [FeedbackBlockKind.TIP]: SpsIcon.LIGHTBULB,
    [FeedbackBlockKind.ERROR]: SpsIcon.EXCLAMATION_CIRCLE,
    [FeedbackBlockKind.INFO]: SpsIcon.INFO_CIRCLE,
    [FeedbackBlockKind.SUCCESS]: SpsIcon.CHECKMARK,
    [FeedbackBlockKind.WARNING]: SpsIcon.EXCLAMATION_TRIANGLE,
});
