// This import nonsense is because ng-packagr uses rollup and rollup sucks
import * as isPlainObjectImport from "lodash.isplainobject";
const isPlainObject = (<any>isPlainObjectImport).default || isPlainObjectImport;

import { copyProperty } from "./copy-property";

/**
 * Recursive merge of any number of objects using `copyProperty()` rather than assignment
 */
export function mergePropertiesDeep(...objectsToMerge: any[]): any {
    const result = {};
    for (const mergee of objectsToMerge) {
        for (const key of Object.keys(mergee)) {
            if (isPlainObject(result[key])) {
                result[key] = mergePropertiesDeep(result[key], mergee[key]);
            } else {
                copyProperty(key, mergee, result);
            }
        }
    }
    return result;
}
