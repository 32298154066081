import { code } from "@spscommerce/utils";

export const SpsIncrementorExamples = {
    stepExample: {
        label: "Incrementor",
        description: "info about incrementor",
        examples: {
            stacked: {
                react: code`
                    function Component () {
                        const [value, setValue] = React.useState("");
                        const handleChange = val => {
                            setValue(val);
                        };
                        return (
                            <React.Fragment>
                                <SpsIncrementor
                                    min={0}
                                    max={10}
                                    step={2}
                                    id="basicIncrementor"
                                    onValueChange={handleChange}
                                />
                                    Value: {value}
                            </React.Fragment>
                        );
                    }
                    render(<Component />)
                `
            }
        }
    }
};
