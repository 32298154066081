/* tslint:disable */
// IMPORTANT TODO: REMOVE ABOVE LINE POST-REFACTOR
import * as React from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, selectChildren } from "../util";
import clsx from "clsx";
import { SpsModalHeader } from "./SpsModalHeader";
import { SpsModalOverlay } from "./SpsModalOverlay";
import { SpsModalBody } from "./SpsModalBody";
import { SpsModalFooter } from "./SpsModalFooter";
import { SpsModalAction } from "./SpsModalAction";

const propsDoc = {
    kind: "string",
    isOpen: "boolean",
    header: "string | () => string",
    size: "string",
    backdrop: "string",
    actionRequired: "boolean",
    onOpen: "Function",
    onClose: "Function",
};

const propTypes = {
    ...spsGlobalPropTypes,
    kind: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.fun<() => string>(),
    ]),
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    size: PropTypes.string,
    backdrop: PropTypes.string,
    actionRequired: PropTypes.bool
};

export type SpsModalProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

function SpsModal(modalProps: SpsModalProps) {
    let lastFocusable;
    let firstFocusable;

    const { isOpen } = modalProps;

    // States
    const [visible, setVisible] = React.useState(false);
    const [isAsyncOn, setIsAsyncOn] = React.useState(false);

    // Refs
    const element: any = React.useRef();

    // Effects
    React.useEffect(() => {
        return document.body.classList.remove("modal-open");
    }, []);

    React.useEffect(() => {
        if (visible) {
            setFocus(null);
        } else if (!visible) {
            document.body.classList.remove("modal-open");
        }
    }, [visible]);

    React.useEffect(() => {
        if (isOpen) {
            open();
        } else if (!isOpen) {
            close();
        }
    }, [isOpen]);

    const open = () => {
        document.addEventListener("keydown", onKeyDown);
        document.body.classList.add("modal-open");
        setVisible(true);
        if (typeof modalProps.onOpen === "function") {
            modalProps.onOpen();
        }
    };

    const close = () => {
        document.removeEventListener("keydown", onKeyDown);
        document.body.classList.remove("modal-open");
        setVisible(false);
        if (typeof modalProps.onClose === "function") {
            modalProps.onClose();
        }
    };

    const turnOnAsyncMode = () => {
        setIsAsyncOn(true);
    };

    const turnOffAsyncMode = () => {
        setIsAsyncOn(false);
    };

    const setFocus = e => {
        // if (element.current) {
        //     const focusable = element.current.querySelectorAll(
        //         'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[contenteditable], [tabindex]:not([tabindex="-1"])'
        //     );
        //     if (focusable.length) {
        //         firstFocusable = focusable[0];
        //         lastFocusable = focusable[focusable.length - 1];
        //         if (e && e.target === lastFocusable) {
        //             firstFocusable.focus();
        //         } else {
        //             lastFocusable.focus();
        //         }
        //     }
        // }
    };

    const closeable = () => {
        if (!modalProps.actionRequired && !isAsyncOn) {
            return close;
        } else {
            return () => {};
        }
    };

    const onKeyDown = e => {
        switch (e.key) {
            case "Escape":
                closeable()();
                break;
            case "Tab":
                if (e.target === lastFocusable) {
                    setFocus(e);
                    e.preventDefault();
                }
                break;
        }
    };

    const renderModalTemplate = () => {
        const handleAsync = {
            turnAsyncOn: turnOnAsyncMode,
            turnAsyncOff: turnOffAsyncMode,
            isAsyncOn: isAsyncOn
        };
        const modalTypes = {
            default: "sps-modal--default",
            success: "sps-modal--success",
            delete: "sps-modal--delete",
            key: "sps-modal--key",
            info: "sps-modal--info",
            tip: "sps-modal--tip",
            warning: "sps-modal--warning"
        };

        const modalSizes = {
            small: "sps-modal__dialog--small",
            large: "sps-modal__dialog--large"
        };

        const show = {
            display: "block",
            pointerEvents: "none",
            paddingRight: "17px"
        };

        const hide = {
            display: "none"
        };

        const {
            id,
            size,
            backdrop,
            onClose,
            onOpen,
            header,
            kind,
            children,
            className,
            actionRequired,
            isOpen,
            "data-testid": testId,
            ...rest
        } = modalProps;
        let modalClass, modalDialogClass;

        if (!kind) {
            throw new Error("SpsModal: kind is a required field");
        }

        if (!id) {
            throw new Error("SpsModal: id is a required field");
        }

        if (!modalTypes[kind]) {
            throw new Error("SpsModal: invalid kind");
        }

        modalClass = clsx("sps-modal", className, modalTypes[kind]);
        modalDialogClass = clsx("sps-modal__dialog", modalSizes[size]);

        const [actionsAndFooter, otherChildren] = selectChildren(children, [
            [
                { type: SpsModalAction },
                { type: SpsModalFooter },
            ]
        ]);

        return (
            <React.Fragment>
                <div
                    className={modalClass}
                    {...rest}
                    style={visible ? show : hide}
                    role="dialog"
                    // tabIndex="-1"
                    data-testid={`${testId}`}
                    onKeyDown={onKeyDown}
                    ref={element}
                >
                    <div className={modalDialogClass} role="document">
                        <div className="sps-modal__content">
                            {header ? <SpsModalHeader header={header} /> : null}
                            {otherChildren}
                            {React.Children.map(actionsAndFooter, child => {
                                return React.cloneElement(child as React.ReactElement<any>, {
                                    closeModal: close,
                                    handleAsync: handleAsync
                                });
                            })}
                        </div>
                    </div>
                </div>
                <SpsModalOverlay
                    id={"modal_overlay_" + id}
                    backdrop={backdrop}
                    onOverlayClick={closeable()}
                    data-testid={`${testId}__overlay`}
                />
            </React.Fragment>
        );
    };

    return ReactDOM.createPortal(
        <React.Fragment>{visible ? renderModalTemplate() : null}</React.Fragment>,
        document.body
    );
}

Object.assign(SpsModal, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModal"
});

export { SpsModal, SpsModalHeader, SpsModalOverlay, SpsModalBody, SpsModalFooter, SpsModalAction };
