import { DelayedFunction } from "./delayed-function.interface";

/**
 * If the function is set to be called, cancel the
 * timeout that would call it. Reset id & pending to defaults.
 *
 * @returns - Whether an invocation was actually canceled or not.
 */
function resetLockedFn(): boolean {
    this.pending = false;
    if (typeof this.id === "number") {
        window.cancelAnimationFrame(this.id);
        return true;
    }
    return false;
}

/**
 * Return a new function to replace the passed in one which ensures that
 * the given function will run only in `requestAnimationFrame()` and at
 * most once per frame.
 */
export function lockToAnimationFrames(
    functionToLock: () => any,
    thisArg?: any
): DelayedFunction<any> {
    const lockFn: DelayedFunction<any> = function(...args) {
        if (!lockFn.pending) {
            lockFn.id = window.requestAnimationFrame(() => {
                functionToLock.apply(thisArg || this, args);
                lockFn.reset();
            });
            lockFn.pending = true;
        }
    };

    lockFn.pending = false;
    lockFn.reset = resetLockedFn.bind(lockFn);

    return lockFn;
}
