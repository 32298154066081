/** @jsx h */
import { SpsIcon } from "@spscommerce/ds-shared";
import { parseFileSize } from "@spscommerce/utils";
import { ClassBindings, Component, EventDispatcher, EventListener, Dispatcher, Prop, QuerySelector, DSComponent } from "../../decorators/index";
import { h } from "../../utils/pragma";

import { MIMEType } from "./mime-type.enum";

const CPT_NAME = "sps-file-upload";

@Component({ tag: CPT_NAME })
export class SpsFileUploadComponent extends HTMLElement {
    static readonly displayName = CPT_NAME;
    static readonly props = {
        description: "string",
        multiple: "boolean",
        dismissable: "boolean",
        processing: "boolean",
        mini: "boolean",
        downloadLabel: "string",
        acceptExtensions: "Array<string> | string",
        maxSize: "string",
        selection: { event: true, type: "CustomEvent<Array<File>>" },
        dismissal: { event: true, type: "CustomEvent<void>" },
        download: { event: true, type: "CustomEvent<void>" },
    };

    /** The "term" for what the user should upload. Defaults to "File" or "Files",
     * but if you set the `acceptExtensions` prop you might set this to something
     * more specific, such as "Spreadsheet" or "Images".
     */
    @Prop() description;

    /** If true, the component will accept multiple files at once. By default, you can
     * only select one file at a time.
     */
    @Prop(Boolean) multiple: boolean;

    /** Controls whether the component is visible. */
    @Prop(Boolean) shown = true;

    /** Controls whether the user can hide the component from view. */
    @Prop(Boolean) dismissable: boolean;

    /** Puts the component in "processing" state, where it displays a spinner and
     * does not allow any files to be selected.
     */
    @Prop(Boolean) processing: boolean;

    /** Specifies that the component should be displayed in the "mini" style. */
    @Prop(Boolean) mini: boolean;

    /** If provided, a button will be rendered with this text as its label. */
    @Prop() downloadLabel: string;

    private accept: string;
    private acceptMIMETypes = new Set<string>();
    private acceptExtensionsDescription: string;
    /** A list of extensions, provided either as an array of strings or a
     * string containing comma-separates extensions. Only files with the MIME types
     * associated with those extensions will be able to be selected. The case of
     * the extensions does not matter; neither does whether you prefix them with a
     * dot. That is, ".doc", "DOCX", and "jpg" are all valid extensions for this prop.
     */
    @Prop() acceptExtensions: Array<string> | string;

    private maxSizeBytes: number;
    /** The maximum allowable size in bytes for files selected by the component. */
    @Prop() maxSize: string;

    /** Event which emits when a file or files have been selected by the user. The
     * event detail will be an array of Files.
     */
    @EventDispatcher() selection: Dispatcher<Array<File>>;

    /** If the `dismissable` prop has been set, this event will fire when the user
     * opts to hide the component.
     */
    @EventDispatcher() dismissal: Dispatcher<void>;

    /** If the `downloadLabel` prop has been provided, this event will fire when the
     * user clicks the download button that is rendered.
     */
    @EventDispatcher() download: Dispatcher<void>;

    /** The HTML file input element within the component. */
    @QuerySelector("input[type='file']") fileInput: HTMLInputElement;

    private active = false;
    private error = false;
    /** The list of selected files. If the `multiple` prop has not been provided,
     * it is still an array; it will simply be an array of one when the user selects
     * a file.
     */
    files: Array<File> = [];
    private namesOfUnsupportedFiles: Array<string> = [];

    get [ClassBindings]() {
        return [
            CPT_NAME,
            this.active && `${CPT_NAME}--active`,
            this.error && `${CPT_NAME}--error`,
            !this.shown && `${CPT_NAME}--hidden`,
            this.mini && `${CPT_NAME}--mini`,
        ];
    }

    constructor() {
        super();
        this.handleBrowseLinkClick = this.handleBrowseLinkClick.bind(this);
        this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this);
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.resetError = this.resetError.bind(this);
        this.dismiss = this.dismiss.bind(this);
    }

    private parseAndValidateMaxSize(maxSize: string) {
        try {
            this.maxSizeBytes = parseFileSize(maxSize);
        } catch (error) {
            throw new Error(`Could not parse "${maxSize}" as a file size.`);
        }
    }

    private processAcceptExtensions(acceptExtensions: Array<string> | string) {
        acceptExtensions = Array.isArray(acceptExtensions)
            ? acceptExtensions
            : acceptExtensions.trim().split(/\s?,\s?/);

        acceptExtensions = acceptExtensions.map(ext => ext.replace(/^\./, ""));

        const extList = acceptExtensions.map(ext => `.${ext}`.toLowerCase());
        this.acceptExtensionsDescription = extList
            .map((ext, i) => i > 0 && i === extList.length - 1 ? `or ${ext}` : ext)
            .join(extList.length > 2 ? ", " : " ");

        this.acceptMIMETypes.clear();
        for (const ext of acceptExtensions) {
            for (const type of MIMEType[ext.toUpperCase()].split(",")) {
                this.acceptMIMETypes.add(type);
            }
        }

        this.accept = extList.concat(Array.from(this.acceptMIMETypes)).join(",");
    }

    connectedCallback() {
        this.description = this.description || (
            this.multiple ? "Files" : "File"
        );

        if (this.acceptExtensions) {
            this.processAcceptExtensions(this.acceptExtensions);
        }

        if (this.maxSize) {
            this.parseAndValidateMaxSize(this.maxSize);
        }
    }

    attributeChangedCallback(attrName: string, oldValue: any, newValue: any) {
        if (attrName === "acceptExtensions") {
            this.processAcceptExtensions(newValue);
        }

        if (attrName === "maxSize") {
            this.parseAndValidateMaxSize(newValue);
        }
    }

    private selectFiles(files: Array<File> | FileList) {
        this.namesOfUnsupportedFiles = [];
        const fileArray = Array.from(files);

        for (const file of fileArray) {
            if (this.acceptExtensions && !this.acceptMIMETypes.has(file.type)) {
                this.namesOfUnsupportedFiles.push(file.name);
            }
            if (this.maxSize && file.size > this.maxSizeBytes) {
                this.namesOfUnsupportedFiles.push(file.name);
            }
        }

        if (this.namesOfUnsupportedFiles.length) {
            this.error = true;
            (this as any as DSComponent).update();
        } else {
            this.files = fileArray;
            this.selection.dispatch(fileArray);
        }
    }

    @EventListener("dragover")
    private handleDragOver(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    @EventListener("dragenter")
    private handleDragEnter(event: Event) {
        event.preventDefault();
        this.active = true;
        this.error = false;
        (this as any as DSComponent).update();
    }

    @EventListener("dragleave")
    private handleDragLeave(event: Event) {
        event.preventDefault();
        this.active = false;
        (this as any as DSComponent).update();
    }

    @EventListener("drop")
    private handleDrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.active = false;

        if (event.dataTransfer.items) {
            this.selectFiles(Array.from<DataTransferItem>(event.dataTransfer.items).map(
                (item: DataTransferItem) => item.getAsFile()
            ));
        } else {
            this.selectFiles(event.dataTransfer.files);
        }

        (this as any as DSComponent).update();
    }

    private handleBrowseLinkClick(event: any) {
        event.preventDefault();
        this.error = false;
        (this as any as DSComponent).update();
        this.fileInput.click();
    }

    private handleDownloadButtonClick() {
        this.download.dispatch();
    }

    private handleFileInputChange(event: any) {
        event.stopPropagation();
        this.selectFiles((event.target as HTMLInputElement).files);
    }

    /** If the `dismissable` prop has been set, this will programmatically
     * dismiss the component and fire the "dismissal" event.
     */
    dismiss() {
        if (this.dismissable) {
            this.shown = false;
            (this as any as DSComponent).update();
            this.dismissal.dispatch();
        }
    }

    /** If the `dismissable` prop has been set, this will programmatically show the component. */
    show() {
        if (this.dismissable) {
            this.shown = true;
            (this as any as DSComponent).update();
        }
    }

    private resetError(event) {
        event.preventDefault();
        this.error = false;
        (this as any as DSComponent).update();
    }

    render({ t }) {
        const icon = this.error ? SpsIcon.EXCLAMATION_CIRCLE : SpsIcon.UPLOAD_CLOUD;

        const instructions = (
            this.multiple
                ? t("design-system:fileUpload.instructions")
                : t("design-system:fileUpload.instructions_plural")
        ).split("|");
        const errorInstructions = (
            this.multiple
                ? t("design-system:fileUpload.errorInstructions")
                : t("design-system:fileUpload.errorInstructions_plural")
        ).split("|");

        return <frag>
            <div className={`${CPT_NAME}__content`}>
                {this.processing
                    ? <frag>
                        <i className="sps-spinner sps-spinner--medium" aria-hidden="true"/>
                        <div className={`${CPT_NAME}__instructions`}>{t("design-system:fileUpload.processing")}</div>
                    </frag>
                    : <frag>
                        <i className={`sps-icon sps-icon-${icon} ${CPT_NAME}__icon-primary`} aria-hidden="true"/>
                        <div className={`${CPT_NAME}__title`}>
                            {this.error
                                ? <frag>{t(
                                    this.namesOfUnsupportedFiles.length > 1
                                        ? "design-system:fileUpload.cancelled_plural"
                                        : "design-system:fileUpload.cancelled",
                                    { fileNames: this.namesOfUnsupportedFiles.join(", ") }
                                )}</frag>
                                : <frag>{t("design-system:fileUpload.title", { description: this.description })}</frag>
                            }
                        </div>
                        <div className={`${CPT_NAME}__instructions`}>
                            {this.error
                                ? <frag>
                                    <span>{errorInstructions[0]}</span>
                                    <a href="" onClick={this.resetError}>{errorInstructions[1]}</a>
                                    <span>{errorInstructions[2]}</span>
                                </frag>
                                : <frag>
                                    <span>{instructions[0]}</span>
                                    <a href="" onClick={this.handleBrowseLinkClick}>{instructions[1]}</a>
                                    <span>{instructions[2]}</span>
                                </frag>
                            }
                        </div>
                        <div className={`${CPT_NAME}__requirements`}>
                            {this.acceptExtensions
                                ? <frag>
                                    ( {t("design-system:fileUpload.acceptedTypes", { fileTypes: this.acceptExtensionsDescription })} )
                                </frag>
                                : ""
                            }
                            {this.maxSize ? <frag>( {t("design-system:fileUpload.maximumSize", { size: this.maxSize })} )</frag> : ""}
                        </div>
                        {this.downloadLabel &&
                            <div className={`sps-btn sps-btn--link ${CPT_NAME}__download-button`}
                                onClick={this.handleDownloadButtonClick}
                            >
                                <button type="button">
                                    <i className="sps-icon sps-icon-download-cloud" aria-hidden="true"></i> {this.downloadLabel}
                                </button>
                            </div>
                        }
                    </frag>
                }
            </div>
            {this.dismissable && !this.processing &&
                <div className={`sps-btn sps-btn--icon ${CPT_NAME}__close-button`}
                    onClick={this.dismiss}
                >
                    <button type="button" title={t("design-system:fileUpload.close")}>
                        <i className="sps-icon sps-icon-x" aria-hidden="true"></i>
                    </button>
                </div>
            }
            <form>
                <input type="file" accept={this.accept || "*/*"}
                    multiple={this.multiple || null}
                    onChange={this.handleFileInputChange}
                />
            </form>
        </frag>;
    }
}
