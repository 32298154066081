import { code } from "@spscommerce/utils";

export const SpsSplitButtonExamples = {
    kinds: {
        label: "Regular Split Buttons",
        description: code`<p>info about split buttons</p>`,
        examples: {
            basic: {
                description: code`<p>Basic</p>`,
                jsx: code`
                    <React.Fragment>
                        <SpsSplitButton id="withIcon" label="Default Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                        <SpsSplitButton kind="confirm" label="Confirm Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                        <SpsSplitButton kind="key" label="Key Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                        <SpsSplitButton kind="delete" label="Delete Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                    </React.Fragment>
                `
            }
        }
    },
    withIcons: {
        label: "Split Buttons with Icons",
        description: code`<p>info about icon split buttons</p>`,
        examples: {
            withIcons: {
                description: code`<p>With Icons</p>`,
                react: code`
                    function SpsSplitButtonWithIcon(props) {
                        function alert1() {
                            window.alert("Button clicked");
                        }
                        return (
                            <React.Fragment>
                                <SpsSplitButton id="withIcon" icon="drill" label="Default Button"
                                    options={[
                                        [{ label: "Option A" }, () => console.log("Option A")]
                                    ]} className="mr-1">
                                </SpsSplitButton>
                                <SpsSplitButton icon="rocket" kind="confirm" label="Confirm Button"
                                    options={[
                                        [{ label: "Option A" }, () => console.log("Option A")]
                                    ]} className="mr-1">
                                </SpsSplitButton>
                                <SpsSplitButton icon="key" kind="key" label="Key Button"
                                    options={[
                                        [{ label: "Option A" }, () => console.log("Option A")]
                                    ]} className="mr-1">
                                </SpsSplitButton>
                                <SpsSplitButton icon="trash" kind="delete" onClick={alert1} label="Delete Button"
                                    options={[
                                        [{ label: "Option A" }, () => console.log("Option A")]
                                    ]} className="mr-1">
                                </SpsSplitButton>
                            </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    disabled: {
        label: "Disabled Split Buttons",
        description: code`<p>info about disabled split buttons</p>`,
        examples: {
            disabled: {
                description: code`<p>Disabled</p>`,
                jsx: code`
                    <React.Fragment>
                        <SpsSplitButton disabled id="disabled" kind="key" label="Disabled Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                        <SpsSplitButton disabled kind="key" icon="drill" label="Disabled Button"
                            options={[
                                [{ label: "Option A" }, () => console.log("Option A")]
                            ]} className="mr-1">
                        </SpsSplitButton>
                    </React.Fragment>
                `
            }
        }
    }
};
