import { code } from "@spscommerce/utils";

export const SpsMultiSelectTagExamples = {
    basic: {
        label: "Basic",
        description:
            "<p>Info about basic multi select tag</p>",
        examples: {
            basic: {
                react: code`
                function DemoComponent() {
                    const onRemove = ()=> {
                        console.log("REMOVED");
                    }
                    const onAdd = ()=> {
                        console.log("ADDED");
                    }
                    return (
                        <>
                            <SpsMultiSelectTag kind={MultiSelectTagKind.BLUE} onRemove={onRemove} className="mr-1 mb-1"><span>Ankle Strap</span></SpsMultiSelectTag>
                            <SpsMultiSelectTag kind={MultiSelectTagKind.RED} onRemove={onRemove} className="mr-1 mb-1"><span>Ankle Strap</span></SpsMultiSelectTag>
                            <SpsMultiSelectTag kind={MultiSelectTagKind.GRAY} onAdd={onAdd} className="mr-1 mb-1"><span>Ankle Strap</span></SpsMultiSelectTag>
                        </>
                    )
                }`
            }
        }
    }
};
