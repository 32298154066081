import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    borderless: "boolean",
    lean: "boolean",
    leaner: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    borderless: PropTypes.bool,
    lean: PropTypes.bool,
    leaner: PropTypes.bool,
};

export type SpsContentRowColProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsContentRowCol(props: SpsContentRowColProps) {
    const {
        borderless,
        children,
        className,
        lean,
        leaner,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-content-row__col",
        borderless && "sps-content-row__col--borderless",
        lean && "sps-content-row__col--lean",
        leaner && "sps-content-row__col--leaner",
    );

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
}

Object.assign(SpsContentRowCol, {
    props: propsDoc,
    propTypes,
    displayName: "SpsContentRowCol"
});
