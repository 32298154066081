import { Eventually } from "../typings";
import { DebouncedFunction } from "./debounced-function.interface";

/**
 * If the function is set to be called, cancel the
 * timeout that would call it. Reset id & pending to defaults.
 *
 * @returns - Whether an invocation was actually canceled or not.
 */
function resetDebouncedFn(): boolean {
    this.pending = false;
    if (typeof this.id === "number") {
        clearTimeout(this.id);
        delete this.id;
        return true;
    }
    return false;
}

/**
 * Takes in a function and returns a new function that debounces it.
 * The returned function returns an observable that can be subscribed to
 * if you need to have something happen when the debounced function
 * finally gets called and returns a value.
 * @see https://css-tricks.com/debouncing-throttling-explained-examples/
 */
export function debounce<T>(
    functionToDebounce: (...args: any[]) => Eventually<T>,
    milliseconds: number,
    thisArg?: any
): DebouncedFunction<T> {
    const debouncedFn: DebouncedFunction<T> = function(...args: any[]): Promise<T> {
        if (!debouncedFn._promise) {
            debouncedFn._promise = new Promise((resolve, reject) => {
                debouncedFn._promiseCallbacks = { resolve, reject };
            });
        }

        debouncedFn.reset();
        debouncedFn.pending = true;

        debouncedFn.id = window.setTimeout(async () => {
            try {
                let output = functionToDebounce.apply(thisArg || this, args);
                if (output instanceof Promise) {
                    output = await output;
                }
                debouncedFn._promiseCallbacks.resolve(output);
            } catch (err) {
                debouncedFn._promiseCallbacks.reject(err);
            } finally {
                delete debouncedFn._promise;
                delete debouncedFn._promiseCallbacks;
                debouncedFn.reset();
            }
        }, milliseconds);

        return debouncedFn._promise;
    };

    debouncedFn.reset = resetDebouncedFn.bind(debouncedFn);

    return debouncedFn;
}
