import { code } from "@spscommerce/utils";

export const SpsDropdownExamples = {
  labelled: {
    label: "Labelled",
    examples: {
      default: {
        description: `<h3> Default Dropdown</h3>`,
        react: code`
                function SpsDropdownBasicDefault(props) {
                    const [action, updateAction] = useSpsAction({
                        label: "Option B"
                    }, () => console.log("Option B"));
                    return (
                        <SpsDropdown id="regular" label="Dropdown" options={[
                            [{ label: "Option A" }, () => console.log("Option A")],
                            action,
                        ]}/>
                    );
                }
                render(<SpsDropdownBasicDefault/>);
            `
      },
      key: {
        description: `<h3> Key Dropdown</h3>`,
        react: code`
                function SpsDropdownBasicKey(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="key"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownBasicKey />);
            `
      },
      confirm: {
        description: `<h3> Confirm Dropdown</h3>`,
        react: code`
                function SpsDropdownBasicConfirm(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="confirm"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownBasicConfirm />)
            `
      },
      delete: {
        description: `<h3> Delete Dropdown</h3>`,
        react: code`
                function SpsDropdownBasicDelete(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="delete"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownBasicDelete />)
            `
      }
    }
  },
  withIcon: {
    label: "Labelled With Icon",
    examples: {
      default: {
        description: `<h3> Default Dropdown With Icon</h3>`,
        react: code`
                function SpsDropdownWithIconDefault(props) {
                    const [action, updateAction] = useSpsAction(
                      {
                        label: "Option B"
                      },
                      () => console.log("Option B")
                    );
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        icon="drill"
                        options={[[{ label: "Option A" }, () => console.log("Option A")], action]}
                      />
                    );
                  }
                  render(<SpsDropdownWithIconDefault />)
            `
      },
      key: {
        description: `<h3>Key Dropdown With Icon</h3>`,
        react: code`
                function SpsDropdownWithIconKey(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="key"
                        icon="key"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownWithIconKey />)
            `
      },
      confirm: {
        description: `<h3> Confirm Dropdown With Icon</h3>`,
        react: code`
                function SpsDropdownWithIconConfirm(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="confirm"
                        icon="rocket"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownWithIconConfirm />)
            `
      },
      delete: {
        description: `<h3> Delete Dropdown With Icon</h3>`,
        react: code`
                function SpsDropdownWithIconDelete(props) {
                    return (
                      <SpsDropdown
                        label="Dropdown"
                        kind="delete"
                        icon="trash"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownWithIconDelete />)
            `
      }
    }
  },
  withIconButton: {
    label: " Icon Button Dropdown",
    examples: {
      default: {
        description: `<h3> Default Icon Button Dropdown</h3>`,
        react: code`
                function SpsDropdownBasicIcon(props) {
                    return (
                      <SpsDropdown
                        kind="icon"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownBasicIcon />)
            `
      },
      custom: {
        description: `<h3>Custom Icon</h3>`,
        react: code`
                function SpsDropdownIconCustomIcon(props) {
                    return (
                      <SpsDropdown
                        kind="icon"
                        icon="gear"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                  render(<SpsDropdownIconCustomIcon />)
            `
      }
    }
  },
  disabled: {
    label: "Disabled",
    examples: {
      labelled: {
        description: `<h3> Disabled Labelled Dropdown</h3>`,
        react: code`
                function SpsDropdownDisabled(props) {
                    return (
                      <SpsDropdown
                        id="disabled"
                        label="Dropdown"
                        icon="drill"
                        disabled
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                render(<SpsDropdownDisabled />)
            `
      },
      iconButton: {
        description: `<h3>Disabled Icon Button Dropdown</h3>`,
        react: code`
                function SpsDropdownIconCustomIcon(props) {
                    return (
                      <SpsDropdown
                        kind="icon"
                        icon="gear"
                        options={[[{ label: "Option A" }, () => console.log("Option A")]]}
                      />
                    );
                  }
                render(<SpsDropdownIconCustomIcon />)
            `
      }
    }
  },
  spinning: {
    label: "Spinning",
    examples: {
      default: {
        description: `<h3>Spinning Dropdown</h3>`,
        jsx: code`
                        <SpsDropdown label="Dropdown" options={[[{ label: "Option A" }, () => console.log("Option A")],]} spinning />
                `
      }
    }
  }
};
