import { SpsOptionListOption, SpsActionDescriptor, SpsActionMethod } from "@spscommerce/ds-shared";
import { PositioningService, Position, PositionAnchor } from "@spscommerce/positioning";
import { debounce, Eventually } from "@spscommerce/utils";
import clsx from "clsx";
import * as React from "react";
import * as scrollIntoViewImport from "scroll-into-view-if-needed";

import * as PropTypes from "../prop-types";
import { FauxChangeEvent, spsGlobalPropTypes, usePatchReducer } from "../util";

const scrollIntoView = (scrollIntoViewImport as any).default || scrollIntoViewImport;

const propTypes = {
    ...spsGlobalPropTypes,
    alignLeft: PropTypes.bool,
    attachTo: PropTypes.ref<HTMLElement>().isRequired,
    captionKey: PropTypes.string,
    comparisonKey: PropTypes.string,
    conformWidth: PropTypes.bool,
    hideInlineSearch: PropTypes.bool,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    keyDown: PropTypes.impl<React.KeyboardEvent>(),
    nullOption: PropTypes.string,
    offsets: PropTypes.arrayOf(PropTypes.number),
    onOptionSelected: PropTypes.fun<(option) => void>(),
    onPositionFlip: PropTypes.fun<(opensUpward: boolean) => void>(),
    onSearchChange: PropTypes.fun<React.ChangeEventHandler>(),
    onSelfToggle: PropTypes.fun<(isOpen: boolean) => void>(),
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.instanceOf<Promise<any[]>>(Promise),
        PropTypes.fun<(filter?: string) => Eventually<any[]>>(),
    ]).isRequired,
    role: PropTypes.string,
    search: PropTypes.string,
    searchDebounce: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    selectedOption: PropTypes.any,
    specialAction: PropTypes.fun<SpsActionMethod>(),
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    zeroState: PropTypes.string,
};

export type SpsOptionListProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

let idNum = 0;

export function SpsOptionList(props: SpsOptionListProps) {
    const {
        alignLeft,
        attachTo,
        captionKey,
        className,
        comparisonKey,
        conformWidth,
        hideInlineSearch,
        id = `sps-option-list-${idNum++}`,
        isOpen: isOpenProp,
        keyDown,
        nullOption,
        offsets = [0, 0],
        onOptionSelected,
        onPositionFlip,
        onSearchChange,
        onSelfToggle,
        options,
        role,
        search = "",
        searchDebounce = 500,
        searchPlaceholder = "Search…",
        selectedOption,
        specialAction,
        textKey,
        valueKey,
        unsafelyReplaceClassName,
        zeroState,
        ...rest
    } = props;

    const specialActionOption = specialAction
        ? new SpsOptionListOption<SpsActionDescriptor>(specialAction, {
            textKey: "label",
            captionKey: "caption"
        })
        : null;

    const [coreState, coreStatePatch] = usePatchReducer({
        optionList: Object.freeze([]),
        highlightedOptionIndex: -1,
        anyOptionHasIcon: false,
        opensUpward: false,
        isOpen: isOpenProp,
        ignoreIntersections: false,
    });

    const [searchState, searchStatePatch] = usePatchReducer({
        isAsync: typeof options === "function",
        pending: false,
        value: search,
        replacementPattern: null,
    });

    let cancelPromise;

    const corePod = {
        rootRef: React.useRef<HTMLDivElement>(null),
        optionsRef: React.useRef<HTMLDivElement>(null),
        highlightedOptionRef: React.useRef<HTMLAnchorElement>(null),

        // tslint:disable-next-line:no-shadowed-variable
        _updateOptions(searchState, searchStatePatch, options, cancelPromise, textKey, captionKey, nullOption, coreStatePatch) {
            searchStatePatch({ pending: true });

            const fetchedOptions = typeof options === "function"
                ? options(searchState.value)
                : options || [];
            const resultPromise = fetchedOptions instanceof Promise
                ? fetchedOptions
                : Promise.resolve(fetchedOptions);

            resultPromise.then((result = []) => {
                if (!cancelPromise) {
                    let newOpts = Array.from(result)
                        .filter(Boolean)
                        .map(
                            thing => new SpsOptionListOption(
                                thing,
                                typeof thing === "function"
                                ? {
                                    textKey: "label",
                                    captionKey: "caption"
                                }
                                : {
                                    textKey,
                                    captionKey
                                }
                            )
                        );

                    if (nullOption) {
                        newOpts.unshift(new SpsOptionListOption<null>(null, { text: nullOption }));
                    }

                    if (searchState.value) {
                        searchStatePatch({ replacementPattern: new RegExp(searchState.value, "ig") });
                        newOpts = newOpts.filter(o => (new RegExp(searchState.value, "i")).test(o.text));
                    } else {
                        searchStatePatch({ replacementPattern: null });
                    }

                    coreStatePatch({
                        optionList: Object.freeze(newOpts),
                        anyOptionHasIcon: newOpts.some(o => o.value && !!o.value["icon"]),
                    });
                    searchStatePatch({ pending: false });
                }
            });
        },
        updateOptions: null,

        openSelf() {
            if (!coreState.isOpen) {
                coreStatePatch({
                    isOpen: true,
                    highlightedOptionIndex: -1,
                });

                if (onSelfToggle) {
                    onSelfToggle(true);
                }
            }
        },

        closeSelf() {
            if (coreState.isOpen) {
                coreStatePatch({
                    isOpen: false,
                    highlightedOptionIndex: -1,
                });

                if (onSelfToggle) {
                    onSelfToggle(false);
                }
            }
        },

        compareOptions(optionA, optionB) {
            return comparisonKey
                ? (optionA && optionA[comparisonKey]) === (optionB && optionB[comparisonKey])
                : optionA === optionB;
        },

        selectOption(option) {
            if (option && !option.disabled) {
                if (typeof option.value === "function") {
                    (option.value as any)();
                } else if (typeof onOptionSelected === "function") {
                    onOptionSelected(option.value);
                }
                corePod.closeSelf();
            }
        },

        handleOptionClick(event: React.MouseEvent, option) {
            event.stopPropagation();
            corePod.selectOption(option);
        },

        handleKeyDown(event: React.KeyboardEvent) {
            if (!event) {
                return;
            }
            switch (event.key) {
                case "Tab":
                case "Escape":
                    corePod.closeSelf();
                    break;

                    case "Enter":
                        if (coreState.highlightedOptionIndex > -1) {
                            const highlightedOption = coreState.optionList[coreState.highlightedOptionIndex] || specialActionOption;
                            event.preventDefault();
                            if (
                                typeof onOptionSelected === "function" &&
                                typeof highlightedOption.value === "function"
                            ) {
                                (highlightedOption.value as any)();
                            } else {
                                corePod.selectOption(highlightedOption);
                            }
                        }
                        break;
                case "Up":
                case "ArrowUp": {
                    let newHighlightIndex = coreState.highlightedOptionIndex;
                    if (coreState.opensUpward) {
                        if (coreState.highlightedOptionIndex <= -1) {
                            // From no highlight, go up to the last option
                            newHighlightIndex = coreState.optionList.length - 1;
                        } else if (coreState.highlightedOptionIndex === 0) {
                            // From the first option, if there's a special action to go up to, go up to it
                            // Otherwise do not go up, we're at the top
                            if (specialAction) {
                                newHighlightIndex = coreState.optionList.length;
                            }
                        } else if (coreState.highlightedOptionIndex < coreState.optionList.length) {
                            // From an option lower than the 0th (index > 0) and not at the special action
                            // (index === length of option list), go up one
                            newHighlightIndex = coreState.highlightedOptionIndex - 1;
                        }
                    } else if (coreState.highlightedOptionIndex > -1) {
                        // When opening downward (default) the very top is "no highlight", when index === -1,
                        // and indices get larger as you go downward. So just go up one
                        newHighlightIndex = coreState.highlightedOptionIndex - 1;
                    }

                    if (newHighlightIndex !== coreState.highlightedOptionIndex) {
                        event.preventDefault();
                        coreStatePatch({ highlightedOptionIndex: newHighlightIndex });
                    }
                    break;
                }

                case "Down":
                case "ArrowDown": {
                    corePod.openSelf();

                    let newHighlightIndex = coreState.highlightedOptionIndex;
                    if (coreState.opensUpward) {
                        if (coreState.highlightedOptionIndex !== -1) {
                            if (coreState.highlightedOptionIndex >= coreState.optionList.length) {
                                // From the special action, go down to the first option
                                newHighlightIndex = 0;
                            } else if (coreState.highlightedOptionIndex === coreState.optionList.length - 1) {
                                // From the last option, go to "no highlight" (index === -1)
                                newHighlightIndex = -1;
                            } else {
                                // Otherwise, go down one
                                newHighlightIndex = coreState.highlightedOptionIndex + 1;
                            }
                        }
                    } else if (coreState.highlightedOptionIndex < coreState.optionList.length - 1 + +!!specialAction) {
                        newHighlightIndex = coreState.highlightedOptionIndex + 1;
                    }

                    if (newHighlightIndex !== coreState.highlightedOptionIndex) {
                        event.preventDefault();
                        coreStatePatch({ highlightedOptionIndex: newHighlightIndex });
                    }
                    break;
                }

                default:
                    corePod.openSelf();
            }
        },

        getPositioningOptions() {
            const position = coreState.opensUpward
                ? (alignLeft ? Position.TOP_LEFT : Position.TOP_RIGHT)
                : (alignLeft ? Position.BOTTOM_LEFT : Position.BOTTOM_RIGHT);
            const anchor = coreState.opensUpward ? PositionAnchor.BOTTOM_LEFT : PositionAnchor.TOP_LEFT;
            return {
                relativeTo: attachTo.current,
                useRelativeTargetWidth: conformWidth,
                position,
                anchor,
                offsets,
            };
        },

        onViewportIntersection(event) {
            if (
                !coreState.ignoreIntersections
                && event.target === corePod.rootRef.current
                && event.intersectionRatio < 1
            ) {
                const opensUpward = event.intersectionRect.bottom >= event.rootBounds.bottom
                    && event.boundingClientRect.height < event.boundingClientRect.top;
                coreStatePatch({ opensUpward });
                if (onPositionFlip) {
                    onPositionFlip(opensUpward);
                }
            }
        },

        onElementIntersection(event) {
            if (
                !coreState.ignoreIntersections
                && (
                    event.target === corePod.rootRef.current
                    || (event.intersectingWith && event.intersectingWith.indexOf(corePod.rootRef.current) > -1)
                )
            ) {
                const opensUpward = !coreState.opensUpward;
                coreStatePatch({
                    ignoreIntersections: true,
                    opensUpward,
                });
                if (onPositionFlip) {
                    onPositionFlip(opensUpward);
                }
            }
        }
    };

    React.useEffect(() => {
        PositioningService.reposition(corePod.rootRef.current, corePod.getPositioningOptions());
    }, [coreState.opensUpward]);

    corePod.updateOptions = React.useRef(
        typeof options === "function"
            ? debounce(corePod._updateOptions, searchDebounce)
            : corePod._updateOptions
    );

    React.useEffect(() => {
        PositioningService.on("viewportIntersection", corePod.onViewportIntersection);
        PositioningService.on("elementIntersection", corePod.onElementIntersection);
        return () => {
            PositioningService.off("viewportIntersection", corePod.onViewportIntersection);
            PositioningService.off("elementIntersection", corePod.onElementIntersection);
            cancelPromise = true;
        };
    }, []);

    React.useEffect(() => {
        if (corePod.highlightedOptionRef.current) {
            scrollIntoView(corePod.highlightedOptionRef.current, {
                scrollMode: "if-needed",
                block: "nearest",
                inline: "nearest"
            });
        }
    }, [coreState.highlightedOptionIndex]);

    const searchPod = {
        inputRef: React.useRef<HTMLInputElement>(null),

        handleChange(event: React.ChangeEvent<HTMLInputElement>) {
            searchStatePatch({ value: event.target.value });
            if (onSearchChange) {
                onSearchChange(event);
            }
        },

        handleInputClick(event: React.MouseEvent) {
            event.nativeEvent.stopImmediatePropagation();
        },

        handleClearClick(event: React.MouseEvent) {
            event.nativeEvent.stopImmediatePropagation();

            searchStatePatch({ value: "" });
            if (onSearchChange) {
                onSearchChange(new FauxChangeEvent(searchPod.inputRef.current));
            }
            if (searchPod.inputRef.current) {
                searchPod.inputRef.current.focus();
            }
        },
    };

    React.useEffect(() => {
        if (coreState.isOpen) {
            PositioningService.position(corePod.rootRef.current, corePod.getPositioningOptions());
            if (searchState.isAsync && searchPod.inputRef.current) {
                setTimeout(() => searchPod.inputRef.current.focus(), 0);
            }
        } else {
            coreStatePatch({
                opensUpward: false,
                ignoreIntersections: false,
            });
            PositioningService.release(corePod.rootRef.current);
        }
    }, [coreState.isOpen]);

    React.useEffect(() => {
        coreStatePatch({ isOpen: isOpenProp });
    }, [isOpenProp]);

    React.useEffect(() => {
        corePod.handleKeyDown(keyDown);
    }, [keyDown]);

    React.useEffect(() => {
        searchStatePatch({ value: search });
    }, [search]);


    React.useEffect(() => {
        corePod.updateOptions.current(searchState, searchStatePatch, options, cancelPromise, textKey, captionKey, nullOption, coreStatePatch);
    }, [options, searchState.value]);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-option-list",
        coreState.isOpen && "sps-option-list--open",
        searchState.isAsync && "sps-option-list--searchable",
        coreState.opensUpward && "sps-option-list--opens-upward",
        specialAction && specialAction.label && "sps-option-list--has-special-action",
        className,
    );

    return (
        <div className={classes}
          aria-activedescendant={coreState.highlightedOptionIndex > -1
            ? `${id}-option-${coreState.highlightedOptionIndex}`
            : null
          }
          ref={corePod.rootRef}
          {...rest}
        >
          {searchState.isAsync && !hideInlineSearch &&
            <div className="sps-option-list__search">
              <div className={clsx("sps-option-list__search-placeholder", searchState.value && "d-none")}>
                <i className="sps-icon sps-icon-filter"></i>
                <span>{searchPlaceholder}</span>
              </div>
              <input type="text"
                className="sps-form-control"
                ref={searchPod.inputRef}
                value={searchState.value}
                onChange={searchPod.handleChange}
                onClick={searchPod.handleInputClick}
              />
              <div className="sps-option-list__search-controls">
                {searchState.value && <i className="sps-icon sps-icon-x-circle" onClick={searchPod.handleClearClick}></i>}
                {searchState.pending && <div className="sps-option-list__pending-spinner"><i className="sps-spinner"></i></div>}
              </div>
            </div>
          }
          <div className="sps-option-list__options" ref={corePod.optionsRef}>
            {zeroState && (searchState.value || !searchState.isAsync) && coreState.optionList.length === 0 &&
              <div className="sps-option-list__zero-state">{zeroState}</div>
            }
            {coreState.optionList.map((option, i) =>
              <a key={i}
                id={`${id}-option-${i}`}
                role={role === "menu" ? "menuitem" : null}
                href={option.href}
                className={clsx(
                  "sps-option-list__option",
                  option.caption && "sps-option-list__option--has-caption",
                  option.disabled && "sps-option-list__option--disabled",
                  corePod.compareOptions(option.value, selectedOption) && "sps-option-list__option--selected",
                  coreState.highlightedOptionIndex === i && "sps-option-list__option--highlighted",
                )}
                onClick={event => corePod.handleOptionClick(event, option)}
                onMouseOver={() => coreState.highlightedOptionIndex = i}
                tabIndex={-1}
                ref={coreState.highlightedOptionIndex === i ? corePod.highlightedOptionRef : null}
              >
                {option.value && option.value.icon &&
                  <i className={clsx(
                    "sps-icon",
                    "sps-option-list__option-icon",
                    `sps-icon-${option.value.icon}`,
                  )}></i>
                }
                {!option.value || !option.value.icon && coreState.anyOptionHasIcon &&
                  <span className="sps-option-list__option-icon-spacer"></span>
                }
                <span dangerouslySetInnerHTML={{ __html: option.getHtml(searchState.replacementPattern) }}></span>
                {option.caption && <div className="sps-option-list__option-caption">{option.caption}</div>}
              </a>
            )}
          </div>
          {specialActionOption && specialAction.label &&
            <a className={clsx(
                "sps-option-list__option",
                "sps-option-list__special-action",
                coreState.highlightedOptionIndex === coreState.optionList.length && "sps-option-list__option--highlighted"
              )}
              href={specialActionOption.href}
              onClick={event => corePod.handleOptionClick(event, specialActionOption)}
              onMouseOver={() => coreState.highlightedOptionIndex = coreState.optionList.length}>
              {specialAction.icon &&
                <i className={clsx(
                    "sps-icon",
                    "sps-option-list__option-icon",
                    `sps-icon-${specialAction.icon}`
                )}></i>
              }
              {!specialAction.icon && coreState.anyOptionHasIcon &&
                <span className="sps-option-list__option-icon-spacer"></span>
              }
              <span>{specialAction.label}</span>
            </a>
          }
        </div>
    );
}

Object.assign(SpsOptionList, {
    propTypes,
    displayName: "SpsOptionList"
});
