import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import clsx from "clsx";

const propsDoc = {
    header: "string | () => string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.fun<() => string>(),
    ]),
};

export type SpsModalHeaderProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsModalHeader(props: SpsModalHeaderProps) {
    const {
        header,
        className,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const headerClass = unsafelyReplaceClassName || clsx("sps-modal__header", className);
    const modalHeader =
        typeof header === "function"
            ? header
            : () => {
                  return <div className="sps-modal__title">{header}</div>;
              };
    return <div className={headerClass} {...rest}>{modalHeader()}</div>;
}

Object.assign(SpsModalHeader, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModalHeader"
});
