import { SpsIcon } from "./icon";
import { SpsActionMethod } from "./sps-action";

/** The possible statuses for a task in the Task Queue. */
export enum SpsTaskStatus {
    COMPLETED = "completed",
    ERRORED = "errored",
    WARNING = "warning",
    IN_PROGRESS = "in_progress",
}

export const SpsTaskStatusIcons = Object.freeze({
    [SpsTaskStatus.COMPLETED]: SpsIcon.STATUS_OK,
    [SpsTaskStatus.ERRORED]: SpsIcon.STATUS_ERROR,
    [SpsTaskStatus.WARNING]: SpsIcon.STATUS_WARNING,
    [SpsTaskStatus.IN_PROGRESS]: "sps-spinner sps-spinner--small",
});

/** Represents a task in the Task Queue. */
export interface SpsTask {
    status: SpsTaskStatus;
    unread: boolean;
    heading: string;
    subheading: any;
    actions?: SpsActionMethod[];
}

export const TASK_QUEUE_NOTIFICATION_DURATION_MS = 5000;
