import { Metadata } from "../utils/metadata";

/** Marks a method as a handler for a given event on the host element. For example, to respond
 * to clicks on a component, you would use a method decorated with `@EventListener("click")`.
 */
export function EventListener(eventName: string): MethodDecorator {
    return (target, key: PropertyKey) => {
        const eventListeners = Metadata.get(target.constructor, "eventListeners", []);
        eventListeners.push([eventName, key]);
        Metadata.add(target.constructor, { eventListeners });
    };
}
