import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsFocusedTaskActionsProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsFocusedTaskActions(props: SpsFocusedTaskActionsProps) {
    const {
        children,
        className,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-list-action-bar-container",
        "z-stratum-bar",
        className,
    );

    return (
        <div className={classes} {...rest}>
            <div className="sps-list-action-bar">
                <div className="sps-list-action-bar__actions">
                    {children}
                </div>
            </div>
        </div>
    );
}

Object.assign(SpsFocusedTaskActions, {
    props: propsDoc,
    propTypes,
    displayName: "SpsFocusedTaskActions",
});
