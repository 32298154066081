import { code } from "@spscommerce/utils";

export const SpsListActionBarExamples = {
    basic: {
        label: "Basic",
        examples: {
            basic: {
                react: code`
                    function Component (props) {
                        const [itemsSelected, setItemsSelected] = React.useState(3);

                        function handleClear() {
                            setItemsSelected(0);
                        }

                        function resetExample() {
                            setItemsSelected(3);
                        }

                        return (
                            <SpsCard>
                                <SpsButton onClick={resetExample} id="resetButton">Reset</SpsButton>
                                <SpsListActionBar itemsSelected={itemsSelected} clearSelected={handleClear}>
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.PRINTER} aria-label="Print" />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.DOWNLOAD_CLOUD} aria-label="Download" />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.ARCHIVE} aria-label="Archive" />
                                    <SpsButton kind={ButtonKind.ICON} icon={SpsIcon.TRASH} aria-label="Delete" />
                                </SpsListActionBar>
                            </SpsCard>
                        );
                    }
                `
            }
        }
    }
};
