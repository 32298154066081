import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes
};

export type SpsDescriptionListDefinitionProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsDescriptionListDefinition(props: SpsDescriptionListDefinitionProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-description-list__definition",
        className,
    );

    return (
        <dd className={classes} data-testid={`${testId}`} {...rest}>
            {children}
        </dd>
    );
}

Object.assign(SpsDescriptionListDefinition, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionListDefinition / SpsDd"
});

export function SpsDd(p: SpsDescriptionListDefinitionProps) {
    return SpsDescriptionListDefinition(p);
}

Object.assign(SpsDd, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDescriptionListDefinition / SpsDd"
});
