import { code } from "@spscommerce/utils";

export const SpsFeedbackBlockExamples = {
    demos: {
        examples: {
            tip: {
                jsx: code`
                    <SpsFeedbackBlock kind="tip">
                        Please Note: Click the Receive button above to check for new transactions from QuickBooks.
                    </SpsFeedbackBlock>
                `
            },
            success: {
                jsx: code`
                    <SpsFeedbackBlock kind="success">
                        All items have been uploaded and are ready for export.
                    </SpsFeedbackBlock>
                `
            },
            info: {
                jsx: code`
                    <SpsFeedbackBlock kind="info">
                        FYI- This page has been updated. Click refresh to see the most recent version.
                    </SpsFeedbackBlock>
                `
            },
            warning: {
                jsx: code`
                    <SpsFeedbackBlock kind="warning">
                        Warning! This order is missing a shipment date and is in jeopardy of being past due.
                    </SpsFeedbackBlock>
                `
            },
            error: {
                jsx: code`
                    <SpsFeedbackBlock kind="error">
                        We are experiencing difficulties communicating to the server. Please try again later.
                    </SpsFeedbackBlock>
                `
            }
        }
    }
};
