import { ButtonKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import { SpsDropdown } from "../dropdown/SpsDropdown";
import * as PropTypes from "../prop-types";
import { selectChildren, spsGlobalPropTypes } from "../util";
import { SpsButton } from "./SpsButton";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsButtonGroupProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsButtonGroup(props: SpsButtonGroupProps) {
    const {
        children,
        className,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [
        links,
        iconButtons,
        others
    ] = selectChildren(children, [
        { type: SpsButton, props: { kind: ButtonKind.LINK } },
        [{ type: SpsButton, props: { kind: ButtonKind.ICON } }, { type: SpsDropdown }],
    ]);

    const linkAndIconOrOther = links.length > 0 && (iconButtons.length > 0 || others.length > 0);
    const iconAndOther = iconButtons.length > 0 && others.length > 0;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-button-group",
        className,
    );

    return (
        <div className={classes} {...rest}>
          {links.length > 0 && links}
          {linkAndIconOrOther && <div className="sps-vertical-rule"></div>}
          {iconButtons.length > 0 && iconButtons}
          {iconAndOther && <div className="sps-vertical-rule"></div>}
          {others.length > 0 && others}
        </div>
    );
}

Object.assign(SpsButtonGroup, {
    props: propsDoc,
    propTypes,
    displayName: "SpsButtonGroup"
});
