import clsx from "clsx";
import * as React from "react";
import { SpsIcon } from "@spscommerce/ds-shared";

import * as PropTypes from "../../prop-types";
import { FauxChangeEvent, spsGlobalPropTypes } from "../../util";
import { useFormControlId } from "../hooks/useFormControlId";
import { SpsFormControl } from "../hooks/formControl";
import { SpsFormComponentWrapper } from "../SpsFormComponentWrapper";

const propsDoc = {
    disabled: "boolean",
    formControl: "SpsFormControl<string>",
    icon: "SpsIcon",
    name: "string",
    onChange: "ChangeEventHandler<HTMLInputElement>",
    placeholder: "string",
    value: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<string>>(),
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    name: PropTypes.string,
    onChange: PropTypes.fun<React.ChangeEventHandler<HTMLInputElement>>(),
    placeholder: PropTypes.string,
    ref: PropTypes.ref<HTMLDivElement>(),
    value: PropTypes.string,
};

export type SpsTextInputProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsTextInput(props: SpsTextInputProps) {
    const {
        className,
        disabled = false,
        formControl,
        icon,
        id,
        name,
        onChange,
        placeholder,
        ref,
        "data-testid": testId,
        title,
        unsafelyReplaceClassName,
        value = "",
        ...rest
    } = props;

    const textInput = React.useRef<HTMLInputElement>();
    const [noFormHookValue, setNoFormHookValue] = React.useState(value);

    React.useEffect(() => {
        setNoFormHookValue(value);
    }, [value]);

    function getValue() {
        return (formControl ? formControl.getValue() : noFormHookValue) || "";
    }

    function setAndPropagateValue(newValue: string, event?: React.ChangeEvent<HTMLInputElement>) {
        setNoFormHookValue(newValue);
        if (formControl) {
          formControl.setValue(newValue);
          formControl.markAsDirty();
      }
        if (onChange) {
            event = event || new FauxChangeEvent(textInput.current);
            onChange(event);
        }
    }

    function clearAndFocus() {
        textInput.current.value = "";
        setAndPropagateValue("");
        textInput.current.focus();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setAndPropagateValue(event.target.value, event);
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-text-input",
        className,
    );

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            inputRef={textInput}
            ref={ref}
            data-testid={`${testId}`}
        >
            <div className={clsx("sps-form-control", disabled && "disabled")}>
                {icon && <i className={clsx("sps-text-input__icon", "sps-icon", `sps-icon-${icon}`)}></i>}
                <input type="text"
                    ref={textInput}
                    data-testid={`${testId}__input`}
                    className="sps-text-input__input"
                    id={useFormControlId(id, formControl)}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    title={title}
                    value={getValue()}
                    disabled={disabled}
                    {...rest}
                />
            </div>
            {getValue() && !disabled && <i
                className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                onClick={clearAndFocus}
            ></i>}
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsTextInput, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTextInput"
});
