import { code } from "@spscommerce/utils";

export const SpsSearchResultsBarExamples = {
    noCount: {
        label: "Basic",
        description: `<p>Info about Basic Search Result Bars</p>`,
        examples: {
            noCount: {
                react: code`
                function SpsSearchResultsBarBasic() {
                    function clearHandler() {
                        console.log("change handler called");
                    }
                    return (
                        <SpsSearchResultsBar onClear={clearHandler}/>
                    );
                }
                `
            }
        }
    },
    withCount: {
        label: "With Count",
        description: `<p>Info about Search Result Bars with count bars</p>`,
        examples: {
            withCount: {
                react: code`
                function SpsSearchResultsBarCount() {
                    function clearHandler() {
                        console.log("change handler called");
                    }

                    return (
                        <SpsSearchResultsBar resultsCount={125} onClear={clearHandler}/>
                    );
                }
                `
            }
        }
    },
    withCountAndTotal: {
        label: "With Count and total",
        description: `<p> Info about Search Result Bars with count & total</p>`,
        examples: {
            withCountAndTotal: {
                react: code`
                function SpsSearchResultsBarCountTotal() {
                    function clearHandler() {
                        console.log("change handler called");
                    }
                    return (
                        <SpsSearchResultsBar resultsCount={125} totalResults={250} onClear={clearHandler} />
                    );
                }
                `
            }
        }
    }
};
