import { code } from "@spscommerce/utils";

export const SpsCheckboxExamples = {
    labels: {
        label: "Labels",
        description: code`
            <p>An individual checkbox should be labeled with its own <code>label</code> prop,
            not the <code>&lt;SpsLabel&gt;</code> component.</p>
        `,
        examples: {
            label: {
                react: code`
                function DemoComponent() {
                    const form = useForm(
                      formGroup({
                        red: formControl(true),
                        blue: formControl(false),
                        green: formControl()
                      })
                    )
                    return (
                      <SpsForm formGroup={form}>
                        <SpsCheckbox
                            name="red"
                            label="Red"
                            formControl={form.get("red")}
                        />
                        <SpsCheckbox
                            name="blue"
                            label="Blue"
                            formControl={form.get("blue")}
                        />
                        <SpsCheckbox
                            name="green"
                            label="Green"
                            formControl={form.get("green")}
                            indeterminate={true}
                        />
                      </SpsForm>
                    )
                  }
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        examples: {
            disabled: {
                react: code`
                function DemoComponent() {
                    const form = useForm(
                      formGroup({
                        red: formControl(true),
                        blue: formControl(false),
                        green: formControl()
                      })
                    )
                    return (
                        <SpsForm formGroup={form}>
                            <SpsCheckbox
                                name="red"
                                label="Red"
                                formControl={form.get("red")}
                                disabled
                            />
                            <SpsCheckbox
                                name="blue"
                                label="Blue"
                                formControl={form.get("blue")}
                                disabled
                            />
                            <SpsCheckbox
                                name="green"
                                label="Green"
                                formControl={form.get("green")}
                                indeterminate={true}
                                disabled
                            />
                        </SpsForm>
                    )
                  }
                `
            }
        }
    },
    noLabels: {
        label: "No Label",
        description: code`
            <p>Checkboxes without labels are used in table rows and Summary List Rows
            to allow those rows to be selected and deselected. They are not normally
            used elsewhere and doing so should be done with utmost caution. Any checkbox
            without a label should visually have an obvious purpose and should always have
            an <code>aria-label</code> describing what it's for to screen reader users.</p>
        `,
        examples: {
            noLabels: {
                react: code`
                    function Component() {
                        const [rowIsSelected, setRowIsSelected] = React.useState(true);

                        function handleSelectionChange(event) {
                            setRowIsSelected(event.target.checked);
                        }

                        return (
                            <SpsSummaryListRow selected={rowIsSelected}>
                                <SpsSummaryListColumn width="2.8rem" lean>
                                    <SpsCheckbox aria-label="Row Selected" checked={rowIsSelected} onChange={handleSelectionChange}/>
                                </SpsSummaryListColumn>
                                <SpsSummaryListColumn>
                                    <SpsI icon={SpsIcon.STATUS_ERROR} size={SpsIconSize.LARGE} className="align-middle mr-2 pr-1" />
                                    <div className="d-inline-block align-middle">
                                        <div className="fs-18">
                                            <a href="#">Heading</a>
                                        </div>
                                        <div className="fs-14">Sub Heading</div>
                                    </div>
                                </SpsSummaryListColumn>
                            </SpsSummaryListRow>
                        );
                    }
                `
            }
        }
    }
};
