import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    icon: "SpsIcon",
};

const propTypes = {
    ...spsGlobalPropTypes,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
};

export type SpsWorkflowDocumentStatusProps = PropTypes.InferTS<typeof propTypes, HTMLSpanElement>;

export function SpsWorkflowDocumentStatus(props: SpsWorkflowDocumentStatusProps) {
    const {
        children,
        className,
        icon,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-workflow__document-status",
        className,
    );

    return (
        <span className={classes}
            data-testid={`${testId}__doc-status`}
            {...rest}
        >
            {icon &&
                <i aria-hidden="true" className={`sps-icon sps-icon-${icon}`}></i>
            }
            {children}
        </span>
    );
}

Object.assign(SpsWorkflowDocumentStatus, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWorkflowDocumentStatus"
});

export function SpsWfDs(props: SpsWorkflowDocumentStatusProps) {
    return SpsWorkflowDocumentStatus(props);
}

Object.assign(SpsWfDs, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWfDs"
});
