import { code } from "@spscommerce/utils";

export const SpsListToolbarExamples = {
    basic: {
        label: "Simple Search Toolbar",
        description: code`
            <p>Most commonly there will be a simple search/filter text input.</p>
        `,
        examples: {
            basic: {
                react: code`
                function Component (props) {
                    const [searchText, setSearchText] = React.useState("");

                    function handleChange(event) {
                        setSearchText(event.target.value);
                    }

                    function handleSearch(event) {
                        console.log(event, searchText);
                    }

                    return (
                        <SpsListToolbar
                            pinned={true}
                        >
                            <SpsListToolbarSearch onSubmit={handleSearch}>
                                <SpsTextInput
                                    name="search_text"
                                    value={searchText}
                                    onChange={handleChange}
                                    placeholder="Type here to search for something"
                                />
                                <SpsButton type="submit" kind="icon" icon="search"/>
                            </SpsListToolbarSearch>
                            <SpsButtonGroup>
                                <SpsButton kind="link">Link Button</SpsButton>
                                <SpsButton kind="icon" icon="download-cloud" />
                                <SpsButton kind="icon" icon="flag" />
                                <SpsButton kind="icon" icon="list-table" />
                            </SpsButtonGroup>
                        </SpsListToolbar>
                    );
                }
                `
            }
        }
    },
    withoutSearch: {
        label: "Without Search/Filter",
        description: code`
            <p>You can also use the List Toolbar with no search or filter at all, if
            you have other controls such as sorting that the user needs access to.</p>
        `,
        examples: {
            withoutSearch: {
                react: code`
                function Component (props) {
                    const { "data-testid": testId } = props;
                    return (
                        <SpsListToolbar data-testid={testId}>
                            <SpsButtonGroup>
                                <SpsButton kind="link">Link Button</SpsButton>
                                <SpsButton kind="icon" icon="download-cloud" />
                                <SpsButton kind="icon" icon="flag" />
                                <SpsButton kind="icon" icon="list-table" />
                            </SpsButtonGroup>
                        </SpsListToolbar>
                    );
                }
                `
            }
        }
    },
    withTabs: {
        label: "Toolbar With Tabs",
        examples: {
            withTabs: {
                react: code`
                function Component (props) {
                    const tabs = [
                        {
                            label: "Tab A",
                            icon: "heart red200"
                        },
                        {
                            label: "Tab B"
                        },
                        {
                            label: "Tab C",
                            tag: "23"
                        }
                    ];
                    const activeTab = { label: "Tab C", tag: "23" };
                    const [searchText, setSearchText] = React.useState("");
                    const handleChange = e => {
                      setSearchText(e.target.value);
                    };
                    function handleTabChange(tab) {
                        console.log(tab);
                    }

                    return (
                        <SpsListToolbar
                            tabs={tabs}
                            activeTab={activeTab}
                            onTabChange={handleTabChange}
                        >
                            <SpsListToolbarSearch>
                                <SpsTextInput
                                  name="search_text"
                                  value={searchText}
                                  onChange={handleChange}
                                  placeholder="Type here to search for something"
                                />
                                <SpsButton kind="icon" icon="search" />
                            </SpsListToolbarSearch>
                            <SpsButtonGroup>
                                <SpsButton kind="link">Link Button</SpsButton>
                                <SpsButton kind="icon" icon="download-cloud" />
                                <SpsButton kind="icon" icon="flag" />
                                <SpsButton kind="icon" icon="list-table" />
                            </SpsButtonGroup>
                        </SpsListToolbar>
                    );
                }
                `
            }
        }
    },
    withTitle: {
        label: "Toolbar With Title",
        examples: {
            withTitle: {
                react: code`
                function Component(props) {
                    return (
                      <SpsListToolbar title="Simple Search" pinned={true}>
                        <SpsButtonGroup>
                          <SpsButton kind="link">Link Button</SpsButton>
                          <SpsButton kind="icon" icon="download-cloud" />
                          <SpsButton kind="icon" icon="flag" />
                          <SpsButton kind="icon" icon="list-table" />
                        </SpsButtonGroup>
                      </SpsListToolbar>
                    );
                  }
                `
            }
        }
    },
    otherInputs: {
        label: "Search/Filter Alternatives",
        description: code`
            <p>You can use other types of inputs aside from text inputs for
            the simple search/filter, such as the Select and the Autocomplete.</p>
        `,
        examples: {
            select: {
                react: code`
                function Component(props) {
                    const filterKeys = ["Document Type", "Sender Name", "Receiver Name"];

                    const [searchText, setSearchText] = React.useState("");

                    function handleChange(event) {
                      setSearchText(event.target.value);
                    }

                    return (
                      <SpsListToolbar pinned={true}>
                        <SpsListToolbarSearch>
                          <SpsSelect options={filterKeys} tabIndex={1} />
                          <SpsTextInput
                            name="search_text"
                            value={searchText}
                            onChange={handleChange}
                            icon="filter"
                            placeholder="Type here to filter"
                          />
                        </SpsListToolbarSearch>
                        <SpsButtonGroup>
                          <SpsButton kind="link">Link Button</SpsButton>
                          <SpsButton kind="icon" icon="download-cloud" />
                          <SpsButton kind="icon" icon="flag" />
                          <SpsButton kind="icon" icon="list-table" />
                        </SpsButtonGroup>
                      </SpsListToolbar>
                    );
                  }
                `
            },
            autocomplete: {
                react: code`
                function Component (props) {
                    const { "data-testid": testId } = props;
                    const colors = ["red", "blue", "green"];

                    const [searchText, setSearchText] = React.useState("");

                    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
                        setSearchText(event.target.value);
                    }

                    function handleSearch(event: React.FormEvent) {
                        console.log(event, searchText);
                    }

                    return (
                        <SpsListToolbar
                            pinned={true}
                            data-testid={testId}
                        >
                            <SpsListToolbarSearch onSubmit={handleSearch}>
                                <SpsAutocomplete
                                    suggestions={colors}
                                    value={searchText}
                                    onChange={handleChange}
                                    placeholder="Enter a color"
                                />
                                <SpsButton
                                    type="submit"
                                    kind={"icon"}
                                    icon={"search"}
                                />
                            </SpsListToolbarSearch>
                            <SpsButtonGroup>
                                <SpsButton kind={"link"}>Link Button</SpsButton>
                                <SpsButton kind={"icon"} icon={"download-cloud"} />
                                <SpsButton kind={"icon"} icon={"flag"} />
                                <SpsButton kind={"icon"} icon={"list-table"} />
                            </SpsButtonGroup>
                        </SpsListToolbar>
                    );
                }
                `
            }
        }
    },
};
