import { Metadata } from "./metadata";

function ensureDefined(tag, Component) {
    if (!customElements.get(tag)) {
        customElements.define(tag, Component);
    }
}

/** Register a list of DS web components so that they can be used. Should be done
 * just once in your app, at init/bootstrap time.
 */
export function register(...Components) {
    for (const Component of Components) {
        ensureDefined(Metadata.get(Component, "tag"), Component);
    }
}

/** If for some reason you have a tag name conflict between a DS web component and
 * another component in your app, you can use this to override the "sps" namespace
 * for the DS web component(s). You pass in the new namespace, and it returns a customized
 * `register()` function that you can pass DS web components to. For example, you could
 * use this to make `<sps-photo>` become `<foo-photo>` instead.
 */
export function namespaceOverrideRegistrar(overrideNamespace: string) {
    return function register(...Components) { // tslint:disable-line:no-shadowed-variable
        for (const Component of Components) {
            const tag = `${overrideNamespace}${Metadata.get(Component, "tag").substr(3)}`;
            ensureDefined(tag, Component);
        }
    };
}
