import { ButtonKind, ButtonType, SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    disabled: "boolean",
    href: "string",
    icon: "SpsIcon",
    kind: "ButtonKind",
    spinning: "boolean",
    spinningTitle: "string",
    type: "ButtonType"
};

const propTypes = {
    ...spsGlobalPropTypes,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    kind: PropTypes.enumValue<ButtonKind>(ButtonKind),
    spinning: PropTypes.bool,
    spinningTitle: PropTypes.string,
    type: PropTypes.enumValue<ButtonType>(ButtonType),
};

export type SpsButtonProps = PropTypes.InferTS<typeof propTypes, HTMLButtonElement | HTMLAnchorElement>;

export function SpsButton(props: SpsButtonProps) {
    const {
        children,
        className,
        disabled,
        href,
        icon,
        kind = ButtonKind.DEFAULT,
        spinning,
        spinningTitle,
        type = ButtonType.BUTTON,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-btn",
        `sps-btn--${kind}`,
        spinning && "sps-btn--spinning",
        disabled && "disabled",
        className,
    );

    const contents = [
        icon && <i className={clsx("sps-icon", `sps-icon-${icon}`)} aria-hidden="true" key={0}></i>,
        children,
        <div className="sps-spinner" key={1}>{spinningTitle || t("design-system:button.spinningTitle")}</div>
    ];

    return (
        <div className={classes}>{
            href
            ? <a href={href} {...rest}>{...contents}</a>
            : <button type={type} disabled={disabled} {...rest}>{...contents}</button>
        }</div>
    );
}

Object.assign(SpsButton, {
    props: propsDoc,
    propTypes,
    displayName: "SpsButton"
});
