import { FeedbackBlockIcons, FeedbackBlockKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    kind: "FeedbackBlockKind",
    message: { type: "string", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    kind: PropTypes.enumValue<FeedbackBlockKind>(FeedbackBlockKind),
    message: PropTypes.node.isRequired,
};

export type SpsMicroBlockProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsMicroBlock(props: SpsMicroBlockProps) {
    const {
        className,
        kind = FeedbackBlockKind.INFO,
        message,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-micro-block",
        `sps-micro-block--${kind}`,
        className,
    );

    return (
        <div className={classes} data-testid={testId} {...rest}>
            <i
                className={`sps-icon sps-icon-${FeedbackBlockIcons[kind]}`}
                aria-hidden="true"
                data-testid={`${testId}__icon`}
            />
            <span>{message}</span>
        </div>
    );
}

Object.assign(SpsMicroBlock, {
    props: propsDoc,
    propTypes,
    displayName: "SpsMicroBlock"
});
