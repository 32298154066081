/** Constrains a number to the given range. If the number is below the
 * low end of the range, the low end will be returned. If the number is
 * above the top end of the range, the top end will be returned. Otherwise
 * the number itself will be returned.
 */
export function constrain(n: number, range: [number, number]) {
    if (!n || n < range[0]) {
        return range[0];
    }
    if (n > range[1]) {
        return range[1];
    }
    return n;
}
