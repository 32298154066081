import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import { SpsButton } from "../button/SpsButton";
import { SpsControlSet } from "../form/hooks/SpsControlSet.interface";
import { SpsForm, spsFormProps, spsFormPropTypes } from "../form/SpsForm";
import { I18nContext } from "../i18n";
import * as PropTypes from "../prop-types";
import { contentOf, spsGlobalPropTypes } from "../util";

const propsDoc = {
    ...spsFormProps,
    controlsDisabled: "boolean",
    footerLinks: "ReactNodeOrRenderFn",
    isOpen: "boolean",
    onClear: "() => void",
    pinned: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    ...spsFormPropTypes,
    controlsDisabled: PropTypes.bool,
    footerLinks: PropTypes.nodeOrRenderFn,
    isOpen: PropTypes.bool,
    onClear: PropTypes.fun<() => void>(),
    pinned: PropTypes.bool,
};

export type SpsAdvancedSearchProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsAdvancedSearch(props: SpsAdvancedSearchProps) {
    const {
        children,
        className,
        controlsDisabled,
        footerLinks,
        formGroup,
        formArray,
        isOpen,
        pinned,
        onClear,
        onSubmit,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const form: SpsControlSet = formGroup || formArray;

    const formRef = React.useRef(null);

    function handleClear() {
        if (form) {
            form.reset();
        } else {
            formRef.current.reset();
        }
        if (onClear) {
            onClear();
        }
    }

    const toolbarClass = clsx(
        unsafelyReplaceClassName || "sps-advanced-search",
        pinned && "sps-advanced-search--pinned",
        pinned && "z-stratum-bar",
        isOpen && "sps-advanced-search--open",
        className,
    );

    return (
        <div className={toolbarClass} {...rest}>
            <SpsForm ref={formRef}
                onSubmit={onSubmit}
                formGroup={formGroup}
                formArray={formArray}
            >
                <div className="sps-advanced-search__form">{children}</div>
                <div className="sps-advanced-search__footer">
                    {footerLinks && <div id="footer_links">{contentOf(footerLinks)}</div>}
                    <div className="sps-advanced-search__footer-controls">
                        <SpsButton
                            kind={ButtonKind.DEFAULT}
                            type={ButtonType.BUTTON}
                            className="mr-2"
                            onClick={handleClear}
                            disabled={controlsDisabled}
                        >
                            {t("design-system:advancedSearch.clear")}
                        </SpsButton>
                        <SpsButton
                            kind={ButtonKind.KEY}
                            type={ButtonType.SUBMIT}
                            disabled={controlsDisabled}
                        >
                            {t("design-system:advancedSearch.search")}
                        </SpsButton>
                    </div>
                </div>
            </SpsForm>
        </div>
    );
}

Object.assign(SpsAdvancedSearch, {
    props: propsDoc,
    propTypes,
    displayName: "SpsAdvancedSearch"
});
