import { code } from "@spscommerce/utils";

export const SpsButtonExamples = {
    button: {
        label: "Buttons",
        examples: {
            basic: {
                description: code`<p>Style variants</p>`,
                jsx: code`
                    <SpsButton className="mr-1 mb-1">Default</SpsButton>
                    <SpsButton className="mr-1 mb-1" kind="confirm">Confirm</SpsButton>
                    <SpsButton className="mr-1 mb-1" kind="delete">Delete</SpsButton>
                    <SpsButton className="mr-1 mb-1" kind="key">Key</SpsButton>
                    <SpsButton className="mr-1 mb-1" kind="link">Link</SpsButton>
                `
            },
            spinning: {
                description: code`<p>Spinning</p>`,
                jsx: code`
                    <SpsButton className="mr-1 mb-1" spinning>Default</SpsButton>
                    <SpsButton className="mr-1 mb-1" spinning kind="confirm">Confirm</SpsButton>
                    <SpsButton className="mr-1 mb-1" spinning kind="delete">Delete</SpsButton>
                    <SpsButton className="mr-1 mb-1" spinning kind="key">Key</SpsButton>
                    <SpsButton className="mr-1 mb-1" spinning kind="link">Link</SpsButton>
                `
            },
            iconButtons: {
                description: code`<p>Buttons with icons</p>`,
                jsx: code`
                    <SpsButton className="mr-1 mb-1" kind="icon" icon="wrench" aria-label="Repair"/>
                    <SpsButton className="mr-1 mb-1" kind="icon" icon="paper-plane" aria-label="Send"/>
                    <SpsButton className="mr-1 mb-1" kind="icon" icon="trash" aria-label="Delete"/>
                    <SpsButton className="mr-1 mb-1" kind="icon" icon="key" aria-label="Authorize"/>
                    <SpsButton className="mr-1 mb-1" kind="icon" icon="download-cloud" aria-label="Download"/>
                `
            },
            buttonsWithIcons: {
                description: code`<p>Buttons with icons</p>`,
                jsx: code`
                    <SpsButton className="mr-1 mb-1" icon="drill">Default</SpsButton>
                    <SpsButton className="mr-1 mb-1" icon="paper-plane" kind="confirm">Confirm</SpsButton>
                    <SpsButton className="mr-1 mb-1" icon="trash" kind="delete" >Delete</SpsButton>
                    <SpsButton className="mr-1 mb-1" icon="key" kind="key">Key</SpsButton>
                    <SpsButton className="mr-1 mb-1" icon="download-cloud" kind="link">Link</SpsButton>
                `
            },
            submit: {
                description: code`<p>Submit Button</p>`,
                react: code`
                    function DemoComponent() {
                        function alertOnSubmit() {
                            window.alert("Form submitted");
                        }
                        return (
                            <SpsButton className="mr-1 mb-1" kind="confirm" onClick={alertOnSubmit}>Submit</SpsButton>
                        );
                    }
                `
            },
            disabled: {
                description: code`<p>Disabled</p>`,
                jsx: code`
                    <SpsButton disabled>Disabled</SpsButton>
                `,
            },
            link: {
                description: code`
                    <h5>Button as a link</h5>
                    <p>(Not necessarily styled as a link, e.g. kind="link")</p>
                `,
                jsx: code`
                    <SpsButton className="mr-1 mb-1" kind="key" href="https://google.com">Google</SpsButton>
                `
            }
        }
    },
    group: {
        label: "Button Groups",
        description: code`
                <p>This kind of grouping of buttons is used in the headers and
                footers of cards, list toolbars, and list action bars.</p>
            `,
        examples: [
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="link">Link Button A</SpsButton>
                          <SpsButton kind="link">Link Button B</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="icon" icon="printer" aria-label="Print" />
                          <SpsButton kind="icon" icon="download-cloud" aria-label="Download"/>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="default">Button</SpsButton>
                          <SpsButton kind="key">Key Button</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="icon" icon="printer" aria-label="Print"/>
                          <SpsButton kind="icon" icon="download-cloud" aria-label="Download"/>
                          <SpsButton kind="link">Link Button</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="link">Link Button</SpsButton>
                          <SpsButton kind="default">Default</SpsButton>
                          <SpsButton kind="key">Key Button</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="icon" icon="printer" aria-label="Print"/>
                          <SpsButton kind="icon" icon="download-cloud" aria-label="Download"/>
                          <SpsButton kind="default">Button</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            },
            {
                jsx: code`
                    <SpsCard headerContent={() => (
                        <SpsButtonGroup>
                          <SpsButton kind="link">Link Button</SpsButton>
                          <SpsButton kind="icon" icon="printer" aria-label="Print"/>
                          <SpsButton kind="icon" icon="download-cloud" aria-label="Download"/>
                          <SpsButton kind="default">Button</SpsButton>
                        </SpsButtonGroup>
                    )}>
                    </SpsCard>
                `
            }
        ]
    }
};
