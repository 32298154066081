import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { spsGlobalPropTypes } from "../../util";
import { SpsFormControl } from "../hooks/formControl";
import { useFormControlId } from "../hooks/useFormControlId";
import { SpsFormComponentWrapper } from "../SpsFormComponentWrapper";

const propsDoc = {
    checked: "boolean",
    disabled: "boolean",
    formControl: "SpsFormControl<boolean>",
    indeterminate: "boolean",
    inline: "boolean",
    label: "string",
    onChange: "ChangeEventHandler",
};

const propTypes = {
    ...spsGlobalPropTypes,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<boolean>>(),
    indeterminate: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
};

export type SpsCheckboxProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsCheckbox(props: SpsCheckboxProps) {
    const {
        checked: checkedProp,
        className,
        disabled,
        formControl,
        id,
        indeterminate: indeterminateProp,
        inline,
        label,
        onChange,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const inputElement = React.useRef<HTMLInputElement>();
    const [checked, setChecked] = React.useState(checkedProp);
    const [indeterminate, setIndeterminate] = React.useState(indeterminateProp);

    const inputId = useFormControlId(id, formControl);

    React.useEffect(() => setChecked(checkedProp), [checkedProp]);
    React.useEffect(() => setIndeterminate(indeterminateProp), [indeterminateProp]);
    React.useEffect(() => {
        inputElement.current.indeterminate = indeterminate;
    }, [indeterminate]);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-custom-checkbox",
        "sps-custom-control",
        inline && "sps-custom-control--inline",
        !label && "sps-custom-control--no-label",
        className
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setChecked(event.target.checked);
        setIndeterminate(false);
        if (formControl) {
            formControl.setValue(event.target.checked);
            formControl.markAsDirty();
        }
        if (onChange) {
            onChange(event);
        }
        inputElement.current.focus();
    }

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            inputRef={inputElement}
            data-testid={testId}
        >
            <input
                type="checkbox"
                ref={inputElement}
                className="sps-custom-control__input"
                id={inputId}
                checked={(formControl ? formControl.getValue() : checked) || false}
                disabled={disabled}
                onChange={handleChange}
                {...rest}
            />
            <label className="sps-custom-control__label" htmlFor={inputId}>{label || ""}</label>
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsCheckbox, {
    props: propsDoc,
    propTypes,
    displayName: "SpsCheckbox"
});
