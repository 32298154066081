import { ChangeEvent, FC, useReducer, useRef, useEffect, EffectCallback } from "react";
import { Op } from "@spscommerce/utils";
import nanoid from "nanoid";

import * as PropTypes from "./prop-types";

export const jsxTestId = (id, str: string = "") => {
    if (!id) {
        return {};
    }
    return {
        "data-testid": id + "" + str
    };
};

export interface LiveDemoFC extends FC {
    reactLive: { code: string };
}

export const spsGlobalPropTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    "data-testid": PropTypes.string,
    unsafelyReplaceClassName: PropTypes.string,
};

export function contentOf(nodeOrRenderFn: PropTypes.ReactNodeOrRenderFn): PropTypes.ReactNodeLike {
    return typeof nodeOrRenderFn === "function"
        ? (nodeOrRenderFn as (() => PropTypes.ReactNodeLike))()
        : nodeOrRenderFn;
}

export function useElementId(props): React.RefObject<string> {
    return useRef<string>(props.id || nanoid());
}

function patchReducer<T>(state: T, patch: Partial<T>): T {
    return Object.assign({}, state, patch);
}
export function usePatchReducer<T>(initState: T): [T, React.Dispatch<Partial<T>>] {
    return useReducer<((state: T, patch: Partial<T>) => T)>(patchReducer, initState) as [T, React.Dispatch<Partial<T>>];
}

export interface ISelectChildrenGroup {
    type?: any;
    props?: { [key: string]: any };
}

export function selectChildren(
    children: any = [],
    groups: Array<ISelectChildrenGroup | Array<ISelectChildrenGroup>> = [],
) {
    const childrenArray = Array.isArray(children) ? children : [children];
    const normGroups: Array<Array<ISelectChildrenGroup>> = groups.map(s => Array.isArray(s) ? s : [s]);
    const nodeLists = (new Array(normGroups.length + 1)).fill(null).map(() => []);

    for (const node of childrenArray) {
        let nodeSelected = false;
        for (let i = 0; i < normGroups.length; i++) {
            for (const { type, props = {} } of normGroups[i]) {
                if (node.type === type) {
                    const propsMatch = Object.keys(props)
                        .map(prop => props[prop] === node.props[prop])
                        .reduce(Op.AND, true);
                    if (propsMatch) {
                        nodeSelected = true;
                        nodeLists[i].push(node);
                        break;
                    }
                }
            }
        }
        if (!nodeSelected) {
            nodeLists[nodeLists.length - 1].push(node);
        }
    }
    return nodeLists;
}

export class FauxChangeEvent<T> implements ChangeEvent<T> {
    constructor(public target: EventTarget & T, eventInit?: EventInit) {
        this.currentTarget = target;
        this.nativeEvent = new CustomEvent("change", eventInit);
        Object.defineProperty(this.nativeEvent, "target", {
            value: target,
            writable: false,
            configurable: false,
            enumerable: true,
        });
    }

    nativeEvent: Event;
    currentTarget: EventTarget & T;
    private _isPropagationStopped = false;

    get bubbles(): boolean {
        return this.nativeEvent.bubbles;
    }

    get cancelable(): boolean {
        return this.nativeEvent.cancelable;
    }

    get defaultPrevented(): boolean {
        return this.nativeEvent.defaultPrevented;
    }

    get eventPhase(): number {
        return this.nativeEvent.eventPhase;
    }

    get isTrusted(): boolean {
        return this.nativeEvent.isTrusted;
    }

    get timeStamp(): number {
        return this.nativeEvent.timeStamp;
    }

    get type(): string {
        return this.nativeEvent.type;
    }

    preventDefault(): void {
        this.nativeEvent.preventDefault();
    }

    isDefaultPrevented(): boolean {
        return this.defaultPrevented;
    }

    stopPropagation(): void {
        this.nativeEvent.stopPropagation();
        this._isPropagationStopped = true;
    }

    isPropagationStopped(): boolean {
        return this._isPropagationStopped;
    }

    persist(): void {
        throw new Error("This is not a real React ChangeEvent. React does not permit the creation of SyntheticEvents in userland.");
    }
}

export function useDidUpdateEffect(cb: EffectCallback, deps?: any[]) {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            return cb();
        } else {
            didMount.current = true;
        }
    }, deps);
}

