import { DEFAULT_PAGE_SIZE_OPTIONS } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { SpsSelect } from "../select/SpsSelect";
import { spsGlobalPropTypes, usePatchReducer } from "../util";
import { SpsPageSelector } from "./SpsPageSelector";

const propsDoc = {
    page: "number",
    pageSize: "number",
    pageSizeOptions: "Array<number>",
    totalResults: "number",
    onPageChange: "(page: number, pageSize: number, indices: [number, number]) => void",
};

const propTypes = {
    onPageChange: PropTypes.fun<(page: number, pageSize: number, indices: [number, number]) => void>().isRequired,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    totalResults: PropTypes.number,
    ...spsGlobalPropTypes
};

export type SpsPaginationProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

interface SpsPaginationState {
    numPages: number;
    indices: [number, number];
    page: number;
    pageSize: number;
}

export function SpsPagination(props: SpsPaginationProps) {
    const {
        className,
        onPageChange,
        page: pageProp = 1,
        pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
        pageSize: pageSizeProp = pageSizeOptions[0],
        "data-testid": testId,
        totalResults,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [state, patchState] = usePatchReducer<SpsPaginationState>({
        page: pageProp,
        pageSize: pageSizeProp,
        numPages: 0,
        indices: [0, 0]
    });

    function setPage(newPage: number) {
        const newState = {
            page: newPage,
            indices: indices(newPage),
        };

        patchState(newState);

        return newState;
    }

    function setPageSize(newPageSize: number, newPage: number) {
        const newState = {
            page: newPage,
            pageSize: newPageSize,
            numPages: numPages(newPageSize),
            indices: indices(newPage, newPageSize),
        };

        patchState(newState);

        return newState;
    }

    React.useEffect(() => {
        if (pageProp !== state.page) {
            setPage(pageProp);
        }
    }, [pageProp]);

    React.useEffect(() => {
        if (pageSizeProp !== state.pageSize) {
            setPageSize(pageSizeProp, pageProp !== state.page ? pageProp : state.page);
        }
    }, [pageSizeProp]);

    React.useEffect(() => {
        patchState({
            numPages: numPages(),
            indices: indices()
        });
    }, [totalResults]);

    function numPages(pageSize = state.pageSize) {
        return Math.ceil(totalResults / pageSize);
    }

    function indices(page = state.page, pageSize = state.pageSize): [number, number] {
        const numPgs = numPages();
        const resultsVisible = numPgs === 0
            ? 0
            : page === numPgs
                ? totalResults % pageSize || pageSize
                : pageSize;
        const start = (page - 1) * pageSize;
        return numPgs === 0
            ? [0, 0]
            : [start + 1, start + resultsVisible];
    }

    function totalResultsString() {
        return typeof totalResults === "number"
            ? totalResults
            : "many";
    }

    function handlePageSizeChange(event) {
        const newState = setPageSize(event.target.value, 1);
        onPageChange(newState.page, newState.pageSize, newState.indices);
    }

    function handlePageChange(newPage: number) {
        const newState = setPage(newPage);
        onPageChange(newState.page, state.pageSize, newState.indices);
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-pagination",
        className,
    );

    return (
        <div className={classes} data-testid={testId} {...rest}>
            <div data-testid={`${testId}__size-selector`} className="sps-page-size-selector">
                View
                <SpsSelect
                    options={pageSizeOptions}
                    onChange={handlePageSizeChange}
                    notClearable
                    value={state.pageSize}
                    className="ml-1 mr-1"
                />
                Per Page
            </div>
            <div className="sps-pagination__details">
                {`Viewing ${state.indices[0]} - ${state.indices[1]} of ${totalResultsString()}`}
            </div>
            <SpsPageSelector numPages={state.numPages} page={state.page} onPageChange={handlePageChange} />
        </div>
    );
}

Object.assign(SpsPagination, {
    props: propsDoc,
    propTypes,
    displayName: "SpsPagination"
});
