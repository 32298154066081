import { Position, PositioningService } from "@spscommerce/positioning";
import clsx from "clsx";
import * as momentImport from "moment-timezone";
import { Moment } from "moment-timezone";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, usePatchReducer } from "../util";

const moment = momentImport["default"] || momentImport as any;


function newWeekArray() {
    return new Array(7);
}

function generateWeeks(date) {
    const baseDate = {
        year: date.year(),
        month: date.month()
    };

    const weekdayArrayIndex = date.weekday();
    const daysInMonth = date.daysInMonth();
    const weeksInMonth = Math.ceil((daysInMonth + weekdayArrayIndex) / 7);
    const newWeeks = new Array(weeksInMonth).fill(true).map(newWeekArray);

    for (let dateIndex = 0; dateIndex < daysInMonth; dateIndex++) {
        const offsetDay = dateIndex + weekdayArrayIndex;
        const week = newWeeks[Math.floor(offsetDay / 7)];
        const dayOfWeekIndex = offsetDay % 7;
        week[dayOfWeekIndex] = Object.freeze(Object.assign({ date: dateIndex + 1 }, baseDate));

        if (dayOfWeekIndex === 6) {
            Object.freeze(week);
        }
    }
    return newWeeks;
}

function parse(value: string): Moment {
    return moment(`${value} 00:00:00`);
}

function weekOfMonth(date: Moment) {
    return Math.floor((date.clone().date(1).day() + date.date() - 1) / 7);
}

const propsDoc = {
    disabled: "boolean",
    format: "string",
    maxDate: "string",
    minDate: "string",
    onChange: "(newValue: Moment) => void",
    onClickOutside: "(event: MouseEvent) => void",
    placeholder: "string",
    value: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    onChange: PropTypes.fun<(newValue: Moment) => void>(),
    onClickOutside: PropTypes.fun<(event: MouseEvent) => void>(),
    placeholder: PropTypes.string,
    value: PropTypes.string
};

export type SpsDatepickerProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

function SpsDatepicker(props: SpsDatepickerProps) {
    const {
        className,
        disabled,
        format = moment.localeData().longDateFormat("MM/DD/YYYY") || "MM/DD/YYYY",
        maxDate,
        minDate,
        onChange,
        onClickOutside,
        placeholder = "MM/DD/YYYY",
        "data-testid": testId,
        unsafelyReplaceClassName,
        value,
        id,
        ...rest
    } = props;

    const calendarRef = React.useRef<HTMLDivElement>();
    const inputRef = React.useRef<HTMLInputElement>();

    const [state, patchState] = usePatchReducer({
        isOpen: false,
        weekdayNames: [],
        view: null,
        weeks: [],
        currentValue: undefined,
        isInitialValue: true,
        keyboardFocusDay: null,
        text: "",
        _minDateMoment: minDate ? moment(minDate) : {},
        _maxDateMoment: maxDate ? moment(maxDate) : {},
    });

    function setCalendarView(displayMoment) {
        if (!displayMoment || !displayMoment.isValid()) {
            if (moment.isMoment(state.currentValue) && state.currentValue.isValid()) {
                displayMoment = state.currentValue.clone();
            } else if (minDate) {
                displayMoment = state._minDateMoment.clone();
            } else if (maxDate) {
                displayMoment = state._maxDateMoment.clone();
            } else {
                displayMoment = moment();
            }
        }
        const newView = displayMoment.set({
            date: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        });
        patchState({
            view: newView,
            weeks: generateWeeks(newView),
        });
    }

    function handleClickOutside (event: MouseEvent) {
        if (calendarRef && calendarRef.current && !calendarRef.current.contains((event.target as Node))) {
            if (inputRef.current && inputRef.current.contains((event.target as Node))) {
                return;
            }
            if (onClickOutside) {
                onClickOutside(event);
            }
            patchState({ isOpen: false });
        }
    }

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        patchState({ weekdayNames: Object.freeze(moment.weekdaysShort()) });
        setCalendarView(null);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        if (value) {
            const parsed = parse(value);
            inputRef.current.value = value;
            patchState({
                currentValue: parsed,
                view: parsed,
                keyboardFocusDay: parsed,
            });
        }
    }, [value]);

    React.useEffect(() => {
        if (moment.isMoment(state.view)) {
            patchState({ weeks: generateWeeks(state.view) });
        }
    }, [state.view]);

    function viewPreviousMonth() {
        setCalendarView(state.view.subtract(1, "month"));
    }

    function viewNextMonth() {
        setCalendarView(state.view.add(1, "month"));
    }

    React.useEffect(() => {
        if (moment.isMoment(state.keyboardFocusDay)) {
            if (state.keyboardFocusDay.isBefore(state.view, "month")) {
                viewPreviousMonth();
            } else if (state.keyboardFocusDay.isAfter(state.view, "month")) {
                viewNextMonth();
            }
        }
    }, [state.keyboardFocusDay]);

    React.useEffect(() => {
        if (!state.isOpen) {
            PositioningService.release(calendarRef.current);
        }
    }, [state.isOpen]);

    React.useEffect(() => {
        if (moment.isMoment(state.currentValue)) {
            setCalendarView(null);
        }
        if (onChange && typeof onChange === "function" && !state.isInitialValue) {
            onChange(state.currentValue);
        }
    }, [state.currentValue]);

    function show() {
        if (!state.isOpen) {
            patchState({ isOpen: true });
            PositioningService.position(calendarRef.current, {
                relativeTo: inputRef.current,
                position: Position.BOTTOM_LEFT,
                offsets: [0, 1]
            });
        }
    }

    function handleTextInputClick(event: React.MouseEvent) {
        event.stopPropagation();
        show();
    }

    function handleTextInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const parsed = parse(event.target.value);
        const newValue = parsed && parsed.isValid() ? parsed : {};
        patchState({
            currentValue: newValue,
            isInitialValue: false,
            text: event.target.value,
        });
    }

    function handleClearClick(event: React.MouseEvent) {
        event.stopPropagation();

        patchState({
            currentValue: null,
            isInitialValue: false,
        });
        setCalendarView(moment());
        inputRef.current.value = "";
        show();
        inputRef.current.focus();
    }

    function dateIsDisabled(date) {
        return (
            (date && (minDate && state._minDateMoment.isAfter(date, "day"))) ||
            (maxDate && state._maxDateMoment.isBefore(date, "day"))
        );
    }

    function updateText(newValue: Moment) {
        inputRef.current.value = moment.isMoment(newValue) ? newValue.format(format) : "";
    }

    function selectDate(date) {
        if (date && !dateIsDisabled(date)) {
            const val = state.currentValue
                ? moment.isMoment(date)
                    ? date.clone()
                    : moment.isMoment(state.currentValue)
                        ? state.currentValue.clone().set(date)
                        : moment(date)
                : moment(date);
                updateText(val);
            patchState({
                isOpen: false,
                currentValue: val,
                isInitialValue: false,
                keyboardFocusDay: null,
            });
            inputRef.current.focus();
        }
    }

    function dateIsSelected(date) {
        return (
            date &&
            moment.isMoment(state.currentValue) &&
            state.currentValue.isValid() &&
            state.currentValue.isSame(date, "day")
        );
    }

    function dateIsFocused(date) {
        return (
            date &&
            moment.isMoment(state.keyboardFocusDay) &&
            state.keyboardFocusDay.isSame(date, "day")
        );
    }

    function onKeyDown(event: React.KeyboardEvent) {
        switch (event.key) {
            case "Up":
            case "ArrowUp":
                if (state.keyboardFocusDay) {
                    event.preventDefault();
                    const newFocusDay = weekOfMonth(state.keyboardFocusDay) === 0
                        ? null
                        : moment.max(
                            state.keyboardFocusDay.clone().subtract(1, "week"),
                            state.keyboardFocusDay.date(1)
                        );
                    patchState({ keyboardFocusDay: newFocusDay });
                    if (!newFocusDay) {
                        inputRef.current.focus();
                    }
                }
                break;

            case "Down":
            case "ArrowDown":
                event.preventDefault();

                if (!state.isOpen) {
                    show();
                }

                if (moment.isMoment(state.keyboardFocusDay)) {
                    const newFocusDay = moment.min(
                        state.keyboardFocusDay.clone().add(1, "week"),
                        state.keyboardFocusDay.date(state.keyboardFocusDay.daysInMonth())
                    );
                    patchState({ keyboardFocusDay: newFocusDay });
                } else if (moment.isMoment(state.view)) {
                    patchState({ keyboardFocusDay: state.view.clone() });
                }
                break;

            case "Left":
            case "ArrowLeft":
                if (state.keyboardFocusDay) {
                    event.preventDefault();
                    let newFocusDay;
                    if (state.keyboardFocusDay.day() === 0 || state.keyboardFocusDay.date() === 1) {
                        const week = weekOfMonth(state.keyboardFocusDay);
                        newFocusDay = week
                            ? moment.min(
                                state.keyboardFocusDay.clone().add(week, "weeks"),
                                state.keyboardFocusDay.date(state.keyboardFocusDay.daysInMonth())
                            )
                            : state.keyboardFocusDay
                                .subtract(1, "month")
                                .date(1)
                                .day(6);
                    } else {
                        newFocusDay = state.keyboardFocusDay.clone().subtract(1, "day");
                    }
                    patchState({ keyboardFocusDay: newFocusDay });
                }
                break;

            case "Right":
            case "ArrowRight":
                if (state.keyboardFocusDay) {
                    event.preventDefault();
                    let newFocusDay;
                    if (
                        state.keyboardFocusDay.day() === 6 ||
                        state.keyboardFocusDay.date() === state.keyboardFocusDay.daysInMonth()
                    ) {
                        const week = weekOfMonth(state.keyboardFocusDay);
                        newFocusDay = week
                            ? moment.min(
                                state.keyboardFocusDay
                                    .clone()
                                    .add(week, "weeks")
                                    .day(0),
                                state.keyboardFocusDay.date(state.keyboardFocusDay.daysInMonth()).day(0)
                            )
                            : state.keyboardFocusDay.add(1, "month").date(1);
                    } else {
                        newFocusDay = state.keyboardFocusDay.clone().add(1, "day");
                    }
                    patchState({ keyboardFocusDay: newFocusDay });
                }
                break;

            case "Enter":
                if (state.keyboardFocusDay) {
                    selectDate(state.keyboardFocusDay);
                }
                break;

            case "Esc":
            case "Escape":
                if (state.keyboardFocusDay) {
                    patchState({ keyboardFocusDay: null });
                    inputRef.current.focus();
                } else {
                    patchState({ isOpen: false });
                }
                break;

            case "Tab":
                patchState({ isOpen: false });
                break;

            default:
                if (!state.isOpen) {
                    show();
                }
        }

        if (state.keyboardFocusDay) {
            if (minDate && state._minDateMoment.isAfter(state.keyboardFocusDay)) {
                patchState({ keyboardFocusDay: state._minDateMoment.clone() });
            } else if (maxDate && state._maxDateMoment.isBefore(state.keyboardFocusDay)) {
                patchState({ keyboardFocusDay: state._maxDateMoment.clone() });
            }
        }
    }

    function renderCalendar() {
        const calendar = [];
        for (let i = 0; i < state.weeks.length; i++) {
            const children = [];
            const week = state.weeks[i];
            for (let j = 0; j < week.length; j++) {
                const date = week[j];

                children.push(
                    <td
                        key={j}
                        className={clsx("sps-datepicker__calendar-cell", {
                            "sps-datepicker__calendar-day": date,
                            "sps-datepicker__calendar-day--selected": dateIsSelected(date),
                            "sps-datepicker__calendar-day--disabled": dateIsDisabled(date),
                            "sps-datepicker__calendar-day--focus": dateIsFocused(date)
                        })}
                        onClick={() => selectDate(date)}
                    >
                        {date ? date.date : ""}
                    </td>
                );
            }
            calendar.push(<tr key={i}>{children}</tr>);
        }
        return calendar;
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-datepicker",
        state.isOpen && "open",
        state.isOpen && "z-stratum-dropdown",
        className
    );

    return (
        <div className={classes} data-testid={`${testId}__datePicker`} id={id} {...rest}>
            <div className="sps-datepicker__dropdown" ref={calendarRef} tabIndex={-1}>
                <div className="sps-datepicker__calendar">
                    <div className="sps-datepicker__calendar-head">
                        <a
                            className="sps-datepicker__button sps-datepicker__button-previous-month"
                            onClick={viewPreviousMonth}
                        >
                            <i className="sps-icon sps-icon-chevron-left" />
                        </a>
                        <span className="sps-datepicker__calendar-head-label">
                            <span className="sps-datepicker__calendar-head-label-month">
                                {moment.isMoment(state.view) ? state.view.format("MMMM") : ""}
                            </span>
                            <span className="sps-datepicker__calendar-head-label-year">
                                {moment.isMoment(state.view) ? state.view.year() : ""}
                            </span>
                        </span>
                        <a
                            className="sps-datepicker__button sps-datepicker__button-next-month"
                            onClick={viewNextMonth}
                        >
                            <i className="sps-icon sps-icon-chevron-right" />
                        </a>
                    </div>
                    <div className="sps-datepicker__calendar-body">
                        <table>
                            <thead>
                                <tr>
                                    {state.weekdayNames.map((dayName, i) => {
                                        return <th key={i}>{dayName}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>{renderCalendar().map(e => e)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="sps-datepicker__inputs">
                <span className="sps-datepicker__input">
                    <span className={clsx("sps-form-control", { disabled })}>
                        <input
                            type="text"
                            ref={inputRef}
                            className="sps-datepicker__text-input"
                            defaultValue={state.text}
                            onKeyDown={onKeyDown}
                            disabled={disabled}
                            onClick={handleTextInputClick}
                            onChange={handleTextInputChange}
                            placeholder={placeholder}
                        />
                    </span>
                    <i className="sps-icon sps-icon-calendar" />
                    {!disabled && (inputRef && inputRef.current && inputRef.current.value) && (
                        <i
                            className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                            onClick={handleClearClick}
                        />
                    )}
                </span>
            </div>
        </div>
    );
}

Object.assign(SpsDatepicker, {
    props: propsDoc,
    propTypes,
    displayName: "SpsDatepicker"
});

export { SpsDatepicker };
