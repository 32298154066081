import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    icon: { type: "SpsIcon", required: true },
    heading: "ReactNodeOrRenderFn",
    subHeading: "ReactNodeOrRenderFn",
};

const propTypes = {
    ...spsGlobalPropTypes,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon).isRequired,
    heading: PropTypes.nodeOrRenderFn,
    subHeading: PropTypes.nodeOrRenderFn,
};

export type SpsMicroZeroStateProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsMicroZeroState(props: SpsMicroZeroStateProps) {
    const {
        className,
        heading,
        icon,
        subHeading,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-zero-state",
        "sps-zero-state--micro",
        className,
    );

    return (
        <div className={classes} data-testid={`${testId}__actual-micro-zero-state`} {...rest}>
            <i className={`sps-icon sps-icon-${icon}`} aria-hidden="true" data-testid={`${testId}__icon`}></i>
            {heading && <h1 className="sps-zero-state__heading" data-testid={`${testId}__heading`}>{heading}</h1>}
            {subHeading && <h2 className="sps-zero-state__subheading" data-testid={`${testId}__subheading`}>{subHeading}</h2>}
        </div>
    );
}

Object.assign(SpsMicroZeroState, {
    props: propsDoc,
    propTypes,
    displayName: "SpsMicroZeroState"
});
