import { code } from "@spscommerce/utils";

export const SpsSpinnerExamples = {
    basic: {
        examples: {
            small: {
                jsx: code`
                    <React.Fragment>
                        <SpsSpinner size="small"/>
                        <SpsSpinner size={SpinnerSize.SMALL}/>
                    </React.Fragment>
                `
            },
            medium: {
                jsx: code`
                    <React.Fragment>
                        <SpsSpinner />
                        <SpsSpinner size="medium" title="Please wait…"/>
                        <SpsSpinner size={SpinnerSize.MEDIUM}/>
                    </React.Fragment>
                `
            },
            large: {
                jsx: code`
                    <React.Fragment>
                        <SpsSpinner size="large"/>
                        <SpsSpinner size={SpinnerSize.LARGE} alt="Loading content…"/>
                    </React.Fragment>
                `
            }
        },
    }
};
