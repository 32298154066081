import { code } from "@spscommerce/utils";

export const SpsGrowlerExamples = {
    styles: {
        label: "Styles",
        examples: {
            success: {
                react: code`
                    function SpsGrowlerSuccess() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)} id="sucess-growler">Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="success" title="Success!">
                                        Your document has been sent.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
            error: {
                react: code`
                    function SpsGrowlerError() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="error" title="Error!">
                                        Your document has not been sent.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
            warning: {
                react: code`
                    function SpsGrowlerWarning() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="warning" title="Offline">
                                        No internet connection detected.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
            info: {
                react: code`
                    function SpsGrowlerInfo() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="info" title="Systems Maintenance Notice">
                                        We'll be offline tonight from 9-11pm CST.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
            progress: {
                react: code`
                    function SpsGrowlerProgress() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="progress" title="Export in Progress">
                                        We'll let you know when it's done.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
            activity: {
                react: code`
                    function SpsGrowlerActivity() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="activity" title="Greg Alexander joined the Network"
                                        imgSrc="assets/images/greg_alexander.jpg"
                                    >
                                        <a href="#">Click to view details</a>
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            },
        }
    },
    events: {
        label: "Events",
        examples: {
            close: {
                react: code`
                    function SpsGrowlerCloseEvent() {
                        const [renderGrowler, setRenderGrowler] = useState(false);

                        return (
                            <React.Fragment>
                                <SpsButton onClick={() => setRenderGrowler(!renderGrowler)}>Show/Reset</SpsButton>
                                {renderGrowler &&
                                    <SpsGrowler kind="success" title="Success!"
                                        onClose={() => console.log("Growler closed.")}
                                    >
                                        Your document has been sent.
                                    </SpsGrowler>
                                }
                            </React.Fragment>
                        );
                    }
                `
            }
        }
    },
    hook: {
        label: "Hook",
        description: code`
            <p>If you use the useGrowlers hook, you should ideally invoke it and
            place <code>{growlers()}</code> at the top level of your app, and pass
            down <code>createGrowler</code>.</p>
        `,
        examples: {
            hook: {
                react: code`
                    function SpsGrowlerHook() {
                        const [growlers, createGrowler] = useGrowlers();

                        function handleButtonClick() {
                            createGrowler({
                                kind: "success",
                                title: "Success!",
                                content: () => <a href="#" title="Content can also be a string.">Look, I can put JSX in here!</a>,
                                onClose() {
                                    console.log("Growler closed.");
                                }
                            });
                        }

                        return (
                            <React.Fragment>
                                <SpsButton onClick={handleButtonClick} id="add-growler">Add a growler</SpsButton>
                                {growlers()}
                            </React.Fragment>
                        );
                    }
                `
            }
        }
    }
};
