export class CustomEvent {
    constructor(eventName: string, initDict: EventInit = {}) {
        let event;
        if (typeof Event === "function") {
            event = new Event(eventName, initDict);
        } else {
            // IE11
            event = document.createEvent("Event");
            event.initEvent(eventName, initDict.bubbles, initDict.cancelable);
            for (const key of Object.keys(initDict)) {
                if (key !== "bubbles" && key !== "cancelable") {
                    event[key] = initDict[key];
                }
            }
        }

        /* Native functions like the getters, setters, and methods on Event.prototype
         * cannot be called with "this" being a custom object. So what we're doing here
         * works around that. */
        const descriptors = Object.getOwnPropertyDescriptors(Event.prototype);
        for (const propKey of Object.keys(descriptors)) {
            const descriptor = descriptors[propKey];
            for (const key of Object.keys(descriptor)) {
                if (typeof descriptor[key] === "function") {
                    descriptor[key] = descriptor[key].bind(event);
                }
            }
        }
        Object.defineProperties(this, descriptors);
    }
}
CustomEvent.prototype = Event.prototype;
