import { forEachNestedObject, traversePath } from "@spscommerce/utils";
import { useReducer, useState } from "react";

import { validate } from "../SpsValidators";
import { SpsControlSet } from "./SpsControlSet.interface";

export function useForm<T extends SpsControlSet>(controls: T, initValue?: any): T {
    if (initValue) {
        controls.setInitValue(initValue);
    }

    const [scheduledDispatch, setScheduledDispatch] = useState();
    const [updatedPaths, setUpdatedPaths] = useState([]);
    const [form, dispatch] = useReducer(state => {
        for (const path of updatedPaths) {
            for (const object of traversePath(state, path)) {
                if (object.validators && object.validators.length) {
                    object.errors = validate(object.getValue(), object.validators);
                    object.invalid = !!object.errors && !object.pristine;
                }
            }
        }

        setScheduledDispatch(null);
        setUpdatedPaths([]);
        return { ...state };
    }, controls);

    forEachNestedObject(form, (path: PropertyKey[], object: any) => {
        if (object._type) {
            object.update = function () {
                updatedPaths.push(path);
                setUpdatedPaths(updatedPaths);
                if (!scheduledDispatch) {
                    setScheduledDispatch(setTimeout(dispatch, 0));
                }
                return this;
            };
        }
    });

    return form;
}
