import { SpsIcon, MultiSelectTagKind } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    active: "boolean",
    kind: "MultiSelectTagKind",
    onAdd: "Function",
    onRemove: "Function",
    disabled: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    active: PropTypes.bool,
    kind: PropTypes.enumValue<MultiSelectTagKind>(MultiSelectTagKind),
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    disabled: PropTypes.bool
};

export type SpsMultiSelectTagProps = PropTypes.InferTS<typeof propTypes, HTMLSpanElement>;

export function SpsMultiSelectTag(props: SpsMultiSelectTagProps) {
    const {
        active,
        children,
        className,
        disabled,
        kind = MultiSelectTagKind.BLUE,
        onRemove,
        onAdd,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-multi-select-tag",
        `sps-multi-select-tag--${kind}`,
        active && "sps-multi-select-tag--active",
        className,
        disabled && 'sps-multi-select-tag--disabled'
    );

    const emitEvent = () => {
        if (kind === MultiSelectTagKind.GRAY) {
            if (typeof onAdd === "function") {
                onAdd();
            }
        } else {
            if (typeof onRemove === "function") {
                onRemove();
            }
        }
    };

    return (
        <span className={classes} data-testid={testId} {...rest}>
            {children}
            <i
                aria-hidden="true"
                className={`sps-icon sps-icon-${
                    (kind === MultiSelectTagKind.GRAY) ? SpsIcon.PLUS_SIGN : SpsIcon.X
                }`}
                onClick={disabled ? () => {} : emitEvent}
                data-testid={`${testId}__icon`}
            />
        </span>
    );
}

Object.assign(SpsMultiSelectTag, {
    props: propsDoc,
    propTypes,
    displayName: "SpsMultiSelectTag"
});
