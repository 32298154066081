// This import nonsense is because ng-packagr uses rollup and rollup sucks
import * as isPlainObjectImport from "lodash.isplainobject";
const isPlainObject = (<any>isPlainObjectImport).default || isPlainObjectImport;

/**
 * Recursively navigate a nested object & call `fn` for each subobject
 */
export function forEachNestedObject(
    object: any,
    fn: (path: PropertyKey[], object: any) => any,
    path: PropertyKey[] = []
): any {
    for (const key of Object.keys(object)) {
        const value = object[key];
        if (isPlainObject(value) || Array.isArray(value)) {
            const newPath = path.concat(key);
            fn(newPath, value);
            forEachNestedObject(value, fn, newPath);
        }
    }
}
