/** @jsx h */
import { Component, Content } from "../../decorators/index";
import { h } from "../../utils/pragma";

/** Contains a set of navigation tabs. */
@Component({ tag: "sps-nav-tab-set" })
export class SpsNavTabSetComponent extends HTMLElement {
    static readonly displayName = "sps-nav-tab-set";
    static readonly props = {};

    @Content() private content = [];

    render() {
        return <nav className="sps-tabbed-nav">
            {this.content}
        </nav>;
    }
}
