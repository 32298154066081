import { MutableRefObject } from "react";
import { values } from "@spscommerce/utils";
import { InferProps, Requireable, oneOf, func, node, object, oneOfType, ReactNodeLike } from "prop-types";

export interface Deprecated<T> extends Requireable<T> {}

export function deprecated<T>(type: Requireable<T>): Deprecated<T> {
    return (type as Deprecated<T>);
}

export function enumValue<T>(e: any): Requireable<T> {
    return oneOf(values(e));
}

export function fun<T extends (...args: any[]) => any>(): Requireable<T> {
    return (func as Requireable<T>);
}

export function impl<T>(): Requireable<T> {
    return (object as any as Requireable<T>);
}

export function ref<T>(): Requireable<MutableRefObject<T>> {
    return (object as any as Requireable<MutableRefObject<T>>);
}

export type ReactNodeOrRenderFn = ReactNodeLike | (() => ReactNodeLike);

export const nodeOrRenderFn = oneOfType<Requireable<ReactNodeOrRenderFn>>([node, func]);

export type InferTS<T, K extends HTMLElement> = InferProps<T> & React.HTMLAttributes<K>;

export * from "prop-types";
