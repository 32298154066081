import { StdButtonKind, SpsIcon, SpsActionDescriptor, SpsActionMethod } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsButton } from "../button/SpsButton";
import { SpsDropdown } from "../dropdown/SpsDropdown";

const propsDoc = {
    disabled: "boolean",
    href: "string",
    label: "string",
    icon: "SpsIcon",
    kind: "StdButtonKind",
    options: "Array<SpsActionMethod | [SpsActionDescriptor, () => void]>",
};

const propTypes = {
    ...spsGlobalPropTypes,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    kind: PropTypes.enumValue<StdButtonKind>(StdButtonKind),
    options: PropTypes.arrayOf<SpsActionMethod | [SpsActionDescriptor, () => void]>(
        PropTypes.oneOfType([PropTypes.func, PropTypes.any])),
};

export type SpsSplitButtonProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsSplitButton(props: SpsSplitButtonProps) {
    const {
        children,
        className,
        "data-testid": testId,
        disabled,
        href,
        label,
        options,
        icon,
        kind = StdButtonKind.DEFAULT,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const setOpen = () => {
        setIsOpen(true);
    };

    const setClose = () => {
        setIsOpen(false);
    };

    const splitButtonClasses = clsx(
        unsafelyReplaceClassName || "sps-split-button",
        `sps-split-button--${kind}`,
        isOpen && "sps-split-button--open",
        className,
    );

    return (
        <div className={splitButtonClasses} data-testid={testId} {...rest}>
            <SpsButton kind={kind} icon={icon} href={href} disabled={disabled}>{label}</SpsButton>
            <SpsDropdown kind={kind} options={options} disabled={disabled} onOpen={setOpen} onClose={setClose}></SpsDropdown>
        </div>
    );
}

Object.assign(SpsSplitButton, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSplitButton",
});
