import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsPageTitleProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsPageTitle(props: SpsPageTitleProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    let icon;
    const otherChildren = [];

    React.Children.forEach(children, child => {
        if (React.isValidElement(child) && child.type === "i") {
            icon = child;
        } else {
            otherChildren.push(child);
        }
    });

    const classes = clsx(
        unsafelyReplaceClassName || "sps-page-title",
        className,
    );

    return (
        <div className={classes} data-testid={`${testId}`} {...rest}>
            {icon}
            <div className="sps-page-title__text" data-testid={`${testId}__text`}>{otherChildren}</div>
        </div>
    );
}

Object.assign(SpsPageTitle, {
    props: propsDoc,
    propTypes,
    displayName: "SpsPageTitle"
});
