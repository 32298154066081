export enum SpsIconSize {
    EXTRA_SMALL = "xs",
    SMALL = "sm",
    MEDIUM = "md",
    LARGE = "lg",
    EXTRA_LARGE = "xl",
}

export enum SpsIcon {
    ANGLE_LEFT = "angle-left",
    ANGLE_RIGHT = "angle-right",
    ARCHIVE = "archive",
    ARCHIVE_CIRCLE = "archive-circle",
    ARROW_DOWN = "arrow-down",
    ARROW_LEFT = "arrow-left",
    ARROW_LEFT_CIRCLE = "arrow-left-circle",
    ARROW_RIGHT = "arrow-right",
    ARROW_RIGHT_CIRCLE = "arrow-right-circle",
    ARROW_UP = "arrow-up",
    ASTERISK = "asterisk",
    ASTERISK_CIRCLE = "asterisk-circle",
    BAN = "ban",
    BAN_CIRCLE = "ban-circle",
    BARCODE = "barcode",
    BELL = "bell",
    BELL_FILLED = "bell-filled",
    BOLT = "bolt",
    BOOK = "book",
    BUILDING = "building",
    BURST = "burst",
    BURST_BOLT = "burst-bolt",
    CALENDAR = "calendar",
    CALENDAR_BLANK = "calendar-blank",
    CALENDAR_BLANK_CIRCLE = "calendar-blank-circle",
    CALENDAR_SELECT = "calendar-select",
    CAMERA = "camera",
    CHART_AREA = "chart-area",
    CHART_BAR = "chart-bar",
    CHART_LINE = "chart-line",
    CHART_PIE = "chart-pie",
    CHECKMARK = "checkmark",
    CHECKMARK_CIRCLE = "checkmark-circle",
    CHEVRON_DOWN = "chevron-down",
    CHEVRON_LEFT = "chevron-left",
    CHEVRON_RIGHT = "chevron-right",
    CHEVRON_UP = "chevron-up",
    CIRCLE_OUTLINE = "circle-outline",
    CLOCK = "clock",
    CLOCK_CIRCLE = "clock-circle",
    CODE_FORK = "code-fork",
    COLUMN_SWITCHER = "column-switcher",
    COMMENT_BUBBLE = "comment-bubble",
    COMMENT_BUBBLE_QUESTION = "comment-bubble-question",
    CONVERSATION = "conversation",
    CREDIT_CARD = "credit-card",
    DASHBOARD = "dashboard",
    DATABASE = "database",
    DESCENDING_ELLIPSES_CIRCLE = "descending-ellipses-circle",
    DISK = "disk",
    DISK_CIRCLE = "disk-circle",
    DOLLAR_SIGN = "dollar-sign",
    DOUBLE_ANGLE_LEFT = "double-angle-left",
    DOUBLE_ANGLE_RIGHT = "double-angle-right",
    DOWNLOAD_CLOUD = "download-cloud",
    DRILL = "drill",
    DUPLICATE = "duplicate",
    ELLIPSES = "ellipses",
    ENVELOPE = "envelope",
    EXCHANGE = "exchange",
    EXCLAMATION_CIRCLE = "exclamation-circle",
    EXCLAMATION_TRIANGLE = "exclamation-triangle",
    EXPAND_COLLAPSE = "expand-collapse",
    EYE = "eye",
    EYE_SLASH = "eye-slash",
    FILE = "file",
    FILE_DUPLICATE = "file-duplicate",
    FILE_PDF = "file-pdf",
    FILE_PRESENTATION = "file-presentation",
    FILE_SIZE = "file-size",
    FILE_SOLID = "file-solid",
    FILE_SOLID_TEXT = "file-solid-text",
    FILE_SPREADSHEET = "file-spreadsheet",
    FILE_TEXT = "file-text",
    FILTER = "filter",
    FLAG = "flag",
    FOLDER = "folder",
    FOLDER_OPEN = "folder-open",
    FOLDER_SOLID = "folder-solid",
    FOLDER_SOLID_OPEN = "folder-solid-open",
    GEAR = "gear",
    GLOBE = "globe",
    GRID = "grid",
    GROUP = "group",
    HAND_STOP = "hand-stop",
    HANDSHAKE = "handshake",
    HAT = "hat",
    HEART = "heart",
    HISTORY = "history",
    INBOX = "inbox",
    INCOMING = "incoming",
    INFO_CIRCLE = "info-circle",
    INTERCOM = "intercom",
    ITEM_TAG = "item-tag",
    KEY = "key",
    LIFE_PRESERVER = "life-preserver",
    LIGHTBULB = "lightbulb",
    LINK = "link",
    LINKEDIN = "linkedin",
    LIST = "list",
    LIST_CARDS = "list-cards",
    LIST_COLUMNS = "list-columns",
    LIST_SUMMARY = "list-summary",
    LIST_TABLE = "list-table",
    LOCATION = "location",
    LOCKED = "locked",
    LOCKED_CIRCLE = "locked-circle",
    MAP = "map",
    MAP_MARKER = "map-marker",
    MAXIMIZE = "maximize",
    MINIMIZE = "minimize",
    MINUS = "minus",
    MINUS_CIRCLE = "minus-circle",
    NEW_FILE = "new-file",
    NEW_SCREEN = "new-screen",
    OUTGOING = "outgoing",
    PAPER_PLANE = "paper-plane",
    PENCIL = "pencil",
    PHOTO = "photo",
    PINTEREST = "pinterest",
    PLAY = "play",
    PLUS_CIRCLE = "plus-circle",
    PLUS_SIGN = "plus-sign",
    PRESENTATION = "presentation",
    PRINTER = "printer",
    QR_CODE = "qr-code",
    QUESTION_CIRCLE = "question-circle",
    QUOTE_LEFT = "quote-left",
    QUOTE_RIGHT = "quote-right",
    RANDOM = "random",
    REFRESH = "refresh",
    RIGHT_CIRCLE = "right-circle",
    ROCKET = "rocket",
    SEARCH = "search",
    SEARCH_PLUS = "search-plus",
    SHARE = "share",
    SHOPPING_CART = "shopping-cart",
    SORT_ALPHA_ASC = "sort-alpha-asc",
    SORT_ALPHA_DESC = "sort-alpha-desc",
    SORT_NUM_ASC = "sort-num-asc",
    SORT_NUM_DESC = "sort-num-desc",
    STAR = "star",
    STAR_OUTLINE = "star-outline",
    STATUS_ARCHIVED = "status-archived",
    STATUS_CANCELLED = "status-cancelled",
    STATUS_ERROR = "status-error",
    STATUS_IN_PROCESS = "status-in-process",
    STATUS_IN_TRANSIT = "status-in-transit",
    STATUS_LOCKED = "status-locked",
    STATUS_NEW = "status-new",
    STATUS_OK = "status-ok",
    STATUS_ON_HOLD = "status-on-hold",
    STATUS_OUTDATED = "status-outdated",
    STATUS_REJECTED = "status-rejected",
    STATUS_SAVED = "status-saved",
    STATUS_SAVING = "status-saving",
    STATUS_TEMPLATE = "status-template",
    STATUS_WARNING = "status-warning",
    TABLE = "table",
    TABS = "tabs",
    TAG = "tag",
    TEMPLATE_CIRCLE = "template-circle",
    THUMBS_DOWN = "thumbs-down",
    THUMBS_DOWN_OUTLINE = "thumbs-down-outline",
    THUMBS_UP = "thumbs-up",
    THUMBS_UP_OUTLINE = "thumbs-up-outline",
    TRASH = "trash",
    TRUCK_SHIPPING = "truck-shipping",
    TWITTER = "twitter",
    UNDO = "undo",
    UNLOCKED = "unlocked",
    UPLOAD_CLOUD = "upload-cloud",
    USER = "user",
    WRENCH = "wrench",
    X = "x",
    X_CIRCLE = "x-circle",
}
