import { SpsIcon } from "./icon";

export enum SpsInsightCardKind {
    GENERAL = "general",
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning",
    PROCESSING = "processing",
}

export const SpsInsightCardIcons = Object.freeze({
    [SpsInsightCardKind.GENERAL]: SpsIcon.STATUS_NEW,
    [SpsInsightCardKind.SUCCESS]: SpsIcon.STATUS_OK,
    [SpsInsightCardKind.ERROR]: SpsIcon.STATUS_ERROR,
    [SpsInsightCardKind.WARNING]: SpsIcon.STATUS_WARNING,
    [SpsInsightCardKind.PROCESSING]: SpsIcon.STATUS_IN_PROCESS,
});
