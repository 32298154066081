import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsButton } from "../index";
import { ButtonKind } from "@spscommerce/ds-shared";

const propsDoc = {
    kind: "ButtonKind",
    closeModal: "() => void",
    handleAsync: "{ turnAsyncOn: () => void, turnAsyncOff: () => void, isAsyncOn: boolean }",
};

const propTypes = {
    ...spsGlobalPropTypes,
    kind: PropTypes.enumValue<ButtonKind>(ButtonKind),
    closeModal: PropTypes.fun<() => void>(),
    handleAsync: PropTypes.shape({
        turnAsyncOn: PropTypes.func,
        turnAsyncOff: PropTypes.func,
        isAsyncOn: PropTypes.bool
    })
};

export type SpsModalActionProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsModalAction(props: SpsModalActionProps) {
    const {
        children,
        onClick,
        kind,
        closeModal,
        handleAsync
    } = props;

    const [asyncButton, setAsyncButton] = React.useState(false);
    let onClickFunc = closeModal;
    if (onClick && typeof onClick === "function") {
        onClickFunc = () => {
            const result: any = onClick(null);
            if (result !== undefined) {
                if (result === false) {
                } else if (typeof result.then === "function") {
                    setAsyncButton(true);
                    handleAsync.turnAsyncOn();
                    result.then((shouldModalClose?: boolean) => {
                        if (shouldModalClose !== false) {
                            closeModal();
                        }
                        handleAsync.turnAsyncOff();
                    });
                }
            } else {
                closeModal();
            }
        };
    }
    return (
        <SpsButton
            kind={kind}
            onClick={onClickFunc}
            disabled={asyncButton ? false : handleAsync.isAsyncOn}
            spinning={asyncButton ? handleAsync.isAsyncOn : false}
        >{children}</SpsButton>
    );
}

Object.assign(SpsModalAction, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModalAction"
});
