import { Metadata } from "../utils/metadata";

/** A property on a component class, when decorated with `@Content()`, will
 * be set to the content that has been placed inside the component. It can
 * then be placed wherever you want in the component's JSX. Analogous to
 * the `children` prop in react or `<ng-content>` in Angular.
 */
export function Content(): PropertyDecorator {
    return (target, key: PropertyKey) => {
        Metadata.add(target.constructor, { contentProp: key });
    };
}
