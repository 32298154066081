import { crumblePath } from "@spscommerce/utils";
import { SpsAbstractControl } from "./SpsAbstractControl.interface";

export interface SpsControlSet extends SpsAbstractControl<any> {
    members: any;
    get<T extends SpsAbstractControl<any>>(path: PropertyKey | PropertyKey[]): T;
    update();
}

export function getMember<T extends SpsAbstractControl<any>>(
    controlSet: SpsControlSet,
    path: PropertyKey | PropertyKey[]
): T {
    const [name, ...rest] = crumblePath(path);
    return rest.length ? (controlSet.members[name] as SpsControlSet).get<T>(rest) : controlSet.members[name];
}
