export enum StdButtonKind {
    DEFAULT = "default",
    CONFIRM = "confirm",
    DELETE = "delete",
    KEY = "key",
}

enum ButtonKindIcon {
    ICON = "icon",
}

enum ButtonKindLink {
    LINK = "link",
}

export type DropdownKind = StdButtonKind | ButtonKindIcon;
export const DropdownKind = Object.freeze({ ...StdButtonKind, ...ButtonKindIcon });

export type ButtonKind = StdButtonKind | ButtonKindIcon | ButtonKindLink;
export const ButtonKind = Object.freeze({ ...StdButtonKind, ...ButtonKindIcon, ...ButtonKindLink });

export enum ButtonType {
    BUTTON = "button",
    SUBMIT = "submit",
}
