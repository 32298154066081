import { I18nObject, noI18nI18n } from "@spscommerce/ds-shared";

let i18n_ = noI18nI18n;

export function webComponentsUseI18n(i18n: I18nObject) {
    i18n_ = i18n;
}

export function getI18n(): I18nObject {
    return i18n_;
}
