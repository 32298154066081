/**
 * Copy a property from one object to another using its property descriptor.
 * In other words, if it's a getter/setter on `sourceObject` it'll be the same
 * getter/setter on `destObject`. If it's just a value it'll be just a value.
 */
export function copyProperty(propName: PropertyKey, sourceObject: any, destObject: any): void {
    Object.defineProperty(
        destObject,
        propName,
        Object.getOwnPropertyDescriptor(sourceObject, propName)
    );
}
