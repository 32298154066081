import {
    attr,
    currentElement,
    currentPointer,
    elementClose,
    elementOpenEnd,
    elementOpenStart,
    skipNode,
    text,
} from "incremental-dom";

import { comment } from "./comment";

/** docs:ignore */
export const MARK_CONTENT_START = "idom-pragma:content-begin";
/** docs:ignore */
export const MARK_CONTENT_END = "idom-pragma:content-end";

function handleChildren(children: any[]) {
    for (const child of children) {
        if (typeof child === "function") {
            child();
        } else if (typeof child === "string") {
            text(child);
        } else if (Array.isArray(child)) {
            // child being an array represents a set of elements managed
            // externally to this system, perhaps by a framework like React.
            // so manually append them if need be and tell idom to skip over them
            let ptr = currentPointer();
            comment(MARK_CONTENT_START);
            if (ptr === null) {
                const ce = currentElement();
                for (let i = 0; i < child.length; i++) {
                    if (!ce.contains(child[i])) {
                        ce.appendChild(child[i]);
                    }
                    skipNode();
                }
            }
            while (ptr && ptr.data !== MARK_CONTENT_END) {
                skipNode();
                ptr = currentPointer();
            }
            comment(MARK_CONTENT_END);
        }
    }
}

/** JSX pragma that utilizes incremental-dom. */
export function h(tagName: string, attrs, ...children) {
    if (typeof tagName === "string" && tagName.toUpperCase() === "FRAG") {
        return () => handleChildren(children);
    }

    return () => {
        elementOpenStart(tagName);
        const eventListenerKeys = [];
        if (attrs) {
            for (const key of Object.keys(attrs)) {
                if (key.toUpperCase() === "CLASSNAME") {
                    attr("class", attrs[key]);
                } else if (/^on[A-Z]/.test(key)) {
                    eventListenerKeys.push(key);
                } else {
                    attr(key, attrs[key]);
                }
            }
        }
        elementOpenEnd(tagName);
        const ce = currentElement();
        if (!ce["__listenersAttached"]) {
            for (const key of eventListenerKeys) {
                ce.addEventListener(key.substr(2).toLowerCase(), attrs[key]);
            }
            ce["__listenersAttached"] = true;
        }
        handleChildren(children);
        elementClose(tagName);
    };
}
