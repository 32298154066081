import { SpsIcon as SpsIconEnum, SpsIconSize } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    icon: { type: "SpsIcon", required: true },
    size: "SpsIconSize",
};

const propTypes = {
    ...spsGlobalPropTypes,
    icon: PropTypes.enumValue<SpsIconEnum>(SpsIconEnum).isRequired,
    size: PropTypes.enumValue<SpsIconSize>(SpsIconSize),
};

export type SpsIconProps = PropTypes.InferTS<typeof propTypes, HTMLElement>;

export function SpsIcon(props: SpsIconProps) {
    const {
        className,
        "data-testid": testId,
        icon,
        size = SpsIconSize.MEDIUM,
        unsafelyReplaceClassName,
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-icon",
        `sps-icon-${icon}`,
        `sps-icon--${size}`,
        className,
    );

    return <i className={classes} aria-hidden="true" data-testid={testId}/>;
}

Object.assign(SpsIcon, {
    props: propsDoc,
    propTypes,
    displayName: "SpsI"
});

export function SpsI(props: SpsIconProps) {
    return <SpsIcon {...props}/>;
}

Object.assign(SpsI, {
    props: propsDoc,
    propTypes,
    displayName: "SpsI"
});
