import * as React from "react";

import { SpsTooltip, TooltipVisibility } from "../tooltip/SpsTooltip";
import {
    SpsTaskStatus,
    SpsTaskStatusIcons,
    TASK_QUEUE_NOTIFICATION_DURATION_MS,
    TooltipKind,
    TooltipShowTrigger,
    SpsIcon,
    SpsTask
} from "@spscommerce/ds-shared";
import { Position, PositioningService } from "@spscommerce/positioning";
import clsx from "clsx";
import { spsGlobalPropTypes } from "../util";
import * as PropTypes from "../prop-types";
import { I18nContext } from "../i18n";

const props = {
    tasks: "ReactSpsTask[]",
    taskQueuePosition: "Position",
    notificationText: "string",
    tooltipConfig: "{ position: Position, kind: TooltipKind }",
    onClose: "() => void",
    onOpen: "() => void",
    onClearCompleted: "() => void"
};

const propTypes = {
    ...spsGlobalPropTypes,
    tasks: PropTypes.arrayOf<ReactSpsTask>(PropTypes.any),
    taskQueuePosition: PropTypes.enumValue<Position>(Position),
    notificationText: PropTypes.string,
    tooltipConfig: PropTypes.shape({
        position: PropTypes.enumValue<Position>(Position),
        kind: PropTypes.enumValue<TooltipKind>(TooltipKind)
    }),
    onClose: PropTypes.fun<() => void>(),
    onOpen: PropTypes.fun<() => void>(),
    onClearCompleted: PropTypes.fun<() => void>()
};

export type SpsTaskQueueProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export type ReactSpsTask = Pick<SpsTask, "heading" | "status" | "unread"> & {
    id?: string;
    subheading?: React.ReactNode;
    actions?: Array<{
        label?: string;
        caption?: string;
        icon?: SpsIcon;
        disabled?: boolean;
        onClick?: (task: ReactSpsTask) => void;
    }>;
};

export function SpsTaskQueue({
    tasks = [],
    taskQueuePosition = Position.BOTTOM_RIGHT,
    notificationText,
    tooltipConfig = {},
    onOpen,
    onClose,
    onClearCompleted,
    className,
    "data-testid": testId,
    unsafelyReplaceClassName,
    ...rest
}: SpsTaskQueueProps) {
    const { t } = React.useContext(I18nContext);

    const hasTasks = !!tasks.length;

    const [isOpen, setIsOpen] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(hasTasks);

    const toggleRef = React.useRef<HTMLButtonElement>(null);
    const taskQueueDropdownRef = React.useRef<HTMLDivElement>(null);

    const tooltipPosition = tooltipConfig.position || Position.BOTTOM_RIGHT;
    const tooltipKind = tooltipConfig.kind || TooltipKind.DEFAULT;

    const unreadTasksCount = tasks.filter(task => task.unread).length;
    const inProgressTasksCount = tasks.filter(task => task.status === SpsTaskStatus.IN_PROGRESS)
        .length;
    const hasCompletedTask = tasks.some(task => task.status === SpsTaskStatus.COMPLETED);

    // this gives us a stable reference to the freshest onClose handler
    const onCloseRef = React.useRef(onClose);
    onCloseRef.current = onClose;

    // this gives us a stable getter for the freshest available positioning options
    const getPositioningOptions = () => ({
        relativeTo: toggleRef.current,
        position: taskQueuePosition
    });
    const getPositioningOptionsRef = React.useRef(getPositioningOptions);
    getPositioningOptionsRef.current = getPositioningOptions;

    const handleToggle = () => {
        if (hasTasks && !isOpen) {
            setIsOpen(true);

            if (onOpen) {
                onOpen();
            }
        } else if (isOpen) {
            setIsOpen(false);

            if (onClose) {
                onClose();
            }
        }
    };

    const getTaskIcon = (task: ReactSpsTask) =>
        task.status === SpsTaskStatus.IN_PROGRESS
            ? SpsTaskStatusIcons[task.status]
            : `sps-icon sps-icon-${SpsTaskStatusIcons[task.status]}`;

    // handles the positioning of the task queue dropdown
    // it should be a layout effect to avoid flickering during positioning
    React.useLayoutEffect(() => {
        if (taskQueueDropdownRef.current && toggleRef.current) {
            if (isOpen) {
                if (PositioningService.isPositioned(taskQueueDropdownRef.current)) {
                    PositioningService.reposition(
                        taskQueueDropdownRef.current,
                        getPositioningOptionsRef.current()
                    );
                } else {
                    PositioningService.position(
                        taskQueueDropdownRef.current,
                        getPositioningOptionsRef.current()
                    );
                }
            } else {
                PositioningService.release(taskQueueDropdownRef.current);
            }
        }
    }, [isOpen, taskQueuePosition, tasks.length]);

    // handles closing the task queue automatically if there are no more tasks in it
    React.useEffect(() => {
        if (isOpen && !hasTasks) {
            setIsOpen(false);

            if (onCloseRef.current) {
                onCloseRef.current();
            }
        }
    }, [isOpen, hasTasks]);

    // handles showing notifications when new tasks are added to the queue
    const notificationTimeout = React.useRef<number>();
    React.useEffect(() => {
        if (unreadTasksCount > 0) {
            setShowNotification(true);

            if (notificationTimeout.current) {
                window.clearTimeout(notificationTimeout.current);
            }

            notificationTimeout.current = window.setTimeout(() => {
                notificationTimeout.current = undefined;
                setShowNotification(false);
            }, TASK_QUEUE_NOTIFICATION_DURATION_MS);
        } else {
            if (notificationTimeout.current) {
                window.clearTimeout(notificationTimeout.current);
                notificationTimeout.current = undefined;
            }

            setShowNotification(false);
        }
    }, [unreadTasksCount]);

    // handles clicks outside of the task queue component
    React.useEffect(() => {
        // handles clicks outside of the task queue
        const documentClickListener = () => {
            setIsOpen(currentIsOpen => {
                if (currentIsOpen && onCloseRef.current) {
                    onCloseRef.current();
                }

                return false;
            });
        };

        // handles repositioning the task queue dropdown if the window is resized
        const resizeListener = () => {
            if (
                taskQueueDropdownRef.current &&
                PositioningService.isPositioned(taskQueueDropdownRef.current)
            ) {
                PositioningService.reposition(
                    taskQueueDropdownRef.current,
                    getPositioningOptionsRef.current()
                );
            }
        };

        document.addEventListener("click", documentClickListener);
        window.addEventListener("resize", resizeListener);

        return () => {
            document.removeEventListener("click", documentClickListener);
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    // handles cleanup
    React.useEffect(
        () => () => {
            if (taskQueueDropdownRef.current) {
                PositioningService.release(taskQueueDropdownRef.current);
            }

            if (notificationTimeout.current) {
                window.clearTimeout(notificationTimeout.current);
            }
        },
        []
    );

    const containerClassNames = clsx(
        unsafelyReplaceClassName || "sps-task-queue",
        isOpen && "sps-task-queue--open",
        isOpen && "z-stratum-dropdown",
        !hasTasks && "sps-task-queue--no-tasks",
        className
    );

    return (
        <div
            style={{ textAlign: "left" }}
            className={containerClassNames}
            onClick={e => {
                // stops click events from propagating outside of the task queue
                // so that it doesn't close itself when clicking inside of it
                e.nativeEvent.stopImmediatePropagation();
            }}
            data-testid={testId}
            {...rest}
        >
            <button
                ref={toggleRef}
                className="sps-task-queue__button"
                title="Task Queue"
                onClick={handleToggle}
            >
                {!!unreadTasksCount && (
                    <span className="sps-badge sps-badge--info">{unreadTasksCount}</span>
                )}
                {!!inProgressTasksCount ? (
                    <span className="sps-spinner sps-spinner--small" />
                ) : (
                    <i className="sps-icon sps-icon-list" aria-hidden="true" />
                )}
            </button>

            <SpsTooltip
                for={toggleRef}
                position={tooltipPosition}
                kind={tooltipKind}
                showOn={hasTasks ? TooltipShowTrigger.MANUAL : TooltipShowTrigger.MOUSEOVER}
                isShown={
                    showNotification && !isOpen
                        ? TooltipVisibility.VISIBLE
                        : TooltipVisibility.HIDDEN
                }
                hideDelay={TASK_QUEUE_NOTIFICATION_DURATION_MS}
            >
                {showNotification
                    ? (notificationText || t("design-system:taskQueue.newTask"))
                    : (hasTasks
                        ? null
                        : t("design-system:taskQueue.noTasks")
                    )}
            </SpsTooltip>

            <div
                ref={taskQueueDropdownRef}
                className="sps-task-queue__task-list z-stratum-dropdown"
            >
                <div className="sps-task-queue__task-list-tasks">
                    {tasks.map((task, taskIndex) => (
                        <div
                            key={taskIndex}
                            className={clsx(
                                "sps-task-queue__task",
                                task.unread && "sps-task-queue__task--unread"
                            )}
                        >
                            <i className={getTaskIcon(task)} />
                            <div className="sps-task-queue__task-description">
                                <div className="sps-task-queue__task-heading">{task.heading}</div>
                                {task.subheading && (
                                    <div className="sps-task-queue__task-subheading">
                                        {task.subheading}
                                    </div>
                                )}
                            </div>

                            {task.actions && !!task.actions.length && (
                                <div className="sps-task-queue__task-actions">
                                    {task.actions.map((action, actionIndex) => (
                                        <div key={actionIndex} className="sps-btn sps-btn--icon">
                                            <button
                                                title={action.label}
                                                disabled={action.disabled}
                                                onClick={() => {
                                                    if (action.onClick) {
                                                        action.onClick(task);
                                                    }
                                                }}
                                            >
                                                {action.caption}
                                                {action.icon && (
                                                    <i
                                                        className={`sps-icon sps-icon-${
                                                            action.icon
                                                        }`}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {hasCompletedTask && onClearCompleted && (
                    <div className="sps-task-queue__footer">
                        <div className="sps-btn sps-btn--link">
                            <button onClick={onClearCompleted}>{t("design-system:taskQueue.clearCompleted")}</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

Object.assign(SpsTaskQueue, {
    props,
    propTypes,
    displayName: "SpsTaskQueue"
});
