export interface SpsValidationErrors {
    [key: string]: any;
}

export type SpsValidationStatus = SpsValidationErrors | null;

export type SpsValidator<T> = (value: T) => SpsValidationStatus;

export const SpsValidators = Object.freeze({
    min(min: number) {
        return value => +value >= min
            ? null
            : { min };
    },
    max(max: number) {
        return value => +value <= max
            ? null
            : { max };
    },
    required(value) {
        return typeof value === "undefined" || value === null || value === "" || Number.isNaN(value)
            ? { required: true }
            : null;
    },
    minLength(minLength: number) {
        return value => value.length >= minLength
            ? null
            : { minLength };
    },
    maxLength(maxLength: number) {
        return value => value.length <= maxLength
            ? null
            : { maxLength };
    },
    pattern(pattern: string | RegExp) {
        const regex = pattern instanceof RegExp ? pattern : new RegExp(pattern);
        return value => regex.test(value)
            ? null
            : { pattern };
    },
});

export function validate<T>(value: T, validators: SpsValidator<T>[] = []) {
    const reduced = (validators as any).reduce(
        (errs: SpsValidationErrors, validator: SpsValidator<T>): SpsValidationErrors =>
            Object.assign(errs, validator(value) || {}),
        {}
    );
    return Object.keys(reduced).length ? reduced : null;
}
