export interface ComponentConfig {
    tag: string;
}

/** docs:ignore */
export interface ComponentChildQuery {
    key: string;
    selector: string;
    refresh: boolean;
    all: boolean;
}

/** docs:ignore */
export interface ComponentMetadata extends ComponentConfig {
    childQueries?: ComponentChildQuery[];
    contentProp?: PropertyKey;
    eventListeners?: Array<[string, PropertyKey]>;
}

const SYMBOL_COMPONENT_META = Symbol("ComponentMetadata");

/** docs:ignore */
export const Metadata = Object.freeze({
    has(target, key: string) {
        return Boolean(
            target[SYMBOL_COMPONENT_META]
            && target[SYMBOL_COMPONENT_META].hasOwnProperty(key)
        );
    },

    add(target, metadata: Partial<ComponentMetadata>) {
        if (target[SYMBOL_COMPONENT_META]) {
            Object.assign(target[SYMBOL_COMPONENT_META], metadata);
        } else {
            target[SYMBOL_COMPONENT_META] = metadata;
        }
    },

    get<T = any>(target, key: string, defaultValue?: T): T {
        return target[SYMBOL_COMPONENT_META]
            ? (target[SYMBOL_COMPONENT_META][key] || defaultValue)
            : defaultValue;
    },
});
