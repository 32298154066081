import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { spsGlobalPropTypes } from "../../util";
import { SpsFormArray } from "../hooks/formArray";
import { SpsFormGroup } from "../hooks/formGroup";
import { SpsControlSet } from "../hooks/SpsControlSet.interface";

const propsDoc = {
    formArray: "SpsFormArray<any>",
    formGroup: "SpsFormGroup",
    stacked: "boolean",
};

const propTypes = {
    ...spsGlobalPropTypes,
    formArray: PropTypes.impl<SpsFormArray<any>>(),
    formGroup: PropTypes.impl<SpsFormGroup>(),
    stacked: PropTypes.bool,
};

export type SpsInputGroupProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsInputGroup(props: SpsInputGroupProps) {
    const {
        children,
        className,
        formArray,
        formGroup,
        stacked,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const formControlSet: SpsControlSet = formGroup || formArray;

    const [wasFocused, setWasFocused] = React.useState();

    React.useEffect(() => {
        if (formControlSet) {
            if (!wasFocused && formControlSet.isFocused() && formControlSet.onFocus) {
                formControlSet.onFocus();
            } else if (wasFocused && !formControlSet.isFocused() && formControlSet.onBlur) {
                formControlSet.onBlur();
            }
            setWasFocused(formControlSet.isFocused());
        }
    });

    const classes = clsx(
        unsafelyReplaceClassName || "sps-form-group",
        formControlSet && formControlSet.invalid && "sps-form-group--error",
        className,
    );

    return (
        <div className={classes} data-testid={testId} {...rest}>
            <div className={clsx("sps-input-group", stacked && "sps-input-group--stacked")}>
              {children}
            </div>
        </div>
    );
}

Object.assign(SpsInputGroup, {
    props: propsDoc,
    propTypes,
    displayName: "SpsInputGroup"
});
