import { SortChangeHandler, SortDirection, SortedColumn } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    controlCell: "boolean",
    currentSort: "Array<SortedColumn>",
    onSortChange: "SortChangeHandler",
    sortKey: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    controlCell: PropTypes.bool,
    currentSort: PropTypes.arrayOf(PropTypes.impl<SortedColumn>()),
    onSortChange: PropTypes.fun<SortChangeHandler>(),
    sortKey: PropTypes.string,
};

export type SpsTableHeaderProps = PropTypes.InferTS<typeof propTypes, HTMLTableHeaderCellElement>;

export function SpsTableHeader(props: SpsTableHeaderProps) {
    const {
        children,
        className,
        controlCell,
        currentSort,
        onSortChange,
        sortKey,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [sort, setSort] = React.useState();

    React.useEffect(() => {
        setSort(currentSort && currentSort[0].key === sortKey ? currentSort[0].direction : undefined);
    }, [currentSort]);

    const flipSort = () => {
        if (sortKey && !controlCell) {
            const curSort = sort === SortDirection.ASCENDING
                ? SortDirection.DESCENDING
                : SortDirection.ASCENDING;
            setSort(curSort);
            onSortChange([{ key: sortKey, direction: curSort }]);
        }
    };

    const handleKeyEvent = (event) => {
        if (event.key === "Enter" || event.key === " " || event.key === "Spacebar") {
            event.preventDefault();
            flipSort();
        }
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-table__header",
        controlCell && "sps-table__header--control",
        !sortKey && "sps-table__header--sort-disabled",
        sort === SortDirection.ASCENDING && "sps-table__header--sorted-asc",
        sort === SortDirection.DESCENDING && "sps-table__header--sorted-desc",
        className,
    );

    return (
        <th className={classes}
            role="columnheader" aria-sort={sort || "none"}
            data-testid={`${testId}__header`}
            {...rest}
        >
            <span className="sps-table__header-cell-body"
                data-testid={`${testId}__header-cell-body`}
                onClick={flipSort}
                tabIndex={sortKey ? 0 : null}
                onKeyDown={handleKeyEvent}
            >
                {children}
            </span>
        </th>
    );
}

Object.assign(SpsTableHeader, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTableHeader"
});

export function SpsTh(props: SpsTableHeaderProps) {
    return SpsTableHeader(props);
}

Object.assign(SpsTh, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTh"
});
