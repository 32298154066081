import { SortChangeHandler, SortedColumn } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsTableRow, SpsTr } from "./SpsTableRow";

const propsDoc = {
    currentSort: "Array<SortedColumn>",
    onSortChange: "SortChangeHandler",
};

const propTypes = {
    ...spsGlobalPropTypes,
    onSortChange: PropTypes.fun<SortChangeHandler>(),
    currentSort: PropTypes.arrayOf(PropTypes.impl<SortedColumn>())
};

export type SpsTableHeadProps = PropTypes.InferTS<typeof propTypes, HTMLTableSectionElement>;

export function SpsTableHead(props: SpsTableHeadProps) {
    const {
        children,
        className,
        currentSort,
        onSortChange,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const newProps = {
        onSortChange: onSortChange,
        currentSort: currentSort
    };

    const classes = clsx(unsafelyReplaceClassName || "sps-table__head", className);

    return (
        <thead className={classes} role="rowgroup" data-testid={`${testId}__head`} {...rest}>
            {React.Children.map(children, (child: any) => {
                if (child && (child.type === SpsTableRow || child.type === SpsTr)) {
                    return React.cloneElement(child, newProps);
                }
                return child;
            })}
        </thead>
    );
}

Object.assign(SpsTableHead, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTableHead"
});

export function SpsThead(props: SpsTableHeadProps) {
    return SpsTableHead(props);
}

Object.assign(SpsThead, {
    props: propsDoc,
    propTypes,
    displayName: "SpsThead"
});
