import { code } from "@spscommerce/utils";

export const SpsKeyValueListExamples = {
    stacked: {
        label: "Stacked",
        examples: {
            stacked: {
                jsx: code`
                    <SpsKeyValueList>
                        <SpsKeyValueListItem itemKey="Buying From">
                            75 Companies
                            <span className="font-weight-light d-block">38 of 75 Operational</span>
                        </SpsKeyValueListItem>
                        <SpsKeyValueListItem itemKey="Some Other Key">Its Value</SpsKeyValueListItem>
                    </SpsKeyValueList>
                `
            }
        }
    },
    inline: {
        label: "Inline",
        examples: {
            inline: {
                jsx: code`
                    <SpsKeyValueList inline>
                        <SpsKeyValueListItem itemKey="Title">Sub copy goes here</SpsKeyValueListItem>
                        <SpsKeyValueListItem itemKey="Long Title">Sub copy</SpsKeyValueListItem>
                        <SpsKeyValueListItem itemKey="Tags">
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">Tag</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">Another Tag</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">Taggart McTaggerson</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">Taggle Rock</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">A Tag On Titan</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">bröther may i have some tägs</SpsTag>
                            <SpsTag kind={TagKind.INFO} className="mr-1 mb-1">Tag Me Up, Tag Me Down</SpsTag>
                        </SpsKeyValueListItem>
                    </SpsKeyValueList>
                `
            }
        }
    }
};
