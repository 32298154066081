import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import clsx from "clsx";

const propsDoc = {
    onOverlayClick: "EventHandler",
    backdrop: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    onOverlayClick: PropTypes.func,
    backdrop: PropTypes.string
};

export type SpsModalOverlayProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsModalOverlay(props: SpsModalOverlayProps) {
    const {
        backdrop,
        onOverlayClick,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const element: any = React.useRef();

    React.useEffect(() => {
        if (typeof onOverlayClick === "function" && backdrop !== 'static' && element) {
            element.current.addEventListener('mousedown', onOverlayClick, false);
        }
    }, []);

    React.useEffect(() => {
        if (typeof onOverlayClick === "function" && backdrop !== 'static' && element) {
            element.current.removeEventListener('mousedown', onOverlayClick, false);
            element.current.addEventListener('mousedown', onOverlayClick, false);
        }
    }, [onOverlayClick]);

    const overlayClasses = unsafelyReplaceClassName || clsx("modal-backdrop show");

    return (
        <div className={overlayClasses} data-testid={testId} ref={element} {...rest}></div>
    );
}

Object.assign(SpsModalOverlay, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModalOverlay"
});
