import { code } from "@spscommerce/utils";

export const SpsLabelExamples = {
    basic: {
        label: "Basic",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl()
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}>
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            }
        }
    },
    suggested: {
        label: "Strongly Suggested",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl()
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")} stronglySuggested>
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            }
        }
    },
    required: {
        label: "Required",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl("", { validators: [SpsValidators.required] })
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                errors={() => form.get("companyName").hasError("required")
                                    && "Please enter a company name."}
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            }
        }
    },
    help: {
        label: "Help Information",
        examples: {
            defaultIcon: {
                description: "<p>Default icon</p>",
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl()
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                help="Pretend there's something helpful written here."
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            },
            customIcon: {
                description: "<p>Custom icon</p>",
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl()
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                help="Pretend there's something helpful written here."
                                helpIcon={SpsIcon.EXCHANGE} helpIconColor="red200"
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            }
        }
    },
    validation: {
        label: "Validation",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl("Target", { validators: [SpsValidators.minLength(7)] })
                        }));

                        React.useEffect(() => {
                            // Make validation error visible for the purpose of this demo
                            form.markAsDirty();
                        }, []);

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                errors={() => form.get("companyName").hasError("minLength")
                                    && "Company Name must be at least 7 characters."}
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            }
        }
    },
    combinations: {
        label: "Combinations",
        examples: {
            combo1: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl("Target", { validators: [SpsValidators.minLength(7)] })
                        }));

                        React.useEffect(() => {
                            // Make validation error visible for the purpose of this demo
                            form.markAsDirty();
                        }, []);

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                stronglySuggested
                                errors={() => form.get("companyName").hasError("minLength")
                                    && "Company Name must be at least 7 characters."}
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            },
            combo2: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            companyName: formControl("", { validators: [SpsValidators.required] })
                        }));

                        return <SpsForm formGroup={form}>
                            <SpsLabel for={form.get("companyName")}
                                help="Pretend there's something helpful written here."
                                errors={() => form.get("companyName").hasError("required")
                                    && "Please enter a company name."}
                            >
                                Company Name
                            </SpsLabel>
                            <SpsTextInput formControl={form.get("companyName")}></SpsTextInput>
                        </SpsForm>;
                    }
                `
            },
        }
    },
};
