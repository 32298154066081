import { currentElement, currentPointer, skipNode } from "incremental-dom";

/** docs:ignore */
export function comment(content: string) {
    let node = currentPointer();
    if (node instanceof Comment && node.data !== content) {
        node.data = content;
    } else {
        node = document.createComment(content);
        currentElement().appendChild(node);
    }
    skipNode();
    return node;
}
