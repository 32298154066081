/** Shape of the config param for the `SpsOptionListOption<T>` constructor. */
export interface SpsOptionListOptionConfig {
    textKey?: string;
    text?: string;
    captionKey?: string;
    caption?: string;
    disabled?: boolean;
}

/** All options passed in to components such as the select, the autocomplete, etc which
 * use the Option List are normalized internally to this representation of an Option List option.
 */
export class SpsOptionListOption<T> {
    constructor(public value: T, config: SpsOptionListOptionConfig = {}) {
        Object.assign(this, config);
    }

    get text() {
        if (this._text) {
            return this._text;
        }
        if (!this.value) {
            return "";
        }
        return this.textKey ? this.value[this.textKey] : this.value.toString();
    }
    set text(value: string) {
        this._text = value;
    }
    get caption() {
        if (this._caption) {
            return this._caption;
        }
        if (!this.value) {
            return "";
        }
        return this.captionKey ? this.value[this.captionKey] : "";
    }
    set caption(value: string) {
        this._caption = value;
    }

    get href() {
        if (this.value) {
            return this.value["isLink"] && typeof this.value === "function"
                ? (this.value as any)()
                : this.value["href"];
        }
    }
    get disabled() {
        if (typeof this._disabled !== "undefined") {
            return this._disabled;
        }
        return this.value ? this.value["disabled"] : false;
    }
    textKey: string;
    captionKey: string;

    private _text: string;

    private _caption: string;

    private _disabled: boolean;

    getHtml(patternToUnderline?: RegExp) {
        if (patternToUnderline) {
            return this.text.replace(patternToUnderline, "<u>$&</u>");
        }
        return this.text;
    }
}
