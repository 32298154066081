import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { jsxTestId, spsGlobalPropTypes } from "../util";

const propsDoc = {
    content: "ReactNodeLike",
    icon: "SpsIcon",
    title: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    content: PropTypes.node,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    title: PropTypes.string,
};

export type SpsCardHeaderProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsCardHeader(props: SpsCardHeaderProps) {
    const {
        content,
        className,
        icon,
        title,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-card__header",
        className,
    );

    return (
        <div className={classes} {...jsxTestId(testId)} title={title} {...rest}>
            <div className="sps-card__title" {...jsxTestId(testId, "__title")}> {icon && <i aria-hidden="true" className={`sps-icon sps-icon-${icon}`} {...jsxTestId(testId, "__icon")}></i>} {title}</div>
            <div className="sps-card__header-content" {...jsxTestId(testId, "__content")}>{content}</div>
        </div>
    );
}

Object.assign(SpsCardHeader, {
    props: propsDoc,
    propTypes,
    displayName: "SpsCardHeader"
});
