import { code } from "@spscommerce/utils";

export const SpsFieldsetExamples = {
    basic: {
        label: "Basic Fieldsets",
        description: "info about basic fieldsets",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            basicFieldset: formGroup({
                                fsTextA: formControl(),
                                fsTextB: formControl(),
                                fsRadio: formControl("a")
                            })
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsFieldset formGroup={form.get("basicFieldset")} legend="Fieldset Legend">
                                    <div className="sps-row">
                                        <div className="col-4">
                                            <SpsLabel for={form.get("basicFieldset.fsTextA")}>Input A</SpsLabel>
                                            <SpsTextInput formControl={form.get("basicFieldset.fsTextA")}></SpsTextInput>
                                        </div>
                                        <div className="col-4">
                                            <SpsLabel for={form.get("basicFieldset.fsTextB")}>Input B</SpsLabel>
                                            <SpsTextInput formControl={form.get("basicFieldset.fsTextB")}></SpsTextInput>
                                        </div>
                                        <div className="col-4">
                                            <SpsLabel for={form.get("basicFieldset.fsTextB")}>Input B</SpsLabel>
                                            <SpsInputGroup>
                                                <SpsRadioButton name="fsRadio" label="Option A" value="a" formControl={form.get("basicFieldset.fsRadio")}/>
                                                <SpsRadioButton name="fsRadio" label="Option B" value="b" formControl={form.get("basicFieldset.fsRadio")}/>
                                            </SpsInputGroup>
                                        </div>
                                    </div>
                                </SpsFieldset>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    nested: {
        label: "Nested Fieldsets",
        description: "info about nested fieldsets",
        examples: {
            nested: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            fieldsetWithNested: formGroup({
                                fsText: formControl(),
                                fsNested: formGroup({
                                    fsnRadio: formControl("b"),
                                    fsNested2: formGroup({
                                        fsn2Text: formControl()
                                    })
                                })
                            })
                        }));
                        return (
                            <SpsForm formGroup={form}>
                                <SpsFieldset formGroup={form.get("fieldsetWithNested")} legend="Fieldset Legend">
                                    <div className="sps-row">
                                        <div className="col-4">
                                            <SpsLabel for={form.get("fieldsetWithNested.fsText")}>Input</SpsLabel>
                                            <SpsTextInput formControl={form.get("fieldsetWithNested.fsText")} placeholder="Enter a thing"></SpsTextInput>
                                        </div>
                                    </div>
                                    <SpsFieldset formGroup={form.get("fieldsetWithNested.fsNested")} legend="Nested Fieldset">
                                        <div className="sps-row">
                                            <div className="col-4">
                                                <SpsLabel for={form.get("fieldsetWithNested.fsNested.fsnRadio")}>Radio</SpsLabel>
                                                <SpsInputGroup>
                                                    <SpsRadioButton name="fsnRadio" label="Option A" value="a" formControl={form.get("fieldsetWithNested.fsNested.fsnRadio")}/>
                                                    <SpsRadioButton name="fsnRadio" label="Option B" value="b" formControl={form.get("fieldsetWithNested.fsNested.fsnRadio")}/>
                                                </SpsInputGroup>
                                            </div>
                                        </div>
                                        <SpsFieldset formGroup={form.get("fieldsetWithNested.fsNested.fsNested2")} legend="Doubly Nested Fieldset">
                                            <div className="sps-row">
                                                <div className="col-4">
                                                    <SpsLabel for={form.get("fieldsetWithNested.fsNested.fsNested2.fsn2Text")}>Input</SpsLabel>
                                                    <SpsTextInput formControl={form.get("fieldsetWithNested.fsNested.fsNested2.fsn2Text")} placeholder="Enter a thing"></SpsTextInput>
                                                </div>
                                            </div>
                                        </SpsFieldset>
                                    </SpsFieldset>
                                </SpsFieldset>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    optional: {
        label: "Optional Fieldsets",
        description: "info about optional fieldsets",
        examples: {
            optional: {
                react: code`
                function DemoComponent() {
                    const form = useForm(formGroup({
                        optionalFieldset: formGroup({
                            fsTextA: formControl(),
                            fsTextB: formControl(),
                        })
                    }));
                    function handleToggle(isChecked) {
                        console.log(isChecked ?"optional fieldset visible":"optional fieldset not visible");
                       }

                    return (
                        <SpsForm formGroup={form} >
                            <SpsFieldset legend="Fieldset Legend" formGroup={form.get("optionalFieldset")} optional enabled ={false} onToggled={handleToggle}>
                                <div class="sps-row">
                                    <div class="col-4">
                                        <SpsLabel for={form.get("optionalFieldset.fsTextA")} >Input A</SpsLabel>
                                        <SpsTextInput formControl={form.get("optionalFieldset.fsTextA")}  placeholder="Enter a thing"></SpsTextInput>
                                    </div>
                                    <div class="col-4">
                                        <SpsLabel for={form.get("optionalFieldset.fsTextB")} >Input B</SpsLabel>
                                        <SpsTextInput formControl={form.get("optionalFieldset.fsTextB")}  placeholder="Enter a thing"></SpsTextInput>
                                    </div>
                                </div>
                            </SpsFieldset>
                        </SpsForm>
                    )
                }
                `
            }
        }
    },
    validationOnField: {
        label: "Validation on Field",
        description: "info about validation on fields",
        examples: {
            validationOnField: {
                react: code`
                        function DemoComponent() {
                            const form = useForm(formGroup({
                                validationFieldset: formGroup({
                                    requiredText: formControl("",{validators: [SpsValidators.required]}),
                                })
                        }));
                            React.useEffect(() => {
                                form.get("validationFieldset.requiredText").markAsDirty();
                            },[])
                            return (
                                <SpsForm formGroup={form}>
                                    <SpsFieldset formGroup={form.get("validationFieldset")} legend="Fieldset Legend">
                                        <div className="sps-row">
                                            <div className="col-4">
                                                <SpsLabel for={form.get("validationFieldset.requiredText")}>Input A</SpsLabel>
                                                <SpsTextInput formControl={form.get("validationFieldset.requiredText")}></SpsTextInput>
                                            </div>
                                        </div>
                                    </SpsFieldset>
                                </SpsForm>
                            )
                        }
                    `
            }
        }
    },
    validationFieldset: {
        label: "Validation on Fieldset",
        description: "info about validation on fieldsets",
        examples: {
            validationOnFieldset: {
                react: code`
                        function DemoComponent() {
                            function exampleFieldsetValidator(value) {
                                if (value.fsnRadio === "b") {
                                    if (value.colors.filter(Boolean).length === 0) {
                                        return {
                                            exampleFieldset: true
                                        };
                                    }
                                }
                                return null;
                            };

                            const colorOptions = ["Red", "Orange", "Yellow", "Green", "Blue", "Indigo", "Violet"];
                            const form = useForm(formGroup({
                                validationFieldset: formGroup({
                                    fsText: formControl(),
                                    nestedValidatedFieldset: formGroup({
                                        fsnRadio: formControl("b"),
                                        colors: formArray(colorOptions.map(() => formControl(false)))
                                    }, {validators: [exampleFieldsetValidator]})
                                })
                        }));

                        React.useEffect(() => {
                            form.get("validationFieldset.nestedValidatedFieldset.fsnRadio").markAsDirty();
                        },[])

                        return (
                            <SpsForm formGroup={form}>
                             <SpsFieldset formGroup={form.get("validationFieldset")} legend="Fieldset Legend">
                                    <div className="sps-row">
                                        <div className="col-4">
                                            <SpsLabel for={form.get("validationFieldset.fsText")}>Input</SpsLabel>
                                            <SpsTextInput formControl={form.get("validationFieldset.fsText")} placeholder="Enter a thing"></SpsTextInput>
                                        </div>
                                    </div>
                                    <SpsFieldset formGroup={form.get("validationFieldset.nestedValidatedFieldset")} legend="Nested Fieldset" errors={() => form.get("validationFieldset.nestedValidatedFieldset").hasError("exampleFieldset") && "If you choose Option B, you must select at least one color."}>
                                        <div className="sps-row">
                                            <div className="col-4">
                                                <SpsLabel for={form.get("validationFieldset.nestedValidatedFieldset.fsnRadio")}>Radio</SpsLabel>
                                                <SpsInputGroup>
                                                    <SpsRadioButton name="fsnRadio" label="Option A" value="a" formControl={form.get("validationFieldset.nestedValidatedFieldset.fsnRadio")}/>
                                                    <SpsRadioButton name="fsnRadio" label="Option B" value="b" formControl={form.get("validationFieldset.nestedValidatedFieldset.fsnRadio")}/>
                                                </SpsInputGroup>
                                            </div>
                                            <div className="col-8">
                                                <SpsLabel for={form.get("validationFieldset.nestedValidatedFieldset.colors")}>Colors</SpsLabel>
                                                <SpsInputGroup>
                                                    {colorOptions.map((color, index) =>
                                                        <SpsCheckbox key={index} name="colors" label={color} formControl={form.get(\`validationFieldset.nestedValidatedFieldset.colors[\${index}]\`)}/>
                                                    )}
                                                </SpsInputGroup>
                                            </div>
                                        </div>
                                    </SpsFieldset>
                                </SpsFieldset>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    }
};
