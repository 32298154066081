import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsWorkflowDocumentProps = PropTypes.InferTS<typeof propTypes, HTMLSpanElement>;

export function SpsWorkflowDocument(props: SpsWorkflowDocumentProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-workflow__document",
        className,
    );

    return (
        <span className={classes} data-testid={`${testId}__document`} {...rest}>
            {children}
        </span>
    );
}

Object.assign(SpsWorkflowDocument, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWorkflowDocument"
});

export function SpsWfDoc(props: SpsWorkflowDocumentProps) {
    return SpsWorkflowDocument(props);
}

Object.assign(SpsWfDoc, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWfDoc"
});
