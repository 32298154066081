import { constrain, debounce } from "@spscommerce/utils";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    page: "number",
    numPages: "number",
    onPageChange: "(number) => void",
};

const propTypes = {
    numPages: PropTypes.number,
    onPageChange: PropTypes.fun<(number) => void>(),
    page: PropTypes.number,
    ...spsGlobalPropTypes
};

export type SpsPageSelectorProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

let idNum = 0;

export function SpsPageSelector(props: SpsPageSelectorProps) {
    const {
        numPages = 0,
        onPageChange,
        page: pageProp = 1,
        unsafelyReplaceClassName,
        className,
        "data-testid": testId,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const [inputPage, setInputPage] = React.useState(pageProp);
    const [page, setPage] = React.useState(pageProp);

    React.useEffect(() => {
        setPage(pageProp);
        setInputPage(pageProp);
    }, [pageProp]);

    function updatePage(newPage: number) {
        newPage = constrain(newPage, [1, numPages]);

        if (newPage !== inputPage) {
            setPage(newPage);
            setInputPage(newPage);
            if (onPageChange && typeof onPageChange === "function") {
                onPageChange(newPage);
            }
        }
    }

    const debouncedPageUpdate = debounce(updatePage, 500);

    function onTextInputInput(event) {
        setInputPage(event.target.value);
        debouncedPageUpdate(parseInt(event.target.value, 10));
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-page-selector",
        className,
    );

    const id = `sps-page-selector-${idNum++}`;

    return (
        <div className={classes} data-testid={testId} {...rest}>
            <div className="sps-pagination__input-wrapper" data-testid={`${testId}__input`}>
                <div className="sps-form-control sps-pagination__input-sizer">{inputPage}</div>
                <label htmlFor={id} className="sr-only">{t("design-system:pagination.page")}</label>
                <input id={id}
                    type="text"
                    value={inputPage}
                    onInput={onTextInputInput}
                    onChange={() => {}} // required or else React renders the input as read only
                    className="sps-form-control sps-pagination__input"
                />
            </div>
            <span className="sps-page-selector__total-pages" data-testid={`${testId}_page-count`}>{
                Number.isNaN(numPages)
                    ? t("design-system:pagination.ofMany")
                    : t("design-system:pagination.ofPageCount", { pageCount: numPages })
            }</span>
            <div className="sps-pagination__navigation-buttons" data-testid={`${testId}__buttons`}>
                <div className={clsx("sps-btn", "sps-btn--icon", page === 1 && "disabled")}>
                    <button
                        onClick={() => updatePage(page - 1)}
                        disabled={page === 1}
                        title={t("design-system:pagination.prevPage")}
                    >
                        <i className="sps-icon sps-icon-chevron-left" aria-hidden="true" />
                    </button>
                </div>
                <div className={clsx("sps-btn", "sps-btn--icon", page === numPages && "disabled")}>
                    <button
                        onClick={() => updatePage(page + 1)}
                        disabled={page === numPages}
                        title={t("design-system:pagination.nextPage")}
                    >
                        <i className="sps-icon sps-icon-chevron-right" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
}

Object.assign(SpsPageSelector, {
    props: propsDoc,
    propTypes,
    displayName: "SpsPageSelector"
});
