import { getPath } from "../object";

const REGEX_REPLACEMENT = /<%=(.+?)%>/g;
const REGEX_IF = /<%\?(.+?)%>(.+?)<\/%>/g;

/**
 *  Every string templating thing out there whether lodash, doT, handlebars, etc
 *  is extremely complicated to cover a variety of use cases. This just has two
 *  very simple features and great caution should be exercised in making it any more
 *  complex than that. A value can be interpolated like so:
 *  `<%= foo.bar %>`
 *  And a basic "if" conditional can be used like so:
 *  `<%? a?.b?.c %>This part will only appear if a.b.c is truthy!</%>`
 *  You can put interpolations inside a conditional, as you'd expect. That's it.
 */
export function template(string: string): (data: any) => string {
    return (data: any) =>
        string
            .replace(REGEX_IF, (match, pathToCheck, content) =>
                getPath(data, pathToCheck.trim()) ? content : ""
            )
            .replace(REGEX_REPLACEMENT, (match, pathToInterpolate) =>
                getPath(data, pathToInterpolate.trim(), "")
            );
}
