import { code } from "@spscommerce/utils";

export const SpsPhotoExamples = {
  sizing: {
    label: "Sizing",
    description: code`
        <p>
            Photos are block elements, so a photo fills its container's width unless
            an explicit width is set. It is always a 4:3 aspect ratio. In this example,
            photos have been placed in cards which have different widths in the 12
            column grid.
        </p>
    `,
    examples: {
      basic: {
        template: code`
            <div class="sps-row-layout">
                <sps-card class="col-2">
                    <sps-photo class="sps-photo" src="assets/images/photo-landscape.jpg"></sps-photo>
                </sps-card>
                <sps-card class="col-4">
                    <sps-photo class="sps-photo" src="assets/images/photo-landscape.jpg"></sps-photo>
                </sps-card>
                <sps-card class="col-6">
                    <sps-photo class="sps-photo" src="assets/images/photo-landscape.jpg"></sps-photo>
                </sps-card>
            </div>
        `
      },
    }
  },
  displayMode: {
    label: "Fill vs. Fit",
    description: code`
        <p>
            There are two display modes available:
            <ul>
                <li><b>"fill"</b> fills the entire 4:3 container, cropping off any excess portions of the image.
                <li><b>"fit"</b> fits the entire image into the container, leaving any excess portions of the container blank.
            </ul>
        </p>
    `,
    examples: {
      landscape: {
        description: "<p>Landscape oriented photo (container outline added for illustrative purposes)</p>",
        template: code`
            <div class="sps-row-layout">
                <sps-photo src="assets/images/photo-landscape.jpg" mode="fill"></sps-photo>
                <sps-photo src="assets/images/photo-landscape.jpg" mode="fit"></sps-photo>
            </div>
        `
      },
      portrait: {
        description: "<p>Portrait oriented photo (container outline added for illustrative purposes)</p>",
        template: code`
            <div class="sps-row-layout">
                <sps-photo src="assets/images/photo-portrait.jpg" mode="fill"></sps-photo>
                <sps-photo id="foo" src="assets/images/photo-portrait.jpg" mode="fit"></sps-photo>
            </div>
        `
      },
    }
  },
  placeholder: {
      label: "Placeholders",
      description: code`
        <p>
            When an image is not provided for a particular use case (such as a user avatar,
            a company photo, item image, etc.), a placeholder should be shown in its place.

            The placeholder's icon, selected from our SPS icon set, should represent the
            type of image it is substituting for.
        </p>
    `,
    examples: {
        general: {
            description: "<p>General Photo</p>",
            template: code`
                <div class="sps-row-layout">
                    <sps-photo placeholderIcon="photo" class="col-3"></sps-photo>
                </div>
            `
        },
        photoAlt: {
            description: "<p>Photo Alt</p>",
            template: code`
                <div class="sps-row-layout">
                    <sps-photo placeholderIcon="camera" class="col-3"></sps-photo>
                </div>
            `
        },
        user: {
            description: "<p>User / Single Person</p>",
            template: code`
                <div class="sps-row-layout">
                    <sps-photo placeholderIcon="user" class="col-3"></sps-photo>
                </div>
            `
        },
        group: {
            description: "<p>Group / Multiple People</p>",
            template: code`
                <div class="sps-row-layout">
                    <sps-photo placeholderIcon="group" class="col-3"></sps-photo>
                </div>
            `
        },
        company: {
            description: "<p>Company / Building</p>",
            template: code`
                <div class="sps-row-layout">
                    <sps-photo placeholderIcon="building" class="col-3"></sps-photo>
                </div>
            `
        },
    }
  },
};
