import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    resultsCount: "number",
    totalResults: "number",
    onClear: "() => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    resultsCount: PropTypes.number,
    totalResults: PropTypes.number,
    onClear: PropTypes.fun<() => void>(),
};

export type SpsSearchResultsBarProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsSearchResultsBar(props: SpsSearchResultsBarProps) {
    const {
        children,
        className,
        resultsCount,
        totalResults,
        onClear,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-search-results-bar",
        className
    );

    const [count, of_, total] = t("design-system:searchResultsBar.count", { count: resultsCount, total: totalResults })
        .split("|");

    return (
        <div className={classes} data-testid={`${testId}`} {...rest} >
            <span className="sps-search-results-bar__label" data-testid={`${testId}__label`}>{t("design-system:searchResultsBar.matchingResults")}</span>
            {
                !Number.isNaN(+count) &&
                <span className="sps-search-results-bar__count" data-testid={`${testId}__count`}>
                    <i className="sps-icon sps-icon-double-angle-right"></i>
                    <span>{count}</span>
                </span>
            }
            {
                !Number.isNaN(+total) &&
                <span className="sps-search-results-bar__total" data-testid={`${testId}__total`}>{of_}{total}</span>
            }
            <div className="sps-search-results-bar__buttons" data-testid={`${testId}__button`}>
                <button onClick={onClear}>{t("design-system:searchResultsBar.clear")}</button>
            </div>
        </div >
    );
}

Object.assign(SpsSearchResultsBar, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSearchResultsBar"
});
