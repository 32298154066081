import { Eventually } from "@spscommerce/utils";
import clsx from "clsx";
import * as React from "react";

import { SpsFormControl } from "../form/hooks/formControl";
import { useFormControlId } from "../form/hooks/useFormControlId";
import { SpsFormComponentWrapper } from "../form/SpsFormComponentWrapper";
import { SpsOptionList } from "../option-list/SpsOptionList";
import * as PropTypes from "../prop-types";
import { FauxChangeEvent, spsGlobalPropTypes, usePatchReducer } from "../util";

const propsDoc = {
    debounce: "number",
    disabled: "boolean",
    formControl: "SpsFormControl<string>",
    onChange: "React.ChangeEventHandler",
    placeholder: "string",
    suggestions: {
        type: `
            Eventually<Iterable<string>>
            | ((filter?: string) => Eventually<Iterable<string>>)
        `,
        required: true
    },
    value: "string",
    zeroState: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    debounce: PropTypes.number,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<string>>(),
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
    placeholder: PropTypes.string,
    suggestions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.instanceOf<Promise<string[]>>(Promise),
        PropTypes.fun<(filter?: string) => Eventually<string[]>>(),
    ]).isRequired,
    value: PropTypes.string,
    zeroState: PropTypes.string,
};

export type SpsAutocompleteProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsAutocomplete(props: SpsAutocompleteProps) {
    const {
        className,
        debounce = 0,
        disabled,
        formControl,
        onChange,
        id,
        placeholder = "",
        suggestions,
        unsafelyReplaceClassName,
        value = "",
        zeroState,
        ...rest
    } = props;

    const [state, patchState] = usePatchReducer({
        isOpen: false,
        keyDown: null,
        opensUpward: false,
        noFormHookValue: value,
    });

    const textInput = React.useRef<HTMLInputElement>();

    function getValue() {
        return (formControl ? formControl.getValue() : state.noFormHookValue) || "";
    }

    function open() {
        patchState({ isOpen: true });
    }

    function setAndPropagateValue(newValue: string, event?: React.ChangeEvent<HTMLInputElement>) {
        patchState({ noFormHookValue: newValue });
        if (formControl) {
            formControl.setValue(newValue);
            formControl.markAsDirty();
        }
        if (onChange) {
            event = event || new FauxChangeEvent<any>({ value: newValue });
            onChange(event);
        }
        textInput.current.focus();
    }

    function clearAndFocus() {
        textInput.current.value = "";
        setAndPropagateValue("");
        textInput.current.focus();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setAndPropagateValue(event.target.value, event);
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (!disabled) {
            if (["Up", "ArrowUp", "Down", "ArrowDown", "Enter"].indexOf(event.key) > -1) {
                event.preventDefault();
                event.persist();
            }
            event.stopPropagation();
            patchState({ keyDown: event });
        }
    }

    function handleSelfToggle(olIsOpen) {
        patchState({ isOpen: olIsOpen });
    }

    function handlePositionFlip(opensUpward) {
        patchState({ opensUpward });
    }

    function handleDocumentClick() {
        patchState({ isOpen: false });
    }

    React.useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, []);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-autocomplete",
        state.isOpen && "sps-autocomplete--open",
        state.isOpen && "z-stratum-dropdown",
        state.opensUpward && "sps-autocomplete--opens-upward",
        disabled && "sps-form-control--disabled",
        className,
    );

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            onClick={event => event.nativeEvent.stopImmediatePropagation()}
        >
            <div className="sps-text-input" >
                <div className="sps-form-control">
                    <input type="text" ref={textInput}
                        value={getValue()}
                        className="sps-text-input__input"
                        placeholder={placeholder}
                        onFocus={open}
                        onClick={open}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                        id={useFormControlId(id, formControl)}
                        {...rest}
                    />
                </div>
                {getValue() && !disabled &&
                    <i className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                        onClick={clearAndFocus}>
                    </i>
                }
            </div>
            <SpsOptionList attachTo={textInput}
                isOpen={state.isOpen}
                options={suggestions}
                hideInlineSearch={true}
                conformWidth={true}
                keyDown={state.keyDown}
                onOptionSelected={setAndPropagateValue}
                onPositionFlip={handlePositionFlip}
                onSelfToggle={handleSelfToggle}
                offsets={[1, 0]}
                search={getValue()}
                searchDebounce={debounce}
                zeroState={zeroState}
            />
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsAutocomplete, {
    props: propsDoc,
    propTypes,
    displayName: "SpsAutocomplete"
});
