import clsx from "clsx";
import * as React from "react";
import { SpsIcon } from "@spscommerce/ds-shared";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { I18nContext } from "../i18n";

const propsDoc = {
    channelId: { type: "string", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    channelId: PropTypes.string.isRequired,
};

export type SpsSlackLinkProps = PropTypes.InferTS<typeof propTypes, HTMLAnchorElement>;

export function SpsSlackLink(props: SpsSlackLinkProps) {
    const {
        channelId,
        className,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const { t } = React.useContext(I18nContext);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-slack-link",
        className,
    );

    return (
        <a className={classes}
            target="_blank"
            href={`https://spscommerce.slack.com/archives/${channelId}`}
            {...rest}
        >
            <i className={`sps-icon sps-icon-${SpsIcon.CONVERSATION}`} aria-hidden="true"/>
            <span>{t("design-system:slackLink.label")}</span>
        </a>
    );
}

Object.assign(SpsSlackLink, {
    props: propsDoc,
    propTypes,
    displayName: "SpsSlackLink",
});
