import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    artwork: { type: "string", required: true },
    heading: { type: "string", required: true },
    subHeading: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    artwork: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
};

export type SpsZeroStateProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsZeroState(props: SpsZeroStateProps) {
    const {
        artwork,
        children,
        className,
        heading,
        subHeading,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-zero-state",
        className,
    );

    return (
        <div className="sps-card__body" data-testid={`${testId}`}>
            <div className={classes} data-testid={`${testId}__actual-zero-state`} {...rest}>
                <img
                    className="sps-zero-state__image"
                    src={artwork}
                    alt=""
                    data-testid={`${testId}__artwork`}
                />
                <h1 className="sps-zero-state__heading" data-testid={`${testId}__heading`}>
                    {heading}
                </h1>
                <h2 className="sps-zero-state__subheading" data-testid={`${testId}__subheading`}>
                    {subHeading}
                </h2>
                <div className="sps-zero-state__content" data-testid={`${testId}__content`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

Object.assign(SpsZeroState, {
    props: propsDoc,
    propTypes,
    displayName: "SpsZeroState"
});
