/** These are the possibilities for the `position` option of
 * `PositioningOptions` when using `relativeTo`. */
export enum Position {
    TOP_LEFT = "top left",
    TOP_MIDDLE = "top middle",
    TOP_RIGHT = "top right",
    RIGHT_TOP = "right top",
    RIGHT_MIDDLE = "right middle",
    RIGHT_BOTTOM = "right bottom",
    BOTTOM_RIGHT = "bottom right",
    BOTTOM_MIDDLE = "bottom middle",
    BOTTOM_LEFT = "bottom left",
    LEFT_BOTTOM = "left bottom",
    LEFT_MIDDLE = "left middle",
    LEFT_TOP = "left top"
}
