import { code } from "@spscommerce/utils";

export const SpsTagExamples = {
    basic: {
        label: "Basic Tag",
        examples: {
            basic: {
                jsx: code`
                <div className="row">
                    <div className="mr-1 mb-1">
                      <SpsTag kind="default">
                        <span>Default</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="key">
                        <span>Key</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="warning">
                        <span>Warning</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="error">
                        <span>Error</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="pending">
                        <span>Pending</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="success">
                        <span>Success</span>
                      </SpsTag>
                    </div>
                    <div className="mr-1 mb-1">
                      <SpsTag kind="info">
                        <span>Info</span>
                      </SpsTag>
                    </div>
                </div>
                `
            }
        }
    },
    withIcon: {
        label: "Tag with icon",
        examples: {
            basic: {
                jsx: code`
                <div className="row">
                  <div className="mr-1 mb-1">
                    <SpsTag kind="default" icon="info-circle">
                      <span>Default</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="key" icon="checkmark">
                      <span>Key</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="warning" icon="exclamation-triangle">
                      <span>Warning</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="error" icon="exclamation-circle">
                      <span>Error</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="pending" icon="ellipses">
                      <span>Pending</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="success" icon="checkmark">
                      <span>Success</span>
                    </SpsTag>
                  </div>
                  <div className="mr-1 mb-1">
                    <SpsTag kind="info" icon="info-circle">
                      <span>Info</span>
                    </SpsTag>
                  </div>
                </div>
                `
            }
        }
    }
};
