import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsContentRowCol } from "./SpsContentRowCol";
import { SpsContentRowExpansion } from "./SpsContentRowExpansion";
import { I18nContext } from "../i18n";

const propsDoc = {
    initiallyExpanded: "boolean",
    cardSpacing: "boolean",
    onBeforeCollapsed: "() => boolean",
    onBeforeExpanded: "() => boolean",
    onCollapsed: "() => void",
    onExpanded: "() => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    initiallyExpanded: PropTypes.bool,
    cardSpacing: PropTypes.bool,
    onBeforeCollapsed: PropTypes.fun<() => boolean>(),
    onBeforeExpanded: PropTypes.fun<() => boolean>(),
    onCollapsed: PropTypes.fun<() => void>(),
    onExpanded: PropTypes.fun<() => void>(),
};

export type SpsContentRowProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsContentRow(props: SpsContentRowProps) {
    const {
        children,
        className,
        onBeforeCollapsed,
        onBeforeExpanded,
        onCollapsed,
        onExpanded,
        initiallyExpanded = false,
        cardSpacing = false,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;
    const { t } = React.useContext(I18nContext);

    const [isExpanded, setIsExpanded] = React.useState(initiallyExpanded);

    // If there is an expansion as a child then treat this as expandable
    const expandable = React.Children.toArray(children).some(child => {
        return React.isValidElement(child) && child.type === SpsContentRowExpansion;
    });

    const handleToggle = (event) => {
        event.stopPropagation();
        if (isExpanded && (!onBeforeCollapsed || onBeforeCollapsed())) {
            setIsExpanded(!isExpanded);
            if (onCollapsed) {
                onCollapsed();
            }
        } else if (!isExpanded && (!onBeforeExpanded || onBeforeExpanded())) {
            setIsExpanded(!isExpanded);
            if (onExpanded) {
                onExpanded();
            }
        }
    };

    const classes = clsx(
        unsafelyReplaceClassName || "sps-content-row",
        isExpanded && "sps-content-row--expanded",
        cardSpacing && "sps-content-row--card-spaced",
        className,
    );

    return (
        <div className={classes} {...rest} data-testid={`${testId}__row`}>
            <div className="sps-content-row__col-group" data-testid={`${testId}__col-group`}>
                {expandable && (
                    <SpsContentRowCol lean style={{ width: 44 }}>
                        <button
                            className="sps-content-row__expand-btn"
                            onClick={(event) => handleToggle(event)}
                            data-testid={`${testId}__expand-btn`}
                            aria-label={t(`design-system:contentRow.${isExpanded ? "collapse" : "expand"}`)}
                        >
                            <i className={
                                clsx("sps-icon", {
                                    "sps-icon-plus-circle": !isExpanded
                                }, {
                                        "sps-icon-minus-circle": isExpanded
                                    }
                                )
                            }
                                data-testid={`${testId}__expand-icon`}
                            />
                        </button>
                    </SpsContentRowCol>
                )}
                {
                    React.Children.map(children, (child: any) => {
                        if (child.type !== SpsContentRowExpansion) {
                            return child;
                        }
                    })
                }
            </div>
            <React.Fragment>
                {React.Children.map(children, (child: any) => {
                    if (child.type === SpsContentRowExpansion) {
                        return child;
            }
        })}
            </React.Fragment>
        </div>
    );
}

Object.assign(SpsContentRow, {
    props: propsDoc,
    propTypes,
    displayName: "SpsContentRow"
});
