import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes, contentOf } from "../util";
import { SpsCardHeader } from "./SpsCardHeader";
import { SpsCardTabbedPane } from "./SpsCardTabbedPane";
import { TabProps, useTab } from "./useTab";

const propsDoc = {
    activeTab: "string",
    footer: "ReactNodeOrRenderFn",
    headerContent: "ReactNodeOrRenderFn",
    headerIcon: "SpsIcon",
    headerTitle: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    activeTab: PropTypes.string,
    footer: PropTypes.nodeOrRenderFn,
    headerContent: PropTypes.nodeOrRenderFn,
    headerIcon: PropTypes.enumValue<SpsIcon>(SpsIcon),
    headerTitle: PropTypes.string,
};

export type SpsCardProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsCard(props: SpsCardProps) {
    const {
        activeTab,
        children,
        className,
        footer,
        headerContent,
        headerIcon,
        headerTitle,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    // This used to be a prop but now we derive it
    const isTabbedCard = React.Children.toArray(children).some(child =>
        React.isValidElement(child) && child.type === SpsCardTabbedPane
    );
    const [currentlyActiveTab, setCurrentlyActiveTab] = useTab(children, activeTab, isTabbedCard);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-card",
        className,
    );

    return (
        <div {...rest} className={classes} data-testid={`${testId}__card`}>
            {(headerTitle || headerContent) && (
                <SpsCardHeader
                    title={headerTitle}
                    content={contentOf(headerContent)}
                    icon={headerIcon}
                    data-testid={`${testId}__header`}
                />
            )}

            {isTabbedCard === true ? (
                <React.Fragment>
                    <nav
                        className="sps-nav sps-nav--tabs"
                        role="tablist"
                        data-testid={`${testId}__tabs`}
                    >
                        {React.Children.map(children, (child, index) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child as React.ReactElement<TabProps>, {
                                    active: index === currentlyActiveTab.index,
                                    index: index,
                                    activateTab: setCurrentlyActiveTab,
                                    'data-testid': `${testId}__tab`
                                });
                            }
                        })}
                    </nav>
                    <div className="sps-card__body" data-testid={`${testId}__body--tab-active`}>
                        {currentlyActiveTab.content}
                    </div>
                </React.Fragment>
            ) : (
                <div className="sps-card__body" data-testid={`${testId}__body`}>
                    {children}
                </div>
            )}

            {footer && (
                <div className="sps-card__footer" data-testid={`${testId}__footer`}>
                    {contentOf(footer)}
                </div>
            )}
        </div>
    );
}

Object.assign(SpsCard, {
    props: propsDoc,
    propTypes,
    displayName: "SpsCard"
});
