import { TOOLTIP_HIDE_DELAY_MS_DEFAULT, TooltipKind, TooltipShowTrigger } from "@spscommerce/ds-shared";
import { Position, PositioningService } from "@spscommerce/positioning";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

export enum TooltipVisibility {
    VISIBLE = "visible",
    DEFER_VISIBLE = "defer_visible",
    HIDDEN = "hidden",
    DELAYED_HIDDEN = "delayed_hidden"
}

export function toggleTooltipState(state: TooltipVisibility) {
    return state === TooltipVisibility.VISIBLE
        ? TooltipVisibility.HIDDEN
        : TooltipVisibility.VISIBLE;
}

const openTips = new Map();

const propsDoc = {
    for: { type: "React.MutableRefObject<any> | string", required: true },
    hideDelay: "number",
    isShown: "TooltipVisibility",
    kind: "TooltipKind",
    offsets: "string",
    onDeferred: "() => void",
    position: "Position",
    showOn: "TooltipShowTrigger",
    title: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    for: PropTypes.oneOfType([
        PropTypes.ref<any>(),
        PropTypes.string
    ]).isRequired,
    hideDelay: PropTypes.number,
    isShown: PropTypes.enumValue<TooltipVisibility>(TooltipVisibility),
    kind: PropTypes.enumValue<TooltipKind>(TooltipKind),
    offsets: PropTypes.string,
    onDeferred: PropTypes.fun<() => void>(),
    position: PropTypes.enumValue<Position>(Position),
    showOn: PropTypes.enumValue<TooltipShowTrigger>(TooltipShowTrigger),
    title: PropTypes.string,
};

export type SpsTooltipProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsTooltip(props: SpsTooltipProps) {
    const {
        children,
        className,
        "for": forProp,
        "data-testid": testId,
        hideDelay = TOOLTIP_HIDE_DELAY_MS_DEFAULT,
        isShown,
        kind = TooltipKind.DEFAULT,
        offsets = "13, 0",
        onDeferred,
        position = Position.TOP_MIDDLE,
        showOn = TooltipShowTrigger.MOUSEOVER,
        title,
        unsafelyReplaceClassName,
    } = props;

    const [instanceKey] = React.useState(Symbol());
    const [shown, setShown] = React.useState(false);
    const [prevIsShown, setPrevIsShown] = React.useState(isShown);
    const [hideTimer, setHideTimer] = React.useState(null);
    const rootElement = React.useRef(null);

    React.useEffect(() => {
        const offsetNumbers = offsets.split(/, ?/).map(Number);
        const forElement = typeof forProp === "string"
            ? document.getElementById(forProp)
            : forProp.current;

        if (!forElement) {
            return;
        }

        function clearHideDelay() {
            if (hideTimer) {
                clearTimeout(hideTimer);
                setHideTimer(null);
            }
        }

        function hide() {
            if (shown) {
                clearHideDelay();
                setShown(false);
                PositioningService.release(rootElement.current);
            }
        }

        function show(defer = false) {
            if (!shown) {
                // If defer is true, the tooltip will only open if no other tooltip is already active.
                if (defer && openTips.size > 0) {
                    if (onDeferred) {
                        onDeferred();
                    }
                    return;
                }

                if (rootElement.current.textContent === "") {
                    console.warn("SpsTooltip is empty; aborting show");
                    return;
                }

                clearHideDelay();

                for (const openTipHide of openTips.values()) {
                    openTipHide();
                }

                setShown(true);
                PositioningService.position(rootElement.current, {
                    relativeTo: forElement,
                    position,
                    offsets: offsetNumbers
                });
            }
        }

        function showHandler() {
            show();
        }

        function toggle() {
            if (shown) {
                hide();
            } else {
                show();
            }
        }

        function delayedHide() {
            clearHideDelay();
            setHideTimer(window.setTimeout(hide, hideDelay));
        }


        if (shown) {
            openTips.set(instanceKey, hide);
        } else if (openTips.has(instanceKey)) {
            openTips.delete(instanceKey);
        }

        switch (showOn) {
            case TooltipShowTrigger.MOUSEOVER:
                forElement.addEventListener("mouseenter", showHandler);
                forElement.addEventListener("mouseleave", delayedHide);
                rootElement.current.addEventListener("mouseenter", clearHideDelay);
                rootElement.current.addEventListener("mouseleave", delayedHide);
                break;

            case TooltipShowTrigger.CLICK:
                forElement.addEventListener("click", toggle);
                break;

            case TooltipShowTrigger.MANUAL:
                if (isShown !== prevIsShown) {
                    switch (isShown) {
                        case TooltipVisibility.VISIBLE:
                            show();
                            break;

                        case TooltipVisibility.DEFER_VISIBLE:
                            show(true);
                            break;

                        case TooltipVisibility.HIDDEN:
                            hide();
                            break;

                        case TooltipVisibility.DELAYED_HIDDEN:
                            delayedHide();
                            break;
                    }
                }
                setPrevIsShown(isShown);
        }

        return () => {
            rootElement.current.removeEventListener("mouseenter", clearHideDelay);
            rootElement.current.removeEventListener("mouseleave", delayedHide);
            if (forElement) {
                forElement.removeEventListener("mouseenter", showHandler);
                forElement.removeEventListener("mouseleave", delayedHide);
                forElement.removeEventListener("click", toggle);
            }
        };
    }, [forProp, props.hideDelay, props.showOn, isShown, shown, hideTimer, rootElement]);

    React.useEffect(() => {
        if (rootElement.current.textContent === "" && shown) {
            if (hideTimer) {
                clearTimeout(hideTimer);
                setHideTimer(null);
            }
            setShown(false);
            if (rootElement.current) {
                PositioningService.release(rootElement.current);
            }
        }
    }, [children, shown, hideTimer]);

    React.useEffect(() => () => {
        if (openTips.has(instanceKey)) {
            openTips.delete(instanceKey);
        }
        if (rootElement.current) {
            PositioningService.release(rootElement.current);
        }
    }, []);

    const classes = clsx(
        unsafelyReplaceClassName || "sps-tooltip",
        "z-stratum-tip",
        `sps-tooltip--${kind}`,
        `sps-tooltip--pos-${position.replace(" ", "-")}`,
        shown && "sps-tooltip--shown",
        className,
    );

    return (
        <div role="tooltip" className={classes} data-testid={testId} ref={rootElement}>
            {title && <span className="sps-tooltip__title d-block">{title}</span>}
            <span className="sps-tooltip__body d-block">{children}</span>
            <span className="sps-tooltip__caret"></span>
        </div>
    );
}

Object.assign(SpsTooltip, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTooltip"
});
