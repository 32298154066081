import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {};

const propTypes = {
    ...spsGlobalPropTypes,
};

export type SpsTableBodyProps = PropTypes.InferTS<typeof propTypes, HTMLTableSectionElement>;

export function SpsTableBody(props: SpsTableBodyProps) {
    const {
        children,
        className,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-table__body",
        className,
    );

    return (
        <tbody className={classes}
            role="rowgroup"
            data-testid={`${testId}__body`}
            {...rest}
        >
            {children}
        </tbody>
    );
}

Object.assign(SpsTableBody, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTableBody"
});

export function SpsTbody(props: SpsTableBodyProps) {
    return SpsTableBody(props);
}

Object.assign(SpsTbody, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTbody"
});
