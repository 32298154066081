import * as React from "react";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import clsx from "clsx";
import { SpsButton } from "../index";
import { ButtonKind } from "@spscommerce/ds-shared";

const propsDoc = {
    handleAsync: "{ turnAsyncOn: () => void, turnAsyncOff: () => void, isAsyncOn: boolean }",
    closeModal: "() => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    handleAsync: PropTypes.shape({
        turnAsyncOn: PropTypes.func,
        turnAsyncOff: PropTypes.func,
        isAsyncOn: PropTypes.bool
    }),
    closeModal: PropTypes.func
};

export type SpsModalFooterProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsModalFooter(props: SpsModalFooterProps) {
    const {
        children,
        className,
        handleAsync,
        closeModal
    } = props;

    const footerClass = clsx("sps-modal__footer", className);
    return (
        <div className={footerClass}>
            {React.Children.count(children) ? (
                React.Children.map(children, (child, i) => {
                    if (i === React.Children.count(children) - 1) {
                        return React.cloneElement(child as React.ReactElement<any>, {
                            closeModal: closeModal,
                            handleAsync: handleAsync,
                            kind: "key"
                        });
                    } else {
                        return React.cloneElement(child as React.ReactElement<any>, {
                            closeModal: closeModal,
                            handleAsync: handleAsync
                        });
                    }
                })
            ) : (
                <SpsButton kind={ButtonKind.KEY} onClick={closeModal}>Ok</SpsButton>
            )}
        </div>
    );
}

Object.assign(SpsModalFooter, {
    props: propsDoc,
    propTypes,
    displayName: "SpsModalFooter"
});
