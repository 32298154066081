import { SortChangeHandler, SortedColumn } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsTableHeader, SpsTh } from "./SpsTableHeader";

const propsDoc = {
    currentSort: "Array<SortedColumn>",
    onSortChange: "SortChangeHandler",
};

const propTypes = {
    ...spsGlobalPropTypes,
    currentSort: PropTypes.arrayOf(PropTypes.impl<SortedColumn>()),
    onSortChange: PropTypes.fun<SortChangeHandler>()
};

export type SpsTableRowProps = PropTypes.InferTS<typeof propTypes, HTMLTableRowElement>;

export function SpsTableRow(props: SpsTableRowProps) {
    const {
        children,
        className,
        currentSort,
        onSortChange,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const newProps = {
        onSortChange: onSortChange,
        currentSort: currentSort
    };
    const classes = clsx(unsafelyReplaceClassName || "sps-table__row", className);

    return (
        <tr className={classes} role="row" data-testid={`${testId}__row`} {...rest}>
            {React.Children.map(children, (child: any) => {
                if (child && (child.type === SpsTableHeader || child.type === SpsTh)) {
                    return React.cloneElement(child, newProps);
                }
                return child;
            })}
        </tr>
    );
}

Object.assign(SpsTableRow, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTableRow"
});

export function SpsTr(props: SpsTableRowProps) {
    return SpsTableRow(props);
}

Object.assign(SpsTr, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTr"
});
