import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";
import { SpsFormArray } from "./hooks/formArray";
import { SpsFormGroup } from "./hooks/formGroup";
import { SpsControlSet } from "./hooks/SpsControlSet.interface";

export const spsFormProps = {
    formArray: "SpsFormArray<any>",
    formGroup: "SpsFormGroup",
    onSubmit: "React.FormEventHandler",
};

export const spsFormPropTypes = {
    ...spsGlobalPropTypes,
    formArray: PropTypes.impl<SpsFormArray<any>>(),
    formGroup: PropTypes.impl<SpsFormGroup>(),
    onSubmit: PropTypes.fun<React.FormEventHandler>(),
};

export type SpsFormProps =
    PropTypes.InferTS<typeof spsFormPropTypes, HTMLFormElement>;
type SpsFormForwardRefType =
    SpsFormProps & React.RefAttributes<HTMLFormElement>;
type SpsFormForwardRefExoticComponent =
    React.ForwardRefExoticComponent<SpsFormForwardRefType>;

export const SpsForm: SpsFormForwardRefExoticComponent = React.forwardRef(
    (props: SpsFormProps, ref: React.MutableRefObject<HTMLFormElement>) => {
        const {
            children,
            onSubmit,
            formGroup,
            formArray,
            ...rest
        } = props;

        const form: SpsControlSet = formGroup || formArray;

        function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
            event.preventDefault();
            if (form) {
                form.markAsDirty();
            }
            if (!form || !form.invalid && onSubmit) {
                onSubmit(event);
            }
        }

        return (
            <form ref={ref} onSubmit={handleSubmit} {...rest}>
                {children}
            </form>
        );
    }
);

Object.assign(SpsForm, {
    props: spsFormProps,
    propTypes: spsFormPropTypes,
    displayName: "SpsForm"
});
