import { SpsIcon } from "@spscommerce/ds-shared";
import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    icon: { type: "SpsIcon", required: true },
    title: { type: "string", required: true },
};

const propTypes = {
    ...spsGlobalPropTypes,
    icon: PropTypes.enumValue<SpsIcon>(SpsIcon).isRequired,
    title: PropTypes.string.isRequired,
};

export type SpsWorkflowStepProps = PropTypes.InferTS<typeof propTypes, HTMLDivElement>;

export function SpsWorkflowStep(props: SpsWorkflowStepProps) {
    const {
        children,
        className,
        icon,
        title,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const classes = clsx(
        unsafelyReplaceClassName || "sps-workflow__step",
        className,
    );

    return (
        <div className={classes} data-testid={`${testId}__step`} {...rest}>
            <i aria-hidden="true" className={`sps-icon sps-icon-${icon} sps-workflow__step-icon`}></i>
            <h2 className="sps-workflow__step-heading" data-testid={`${testId}__step-heading`}>
                {title}
            </h2>
            {children}
        </div >
    );
}

Object.assign(SpsWorkflowStep, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWorkflowStep"
});

export function SpsWfStep(props: SpsWorkflowStepProps) {
    return SpsWorkflowStep(props);
}

Object.assign(SpsWfStep, {
    props: propsDoc,
    propTypes,
    displayName: "SpsWfStep"
});
