import { code } from "@spscommerce/utils";

export const ReactSpsFileUploadExamples = {
    basic: {
        label: "Basic File Upload",
        description: code`
            <p>Simply allow the user to drag-and-drop or select either a single file or multiple files at once.</p>
        `,
        examples: {
            single: {
                description: `<p>Single file upload</p>`,
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload ref={ref}/>
                                </div>
                            </div>
                        )
                    }
                `
            },
            multiple: {
                description: `<p>Multiple file upload</p>`,
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload ref={ref} multiple/>
                                </div>
                            </div>
                        )
                    }
                `
            },
        }
    },
    restrictions: {
        label: "Restrictions",
        description: code`
            <p>You can place restrictions on what files will be accepted with an extension whitelist and/or a maximum size.</p>
        `,
        examples: {
            size: {
                description: `<p>Maximum size</p>`,
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload ref={ref} maxSize="100KB"/>
                                </div>
                            </div>
                        )
                    }
                `
            },
            type: {
                description: `<p>File type whitelist</p>`,
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload
                                        ref={ref}
                                        description="Spreadsheets"
                                        multiple
                                        acceptExtensions="XLS, xlsx, .csv"
                                    />
                                </div>
                            </div>
                        )
                    }
                `
            },
            both: {
                description: `<p>Both restrictions</p>`,
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload
                                        ref={ref}
                                        description="Spreadsheets"
                                        multiple
                                        acceptExtensions="XLS, xlsx, .csv"
                                        maxSize="25MB"
                                    />
                                </div>
                            </div>
                        )
                    }
                `
            },
        }
    },
    dismissable: {
        label: "Dismissable",
        description: code`
            <p>With this option, the file upload component can be dismissed by the user. If the user does dismiss it, you can
            show it again programmatically if you need to.</p>
        `,
        examples: {
            dismissable: {
                react: code`
                    function Component() {
                        const [showFileUpload, setShowFileUpload] = React.useState(true)
                        const ref = React.useRef()

                        function handleDismissal() {
                            setShowFileUpload(false)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("dismissal", handleDismissal)
                            return () => {
                                ref.current.removeEventListener("dismissal", handleDismissal)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload
                                        ref={ref}
                                        dismissable
                                        shown={showFileUpload}
                                    />
                                    {!showFileUpload && (
                                    <SpsButton onClick={() => setShowFileUpload(true)}>
                                        Reset
                                    </SpsButton>
                                    )}
                                </div>
                            </div>
                        )
                    }
                `
            },
        }
    },
    downloadButton: {
        label: "Download Button",
        description: code`
            <p>You can optionally provide a button to download a file. Typically this will be a template for
            whatever the user is expected to upload. They can then fill out the template, save it, and upload it.</p>
        `,
        examples: {
            downloadButton: {
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleDownload() {
                            console.log("Download triggered.")
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("download", handleDownload)
                            return () => {
                                ref.current.removeEventListener("download", handleDownload)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-12">
                                    <sps-file-upload
                                        ref={ref}
                                        description="Spreadsheet"
                                        acceptExtensions="xlsx"
                                        downloadLabel="Download Excel Template"
                                    />
                                </div>
                            </div>
                        )
                    }
                `
            },
        }
    },
    mini: {
        label: "Mini File Upload",
        description: code`
            <p>When the file upload component is to be placed in a small container,
            it should be marked as a mini file upload so that everything fits.</p>
        `,
        examples: {
            single: {
                react: code`
                    function Component() {
                        const ref = React.useRef()

                        function handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }

                        React.useEffect(() => {
                            ref.current.addEventListener("selection", handleChange)
                            return () => {
                                ref.current.removeEventListener("selection", handleChange)
                            }
                        })

                        return (
                            <div className="sps-row">
                                <div className="col-4">
                                    <sps-file-upload ref={ref} mini/>
                                </div>
                            </div>
                        )
                    }
                `
            },
        }
    },
};

export const NgSpsFileUploadExamples = {
    basic: {
        label: "Basic File Upload",
        description: code`
            <p>Simply allow the user to drag-and-drop or select either a single file or multiple files at once.</p>
        `,
        examples: {
            single: {
                description: `<p>Single file upload</p>`,
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload (selection)="handleChange($event)"></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
            multiple: {
                description: `<p>Multiple file upload</p>`,
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload multiple (selection)="handleChange($event)"></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
        }
    },
    restrictions: {
        label: "Restrictions",
        description: code`
            <p>You can place restrictions on what files will be accepted with an extension whitelist and/or a maximum size.</p>
        `,
        examples: {
            size: {
                description: `<p>Maximum size</p>`,
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload maxSize="100KB" (selection)="handleChange($event)"></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
            type: {
                description: `<p>File type whitelist</p>`,
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload multiple
                                description="Spreadsheets"
                                (selection)="handleChange($event)"
                                acceptExtensions="XLS, xlsx, .csv"
                            ></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
            both: {
                description: `<p>Both restrictions</p>`,
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload multiple
                                description="Spreadsheets"
                                (selection)="handleChange($event)"
                                acceptExtensions="XLS, xlsx, .csv"
                                maxSize="25MB"
                            ></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
        }
    },
    dismissable: {
        label: "Dismissable",
        description: code`
            <p>With this option, the file upload component can be dismissed by the user. If the user does dismiss it, you can
            show it again programmatically if you need to.</p>
        `,
        examples: {
            dismissable: {
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload dismissable
                                [attr.shown]="showFileUpload"
                                (dismissal)="handleDismissal()"
                            ></sps-file-upload>
                            <sps-button *ngIf="!showFileUpload"
                                (click)="showFileUpload = true"
                            >Reset</sps-button>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        showFileUpload = true;

                        handleDismissal() {
                            this.showFileUpload = false;
                        }
                    }
                `
            },
        }
    },
    downloadButton: {
        label: "Download Button",
        description: code`
            <p>You can optionally provide a button to download a file. Typically this will be a template for
            whatever the user is expected to upload. They can then fill out the template, save it, and upload it.</p>
        `,
        examples: {
            downloadButton: {
                template: code`
                    <div class="sps-row">
                        <div class="col-12">
                            <sps-file-upload description="Spreadsheet"
                                acceptExtensions="xlsx"
                                downloadLabel="Download Excel Template"
                                (download)="handleDownload()"
                            ></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleDownload() {
                            console.log("Download triggered.")
                        }
                    }
                `
            },
        }
    },
    mini: {
        label: "Mini File Upload",
        description: code`
            <p>When the file upload component is to be placed in a small container,
            it should be marked as a mini file upload so that everything fits.</p>
        `,
        examples: {
            single: {
                template: code`
                    <div class="sps-row">
                        <div class="col-4">
                            <sps-file-upload mini (selection)="handleChange($event)"></sps-file-upload>
                        </div>
                    </div>
                `,
                class: code`
                    class DemoComponent {
                        handleChange(event: CustomEvent<Array<File>>) {
                            console.log(event.detail)
                        }
                    }
                `
            },
        }
    },
};
