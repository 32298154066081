/** @jsx h */
import { SpsIcon } from "@spscommerce/ds-shared";

import { ClassBindings, Component, Prop, QuerySelector, StyleBindings, Watch } from "../../decorators/index";
import { h } from "../../utils/pragma";

export enum SpsPhotoDisplayMode {
    FIT = "fit",
    FILL = "fill",
}

const CPT_NAME = "sps-photo";

@Component({ tag: CPT_NAME })
export class SpsPhotoComponent extends HTMLElement {
    static readonly displayName = CPT_NAME;
    static readonly props = {
        src: "string",
        mode: "SpsPhotoDisplayMode",
        placeholderIcon: "SpsIcon",
    };

    /** Photo source path, exactly the same as for an <img> tag. */
    @Prop() src: string;
    /** Display mode - fill or fit. */
    @Prop() mode = SpsPhotoDisplayMode.FILL;
    /** If src is empty, the placeholder icon will be displayed instead. */
    @Prop() placeholderIcon: SpsIcon;

    private _image: HTMLImageElement;
    /** The native HTML <img> element within the photo component. */
    @QuerySelector("img", { refresh: true }) get image() {
        return this._image;
    }
    set image(newValue: HTMLImageElement) {
        this._image = newValue;
        if (newValue) {
            if (this.waitForImgHeightInterval) {
                window.clearInterval(this.waitForImgHeightInterval);
            }
            this.waitForImgHeightInterval = window.setInterval(() => {
                const imgRect = newValue.getBoundingClientRect();
                if (imgRect.height) {
                    window.clearInterval(this.waitForImgHeightInterval);
                    delete this.waitForImgHeightInterval;
                    let imageIsLandscape = true;
                    imageIsLandscape = imgRect.width / imgRect.height > 1;
                    this.fitWidth = imageIsLandscape === (this.mode === SpsPhotoDisplayMode.FIT);
                }
            }, 1000 / 60);
        }
    }

    @Watch() private width; // rem
    @Watch() private fitWidth;

    get [ClassBindings]() {
        return [
            CPT_NAME,
            this.fitWidth
                ? `${CPT_NAME}--fit-width`
                : `${CPT_NAME}--fit-height`,
            this.placeholderIcon && `${CPT_NAME}--placeholder`,
        ];
    }

    get [StyleBindings]() {
        return {
            fontSize: `${this.width * 0.05}rem`,
            width: `${this.width}rem`,
            height: `${0.75 * this.width}rem`,
        };
    }

    private waitForWidthInterval: number;
    private waitForImgHeightInterval: number;

    private setWidth(hostWidth) {
        if (hostWidth && !this.width) {
            const baseFontSize = document.body.parentElement.style.fontSize || "16px";
            const remPx = +(baseFontSize.substr(0, baseFontSize.length - 2));
            this.width = hostWidth / remPx;
        }
    }

    connectedCallback() {
        this.waitForWidthInterval = window.setInterval(() => {
            const boundingClientRect = this.getBoundingClientRect();
            if (boundingClientRect.width) {
                this.setWidth(boundingClientRect.width);
                window.clearInterval(this.waitForWidthInterval);
                delete this.waitForWidthInterval;
            }
        }, 1000 / 60);
    }

    disconnectedCallback() {
        if (this.waitForWidthInterval) {
            window.clearInterval(this.waitForWidthInterval);
        }
        if (this.waitForImgHeightInterval) {
            window.clearInterval(this.waitForImgHeightInterval);
        }
    }

    render() {
        return this.src
            ? this.width
                ? <img src={this.src}/>
                : <span></span>
            : <i className={`sps-icon sps-icon-${this.placeholderIcon}`}/>;
    }
}
