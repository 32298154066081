import { code } from "@spscommerce/utils";

export const SpsTextareaExamples = {
    basic: {
        label: "Basic",
        description: "info about basic text areas",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        function numLinesValidator(n: number) {
                            return function(value) {
                                const lines = typeof value === "string" ? value.split("\\n").length : 0;
                                if (lines !== n) {
                                    return {
                                        numLines: {
                                            actualLines: lines,
                                            requiredLines: n
                                        }
                                    };
                                }
                                return null;
                            };
                        }
                        const form = useForm(formGroup({
                            haiku: formControl("", {validators: [numLinesValidator(3)]})
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("haiku")}
                                    help="A haiku is a three-line poem of 5, 7, and 5 syllables respectively."
                                    errors={() =>
                                        form.get("haiku").hasError("numLines") && (
                                            <>
                                            You need {form.get("haiku").errors['numLines'].requiredLines} lines,
                                            but you've only written {form.get("haiku").errors["numLines"].actualLines} lines!
                                        </>
                                    )
                                }>
                                    Haiku
                                </SpsLabel>
                                <SpsTextarea formControl={form.get("haiku")} placeholder="Write a haiku" rows={3}></SpsTextarea>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    description: {
        label: "Description",
        description: "info about text areas with descriptions",
        examples: {
            description: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            textareaWithDescription: formControl()
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("textareaWithDescription")}
                                    description="This is a brief description of the field to provide additional context."
                                >
                                    Label
                                </SpsLabel>
                                <SpsTextarea formControl={form.get("textareaWithDescription")}/>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        description: "info about disabled text areas",
        examples: {
            disabled: {
                react: code`
                    function DemoComponent() {
                        const form = useForm(formGroup({
                            notes: formControl("This is the value of the textarea but you can't edit it right now")
                        }));

                        return (
                            <SpsForm formGroup={form}>
                                <SpsLabel for={form.get("notes")}>Notes</SpsLabel>
                                <SpsTextarea disabled formControl={form.get("notes")} rows={5}></SpsTextarea>
                            </SpsForm>
                        )
                    }
                `
            }
        }
    }
};
