/** @jsx h */
import { ClassBindings, Component, Content, QuerySelectorAll, DSComponent } from "../../decorators/index";
import { h } from "../../utils/pragma";

const CPT_NAME = "sps-insights";

/** Container for Insight Cards. */
@Component({ tag: CPT_NAME })
export class SpsInsightsComponent extends HTMLElement {
    static readonly displayName = CPT_NAME;
    static readonly props = {};

    @Content() private content = [];
    @QuerySelectorAll(".sps-insight-card__detail") private details: NodeList;

    private showAdditionalInsights = false;

    get [ClassBindings]() {
        return [
            CPT_NAME,
            this.showAdditionalInsights && `${CPT_NAME}--show-additional`,
            this.details && Array.from(this.details).every(d => !d.textContent)
                ? `${CPT_NAME}--no-details`
                : null,
            this.content.length === 6 ? `${CPT_NAME}--break-4` : null,
            this.content.length === 7 ? `${CPT_NAME}--break-5` : null,
            this.content.length === 8 ? `${CPT_NAME}--break-5` : null,
        ];
    }

    constructor() {
        super();
        this.toggleAdditionalInsights = this.toggleAdditionalInsights.bind(this);
    }

    /** Only the first ten Insight Cards are visible by default; the rest are behind
     * the "Show Additional Insights"/"Hide Additional Insights" button. This method
     * will toggle the additional insights.
     */
    toggleAdditionalInsights() {
        this.showAdditionalInsights = !this.showAdditionalInsights;
        (this as any as DSComponent).update();
    }

    render() {
        return (
            <frag>
                {this.content}
                {this.content.length > 10 &&
                    <div className="sps-btn sps-btn--link" onClick={this.toggleAdditionalInsights}>
                        <button type="button">
                            {this.showAdditionalInsights ? 'Hide ' : 'Show '}
                            Additional Insights
                        </button>
                    </div>
                }
            </frag>
        );
    }
}
