import * as React from "react";

export type ActiveCardState = {
    index;
    content;
};
export type TabProps = {
    index;
    active;
    activateTab;
    "data-testid"?: string;
};

export type TabHook = [ActiveCardState, any];

/**
 * A react hook for tabs
 * @param children
 * @param activeTab
 * @param isTabbedCard
 */
export function useTab(children, activeTab, isTabbedCard): TabHook {
    // STATE: Currently active tab state
    const [currentlyActiveTab, setCurrentlyActiveTab] = React.useState<ActiveCardState>({
        index: 0,
        content: null
    });

    // CALLBACK: Memoized callback that sets the active tab
    const activateTab = React.useCallback(function activateTabCallback(event, index, content) {
        setCurrentlyActiveTab({ index, content });
    }, []);

    // EFFECT: Set up the initial tab state
    React.useEffect(
        () => {
            /**
             * Set the initially displayed tab content
             * @param childrenArr an array of children representing tabs
             */
            function setInitialTabContent(initialChildrenArray) {
                for (const child of initialChildrenArray) {
                    const firstTab = child.props;
                    setCurrentlyActiveTab({
                        ...currentlyActiveTab,
                        content: firstTab.children
                    });
                    break;
                }
            }

            /**
             * Set the currently active tab by id
             * @param childrenArr an array of children representing tabs
             * @param activeTabId the id to set as active
             */
            function setActiveTabById(availableChildrenArray, activeTabId) {
                for (let index = 0; index <= availableChildrenArray.length; index++) {
                    if (
                        availableChildrenArray[index].props &&
                        availableChildrenArray[index].props.id !== undefined &&
                        availableChildrenArray[index].props.id === activeTabId
                    ) {
                        setCurrentlyActiveTab({
                            content: availableChildrenArray[index].props.children,
                            index
                        });
                        break;
                    }
                }
            }

            const childrenAsArray = React.Children.toArray(children);
            if (isTabbedCard && activeTab) {
                setActiveTabById(childrenAsArray, activeTab);
            } else if (isTabbedCard) {
                setInitialTabContent(childrenAsArray);
            }
        },
        [activeTab, children]
    );

    return [currentlyActiveTab, activateTab];
}
