import nanoid from "nanoid";

import { SpsAbstractControl, SpsAbstractControlConfig } from "./SpsAbstractControl.interface";
import { SpsValidators } from "../SpsValidators";

export interface SpsFormControl<T> extends SpsAbstractControl<T> {
    id: string;
    value: T;
    focused: boolean;
    pristine: boolean;
    isRequired: () => boolean;
}

export function formControl<T>(initValue?: T, config: SpsAbstractControlConfig<T> = {}): SpsFormControl<T> {
    return <SpsFormControl<T>>{
        validators: [],
        id: nanoid(),
        ...config,
        _type: "Control",
        initValue,
        setInitValue(newValue: T): SpsFormControl<T> {
            this.value = newValue;
            this.initValue = newValue;
            return this;
        },
        value: initValue,
        getValue() {
            return this.value;
        },
        setValue(newValue: T): SpsFormControl<T> {
            this.value = newValue;
            this.update();
            return this;
        },
        errors: null,
        invalid: false,
        isValid() {
            return !this.invalid;
        },
        hasError(errorKey: string) {
            return this.errors && this.errors.hasOwnProperty(errorKey);
        },
        focused: false,
        isFocused() {
            return this.focused;
        },
        pristine: true,
        isPristine() {
            return this.pristine;
        },
        isRequired() {
            return this.validators && this.validators.indexOf(SpsValidators.required) > -1;
        },
        update(): SpsFormControl<T> {
            return this;
        },
        focus(): SpsFormControl<T> {
            this.focused = true;
            this.update();
            if (this.onFocus) {
                this.onFocus();
            }
            return this;
        },
        blur(): SpsFormControl<T> {
            this.focused = false;
            this.update();
            if (this.onBlur) {
                this.onBlur();
            }
            return this;
        },
        markAsPristine(): SpsFormControl<T> {
            this.pristine = true;
            this.update();
            return this;
        },
        markAsDirty(): SpsFormControl<T> {
            this.pristine = false;
            this.update();
            return this;
        },
        reset(value = this.initValue): SpsFormControl<T> {
            this.value = value;
            this.pristine = true;
            this.update();
            return this;
        },
    };
}
