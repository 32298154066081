import clsx from "clsx";
import * as React from "react";

import * as PropTypes from "../../prop-types";
import { FauxChangeEvent, spsGlobalPropTypes } from "../../util";
import { useFormControlId } from "../hooks/useFormControlId";
import { SpsFormControl } from "../hooks/formControl";
import { SpsFormComponentWrapper } from "../SpsFormComponentWrapper";

const propsDoc = {
    disabled: "boolean",
    formControl: "SpsFormControl<string>",
    name: "string",
    onChange: "ChangeEventHandler",
    placeholder: "string",
    rows: "number",
    value: "string",
};

const propTypes = {
    ...spsGlobalPropTypes,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<string>>(),
    name: PropTypes.string,
    onChange: PropTypes.fun<React.ChangeEventHandler>(),
    placeholder: PropTypes.string,
    ref: PropTypes.ref<HTMLDivElement>(),
    rows: PropTypes.number,
    value: PropTypes.string,
};

export type SpsTextareaProps = PropTypes.InferTS<typeof propTypes, HTMLTextAreaElement>;

export function SpsTextarea(props: SpsTextareaProps) {
    const {
        className,
        disabled = false,
        formControl,
        id,
        name,
        onChange,
        placeholder = "",
        ref,
        rows = 2,
        "data-testid": testId,
        unsafelyReplaceClassName,
        value = "",
        ...rest
    } = props;

    const textarea = React.useRef<HTMLTextAreaElement>();
    const [noFormHookValue, setNoFormHookValue] = React.useState(value);

    React.useEffect(() => {
        setNoFormHookValue(value);
    }, [value]);

    function setAndPropagateValue(newValue: string, event?: React.ChangeEvent<HTMLTextAreaElement>) {
        setNoFormHookValue(newValue);
        if (formControl) {
          formControl.setValue(newValue);
          formControl.markAsDirty();
      }
        if (onChange) {
            event = event || new FauxChangeEvent(textarea.current);
            onChange(event);
        }
    }

    function clearAndFocus() {
        textarea.current.value = "";
        setAndPropagateValue("");
        textarea.current.focus();
    }

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setAndPropagateValue(event.target.value, event);
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-textarea",
        className,
    );

    return (
        <SpsFormComponentWrapper id={id}
            className={classes}
            formControl={formControl}
            inputRef={textarea}
            ref={ref}
            data-testid={testId}
        >
            <div className="position-relative">
                <textarea
                    ref={textarea}
                    rows={rows}
                    data-testid={`${testId}__input`}
                    className="sps-form-control input--clearable"
                    id={useFormControlId(id, formControl)}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    aria-label={placeholder}
                    value={(formControl ? formControl.getValue() : noFormHookValue) || ""}
                    disabled={disabled}
                    {...rest}
                ></textarea>
                {(formControl ? formControl.value : noFormHookValue) && !disabled && <i
                    className="sps-icon sps-icon-x-circle sps-form-control__clear-btn"
                    onClick={clearAndFocus}
                ></i>}
            </div>
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsTextarea, {
    props: propsDoc,
    propTypes,
    displayName: "SpsTextarea"
});
