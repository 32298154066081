export enum MIMEType {
    "7Z" = "application/x-7z-compressed",
    AAC = "audio/aac",
    ABW = "application/x-abiword",
    ARC = "application/octet-stream",
    AVI = "video/x-msvideo",
    AZW = "application/vnd.amazon.ebook",
    BIN = "application/octet-stream",
    BMP = "image/bmp",
    BZ = "application/x-bzip",
    BZ2 = "application/x-bzip2",
    CSH = "application/x-csh",
    CSS = "text/css",
    CSV = "text/csv,application/vnd.ms-excel",
    DOC = "application/msword",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    EOT = "application/vnd.ms-fontobject",
    EPUB = "application/epub+zip",
    ES = "application/ecmascript",
    FLV = "video/x-flv",
    GIF = "image/gif",
    GZ = "application/x-gzip",
    HTM = "text/html",
    HTML = "text/html",
    ICO = "image/x-icon",
    ICS = "text/calendar",
    JAR = "application/java-archive",
    JPEG = "image/jpeg",
    JPG = "image/jpeg",
    JS = "application/javascript",
    JSON = "application/json",
    M3U = "audio/x-mpequrl",
    MID = "audio/midi",
    MIDI = "audio/midi",
    MOV = "video/quicktime",
    MP3 = "audio/mpeg3",
    MP4 = "video/mp4",
    MPEG = "video/mpeg",
    MPKG = "application/vnd.apple.installer+xml",
    ODP = "application/vnd.oasis.opendocument.presentation",
    ODS = "application/vnd.oasis.opendocument.spreadsheet",
    ODT = "application/vnd.oasis.opendocument.text",
    OGA = "audio/ogg",
    OGV = "video/ogg",
    OGX = "application/ogg",
    OTF = "font/otf",
    PNG = "image/png",
    PDF = "application/pdf",
    PPT = "application/vnd.ms-powerpoint",
    PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    RAR = "application/x-rar-compressed",
    RTF = "application/rtf",
    SH = "application/x-sh",
    SVG = "image/svg+xml",
    SWF = "application/x-shockwave-flash",
    TAR = "application/x-tar",
    TIF = "image/tiff",
    TIFF = "image/tiff",
    TS = "application/typescript",
    TTF = "font/ttf",
    TXT = "text/plain",
    VSD = "application/vnd.visio",
    WAV = "audio/wav",
    WEBA = "audio/webm",
    WEBM = "video/webm",
    WEBP = "image/webp",
    WOFF = "font/woff",
    WOFF2 = "font/woff2",
    XHTML = "application/xhtml+xml",
    XLS = "application/vnd.ms-excel",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    XML = "application/xml",
    XUL = "application/vnd.mozilla.xul+xml",
    ZIP = "application/zip"
}
