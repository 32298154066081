import clsx from "clsx";
import * as React from "react";

import { SpsFormControl } from "../form/hooks/formControl";
import { useFormControlId } from "../form/hooks/useFormControlId";
import { SpsFormComponentWrapper } from "../form/SpsFormComponentWrapper";
import * as PropTypes from "../prop-types";
import { spsGlobalPropTypes } from "../util";

const propsDoc = {
    active: "boolean",
    activeDescription: "string",
    activeLabel: "string",
    disabled: "boolean",
    formControl: "SpsFormControl<boolean>",
    inactiveDescription: "string",
    inactiveLabel: "string",
    large: "boolean",
    onChange: "(isActive: boolean) => void",
};

const propTypes = {
    ...spsGlobalPropTypes,
    active: PropTypes.bool,
    activeDescription: PropTypes.string,
    activeLabel: PropTypes.string,
    disabled: PropTypes.bool,
    formControl: PropTypes.impl<SpsFormControl<boolean>>(),
    id: PropTypes.string,
    inactiveDescription: PropTypes.string,
    inactiveLabel: PropTypes.string,
    large: PropTypes.bool,
    onChange: PropTypes.fun<(isActive: boolean) => void>(),
    ref: PropTypes.ref<HTMLDivElement>(),
};

export type SpsToggleProps = PropTypes.InferTS<typeof propTypes, HTMLInputElement>;

export function SpsToggle(props: SpsToggleProps) {
    const {
        active: activeProp,
        activeDescription,
        activeLabel,
        className,
        disabled,
        formControl,
        id,
        inactiveDescription,
        inactiveLabel,
        large,
        onChange,
        ref,
        "data-testid": testId,
        unsafelyReplaceClassName,
        ...rest
    } = props;

    const [active, setActive] = React.useState(activeProp);
    const [statusLabel, setStatusLabel] = React.useState("");
    const [description, setDescription] = React.useState("");

    const inputId = useFormControlId(id, formControl);
    const statusLabelID = `${inputId}_status-label`;
    const descriptionID = `${inputId}_description`;

    const inputElement = React.useRef<HTMLInputElement>();

    React.useEffect(() => setActive(activeProp), [activeProp]);

    React.useEffect(() => {
        const isActive = formControl ? formControl.value : active;
        setStatusLabel(isActive ? activeLabel : inactiveLabel);
        setDescription(isActive ? activeDescription : inactiveDescription);
    }, [active, formControl]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setActive(event.target.checked);
        if (formControl) {
            formControl.setValue(event.target.checked);
            formControl.markAsDirty();
        }
        if (onChange) {
            onChange(event.target.checked);
        }
        inputElement.current.focus();
    }

    const classes = clsx(
        unsafelyReplaceClassName || "sps-toggle",
        active && "sps-toggle--active",
        large && "sps-toggle--large",
        disabled && "sps-toggle--disabled",
        className
    );

    return (
        <SpsFormComponentWrapper
            id={id}
            formControl={formControl}
            inputRef={inputElement}
            ref={ref}
            data-testid={`${testId}`}
        >
            <div className={classes}>
                <input
                    type="checkbox"
                    ref={inputElement}
                    className="sps-toggle__input"
                    id={inputId}
                    checked={(formControl ? formControl.value : active) || false}
                    disabled={disabled}
                    onChange={handleChange}
                    aria-labelledby={statusLabelID}
                    aria-describedby={descriptionID}
                    {...rest}
                />
                <span className="sps-toggle__slider"></span>
            </div>
            {statusLabel &&
                <label className="sps-toggle__status-label"
                    htmlFor={inputId}
                    id={statusLabelID}
                >{statusLabel}</label>
            }
            {description &&
                <span className="sps-toggle__description"
                    id={descriptionID}
                >{description}</span>
            }
        </SpsFormComponentWrapper>
    );
}

Object.assign(SpsToggle, {
    props: propsDoc,
    propTypes,
    displayName: "SpsToggle"
});
