import { code } from "@spscommerce/utils";

export const SpsDateRangePickerExamples = {
    basic: {
        label: "Basic",
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-3">
                            <SpsDateRangePicker id="dateRangeBasic"/>
                        </div>
                    </div>
                `
            }
        }
    },
    minMax: {
        label: "Minimum & Maximum Dates",
        description: code`
            <p>With these props you can constrain the range of dates that
            are selectable.</p>
        `,
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-3">
                            <SpsDateRangePicker minDate="01/25/2019" maxDate="07/25/2019"/>
                        </div>
                    </div>
                `
            }
        }
    },
    disabled: {
        label: "Disabled",
        examples: {
            basic: {
                jsx: code`
                    <div class="row">
                        <div class="col-3">
                            <SpsDateRangePicker  id="dateRangeDisabled" disabled/>
                        </div>
                    </div>
                `
            }
        }
    },
    defaultPresets: {
        label: "Default Preset",
        description: code`
            <p>You can default a preset to be selected with the
            <code>preset</code> prop.</p>
        `,
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        return (
                            <div class="row">
                                <div class="col-3">
                                    <SpsDateRangePicker preset={{ days: 30 }} id="dateRangePreset"/>
                                </div>
                            </div>
                        );
                    }
                `
            }
        }
    },
    customPresets: {
        label: "Custom Presets",
        examples: {
            basic: {
                react: code`
                    function DemoComponent() {
                        const presets = [
                            { days: 1 },
                            { days: 15 },
                            { months: 10 }
                        ].map(moment.duration);

                        return (
                            <div class="row">
                                <div class="col-3">
                                    <SpsDateRangePicker availablePresets={presets}/>
                                </div>
                            </div>
                        );
                    }
                `
            }
        }
    }
};
