import { Metadata } from "../utils/metadata";

/** Options for the query selector decorators. If `refresh` is set to true,
 * the property will be refreshed on every render.
 */
export interface QuerySelectorOptions {
    refresh?: boolean;
}

function querySelectorDecorator(selector: string, options: QuerySelectorOptions, all?: boolean): PropertyDecorator {
    return (target, key: PropertyKey) => {
        const childQueries = Metadata.get(target.constructor, "childQueries", []);
        childQueries.push({ key, selector, all, refresh: options.refresh });
        Metadata.add(target.constructor, { childQueries });
    };
}

/** Defines a component class property as containing the results of a `querySelector(selector)` call on
 * the component host element.
 */
export function QuerySelector(selector: string, options: QuerySelectorOptions = {}): PropertyDecorator {
    return querySelectorDecorator(selector, options);
}

/** Defines a component class property as containing the results of a `querySelectorAll(selector)` call on
 * the component host element.
 */
export function QuerySelectorAll(selector: string, options: QuerySelectorOptions = {}): PropertyDecorator {
    return querySelectorDecorator(selector, options, true);
}
