import { lockedToAnimationFrames, onNextTick } from "@spscommerce/utils";
import { EventEmitter } from "eventemitter3";
import "intersection-observer";

import { PositioningOptions, DEFAULT_POSITIONING_OPTIONS } from "./positioning-options.interface";
import { PositionAnchor } from "./position-anchor.enum";

const NAVBAR_HEIGHT = 60;

/** This class follows a singleton pattern where you can create an instance by calling
 * `new PositioningService()` if you wish, but all instance methods pass through to
 * static methods, so there's really only one set of elements being positioned that's
 * shared between all instances and static usages of PositioningService.
 */
export class PositioningService {
    private static elements: Map<HTMLElement, PositioningOptions> = new Map();
    private static events = new EventEmitter();
    private static viewportObserver = new IntersectionObserver(PositioningService.onViewportIntersection, {
        rootMargin: `-${NAVBAR_HEIGHT}px 0px 0px`, // does not work in an iframe :( kill the iframe :(((
        threshold: 1
    });

    static on(eventName, handler) {
        PositioningService.events.on(eventName, handler);
    }

    static off(eventName, handler) {
        PositioningService.events.off(eventName, handler);
    }

    static once(eventName, handler) {
        PositioningService.events.once(eventName, handler);
    }

    private static onViewportIntersection(entries: IntersectionObserverEntry[]) {
        for (const entry of entries) {
            // This is how you make a copy of an IntersectionObserverEntry that
            // you can modify; it's read-only and incompatible with simply using
            // destructuring or Object.assign
            const entryCopy: any = Object.keys(IntersectionObserverEntry.prototype)
                                    .reduce((copy, key) => {
                                        copy[key] = entry[key];
                                        return copy;
                                    }, {});
            entryCopy.rootBounds = entryCopy.rootBounds || PositioningService.getRootBounds();
            PositioningService.events.emit("viewportIntersection", entryCopy);
        }
    }

    private static onElementIntersection(entries: IntersectionObserverEntry[]) {
        for (const entry of entries) {
            PositioningService.events.emit("elementIntersection", entry);
        }
    }

    private static getRootBounds() {
        const rootWidth = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        );
        const rootHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
        );

        return {
            x: 0,
            y: NAVBAR_HEIGHT,
            left: 0,
            top: NAVBAR_HEIGHT,
            right: rootWidth,
            bottom: rootHeight,
            height: rootHeight - NAVBAR_HEIGHT,
            width: rootWidth,
        };
    }

    private static clearStyles(element: HTMLElement): void {
        Object.assign(element.style, {
            position: null,
            width: null,
            top: null,
            left: null,
            right: null,
            bottom: null,
            zIndex: null,
            visibility: null,
        });
    }

    private static fixElementPosition(element: HTMLElement) {
        if (!this.elements.has(element)) {
            return;
        }

        const options = this.elements.get(element);
        const rootBounds = this.getRootBounds();
        const positionedElementBounds = element.getBoundingClientRect();
        const width = positionedElementBounds.width;
        Object.assign(element.style, {
            width: `${width}px`,
            position: "fixed",
            zIndex: options.zIndex,
        });

        let top: number, bottom: number, left: number, right: number;

        const relativeTargetBounds = options.relativeTo.getBoundingClientRect();
        const positionRelativeToTarget = options.position.split(" ");
        const offsetA = options.offsets[0] || 0;
        const offsetB = options.offsets[1] || 0;

        top = 0;
        left = 0;

        switch (positionRelativeToTarget[0]) {
            case "top":
                top = relativeTargetBounds.top - positionedElementBounds.height - offsetA;
                break;
            case "left":
                left = relativeTargetBounds.left - width - offsetA;
                break;
            case "right":
                left = relativeTargetBounds.right + offsetA;
                break;
            case "bottom":
                top = relativeTargetBounds.bottom + offsetA;
        }
        switch (positionRelativeToTarget[1]) {
            case "left":
                left = relativeTargetBounds.left - offsetB;
                break;
            case "top":
                top = relativeTargetBounds.top - offsetB;
                break;
            case "middle":
                if (
                    positionRelativeToTarget[0] === "top" ||
                    positionRelativeToTarget[0] === "bottom"
                ) {
                    left = relativeTargetBounds.left
                            + relativeTargetBounds.width / 2
                            - width / 2
                            + offsetB;
                } else if (
                    positionRelativeToTarget[0] === "left" ||
                    positionRelativeToTarget[0] === "right"
                ) {
                    top = relativeTargetBounds.top
                        + relativeTargetBounds.height / 2
                        - positionedElementBounds.height / 2
                        + offsetB;
                }
                break;
            case "bottom":
                top = relativeTargetBounds.bottom - positionedElementBounds.height + offsetB;
                break;
            case "right":
                left = relativeTargetBounds.right - width + offsetB;
        }

        bottom = top + positionedElementBounds.height;
        right = left + positionedElementBounds.width;

        const topPx = `${Math.round(top)}px`;
        const bottomPx = `${Math.round(rootBounds.bottom - bottom)}px`;
        const leftPx = `${Math.round(left)}px`;
        const rightPx = `${Math.round(rootBounds.right - right)}px`;

        switch (options.anchor) {
            case PositionAnchor.TOP_LEFT:
                Object.assign(element.style, {
                    top: topPx,
                    bottom: "auto",
                    left: leftPx,
                    right: "auto"
                });
                break;

            case PositionAnchor.TOP_RIGHT:
                Object.assign(element.style, {
                    top: topPx,
                    bottom: "auto",
                    left: "auto",
                    right: rightPx
                });
                break;

            case PositionAnchor.BOTTOM_LEFT:
                    Object.assign(element.style, {
                        top: "auto",
                        bottom: bottomPx,
                        left: leftPx,
                        right: "auto"
                    });
                    break;

            case PositionAnchor.BOTTOM_RIGHT:
                    Object.assign(element.style, {
                        top: "auto",
                        bottom: bottomPx,
                        left: "auto",
                        right: rightPx
                    });
                    break;
        }
    }

    @lockedToAnimationFrames
    private static update(event?: Event): void {
        for (const element of PositioningService.elements.keys()) {
            PositioningService.fixElementPosition(element);
        }
    }

    /** Returns `true` if the element is currently being positioned by the service
     * and `false` otherwise. */
    static isPositioned(element: HTMLElement): boolean {
        return PositioningService.elements.has(element);
    }

    /** Returns the options currently beinng used for positioning the element,
     * if it is indeed being positioned by the service. */
    static getPositioningOptions(element: HTMLElement): PositioningOptions {
        if (PositioningService.elements.has(element)) {
            return PositioningService.elements.get(element);
        }
    }

    /** PositioningService will start controlling the position of the given element. */
    static position(element: HTMLElement, options: PositioningOptions = {}): void {
        element.style.visibility = "hidden";

        if (PositioningService.elements.size === 0) {
            window.addEventListener("resize", PositioningService.update);
            window.addEventListener("scroll", PositioningService.update);
        }

        options = {
            ...DEFAULT_POSITIONING_OPTIONS,
            ...options,
            __observer: new IntersectionObserver(PositioningService.onElementIntersection),
        };

        if (!options.relativeTo) {
            throw new Error("You must provide an element for the relativeTo option to position an element.");
        }

        for (const positionedElement of PositioningService.elements.keys()) {
            options.__observer.observe(positionedElement);
        }
        PositioningService.elements.set(element, options);
        PositioningService.viewportObserver.observe(element);
        onNextTick(() => {
            PositioningService.fixElementPosition(element);
            element.style.visibility = null;
        });
    }

    /** PositioningService will stop controlling the position of the given element. */
    static release(element: HTMLElement): void {
        if (PositioningService.elements.has(element)) {
            PositioningService.clearStyles(element);
            const options = PositioningService.elements.get(element);
            options.__observer.disconnect();
            PositioningService.elements.delete(element);

            if (PositioningService.elements.size === 0) {
                window.removeEventListener("resize", PositioningService.update);
                window.removeEventListener("scroll", PositioningService.update);
            }

            PositioningService.viewportObserver.unobserve(element);
        }
    }

    static releaseAll() {
        for (const element of PositioningService.elements.keys()) {
            PositioningService.release(element);
        }
    }

    /** PositioningService will refresh the positioning of the given element */
    static refresh(element: HTMLElement): void {
        if (PositioningService.elements.has(element)) {
            PositioningService.clearStyles(element);
            PositioningService.fixElementPosition(element);
        }
    }

    static refreshAll() {
        for (const element of PositioningService.elements.keys()) {
            PositioningService.refresh(element);
        }
    }

    /** Update the positioning options of a currently positioned element */
    static reposition(element: HTMLElement, newOptions: PositioningOptions): void {
        if (PositioningService.elements.has(element)) {
            const options = PositioningService.elements.get(element);
            PositioningService.elements.set(element, Object.assign(options, newOptions));
            PositioningService.refresh(element);
        }
    }

    on(eventName, handler) {
        PositioningService.on(eventName, handler);
    }

    off(eventName, handler) {
        PositioningService.off(eventName, handler);
    }

    once(eventName, handler) {
        PositioningService.once(eventName, handler);
    }

    isPositioned(element: HTMLElement): boolean {
        return PositioningService.isPositioned(element);
    }

    getPositioningOptions(element: HTMLElement): PositioningOptions {
        return PositioningService.getPositioningOptions(element);
    }

    position(element: HTMLElement, options: PositioningOptions = {}) {
        PositioningService.position(element, options);
    }

    release(element: HTMLElement) {
        PositioningService.release(element);
    }

    releaseAll() {
        PositioningService.releaseAll();
    }

    refresh(element: HTMLElement) {
        PositioningService.refresh(element);
    }

    refreshAll() {
        PositioningService.refreshAll();
    }

    reposition(element: HTMLElement, newOptions: PositioningOptions) {
        PositioningService.reposition(element, newOptions);
    }
}
