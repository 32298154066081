import { DSExamples } from "@spscommerce/ds-shared";
import { code } from "@spscommerce/utils";

export const SpsModalExamples: DSExamples = {
    sizes: {
        label: "Sizes",
        examples: {
            sizes: {
                react: code`
                    function Component () {
                        var smallModal;
                        const [open, setOpen] = React.useState(false);
                        const [size, setSize] = React.useState("default");
                        function openModal(size){
                            if(size) setSize(size);
                            setOpen(s=> !s);
                        }
                        function handleClose() {
                            setOpen(false)
                        }
                        return (
                            <SpsCard>
                                <SpsModal
                                    kind={'default'}
                                    id={"Basic_modal"}
                                    isOpen={open}
                                    header="This is modal header"
                                    onClose={handleClose}
                                    size={size}
                                >
                                    <SpsModalBody>"This is the body of small modal"</SpsModalBody>
                                    <SpsModalFooter />
                                </SpsModal>
                                <SpsButton onClick={e=>{openModal('small')}}>Small Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('default')}}>Default Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('large')}}>Large Modal</SpsButton>
                            </SpsCard>
                        );
                    }
                `
            }
        }
    },
    kinds: {
        label: "Styles",
        examples: {
            kinds: {
                react: code`
                    function Component () {
                        var smallModal;
                        const [open, setOpen] = React.useState(false);
                        const [kind, setKind] = React.useState("default");
                        function openModal(modalKind){
                            if(modalKind) setKind(modalKind);
                            setOpen(s=> !s);
                        }
                        function handleClose() {
                            setOpen(false)
                        }
                        return (
                            <SpsCard>
                                <SpsModal
                                    kind={kind}
                                    id="sucess-modal"
                                    isOpen={open}
                                    header="This is modal header"
                                    onClose={handleClose}
                                >
                                    <SpsModalBody>"This is the body of small modal"</SpsModalBody>
                                    <SpsModalFooter />
                                </SpsModal>
                                <SpsButton  id="sucess-modal-btn" onClick={e=>{openModal('success')}}>Success Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('warning')}}>Warning Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('delete')}}>Delete Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('info')}}>Info Modal</SpsButton>
                                <SpsButton onClick={e=>{openModal('tip')}}>Tip Modal</SpsButton>
                            </SpsCard>
                        );
                    }
                `
            }
        }
    },
    actionRequired: {
        label: "Action",
        description: code`
            <p>When <code>actionRequired</code> is passed in, the user must
            click one of the buttons defined by the <code>actions</code> prop.
            They cannot dismiss the modal by pressing ESC or clicking an "x"
            button in the titlebar of the modal.</p>
        `,
        examples: {
            actionRequired: {
                react: code`
                        function Component () {
                            const [open, setOpen] = React.useState(false);
                            const [num, setNum] = React.useState(0);
                            function openModal(){
                                setOpen(s=> !s);
                            }
                            function handleClose() {
                                setOpen(false)
                            }
                            function increment() {
                                setNum(num => num + 1);
                            }
                            return (
                                <SpsCard>
                                    <SpsModal
                                        kind={'default'}
                                        actionRequired
                                        id="action-required-modal"
                                        isOpen={open}
                                        header="This is modal header"
                                        onClose={handleClose}
                                    >
                                    <SpsModalBody>"This is the body of modal"</SpsModalBody>
                                        <SpsModalFooter>
                                            <SpsModalAction kind="default">Cancel</SpsModalAction>
                                            <SpsModalAction
                                            kind="confirm"
                                            onClick={() => {
                                                increment();
                                            }}
                                            >
                                            Increase
                                            </SpsModalAction>
                                        </SpsModalFooter>
                                    </SpsModal>
                                    <SpsButton  id="action-required-modal-btn" onClick={e=>{openModal()}}>Open "{num}"</SpsButton>
                                </SpsCard>
                            );
                        }
                    `
            }
        }
    },
    stayOpen: {
        label: "Stay Open",
        description: code`
            <p>If the handler for a modal action returns false, the modal will
            remain open rather than the default behavior of closing.</p>
        `,
        examples: {
            stayOpen: {
            react: code`
                function Component () {
                    const [open, setOpen] = React.useState(false);
                    function openModal(){
                        setOpen(s=> !s);
                    }
                    function handleClose() {
                        setOpen(false)
                    }
                    return (
                        <SpsCard>
                            <SpsModal
                                kind={'default'}
                                id="stay-open-modal"
                                isOpen={open}
                                header="This is modal header"
                                onClose={handleClose}
                                actionRequired={true}
                            >
                                <SpsModalBody>"This is the body of modal"</SpsModalBody>
                                <SpsModalFooter>
                                    <SpsModalAction kind="default">Cancel</SpsModalAction>
                                    <SpsModalAction kind="key" onClick={() => false}>Ok</SpsModalAction>
                                </SpsModalFooter>
                            </SpsModal>
                            <SpsButton id="stay-open-modal-btn" onClick={e=>{openModal()}}>Open Modal</SpsButton>
                        </SpsCard>
                    );
                }`
            }
        }
    },
    asyncAction: {
        label: " Async Action",
        description: code`
            <p>If an action's handler returns a Promise, the modal will wait
            for it to resolve before closing.</p>
        `,
        examples: {
            asyncAction: {
                react: code`
                    function Component () {
                        const [open, setOpen] = React.useState(false);
                        function openModal(){
                            setOpen(s=> !s);
                        }
                        function handleClose() {
                            setOpen(false)
                        }
                        return (
                            <SpsCard>
                                <SpsModal
                                    kind={'default'}
                                    id={"increment_modal"}
                                    isOpen={open}
                                    header="This is modal header"
                                    onClose={handleClose}
                                >
                                    <SpsModalBody>"This is the body of modal"</SpsModalBody>
                                    <SpsModalFooter>
                                        <SpsModalAction kind="default">Cancel</SpsModalAction>
                                        <SpsModalAction kind="key" onClick={() => {
                                            return new Promise(resolve => {
                                            window.setTimeout(resolve, 3000);
                                            });
                                        }}
                                        >Action for 3s</SpsModalAction>
                                    </SpsModalFooter>
                                </SpsModal>
                                <SpsButton onClick={e=>{openModal()}}>Open Modal</SpsButton>
                            </SpsCard>
                        );
                    }
                `
            }
        }
    },
    form: {
        label: "Form",
        description: code`
            <p>It is possible - albeit generally inadvisable - to put a small
            form inside of a modal.</p>
        `,
        examples: {
            asyncAction: {
                react: code`
                    function Component() {
                        const form = useForm(
                            formGroup({
                            name: formControl("Default Value")
                        })
                        )
                        const [open, setOpen] = React.useState(false);
                        function openModal(){
                            setOpen(s=> !s);
                        }
                        function handleClose() {
                            setOpen(false)
                        }
                    return (
                        <SpsCard>
                            <SpsModal
                                kind={'default'}
                                id={"form_modal"}
                                isOpen={open}
                                header="This is modal header"
                                onClose={handleClose}
                                actionRequired={true}
                            >
                                <SpsModalBody>
                                    <div className="sps-row">
                                        <div className="col-12">
                                            <SpsForm formGroup={form}>
                                                <SpsLabel for={form.get("name")}>Name</SpsLabel>
                                                <SpsTextInput formControl={form.get("name")} />
                                            </SpsForm>
                                        </div>
                                    </div>
                                </SpsModalBody>
                                <SpsModalFooter>
                                    <SpsModalAction>OK</SpsModalAction>
                                </SpsModalFooter>
                            </SpsModal>
                            <SpsButton id="async-action-modal-btn" onClick={e=>{ openModal()}}>Open Modal</SpsButton>
                        </SpsCard>
                    )
                } `
            }
        }
    },
};
